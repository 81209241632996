import { AppLoading,
	// SplashScreen
} from 'expo';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import React, { useState, useEffect } from 'react';
import { ActivityIndicator, SafeAreaView,
	// AsyncStorage,
	Animated, Platform, StatusBar, Image, StyleSheet, View, ImageBackground } from 'react-native';

import { useFonts } from '@use-expo/font';

import AsyncStorage from '@react-native-community/async-storage';

import * as SplashScreen from 'expo-splash-screen';

import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { persistStore, persistCombineReducers, } from 'redux-persist'

import { connect, useDispatch } from 'react-redux';

import thunk from 'redux-thunk';

import { PersistGate } from 'redux-persist/integration/react'

import firebase from 'firebase/app';
import 'firebase/auth';

import * as reducers from './store/reducers/parents';
import * as kidReducers from './store/reducers/kids';
import auth from './store/reducers/auth';

import * as authActions from './store/actions/parents/authActions';

import AppNavigator from './navigation/AppNavigator';

import config from './constants/config';

console.log('AsyncStorage ', AsyncStorage);

// const auth = firebase.auth

// if (!__DEV__) {
// 	console.log = () => { }
// }


console.log('GLOBAL LOCATION', global.location)

var firebaseConfig = {
	apiKey: config.apiKey,
	authDomain: config.authDomain,
	databaseURL: config.databaseURL,
	projectId: config.projectId,
	storageBucket: config.storageBucket,
	messagingSenderId: config.messagingSenderId,
	appId: config.appId,
	...config.measurementId ? { measurementId: config.measurementId } : null
};

firebase.initializeApp(firebaseConfig);
// if (location.hostname === "localhost") {
// 	console.log('LOCALOHST')
// 	var firebaseConfig = {
// 	  // Point to the RTDB emulator running on localhost.
// 	  // In almost all cases the ns (namespace) is your project ID.
// 	  databaseURL: "http://localhost:9000?ns="+config.projectId
// 	}

// 	var myApp = firebase.initializeApp(firebaseConfig);
// 	var db = myApp.database();
//   }
//   else {

// 	var firebaseConfig = {
// 		apiKey: config.apiKey,
// 		authDomain: config.authDomain,
// 		databaseURL: config.databaseURL,
// 		projectId: config.projectId,
// 		storageBucket: config.storageBucket,
// 		messagingSenderId: config.messagingSenderId,
// 		appId: config.appId
// 	};
// 	firebase.initializeApp(firebaseConfig);
//   }


function saveUser(user) {
	const dispatch = useDispatch()
	dispatch(authActions.login({user:user}))

}


firebase.auth().onAuthStateChanged(function (user) {
	if (user) {
		console.log("SIGNED IN", user)
		// saveUser
		// User is signed in.
	} else {
		console.log("SIGNED OUT")
	}
});

const persistConfig = {
	key: 'root',
	storage: AsyncStorage,
	// stateReconciler: autoMergeLevel2,
}
const reducer = combineReducers(reducers, kidReducers, auth);

// persistCombineReducers
const persistedReducer = persistCombineReducers(persistConfig, { ...reducers, ...kidReducers, auth })

const appReducer = combineReducers({
	/* your app’s top-level reducers */
})

const rootReducer = (state, action) => {
	if (action.type === 'USER_LOGOUT') {
		state = undefined
	}

	return persistedReducer(state, action)
}

let store = applyMiddleware(thunk)(createStore)(rootReducer)
let persistor = persistStore(store)


export default function App(props) {
	const [isLoadingComplete, setLoadingComplete] = useState('splash11');

	let [fontsLoaded] = useFonts({
		// 'Inter-SemiBoldItalic': 'https://rsms.me/inter/font-files/Inter-SemiBoldItalic.otf?v=3.12',
		'quicksand-bold': require('./assets/fonts/Quicksand_Bold.otf'),
		'quicksand-regular': require('./assets/fonts/Quicksand_Book.otf'),
		'strawberry': require('./assets/fonts/Strawberry_Blossom.ttf'),
		'Carmen Sans Regular': require('./assets/fonts/carmen-sans/Carmen_Sans_Regular.otf'),
		'Carmen Sans Bold': require('./assets/fonts/carmen-sans/Carmen_Sans_Bold.otf'),
		// 'quicksand-bold': require('./assets/fonts/Quicksand_Bold_Oblique.otf'),
		// 'quicksand-regular': require('./assets/fonts/Quicksand_Book.otf'),
		// 'strawberry': require('./assets/fonts/Strawberry_Blossom.ttf'),
	  });

	// useEffect(() => {

	// 	SplashScreen.preventAutoHide();
	// 	loadResourcesAsync() // ask for resources
	// 		.then(() => handleFinishLoading(setLoadingComplete)) // mark resources as loaded
	// 	// loadResourcesAsync().then(()=>{handleFinishLoading(setLoadingComplete)})
	// 	// setTimeout(() => {handleFinishLoading(setLoadingComplete)}, 3000)
	// }, []);

	// if (isLoadingComplete === 'splash') {
	// 	return (null);
	// }
	// if (isLoadingComplete === 'animated') {
	// 	return <AppLoading
	// 			startAsync={loadResourcesAsync}
	// 			// startAsync={()=>{setTimeout(loadResourcesAsync, 3000)}}
	// 			onError={handleLoadingError}
	// 			onFinish={() => handleFinishLoadingFinal(setLoadingComplete)}
	// 		/>
	// }
	if (!fontsLoaded) {
		console.log('not loaded', fontsLoaded)
		return <AppLoading />;
	  } else {
		console.log('loaded!!!!!!!', fontsLoaded)
		SplashScreen.hideAsync()

		return (
			<Provider store={store}>
				{/*<CounterApp />*/}
				<PersistGate loading={<ActivityIndicator></ActivityIndicator>}
					// loading={<Image style={{width: '100%', height: '100%'}} source={require('../../assets/images/unsplash.png')} />}
					persistor={persistor}>
					<View style={styles.container}>
						{Platform.OS === 'ios' && <StatusBar barStyle="default" />}
						<SafeAreaView style={{flex: 1}}>
							<AppNavigator />
						</SafeAreaView>
					</View>
				</PersistGate>
			</Provider>
		);
	}
}



async function loadResourcesAsync() {
	// const images = [require('./assets/Background_splash.png'), require('./assets/Pennygem_Logo_Gem.png')]
	await Promise.all([
		// Asset.loadAsync([
		// 	require('./assets/Background_splash.png'),
		// 	require('./assets/Pennygem_Text_White.png'),
		// 	require('./assets/Pennygem_Logo_Gem.png'),
		// 	require('./assets/Avatars/icon_1.png'),
		// 	require('./assets/Avatars/icon_2.png'),
		// 	require('./assets/Avatars/icon_3.png'),
		// 	require('./assets/Avatars/icon_4.png'),
		// 	require('./assets/Avatars/icon_5.png'),
		// ]),
		// images.map(image => Asset.fromModule(image).downloadAsync()),
		Font.loadAsync({
			// This is the font that we are using for our tab bar
			// ...Ionicons.font,
			// We include SpaceMono because we use it in HomeScreen.js. Feel free to
			// remove this if you are not using it in your app
			'quicksand-bold': require('./assets/fonts/Quicksand_Bold.otf'),
			'quicksand-regular': require('./assets/fonts/SpaceMono-Regular.ttf'),
			'strawberry': require('./assets/fonts/Strawberry_Blossom.ttf'),
		}),
	]);
}


console.disableYellowBox = true;

function handleLoadingError(error) {
	// In this case, you might want to report the error to your error reporting
	// service, for example Sentry
	console.warn(error);
}

function handleFinishLoading(setLoadingComplete) {
	// setTimeout(()=>setLoadingComplete('animated'),3000)
	setLoadingComplete('animated');
	console.log('isLoadingComplete yseeffect')
	console.log('test2')

	// console.log(isLoadingComplete)

	// SplashScreen.hide();

}

function handleFinishLoadingFinal(setLoadingComplete) {
	setTimeout(() => setLoadingComplete('animated1'), 2000)

	// setLoadingComplete('animated1');
	console.log('isLoadingComplete yseeffect')
	// console.log(isLoadingComplete)

	// SplashScreen.hide();

}


const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: 'white',
	},
});
