/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
    Animated,
    Dimensions,
    Image,
    ImageBackground,
    StyleSheet,
    ScrollView,
    View,
    Platform,
    TouchableOpacity,
} from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome';

import Text from '../CustomText'
import Colors from '../../constants/Colors';
import globalStyle, { defaultStyles } from '../../constants/style';
import { formatNumberFloat } from '../../helpers/formatNumber';

const chequingElements = [
    { title: 'income', image: require('../../assets/Icons/Income.png') },
    { title: 'expense', image: require('../../assets/Icons/Expense.png') },
    { title: 'saved', image: require('../../assets/Icons/Saved.png') }]

function formatInputValue(str) {
    return (str || str === 0) ? str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".") : ''
}


class Balance extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            type: 'Chequing',
        };
    };


    parseInt(value) {
        return value !== '' ? parseInt(value) : 0
    }


    parseFloat(value) {
        return value ? parseFloat(value) : 0
    }

    render() {
        const { balance, movement, layout } = this.props;
        const vmin = Math.min(...[layout.width, layout.height]);

        return <View style={{ marginBottom: 5 }}>
            <Text style={[{}]}>
                Current balance:  <Text style={[{ color: Colors.green1 }, globalStyle.h2, defaultStyles.bold]}>${balance ? this.parseFloat(balance).toFixed(2) : 0}</Text>
            </Text>
            <View></View>
            {/* <View style={{
                flexDirection: 'row', padding: 5, borderRadius: 10, maxHeight: 30, paddingHorizontal: 20,
                backgroundColor: 'rgba(255,255,255,0.7)', borderWidth: 2, borderColor: 'white'
            }}>
                <Image resizeMode={'contain'} style={{ width: 20, height: 20, alignSelf: 'center' }}
                    source={movement >= 0 ? require('../../assets/Up.png') : require('../../assets/Down.png')} />
                <View style={{ height:20, justifyContent:"center",alignItems:'center'}}><Text style={{ color: '#106433',  }}>${this.parseFloat(movement).toFixed(2)}</Text></View>
            </View> */}
        </View>

    }
}


export function Movement({ value }) {
    return <View>
        <Text style={[{ justifyContent: 'center' }]}>
            Movement: <Icon style={{ padding: 0, }} name={value >= 0 ? "arrow-up" : 'arrow-down'} size={20} color={value >= 0 ? Colors.green1 : Colors.red} />{' '}
            <Text style={defaultStyles.bold}>${formatNumberFloat(value)}</Text>
        </Text>
    </View>
}


export function Interest({ interest, rate }) {
    return <View>
        <View style={{}}>
            <Text style={[{ marginBottom: 5 }]}>Interest earned: <Text style={[defaultStyles.bold, globalStyle.h4]}>${interest ? parseFloat(interest).toFixed(2) : '0.00'}</Text></Text>
            <View style={{}}>
                <Text style={{}}>Interest rate: {rate ? parseInt(rate).toFixed(2) : '0.00'}%</Text>
            </View>
        </View>
    </View>
}

export function ChequingTotalActivity(props) {
    // const imageSize = Platform.OS==='web'?'2.5em': 45
    const imageSize = 38

    return <View style={{ flexDirection: 'row', width: '90%', maxWidth: 600, alignSelf: 'center', justifyContent: 'space-between', marginTop: 10, marginBottom: 20 }}>
        {chequingElements.map((item, index) => {
            return <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', }}>
                <Image resizeMode={'contain'} style={{ width: imageSize, height: imageSize, }} source={item.image} />
                <View style={{ justifyContent: 'space-between', marginLeft: 5 }}>
                    <Text style={[defaultStyles.bold]}>${props[item.title] ? parseFloat(props[item.title]).toFixed(2) : 0}</Text>
                    {/* <Text style={{ }}>${this.parseFloat(item).toFixed(2)}</Text> */}
                    <Text style={{}}>{item.title[0].toUpperCase() + item.title.slice(1)}</Text>
                </View>
            </View>
        })}
    </View>
}

const styles = StyleSheet.create({

});

export default Balance
