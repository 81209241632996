/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import {
    ActivityIndicator,
    Animated,
    AsyncStorage,
    Image,
    FlatList,
    ScrollView,
    StyleSheet,
    TextInput,
    View,
    // Picker,
    Platform,
    TouchableOpacity,
} from 'react-native';

import Slider from 'rc-slider';
import Icon from 'react-native-vector-icons/FontAwesome';

import moment from 'moment';


import * as firebase from 'firebase'
import 'firebase/firestore';
import 'firebase/database';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { Picker } from '@react-native-community/picker';

import { useHover, useFocus, useActive, useDimensions, Resizable, Hoverable } from 'react-native-web-hooks';

import Text from '../components/CustomText'

import TouchableNativeFeedback from '../components/TouchableFeedback';
import Dropdown, { dropdown, dropdownParent, handleScroll, DropdownElement } from '../components/Dropdown/Dropdown';
import ParentComponentHeader from '../components/ComponentHeader';
import { ConfirmationView } from '../components/confirmation';
import { Button } from '../components/Buttons';
import StoreChildren from '../components/StoreChildren';
import { AddChildren } from '../components/store/SelectChildren';
import ProductSearch from '../components/store/ProductSearch';
import FeaturedItems from '../components/store/FeaturedItems';
import { ParentStoreCard as StoreCard } from '../components/store/StoreCard';
import StoreHeader from '../components/store/StoreHeader';


import * as storeActions from '../store/actions/parents/storeActions'
import * as authActions from '../store/actions/parents/authActions'
import * as goalsActions from '../store/actions/parents/goalsActions'
import Colors from '../constants/Colors';
import globalStyles, { defaultStyles } from '../constants/style';
import storeJson from '../constants/store.json';
// import Dropdown from '../components/Dropdown/Dropdown.web';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const side_bar_items = [
    { title: 'All', image: require('../assets/Store/accessories.png') },
    { title: 'Beauty', image: require('../assets/Store/beauty.png') },
    { title: 'Sports', image: require('../assets/Store/sport.png') },
    { title: 'Arts', image: require('../assets/Store/arts.png') },
    { title: 'Accessories', image: require('../assets/Store/accessories.png') },

    { title: 'Toys', image: require('../assets/Store/toys.png') },
    { title: 'Electronics', image: require('../assets/Store/electronics.png') },
    { title: 'Activities', image: require('../assets/Store/activities.png') },
    { title: 'Food', image: require('../assets/Store/food.png') },
    // { title: 'Donations', image: require('../assets/Store/donations.png') },
]

const sortCategories = { 'new': 'Most popular', 'priceLow': 'Price Low to High', 'priceHigh': 'Price High To Low' }

const categories = ['Toys', 'Beauty', 'Electronics', 'Sports', 'Activities', 'Arts & Crafts', 'Food', 'Accessories', 'Donations', 'Other']

// title, price, itemId,
function formatStoreData(item) {
    return { ...item, image: item.imageurl[0], title: item.productname[0], price: item.price[0]._, new: true, description: item.description[0].long[0] }
}

function Search({ value, onChange, onSubmitEditing }) {
    const [text, setText] = useState('')

    return <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <TouchableNativeFeedback onPress={onSubmitEditing}>
            <Image style={{ height: 20, width: 20, }} source={require('../assets/Search.png')} />
        </TouchableNativeFeedback>
        <TextInput
            onChangeText={text => { onChange(text); }}
            // onChange={text => { console.log('text ', text); onChange(text.target.value)} }
            onSubmitEditing={onSubmitEditing}
            // value={value}
            style={[{
                justifyContent: 'center', paddingLeft: 10, maxWidth: 650, height: 40, width: '100%',
                ...Platform.OS === 'web' ? { outlineWidth: 0, outlineStyle: 'none' } : {},
                fontFamily: 'Carmen Sans Regular', color: Colors.black,
                // borderWidth: 3, borderRadius: 20, borderColor: Colors.gray,
            }]}
            multiline={false}
            placeholderTextColor={Colors.black}
            placeholder={'Search Item'}
        />
    </View>

}

const SliderComponent = ({ title, values, onChange, minValue, maxValue, centered, color, onAfterChange }) => {
    return <View style={{
        zIndex: 10, marginRight: 20,
        width: 270, paddingVertical: 10, marginLeft: centered ? 0 : 20,
    }}>
        <View style={{ marginBottom: 10 }}><Text style={{ color: color ? color : 'black', textAlign: centered ? 'center' : 'left', ...defaultStyles.bold, }}>{title}</Text></View>
        <View style={{ width: '90%', marginTop: 5, left: '2%' }}>
            <Range defaultValue={[minValue, maxValue]}
                onChange={onChange}
                onAfterChange={onAfterChange}
                min={minValue} max={maxValue}
                handle={() => null}
                // ariaLabelGroupForHandles={['123','345']}
                formatLabel={[label => '$' + label, label => '$' + label]}
                style={{ height: 14, paddingVertical: 5, width: '100%', borderRadius: 6 }}
                railStyle={{ position: 'absolute', width: '100%', backgroundColor: '#e9e9e9', height: 4, borderRadius: 6, marginTop: 1 }}
                handleStyle={{
                    position: 'absolute', width: 14, height: 14, marginTop: -5, borderRadius: '50%', border: `solid 2px ${Colors.green1}`,
                    backgroundColor: Colors.green1
                }}
                trackStyle={[{ position: 'absolute', left: 0, height: 4, borderRadius: 6, marginTop: 1, backgroundColor: Colors.green1 },
                { position: 'absolute', left: 0, height: 4, borderRadius: 6, marginTop: 1, backgroundColor: Colors.green1 },
                { position: 'absolute', left: 0, height: 4, borderRadius: 6, marginTop: 1, backgroundColor: Colors.green1 },
                { position: 'absolute', left: 0, height: 4, borderRadius: 6, marginTop: 1, backgroundColor: Colors.green1 }
                ]}
                marksStyle={[{ height: 200, width: 100, position: 'absolute', backgroundColor: 'red', color: 'red' }]}
                tipFormatter={value => ''}
                ariaValueTextFormatterGroupForHandles={[value => { return '$' + value }]}
                aria-valuenow={'123'}
            />

        </View>
        <View style={{ flexDirection: 'row', marginTop: 5, width: '90%', }}>
            <View style={{ marginRight: 5, position: 'absolute', left: (values[0] / (maxValue - minValue)) * 100 + '%' }}>
                <Text style={{ color: Colors.green1, }}>{values[0]}</Text>
            </View>
            <View style={{ marginRight: 5, position: 'absolute', left: (values[1] / (maxValue - minValue)) * 100 + '%' }}>
                <Text style={{ color: Colors.green1, }}>{values[1]}</Text>
            </View>
        </View>
    </View>
}


const CategoryContainer = React.forwardRef(({ title, image, onPress, isFocused }, ref) => {
    const [animation, setAnimation] = useState(new Animated.Value(0));
    const { isHovered } = useHover(ref);

    useEffect(() => {
        Animated.timing(animation, {
            toValue: isHovered ? 1 : 0,
            duration: 200,
        }).start();
    });

    return <TouchableNativeFeedback onPress={() => { onPress(); }}>
        <View style={{ alignItems: 'center', flexDirection: 'row', margin: 10 }}>

            {/* <View style={{height:30, width:30, backgroundColor:'yellow'}}></View> */}
            <View style={{ height: 20, width: 20, borderRadius: 5, borderColor: Colors.green1, borderWidth: 2, justifyContent: 'center', alignItems: 'center', marginRight: 5 }}>
                <Icon style={{ zIndex: 3, opacity: isFocused ? 1 : 0 }} name="check" size={16} color={Colors.green1} />
            </View>
            <Text style={{ marginTop: 3, alignSelf: 'center', textAlign: 'center', ...defaultStyles.bold, }}>{title}</Text>
        </View>
    </TouchableNativeFeedback>
})

const ButtonContainerMobile = ({ title, image, onPress, isFocused, width }) => {
    const vmin = 1000;
    const [animation, setAnimation] = useState(new Animated.Value(0));
    const [focused, setFocus] = useState(false);

    let scale = animation.interpolate({
        inputRange: [0, 1],
        outputRange: [1, 1.1]
    })


    return <TouchableNativeFeedback onPress={onPress}>
        <View style={{ marginVertical: 15, alignItems: 'center', justifyContent: 'center', width: width * 0.3 }}>
            <View style={{ height: 20, width: 20, borderRadius: 5, borderColor: Colors.gray1, borderWidth: 2, justifyContent: 'center', alignItems: 'center' }}>
                <Icon style={{ zIndex: 3, opacity: isFocused ? 1 : 0 }} name="check" size={16} color={Colors.gray1} />
            </View>
            <Text style={{ marginTop: 5, alignSelf: 'center', textAlign: 'center', }}>{title}</Text>
        </View>
    </TouchableNativeFeedback>

}

const sortValues = { 'Most popular': 'new', 'Newest First': 'new', 'Price Low to High': 'priceLow', 'Price High To Low': 'priceHigh' }

function raitingSelectionBlock() {


    function raitingSelected(num) {
        if (this.state.raitingSelected == num)
            return;

        const { type, sort, categories, search, age, minPrice, maxPrice } = this.state;
        const { childIds } = this.props.parent;

        this.props.actions.getStoreItems({ rating: num, sort, type, filter: categories, ages: age, prices: [minPrice, maxPrice], page: 0 })
            .then(data => this.setState({
                storeData: data.hits, data: data.hits, storePage: data.page, storePages: data.nbPages, storeItems: data.nbHits,
                storeKids: childIds.reduce((curr, item) => { return { ...curr, [item]: false, } }, {})
            }))

        this.setState({ raitingSelected: num })
    };

    return (<View style={{ marginLeft: 5 }}>
        <Text style={{ marginTop: 10, marginLeft: 3, ...defaultStyles.bold, }}>Raiting</Text>

        <View style={{ flexDirection: 'row' }}>
            <TouchableNativeFeedback style={{ margin: 3 }} onPress={() => { raitingSelected.call(this, 1) }}>
                <Image resizeMode={"cover"} style={{ width: 40, height: 40 }} source={require('../assets/Pennygem_Logo.png')} />
            </TouchableNativeFeedback>

            <TouchableNativeFeedback style={{ margin: 3 }} onPress={() => { raitingSelected.call(this, 2) }}>
                <Image resizeMode={"cover"} style={{ width: 40, height: 40, opacity: this.state.raitingSelected < 2 ? 0.5 : 1 }} source={require('../assets/Pennygem_Logo.png')} />
            </TouchableNativeFeedback>

            <TouchableNativeFeedback style={{ margin: 3 }} onPress={() => { raitingSelected.call(this, 3) }}>
                <Image resizeMode={"cover"} style={{ width: 40, height: 40, opacity: this.state.raitingSelected < 3 ? 0.5 : 1 }} source={require('../assets/Pennygem_Logo.png')} />
            </TouchableNativeFeedback>

            <TouchableNativeFeedback style={{ margin: 3 }} onPress={() => { raitingSelected.call(this, 4) }}>
                <Image resizeMode={"cover"} style={{ width: 40, height: 40, opacity: this.state.raitingSelected < 4 ? 0.5 : 1 }} source={require('../assets/Pennygem_Logo.png')} />
            </TouchableNativeFeedback>

            <TouchableNativeFeedback style={{ margin: 3 }} onPress={() => { raitingSelected.call(this, 5) }}>
                <Image resizeMode={"cover"} style={{ width: 40, height: 40, opacity: this.state.raitingSelected < 5 ? 0.5 : 1 }} source={require('../assets/Pennygem_Logo.png')} />
            </TouchableNativeFeedback>
        </View>
    </View>)
}

const Rating = ({ rating }) => {

    rating = rating > 2 ? rating : 3
    return <View style={{ flexDirection: 'row' }}>
        {[...new Array(Math.round(rating))].map((item, index) => <View>
            <Image resizeMode={"cover"} style={{ width: 30, height: 30, opacity: 1 }} source={require('../assets/Pennygem_Logo.png')} />
        </View>)}
    </View>
}

class Shop extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
            headerMode: 'none',
            headerStyle: {
                zIndex: 2,
                // backgroundColor:'white',
                borderWidth: 0,
                borderBottomWidth: 0,
                shadowColor: 'transparent',
                elevation: 0,
                boxShadow: '',
                shadowOpacity: 0,
            }
        }
    }

    constructor(props) {
        super(props);
        // this.picker = React.createRef() ;
        this.picker = null


        this.state = {
            data: [],
            storeData: [],
            storePage: 0, storePages: 1, storeItems: 0,
            apiData: [],
            apiPage: 1, apiPages: 1, apiResults: 0,

            isLoading: true,
            type: 'All',

            modalVisible: false,
            settingsMobileVisible: false,
            sortButtons: [true, false, false],
            categories: {},
            tempCategories: {},
            scrolledHeader: new Animated.Value(0),
            scrolledPadding: new Animated.Value(0),
            raitingSelected: 0,
            age: [0, 18],
            sortDropdown: false,
            id: 0,
            sortBy: 'new',
            itemCategory: 'Select category',
            showing: 'all',
            stage: 0,
            storeKids: {},
            storeItemUrl: '',
            storeItemAge: [0, 18],
            search: '',
            childItems: 0
        };
    };



    async componentDidMount() {
        const { childIds } = this.props.parent;
        const { type, categories, age, minPrice, maxPrice } = this.state;

        let data = await this.props.actions.getStoreItems({ sort: 'Most popular', type, filter: categories, ages: age, prices: [minPrice, maxPrice], page: 0 })
        this.props.actions.getFeaturedStoreItems()

        this.setState({
            storeData: data.hits, data: data.hits, storePage: data.page, storePages: data.nbPages, storeItems: data.nbHits,
            storeKids: childIds.reduce((curr, item) => { return { ...curr, [item]: false, } }, {}),
            isLoading: false
        })

        if (!this.props.auth.parentId) {
            // this.props.actions.clearAuth()
            //     .then(() => {
            //         this.props.navigation.navigate('Auth')
            //     })
        }
    }

    componentWillUnmount() {

    }

    defineValue(width, height, a, b, c) {
        if (width < 850 && width < height) {
            return a
        }
        else if (width < 850 && width > height) {
            return b
        }
        else {
            return c
        }
    }

    logout = async () => {
        // this.props.actions.logout();
        await AsyncStorage.clear();
        // this.props.navigation.navigate('Login')
    }

    renderCard({ item, }, layout) {
        const { names, childIds, } = this.props.parent;

        // const itemValue = this.state.data[item]
        const itemValue = item
        const title = itemValue ? itemValue.title : '';
        // console.log(item.price)
        return <StoreCard layout={layout} item={item} auth={this.props.auth} navigation={this.props.navigation}
            addNew={() => {
                this.setState({
                    storeKids: childIds.reduce((curr, item) => { return { ...curr, [item]: false, } }, {}),
                    modalVisible: true, confirmationNewGoal: true, title: title, price: itemValue.price, itemId: itemValue.key,
                    itemKey: item
                })
            }}
            onPress={() => {
                this.setState({
                    storeKids: childIds.reduce((curr, item) => { return { ...curr, [item]: false, } }, {}),
                    modalVisible: true, confirmationGoal: true, title: title, price: itemValue.price, itemId: itemValue.key,
                    itemKey: item
                })
            }} />
    }



    selectChild(move) {
        const { parentId } = this.props.auth;
        const { childIds } = this.props.parent;

        let index = this.state.id + move;
        if (index > childIds.length - 1) { this.setState({ id: 0 }); return 0 }
        if (index < 0) { this.setState({ id: childIds.length - 1 }); return 0 }
        this.setState({ id: index }); return 0
    }

    confirmationGoal(layout) {
        const { parentId, } = this.props.auth;
        const { childIds, names } = this.props.parent;

        const vmin = Math.min(...[layout.width, layout.height]);

        return <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            {/* <Image style={{ width: 70, height: 70, marginTop: '10%' }} source={require('../assets/Goals/Goal_2.png')} /> */}
            <View>
                <Text style={[{ marginTop: '15%', }, defaultStyles.bold, globalStyles.h4]}>Add to store?</Text>
            </View>

            {/* <ParentComponentHeader layout={layout} theme={'light'} containerStyle={{ width: '80%', marginTop: 0.03 * vmin, marginBottom: 0.03 * vmin }}
				title={names[childIds[this.state.id]]}
				pressLeft={() => this.getKidId(-1)}
				pressRight={() => this.getKidId(+1)}
			/> */}
            <AddChildren
                kids={this.state.storeKids}
                names={names}
                setKid={(item) => { this.setState({ storeKids: { ...this.state.storeKids, [item]: !this.state.storeKids[item] } }) }}
                removeKid={(item) => { this.setState({ storeKids: { ...this.state.storeKids, [item]: false } }) }}
            />
            <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', marginHorizontal: 15, }}>
                <View style={{ width: '40%', }}>
                    <TouchableNativeFeedback onPress={() => {
                        Object.keys(this.state.storeKids).filter(item => this.state.storeKids[item]).length ?
                            this.setState({ modalVisible: false, confirmationGoal: false, }) : null;
                        // this.props.actions.addGoal(this.state.price.toString(), this.state.title, parentId, childIds[this.state.id])
                        Object.keys(this.state.storeKids).filter(item => this.state.storeKids[item]).length ?
                            this.props.actions.addToChildStore(parentId, Object.keys(this.state.storeKids).filter(item => this.state.storeKids[item]),
                                'amazon', this.state.itemId) : null
                    }}>
                        <View style={{
                            justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 20,
                            margin: 5, backgroundColor: 'rgba(126,193,61,1)'
                        }}>
                            <Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>YES</Text></View>
                    </TouchableNativeFeedback>
                </View>
                <View style={{ width: '40%', }}>
                    <TouchableNativeFeedback onPress={() => this.setState({ modalVisible: false, confirmationActivate: false })}>
                        <View style={{
                            justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 20, margin: 5,
                            backgroundColor: '#EE5060'
                        }}>
                            <Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>NO</Text></View>
                    </TouchableNativeFeedback>
                </View>
            </View>
        </View>
    }

    confirmationNewGoal(layout) {
        const { itemKey, } = this.state;
        const { parentId, } = this.props.auth;
        const { childIds, names } = this.props.parent;

        const vmin = Math.min(...[layout.width, layout.height]);

        return <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Image style={{ width: 70, height: 70, marginTop: '10%' }} source={require('../assets/Goals/Goal_2.png')} />
            <View>
                <Text style={{ fontSize: 24, color: Colors.gray }}>Add to store?</Text>
            </View>

            {/* <ParentComponentHeader layout={layout} theme={'light'} containerStyle={{ width: '80%', marginTop: 0.03 * vmin, marginBottom: 0.03 * vmin }}
				title={names[childIds[this.state.id]]}
				pressLeft={() => this.getKidId(-1)}
				pressRight={() => this.getKidId(+1)}
			/> */}
            <AddChildren
                kids={this.state.storeKids}
                names={names}
                setKid={(item) => { this.setState({ storeKids: { ...this.state.storeKids, [item]: !this.state.storeKids[item] } }) }}
                removeKid={(item) => { this.setState({ storeKids: { ...this.state.storeKids, [item]: false } }) }}
            />
            <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', marginHorizontal: 15, }}>
                <View style={{ width: '40%', }}>
                    <TouchableNativeFeedback onPress={() => {
                        // this.props.actions.addGoal(this.state.price.toString(), this.state.title, parentId, childIds[this.state.id])
                        console.log('this.state.itemKey ', this.state.itemKey)
                        let { mid, linkurl, imageurl, description, saleprice, merchantname, title, linkid } = itemKey;

                        let id = linkid[0]; let url = linkurl[0]; let category = 'Toys'; let price = saleprice[0]._; let image = imageurl[0]
                        merchantname = merchantname[0]
                        Object.keys(this.state.storeKids).filter(item => this.state.storeKids[item]).length ?
                            this.props.actions.addToStore({
                                parentId, childIds: Object.keys(this.state.storeKids).filter(item => this.state.storeKids[item]),
                                id, url, image, category, description, title, price, merchantname
                            }) : null

                        Object.keys(this.state.storeKids).filter(item => this.state.storeKids[item]).length ?
                            this.setState({ storeData: [...this.state.storeData, { id, url, image, category, description, title, price, merchantname }], confirmationNewGoal: false, modalVisible: false, confirmationGoal: false, }) : null;
                    }}>
                        <View style={{
                            justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
                            margin: 5, backgroundColor: 'rgba(126,193,61,1)'
                        }}>
                            <Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>YES</Text></View>
                    </TouchableNativeFeedback>
                </View>
                <View style={{ width: '40%', }}>
                    <TouchableNativeFeedback onPress={() => this.setState({ confirmationNewGoal: false, modalVisible: false, confirmationActivate: false })}>
                        <View style={{
                            justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10, margin: 5,
                            backgroundColor: '#EE5060'
                        }}>
                            <Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>NO</Text></View>
                    </TouchableNativeFeedback>
                </View>
            </View>
        </View>
    }


    confirmationBuy() {
        const { parentId, } = this.props.auth;
        const { childId } = this.props.parent;

        return <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Image style={{ width: 70, height: 70, marginTop: '10%' }} source={require('../assets/Goals/Goal_2.png')} />
            <View style={{ marginVertical: 15, alignItems: 'center' }}><Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>Activate</Text>
                <Text style={{ fontSize: 20, margin: 5, color: '#5aca3c', }}>Goal</Text>
            </View>
            <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', marginHorizontal: 15, }}>
                <View style={{ width: '40%', }}>
                    <TouchableNativeFeedback onPress={() => {
                        this.setState({ modalVisible: false, confirmationActivate: false });
                        this.props.actions.activateGoal(this.state.confirmGoalId, parentId, childId).then(() => { this.setState({ isLoading: true }); this.props.actions.getGoals(parentId, childId).then(this.setState({ isLoading: false })) })
                    }}>
                        <View style={{
                            justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
                            margin: 5, backgroundColor: 'rgba(126,193,61,1)'
                        }}>
                            <Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>YES</Text></View>
                    </TouchableNativeFeedback>
                </View>
                <View style={{ width: '40%', }}>
                    <TouchableNativeFeedback onPress={() => this.setState({ modalVisible: false, confirmationActivate: false })}>
                        <View style={{
                            justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10, margin: 5,
                            backgroundColor: '#EE5060'
                        }}>
                            <Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>NO</Text></View>
                    </TouchableNativeFeedback>
                </View>
            </View>
        </View>
    }

    settingsMobile(width) {
        // type, filter, ages, prices, search
        const { type, categories, age, search } = this.state;
        return <ScrollView style={{ flex: 1 }}>
            <TouchableNativeFeedback onPress={() => {
                this.setState({ settingsMobileVisible: false, tempCategories: Object.assign({}, this.state.categories) })
            }}>
                <View style={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}>
                    <Text style={{ fontSize: 45, margin: 15, }}>X</Text>
                </View>
            </TouchableNativeFeedback>

            <View style={{ marginLeft: 20 }}>
                <Text>Order</Text>
            </View>
            <View style={{
                width: width * 0.9, alignSelf: "center", flexDirection: 'row',
                marginVertical: 15,
            }}>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', width: width * 0.3, }}>
                    <TouchableNativeFeedback onPress={() => { this.setState({ sortButtons: [true, false, false], type: 'Newest' }) }}>
                        <View elevation={5} style={{
                            backgroundColor: 'white', boxShadow: '2px 2px 15px #272827', height: 80, width: 80, borderRadius: 80,
                            justifyContent: 'center', alignItems: 'center', borderWidth: this.state.sortButtons[0] ? 3 : 0, borderColor: Colors.gray1
                        }}>
                            <Text style={{ textAlign: "center" }}>Newest first</Text>
                        </View>
                    </TouchableNativeFeedback>
                </View>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', width: width * 0.3, }}>
                    <TouchableNativeFeedback onPress={() => { this.setState({ sortButtons: [false, true, false], sortBy: 'priceHigh' }) }}>
                        <View elevation={5} style={{
                            backgroundColor: 'white', boxShadow: '2px 2px 15px #272827', height: 80, width: 80, borderRadius: 80,
                            justifyContent: 'center', alignItems: 'center', borderWidth: this.state.sortButtons[1] ? 3 : 0, borderColor: Colors.gray1
                        }}>
                            <Text style={{ textAlign: "center" }}>Price High to Low</Text>
                        </View>
                    </TouchableNativeFeedback>
                </View>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', width: width * 0.3, }}>
                    <TouchableNativeFeedback onPress={() => { this.setState({ sortButtons: [false, false, true], sortBy: 'priceLow' }) }}>
                        <View elevation={5} style={{
                            backgroundColor: 'white', boxShadow: '2px 2px 15px #272827', height: 80, width: 80, borderRadius: 80,
                            justifyContent: 'center', alignItems: 'center', borderWidth: this.state.sortButtons[2] ? 3 : 0, borderColor: Colors.gray1
                        }}>
                            <Text style={{ margin: 5, textAlign: "center" }}>Price Low to High</Text>
                        </View>
                    </TouchableNativeFeedback>
                </View>
            </View>
            <View style={{ marginLeft: 20 }}>
                <Text>Category</Text>
            </View>
            <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'center', flexWrap: 'wrap', }}>
                {side_bar_items.map((item, index) => {
                    return <ButtonContainerMobile width={width}
                        onPress={() => {
                            console.log('item', item)
                            let newCategories = this.state.tempCategories // Lena's mark
                            if (newCategories[item.title] === true) {
                                delete newCategories[item.title]
                            }
                            else {
                                newCategories[item.title] = true
                            }
                            console.log(newCategories)
                            this.setState({ tempCategories: newCategories })
                        }}
                        isFocused={this.state.tempCategories[item.title]}
                        key={item.title} image={item ? item.image : null} title={item.title} />
                })}
            </View>
            <TouchableNativeFeedback onPress={() => {
                let tmp = this.state.tempCategories;
                this.setState({
                    settingsMobileVisible: false,
                    categories: Object.assign({}, this.state.tempCategories),
                }, () => {
                    // this.props.actions.getStoreItems({ type, filter: categories, ages: age, prices: [minPrice, maxPrice], search, page: 0 })
                    //     .then(res => this.setState({ storeData: res.hits, storePage: res.page, storePages: res.nbPages, storeItems: res.nbHits }))
                    this.props.actions.getStoreItems({ type, filter: categories, ages: age, prices: [this.state.minPrice, this.state.maxPrice], search, page: 0 })
                        .then(res => this.setState({ storeData: res.hits, storePage: res.page, storePages: res.nbPages, storeItems: res.nbHits }))

                })
            }}>
                <View style={{ boxShadow: '2px 2px 15px #272827', margin: 10, alignSelf: 'center', borderRadius: 10, width: 100, padding: 5, backgroundColor: Colors.green1 }}>
                    <Text style={{ color: 'white', textAlign: 'center' }}>Apply</Text>
                </View>
            </TouchableNativeFeedback>
        </ScrollView>
    }

    getKidId(move) {
        const { parentId, } = this.props.auth;
        const { childIds } = this.props.parent;

        const { getJobs } = this.props.actions;
        let index = this.state.id + move;
        if (index > childIds.length - 1) { this.setState({ id: 0 }); return 0 }
        if (index < 0) { this.setState({ id: childIds.length - 1 }); return 0 }
        this.setState({ id: index }); return 0
    }


    getKid = (layout) => {
        const { childIds, names } = this.props.parent;
        const vmin = Math.min(...[layout.width, layout.height]);
        return <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Image style={{ width: 70, height: 70, marginVertical: '3%' }} source={require('../assets/Goals/Goal_2.png')} />
            <View>
                <Text style={{ fontSize: 20, color: Colors.green1 }}>Select kid</Text>
            </View>
            {/* <ParentComponentHeader layout={layout} theme={'light'} containerStyle={{ width: '80%', marginTop: 0.03 * vmin, marginBottom: 0.03 * vmin }}
				title={names[childIds[this.state.id]]}
				pressLeft={() => this.getKidId(-1)}
				pressRight={() => this.getKidId(+1)}
			/> */}
            <AddChildren
                kids={this.state.storeKids}
                names={names}
                setKid={(item) => { this.setState({ storeKids: { ...this.state.storeKids, [item]: !this.state.storeKids[item] } }) }}
                removeKid={(item) => { this.setState({ storeKids: { ...this.state.storeKids, [item]: false } }) }}
            />
            <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', marginHorizontal: 15, }}>
                <View style={{ width: '80%', }}>
                    <TouchableNativeFeedback onPress={() => { this.setState({ stage: 1 }) }}>
                        <View style={{
                            justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
                            margin: 5, backgroundColor: 'rgba(126,193,61,1)'
                        }}>
                            <Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>Next</Text></View>
                    </TouchableNativeFeedback>
                </View>
            </View>
        </View>
    }


    getCategory = (layout) => {
        const { childIds, names } = this.props.auth;
        const vmin = Math.min(...[layout.width, layout.height]);
        return <View style={{ flex: 1, width: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
            <Text style={{ marginVertical: 20, alignSelf: 'center', fontSize: 18 }}>Category</Text>
            <Dropdown elementContainerStyle={{ height: 60, width: '100%', borderTopWidth: 1, borderColor: Colors.green1 }}
                containerStyle={{ zIndex: 7, width: '100%', alignSelf: 'center' }}
                mainElementStyle={{ backgroundColor: 'white' }} mainTextStyle={{ color: Colors.green1, marginLeft: 0, width: '100%', textAlign: 'center' }}
                onPress={(val) => { this.setState({ itemCategory: val }); }} defaultValue={this.state.itemCategory}
                categories={categories}
            />
            <View style={{ flexDirection: 'row', width: '82.5%', justifyContent: 'center', }}>
                <View style={{ width: '100%', }}>
                    <TouchableNativeFeedback onPress={() => {
                        this.state.itemCategory === 'Select category' ? null : this.setState({ stage: this.state.stage + 1 })
                    }}>
                        <View style={{
                            justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
                            margin: 5, backgroundColor: 'rgba(126,193,61,1)'
                        }}>
                            <Text style={{ fontSize: 19, marginVertical: 5, ...defaultStyles.bold, color: 'white' }}>Next</Text></View>
                    </TouchableNativeFeedback>
                </View>
            </View>
        </View>
    }


    getAge = (layout) => {
        const { childIds, names } = this.props.auth;
        const vmin = Math.min(...[layout.width, layout.height]);
        return <View style={{ flex: 1, width: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
            <Text style={{ marginVertical: 20, alignSelf: 'center', fontSize: 18 }}>Age</Text>
            <SliderComponent title={'Age'} values={this.state.storeItemAge} minValue={0} maxValue={18} title={false}
                onChange={(value) => { this.setState({ storeItemAge: value }) }}
                onAfterChange={(value) => { this.setState({ storeItemAge: value }) }}
            />
            <View style={{ flexDirection: 'row', width: '82.5%', justifyContent: 'center', }}>
                <View style={{ width: '100%', }}>
                    <TouchableNativeFeedback onPress={() => { this.setState({ stage: this.state.stage + 1 }) }}>
                        <View style={{
                            justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
                            margin: 5, backgroundColor: 'rgba(126,193,61,1)'
                        }}>
                            <Text style={{ fontSize: 19, marginVertical: 5, ...defaultStyles.bold, color: 'white' }}>Next</Text></View>
                    </TouchableNativeFeedback>
                </View>
            </View>
        </View>
    }

    getApprove = (layout) => {
        const { parentId } = this.props.auth;
        const { type, categories, age, minPrice, maxPrice, search } = this.state;

        const vmin = Math.min(...[layout.width, layout.height]);
        return <View style={{ flex: 1, width: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
            <Text style={{ marginVertical: 20, alignSelf: 'center', fontSize: 18, opacity: 0 }}>0</Text>
            <View><Text style={{ fontSize: 26 }}>Add item?</Text></View>
            <View style={{ flexDirection: 'row', width: '82.5%', justifyContent: 'center', }}>
                <View style={{ width: '40%', }}>
                    <TouchableNativeFeedback onPress={() => {
                        this.setState({ stage: 0 })
                        console.log('this.state.storeItemUrl, this.state.itemCategory', this.state.storeItemUrl, this.state.itemCategory)
                        this.props.actions.parseDataFromUrl(this.state.storeItemUrl, this.state.itemCategory, this.state.storeItemAge)
                            .then((storeItem) => {
                                let childIds = Object.keys(this.state.storeKids).filter(item => this.state.storeKids[item])
                                console.log('storeItem', storeItem)
                                // for (let i in childIds) {
                                this.setState({ itemCategory: 'Select category', storeItemUrl: '' })
                                childIds.length ? this.props.actions.addToChildStore(parentId, childIds, 'amazon', storeItem) : null
                                // }
                                this.props.actions.getStoreItems({ type, filter: categories, ages: age, prices: [minPrice, maxPrice], search })
                            });
                        // this.props.actions.parseDataTest(this.state.storeItemUrl);
                        this.setState({ addStoreItem: false })
                    }}>
                        <View style={{
                            justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
                            margin: 5, backgroundColor: 'rgba(126,193,61,1)'
                        }}>
                            <Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>Yes</Text></View>
                    </TouchableNativeFeedback>
                </View>
                <View style={{ width: '40%', }}>
                    <TouchableNativeFeedback onPress={() => { this.setState({ stage: this.state.stage + 1 }) }}>
                        <View style={{
                            justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
                            margin: 5, backgroundColor: '#EE5060'
                        }}>
                            <Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>No</Text></View>
                    </TouchableNativeFeedback>
                </View>
            </View>
        </View>
    }


    sortKeys(data) {
        let sortable = Object.keys(data).map(item => { return { ...data[item], key: item } })
        // for (var item in data) {
        //     sortable.push([item, data[item]]);
        // }
        console.log('sortable', sortable)
        sortable.sort((a, b) => {
            console.log(a.price, b.price, a.price - b.price)
            return this.state.sortBy === 'priceLow' ? (a.price - b.price) : (b.price - a.price);
            // return a.price - b.price;

        });

        return sortable.map(item => item.key)
    }

    checkAmazonUrl() {
        let pattern = RegExp('(amazon\.(com|ca)(?:\/.*){0,1}(?:\/dp\/|\/gp\/product\/))(.*?)(?:\/.*|$)')
        console.log(123, this.state.storeItemUrl, this.state.storeItemUrl.match(pattern))

        return this.state.storeItemUrl.match(pattern)
    }

    loadMore() {
        const { actions, auth } = this.props;

        const { storePage, storePages, type, categories, search, apiPage, apiPages, apiData, } = this.state;
        console.log('load more ', storePage, storePages)
        if (storePage < storePages) {
            // let newStoreData =
            this.setState({isLoading:true},()=>{
                actions.getStoreItems({ type, filter: categories, prices: [this.state.minPrice, this.state.maxPrice], search, page: storePage + 1 })
                .then(res => this.setState({isLoading: false, storeData: [...this.state.storeData, ...res.hits], storePage: res.page, storePages: res.nbPages, storeItems: res.nbHits }))
            })

        }

        if (search && type !== 'Rated' && auth.type === 0) {
            this.setState({isLoading: true})
            this.props.actions.rakutenSearch({ keyword: search, pagenumber: apiPage + 1 })
                .then(res => {
                    console.log('res load more ', res.items);
                    let obj = res.items.item ? res.items.item.map(item => { return formatStoreData(item) }) : []
                    // let obj = res.items.item && res.items.item.reduce((curr, item) => { return { ...curr, [item.linkid[0]]: formatStoreData(item), } }, {})
                    // console.log('res load more ', arr, typeof arr);

                    this.setState({
                        apiPage: parseInt(res.items.PageNumber[0]), apiData: [...apiData, ...obj],
                        apiPages: parseInt(res.items.TotalPages[0]), apiItems: parseInt(res.items.TotalMatches[0]),
                        isLoading: false
                        // data: [...data, ...obj]
                    })
                })
        }

    }

    firestoreTest() {

    }

    buttonsWidth(numColumns, layout, vmin) {
        if (layout.width < 800) {
            return '90%'
        }
        let blockWidth = layout.width * 0.2 > 300 ? 300 : layout.width * 0.2;
        let margin = vmin * 0.02
        return blockWidth * numColumns + margin * 2 * numColumns
    }

    footer() {
        return this.state.isLoading && <View>
            <ActivityIndicator color={Colors.green1} size={'large'} />
        </View>
    }

    render() {
        const { navigation, store, auth, parent, actions } = this.props;
        const { type, categories, age, search, minPrice, maxPrice, storeData, apiData } = this.state;

        let data = []
        //    [...storeData, ...apiData]
        if (type === 'Rated') {
            data = [...storeData]
        }
        else if (type === 'New') {
            data = [...apiData]
        }
        else {
            data = [...storeData, ...apiData]
        }

        return (
            <Resizable>
                {layout => {
                    const vmin = Math.min(...[layout.width, layout.height]);
                    const width = layout.width;
                    const numColumns = width > 1300 ? 2 : (width > 1000 ? 1 : (width > 850 ? 1 : 1))
                    return <View style={{ flex: 1, backgroundColor: 'white' }}>
                        {<Modal
                            animationIn={'zoomIn'} animationOut={'zoomOut'}
                            animationInTiming={500} animationOutTiming={500}
                            backdropColor={'darkgrey'} backdropOpacity={0.6}
                            onBackdropPress={() => this.setState({
                                modalVisible: false, confirmationDelete: false, confirmationNewGoal: false,
                                confirmationPurchase: false, confirmationActivate: false
                            })}
                            isVisible={this.state.modalVisible}
                            onRequestClose={() => {
                                this.setState({
                                    modalVisible: false, confirmationDelete: false, confirmationNewGoal: false,
                                    confirmationPurchase: false, confirmationActivate: false
                                })
                            }}>
                            <View style={{
                                borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
                                maxWidth: 400, paddingBottom: 10
                            }}>
                                <View style={{ position: 'absolute', top: 0, right: 0, zIndex: 5 }}>
                                    <TouchableNativeFeedback onPress={() => this.setState({
                                    modalVisible: false, confirmationDelete: false, confirmationNewGoal: false,
                                    confirmationPurchase: false, confirmationActivate: false
                                })}>
                                        <View elevation={10} style={{ alignSelf: 'center', padding: 5, margin: 10, }}>
                                            <Image style={{ height: 10, width: 10 }} source={require('../assets/Delete-X.png')} />
                                            {/* <Text style={{ fontSize: closeFontSize, color: Colors.gray0, ...defaultStyles.bold, }}>X</Text> */}
                                        </View>
                                    </TouchableNativeFeedback>
                                </View>
                                <View style={{}}>
                                    {this.state.confirmationGoal ? this.confirmationGoal(layout) : null}
                                    {this.state.confirmationNewGoal ? this.confirmationNewGoal(layout) : null}

                                    {/* {this.state.confirmationBuy ? this.confirmationBuy() : null} */}
                                </View>
                            </View>
                        </Modal>}
                        {<Modal
                            animationIn={'zoomIn'} animationOut={'zoomOut'}
                            animationInTiming={500} animationOutTiming={500}
                            style={{ margin: 0 }}
                            // backdropOpacity={0.6}
                            // onBackdropPress={() => this.setState({settingsMobileVisible:false})}
                            isVisible={this.state.settingsMobileVisible} onRequestClose={() => { this.setState({ settingsMobileVisible: false }) }}>
                            <View style={{ flex: 1, margin: 0, backgroundColor: 'white' }}>
                                {this.settingsMobile(layout.width)}
                            </View>
                        </Modal>}

                        {Platform.OS !== 'web' && this.state.header ? <TouchableNativeFeedback style={{ position: 'absolute', bottom: 10 }}
                            onPress={() => this._scroll.scrollTo({ x: 0, y: 0, animated: true })}>
                            <View elevation={5} style={{
                                position: 'absolute', bottom: layout.height * 0.1, right: 10, width: 40, height: 40, borderRadius: 30, backgroundColor: Colors.green1, zIndex: 10,
                                justifyContent: 'center', alignItems: 'center'
                            }}>
                                <Icon style={{ zIndex: 3, }} name="arrow-up" size={30} color="white" />
                            </View>
                        </TouchableNativeFeedback> : null}

                        <View style={{ flex: 1, width: '100%', height: Platform.OS === 'web' ? '100vh' : '100%' }}>
                            <StoreHeader style={{}} navigation={this.props.navigation} signOut={() => { }} vmin={vmin} layout={layout} clearAuth={this.props.actions.clearAuth} dashboard={false} >
                                <Search style={{ width: '80%' }} value={this.state.search} onChange={(text) => this.setState({ search: text })}
                                    onSubmitEditing={() => {
                                        this.setState({isLoading: true, data: [], storeData: [], apiData: []})
                                        actions.getStoreItems({ type, filter: categories, prices: [minPrice, maxPrice], search, page: 0, })
                                            .then((res) => this.setState({ isLoading: false, storeData: res.hits, storePage: res.page, storePages: res.nbPages, storeItems: res.nbHits }))
                                    }} />
                            </StoreHeader>
                            <ScrollView
                                nestedScrollEnabled={false}
                                ref={ref => this._scroll = ref}
                                {...Platform.OS === 'web' ? { stickyHeaderIndices: [1, 2] } : {}}
                                style={{ flex: 1, zIndex: 3 }}
                                onScroll={(e) => {
                                    let finish = (e.nativeEvent.contentOffset.y + e.nativeEvent.layoutMeasurement.height) / e.nativeEvent.contentSize.height
                                    let threshold = 0.6
                                    console.log('finish ', finish)
                                    if (finish > threshold) {
                                        this.loadMore()
                                    }
                                    // handleScroll.call(this, e, layout.height)
                                }}
                            >

                                {<FeaturedItems layout={layout} getFeaturedStoreItems={actions.getFeaturedStoreItems} featuredData={Object.values(this.props.store.featuredItems)} navigation={navigation} />}

                                {/* <View style={{ width: '90%', alignSelf: 'center' }}>
                                    <Search style={{ width: '80%' }} value={this.state.search} onChange={(text) => this.setState({ search: text })}
                                        onSubmitEditing={() => {
                                            actions.getStoreItems({ type, filter: categories, prices: [minPrice, maxPrice], search, page: 0, })
                                                .then((res) => this.setState({ storeData: res.hits, storePage: res.page, storePages: res.nbPages, storeItems: res.nbHits }))
                                        }} />
                                </View> */}

                                {Platform.OS === 'web' ? <ScrollView style={{
                                    zIndex: 2, backgroundColor: 'white', opacity: 0.9, paddingLeft: 15,
                                    width: layout.width > 850 ? 305 : '100%', position: 'absolute',
                                    display: (layout.width > 850 || this.state.showFilters) ? 'flex' : ('none'),
                                    borderRightColor: 'white', marginTop: 0.1 * vmin, maxHeight: layout.height * 0.9,
                                    borderRightWidth: 2,
                                }}>
                                    <View style={{ justifyContent: 'center', borderRightWidth: 1.5, borderRightColor: Colors.gray0, }}>
                                        {side_bar_items.map((item, index) => {
                                            return <View key={item.title} style={{ flexDirection: 'column' }}>
                                                <CategoryContainer
                                                    onPress={() => {

                                                    // this.state.categories[item.title] = !this.state.categories[item.title];
                                                    // this.props.actions.getStoreItems(this.state.type, this.state.categories);
                                                    // console.log('item.title ', actions.categoryFilterQuery({filter:Object.keys(this.state.categories)}))

                                                    let newCategories = this.state.categories //Lena's mark
                                                    console.log('item.title', item.title, newCategories)
                                                    if (item.title !== 'All') {
                                                        delete newCategories['All']
                                                    }
                                                    if (newCategories[item.title] === true) {
                                                        delete newCategories[item.title]
                                                    } //execute the manipulations
                                                    else {
                                                        newCategories[item.title] = true
                                                    }

                                                    if (item.title === 'All') {
                                                        newCategories = { 'All': true }
                                                    }
                                                    this.setState({ categories: newCategories },
                                                        () => {
                                                            this.props.actions.getStoreItems({ type, filter: newCategories, prices: [minPrice, maxPrice], search, page: 0 })
                                                                .then(res => this.setState({ storeData: res.hits, storePage: res.page, storePages: res.nbPages, storeItems: res.nbHits }))
                                                        })
                                                }}
                                                isFocused={this.state.categories[item.title]}
                                                image={item ? item.image : null} title={item.title} />
                                            </View>
                                        })}
                                        <View style={{ marginTop: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-around' }}>
                                            <View style={{ width: '45%', }}>
                                                {this.state.minPrice ? <View style={{ position: 'absolute', height: 30, justifyContent: 'center', left: 12 }}><Text style={{ marginTop: 5 }}>$</Text></View> : null}
                                                <TextInput placeholder={'Min Price, $'}
                                                    style={{ outlineWidth: 0, borderRadius: 10, paddingLeft: this.state.minPrice ? 20 : 10, borderColor: Colors.green1, width: '100%', height: 30, borderWidth: 2 }}
                                                    onChangeText={text => this.setState({ minPrice: text.replace(/\D/, '') })}
                                                    value={this.state.minPrice}
                                                    onSubmitEditing={() => {
                                                        this.props.actions.getStoreItems({ type, filter: categories, prices: [minPrice, maxPrice], search, page: 0 })
                                                            .then(res => this.setState({ storeData: res.hits, storePage: res.page, storePages: res.nbPages, storeItems: res.nbHits }))
                                                    }}
                                                /></View>
                                            <View style={{ width: '45%', }}>
                                                {this.state.maxPrice ? <View style={{ position: 'absolute', height: 30, justifyContent: 'center', left: 12 }}><Text style={{ marginTop: 5 }}>$</Text></View> : null}
                                                <TextInput placeholder={'Max Price, $'}
                                                    style={{ outlineWidth: 0, borderColor: Colors.green1, borderRadius: 10, paddingLeft: this.state.maxPrice ? 20 : 10, width: '100%', height: 30, borderWidth: 2 }}
                                                    onChangeText={maxPrice => this.setState({ maxPrice: maxPrice.replace(/\D/, '') })}
                                                    value={this.state.maxPrice}
                                                    onSubmitEditing={() => {
                                                        this.props.actions.getStoreItems({ type, filter: categories, ages: age, prices: [minPrice, maxPrice], search })
                                                            .then(res => this.setState({ storeData: res.hits, storePage: res.page, storePages: res.nbPages, storeItems: res.nbHits }))
                                                    }}
                                                />
                                            </View>
                                        </View>

                                        {raitingSelectionBlock.call(this)}
                                    </View>
                                </ScrollView> : null}

                                {Platform.OS === 'web' ? <TouchableNativeFeedback style={{ zIndex: 1 }} onPress={() => this._scroll.scrollTo({ x: 0, y: 0, animated: true })}>
                                    <View style={{
                                        position: 'absolute', top: layout.height * 0.85, right: 10, width: 40, height: 40, borderRadius: 30, backgroundColor: Colors.green1,
                                        justifyContent: 'center', alignItems: 'center'
                                    }}>
                                        <Icon style={{ zIndex: 3, }} name="arrow-up" size={30} color="white" />
                                    </View>
                                </TouchableNativeFeedback> : null}

                                <View style={{ flex: 1, zIndex: 2, flexDirection: layout.width > 850 ? 'row' : 'column', }}>
                                    <View style={{ flex: 1, alignItems: 'center', marginLeft: layout.width > 850 ? 300 : 0, }}>

                                        <View style={{
                                            // width: this.buttonsWidth(numColumns, layout, vmin),
                                            width: '90%',
                                            justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column',
                                        }}>
                                            <View style={{
                                                flex: 1, paddingHorizontal: layout.width > 800 ? vmin * 0.02 : 0, paddingLeft: 0, paddingTop: 20,
                                                width: layout.width > 800 ? '100%' : '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
                                            }}>

                                                {/* <View style={{ flexDirection: 'row' }}> */}
                                                {layout.width > 850 ? <DropdownElement
                                                    containerStyle={{ zIndex: 127, width: 160, maxWidth: 160, }}
                                                    mainElementStyle={{ width: '100%', marginTop: 0, paddingHorizontal: 10, backgroundColor: Colors.green1, borderColor: Colors.green1 }}
                                                    arrowStyle={{ borderTopColor: 'white' }}
                                                    elementContainerStyle={{ height: 60, borderTopWidth: 1, borderColor: Colors.green1 }}
                                                    mainTextStyle={{ color: 'white', marginLeft: 0, width: '100%', textAlign: 'center' }}
                                                    onPress={(val) => {
                                                        this.props.actions.getStoreItems({ sort: val, type, filter: categories, ages: age, prices: [minPrice, maxPrice], search, page: 0 })
                                                            .then(res => this.setState({ storeData: res.hits, storePage: res.page, storePages: res.nbPages, storeItems: res.nbHits }))
                                                        this.setState({ sortBy: sortValues[val] });
                                                    }} defaultValue={sortCategories[this.state.sortBy]}
                                                    categories={['Most popular', 'Price Low to High', 'Price High To Low']}
                                                /> :
                                                    <TouchableNativeFeedback style={Platform.OS === 'web' && { width: '30%' }}
                                                        onPress={() => this.setState({ settingsMobileVisible: true })}>
                                                        <View style={[{ maxWidth: 120, backgroundColor: Colors.green1, height: 45, justifyContent: 'center', borderRadius: 20, }]}>
                                                            <Text style={[{ color: 'white', textAlign: 'center', }]}>Filter</Text>
                                                        </View>
                                                    </TouchableNativeFeedback>}

                                                {false ?
                                                    // <Dropdown categories={[{title:'All'}, {title:'New'}]} onPress={item=>this.setState({type:item})} defaultValue={this.state.type} />
                                                    <DropdownElement
                                                        disabled={!this.state.apiResults}
                                                        containerStyle={{ width: '30%', maxWidth: 120, }}
                                                        mainElementStyle={{
                                                            height: 45, marginTop: 0, backgroundColor: Colors.green1,
                                                            borderColor: Colors.green1, width: '100%', maxWidth: 120
                                                        }}
                                                        arrowStyle={{ borderTopColor: 'white' }}
                                                        mainTextStyle={{ color: 'white', }}
                                                        // mainElementStyle, mainTextStyle, containerStyle, elementContainerStyle, arrowStyle, dropdownStyle
                                                        categories={['All', 'Store', 'New']}
                                                        defaultValue={this.state.type}
                                                        onPress={value => this.setState({ type: value })} />
                                                    : null}
                                                {/* </View> */}
                                                {/* <TouchableNativeFeedback onPress={() => this.firestoreTest()}><Text>{this.state.type}</Text></TouchableNativeFeedback> */}

                                                {
                                                    auth.type === 0 ? <TouchableNativeFeedback style={Platform.OS && { alignItems: 'flex-end', width: '30%', }} onPress={() => { this.setState({ showing: this.state.showing === 'all' ? 'children' : 'all' }) }}>
                                                        <View style={{
                                                            width: '100%', maxWidth: 120,
                                                            padding: 10, height: 45, justifyContent: 'center',
                                                            borderWidth: 3, borderRadius: 20, borderColor: Colors.gray
                                                        }}>
                                                            <Text style={{ textAlign: 'center', alignSelf: 'center', color: Colors.black, }}>
                                                                {this.state.showing === 'all' ? 'Child Store' : 'All Items'}</Text></View>
                                                    </TouchableNativeFeedback> : null
                                                }

                                            </View>
                                            {
                                                // layout.width < 800 ?
                                                <Text style={{ marginTop: 0.04 * vmin, alignSelf: 'flex-end', }}>
                                                    {this.state.showing === 'all' ? this.state.storeItems : this.state.childItems} {this.state.data.length === 1 ? 'item' : 'items'}
                                                </Text>
                                                // : null
                                            }
                                        </View>

                                        {this.state.showing === 'all' ?
                                            <FlatList
                                                key={numColumns}
                                                nestedScrollEnabled={false}
                                                numColumns={numColumns}
                                                showsVerticalScrollIndicator={false}
                                                ListFooterComponent={()=>this.footer()}
                                                contentContainerStyle={{ justifyContent: 'center', alignContent: 'center' }}
                                                style={{ zIndex: -1, flex: 1, width: '100%',
                                                    alignSelf: 'center', paddingVertical: 0.04 * vmin }}
                                                data={data}
                                                keyExtractor={(item, index) => index.toString()}
                                                renderItem={(item) => this.renderCard(item, layout)}
                                                // ItemSeparatorComponent={()=><View style={{width:10, borderWidth:1, height:10}}></View>}
                                                // columnWrapperStyle={{borderWidth:1,flex:0.5,alignItems:'flex-start', justifyContent:'flex-start'}}
                                                // data={this.state.data}
                                                // data={Object.keys(this.state.data)}
                                                // onEndReached={() => { console.log('end reached') }}
                                                // onScroll={({ nativeEvent }) => { console.log('nativeEvent ', nativeEvent) }}
                                            /> :
                                            <StoreChildren
                                            // parentId={parentId} childIds={this.props.parent.childIds}
                                            id={this.props.parent.id} items={this.state.items} setItems={(val) => this.setState({ childItems: val })}
                                                navigation={navigation} categories={JSON.stringify(this.state.categories)} type={this.state.sortBy} />
                                        }
                                    </View>
                                </View>
                            </ScrollView>
                        </View>
                        {/* </ImageBackground> */}
                    </View>
                }}
            </Resizable>
        )
    }
}

const styles = StyleSheet.create({
});

export default connect(state => {
    return ({
        auth: state.auth,
        parent: state.parent,
        store: state.store,
    })
},
    (dispatch) => ({
        actions: bindActionCreators({ ...storeActions, ...authActions, ...goalsActions }, dispatch)
    })
)(Shop);



// let amazon = (storeJson.store.amazon)
// let rakuten = (storeJson.store.rakuten)
// let storeRef = firebase.database().ref('store');

// console.log(amazon)
// let keys = Object.values(amazon);
// for (let i in keys) {
//     let {amazon_id, category, description, image, parents, kids, price, title} = keys[i]
//     let newPush = storeRef.push()
//     console.log('newPush.key ', newPush.key)
//     console.log('newPush.key ', amazon_id, category, description, image, parents, kids, price, title)

//     newPush.update({id:amazon_id, key:newPush.key, category, description, image, link:keys[i].link, parents:parents||1, kids:kids||1, price, title})
// }

// let rakutenKeys = Object.values(rakuten);

// for (let i in rakutenKeys) {
//     let {id, category, description, image, parents, kids, price, merchantname, title} = rakutenKeys[i]
//     let newPush = storeRef.push()
//     console.log('newPush.key ', newPush.key)
//     // console.log('newPush.key ', amazon_id, category, description, image, parents, kids, price, title)

//     newPush.update({id, key:newPush.key, category, merchantname:merchantname||'LEGO Brand Retail, Inc.', description, image, link:rakutenKeys[i].url, parents:parents||1, kids:kids||1, price, title})
// }
// for (let i in amazon) {
//     let { title, category, description, image, link, price, amazon_id } = amazon[i]
//     firestore().collection("store")
//         .doc(amazon_id.toString()).set({
//             // .add({
//             title, category, description, image, link, price, merchantname: 'Amazon',
//             id: amazon_id
//         })
//         .then(function () {
//             console.log("Document successfully written!");
//         })
//         .catch(function (error) {
//             console.error("Error writing document: ", error);
//         });
// }
// for (let i in rakuten) {
//     let { title, category, description, image, url, price, merchantname, id } = rakuten[i]
//     firestore().collection("store")
//         .doc(id).set({
//             // .add({
//             title, category: 'Toys', description, image, link: url, price, merchantname: merchantname || "LEGO Brand Retail, Inc.", id
//         })
// }