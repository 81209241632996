/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
    Animated,
    Dimensions,
    Image,
    ImageBackground,
    StyleSheet,
    ScrollView,
    View,
    Platform,
    TouchableOpacity,
} from 'react-native';

import Carousel from 'react-native-snap-carousel';
// import Carousel from '../../components/Carousel/Carousel';
import { LinearGradient } from 'expo-linear-gradient';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';


import {
    useDimensions,
    useActive,
    useFocus,
    useHover,
    useREM,
    useScaledSize,
    Hoverable,
    Resizable
} from 'react-native-web-hooks';
import Icon from 'react-native-vector-icons/FontAwesome';

import { database } from 'firebase';

import TouchableNativeFeedback from '../../components/TouchableFeedback'
import Text from '../../components/CustomText'
import CounterTextInput from '../../components/CounterTextInput'
import ParentComponentHeader from '../../components/ComponentHeader'
import { dropdown, handleScroll, dropdownParent } from '../../components/Dropdown'
import { CloseButton } from '../../components/Buttons'

import { ParentHeaderCard } from '../../components/Header/HeaderBlock'

import CarouselComponent from '../../components/Carousel/Carousel'
import Balance, { ChequingTotalActivity, Movement, Interest } from '../../components/chequing/balance'
import { SectionTable, SectionRow } from '../../components/chequing/table'
import Intervals from '../../components/chequing/Intervals'
import ParentTransferButton from '../../components/chequing/ParentTransferButton'

import * as authActions from '../../store/actions/parents/authActions'
import * as chequingActions from '../../store/actions/parents/chequingActions'
import * as savingsActions from '../../store/actions/parents/savingsActions'

import Colors from '../../constants/Colors';
import { defaultStyles } from '../../constants/style';

function getDate(index) {
	let newDate = new Date();
	if (index === 0) {
		newDate.setDate(newDate.getDate() - 7)
	}
	if (index === 1) {
		newDate.setMonth(newDate.getMonth() - 1)

	}
	if (index === 2) {
		newDate.setMonth(newDate.getMonth() - 6)

	}
	if (index === 3) {
		newDate.setFullYear(newDate.getFullYear() - 7)
	}
	return newDate
}

class Chequing extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            header: null,
        }
    }

    constructor(props) {
        super(props);
        this._carousel = React.createRef();

        this.state = {
            type: 'Chequing',
            movement: 0,
            isLoading: true,
            interval: new Date(),
            debit_card: 0,
            credit_card: 0,
            visible: false,
            intervalId: 1,
            transfer: '',
            confirmation: false,
            header: false,
            conditions: {},
            id: 0
        };
    };

    async getCards() {
        const { parentId, } = this.props.auth;
        const { childIds, names, id } = this.props.parent;

        var settings_ref = database().ref('/users/' + parentId + '/' + childIds[id] + '/settings');
        const snapshot = await settings_ref.once('value')
        let settings_val = snapshot.val()
        this.setState({
            debit_card: settings_val.debit_card,
            credit_card: settings_val.credit_card,
        })
    }

    async componentDidMount() {
        const { parentId, } = this.props.auth;
        const { childIds, names, id } = this.props.parent;
        const { actions, navigation } = this.props;

        if (!this.props.auth.parentId) {
            this.props.actions.clearAuth()
                .then(() => {
                    this.props.navigation.navigate('Auth')
                })
        }

        this.focusListener = navigation.addListener('didFocus', () => {
            this.getCards();
            let cards = navigation.getParam('cards', 'Chequing')

            let month = new Date()
            month.setMonth(month.getMonth() - 1)
            // this.state.interval.setMonth(new Date().getMonth() - 1);
            this.setState({ type: navigation.getParam('cards', 'Chequing') })
            this._carousel.current.snapToItem(cards === 'Savings'? 2:1)

            navigation.getParam('cards', 'Chequing') === 'Chequing' ?
                actions.getChequing(month, parentId, childIds[0], navigation)
                :
                actions.getSavings(month, parentId, childIds[0], navigation);
        });
    }

    componentWillUnmount() {
        console.log('123')
        // this.focusListener.remove();
    }

    parseInt(value) {
        return value !== '' ? parseInt(value) : 0
    }


    getData(move) {
        const { parentId, } = this.props.auth;
        const { childIds, names, id } = this.props.parent;
        const { getChequing, getSavings, changeChildIndex } = this.props.actions;
        const { navigation } = this.props;
        let index = id + move;
        changeChildIndex(move)
        if (index > childIds.length - 1) {
            this.setState({ id: 0 }, () => { this.getCards(); });
            this.state.type === 'Chequings' ? getChequing(this.state.interval, parentId, childIds[0], navigation) :
                getSavings(this.state.interval, parentId, childIds[0], navigation);
            return 0
        }
        if (index < 0) {
            this.setState({ id: childIds.length - 1 }, () => { this.getCards(); });
            this.state.type === 'Chequings' ? getChequing(this.state.interval, parentId, childIds[childIds.length - 1], navigation) :
                getSavings(this.state.interval, parentId, childIds[childIds.length - 1], navigation);

            return 0
        }
        this.setState({ id: index }, () => { this.getCards(); });
        this.state.type === 'Chequings' ? getChequing(this.state.interval, parentId, childIds[index], navigation) :
            getSavings(this.state.interval, parentId, childIds[index], navigation);
        return 0
    }

    parseFloat(value) {
        return value ? parseFloat(value) : 0
    }

    render() {
        const { navigation, actions } = this.props;
        const { parentId } = this.props.auth;
        const { childIds, names, id } = this.props.parent;

        const chequing = this.props.chequing[childIds[id]] ? this.props.chequing[childIds[id]] : {}
        const savings = this.props.savings[childIds[id]] ? this.props.savings[childIds[id]] : {}

        return (
            <Resizable>
                {layout => {
                    const vmin = Math.min(...[layout.width, layout.height]);

                    if (!chequing || !savings) {
                        return <View>
                        </View>
                    }

                    return <View style={{ zIndex: 1, flex: 1, backgroundColor: 'white' }}>
                        <ParentTransferButton interval={this.state.intervalId} />

                        <ScrollView style={{ flex: 1, zIndex: 1, }}
                        // onScroll={(e) => handleScroll.call(this, e, layout.height)}
                        >

                            <View style={{ flex: 1, zIndex: 1, alignItems: 'center', paddingTop: layout.height * 0.015 }}>

                                <ParentHeaderCard style={{  paddingBottom: 0, zIndex: -1, }} name={names[childIds[id]]} pressNext={() => this.getData(+1)} navigation={navigation} signOut={() => { }} vmin={vmin} layout={layout} clearAuth={this.props.actions.clearAuth} />

                                <CarouselComponent
                                    carouselRef={this._carousel}
                                    type={this.state.type}
                                    layout={layout}
                                    debit_card={this.state.debit_card} credit_card={this.state.credit_card}
                                    onSnapToItem={(index) => {
                                        let month = new Date()
                                        month.setMonth(month.getMonth() - 1)
                                        console.log('month ', month)
                                        // navigation.setParams({'cards':index===0?'Chequing':'Savings'});
                                        this.setState({ intervalId: 1, isLoading: true, type: index === 1 ? 'Chequing' : 'Savings' }, () => {
                                            index === 1 ?
                                                actions.getChequing(month, parentId, childIds[id], navigation)
                                                :
                                                actions.getSavings(month, parentId, childIds[id], navigation)
                                        })
                                    }}
                                />

                                <View style={{ width: '100%', justifyContent: 'center', paddingBottom: 0.05 * vmin }}>
                                    <View style={{ width: '100%', alignItems: 'center' }}>
                                        <Intervals focused={this.state.intervalId} setFocused={(index) => this.setState({ intervalId: index })} onPress={this.state.type === 'Chequing' ? actions.getChequing : actions.getSavings} type={this.state.type}
                                            parentId={parentId} childId={childIds[id]} navigation={navigation} />
                                    </View>

                                    {this.state.type === 'Chequing' ? <View style={{ alignItems: 'center', }}>

                                        <View style={{ width: '90%', maxWidth: 600, marginTop: 30, marginBottom: 20 }}>
                                            <Balance layout={layout} balance={chequing.chequing_balance} movement={chequing.movement} />
                                            <Movement balance={chequing.chequing_balance} movement={chequing.movement} value={chequing.movement} />
                                        </View>

                                        <ChequingTotalActivity income={chequing.income} expense={chequing.expense} saved={chequing.saved} />

                                        <SectionTable title={'Income'} value={chequing.income} transactions={chequing.income_trans}>
                                            <SectionRow data={chequing.allowance} title={'Allowance'} value={chequing.allowance_income}
                                                transactions={chequing.allowance_income_trans}
                                                bottomBorder={true} />
                                            {/* <View style={{height:1.5, width:'100%', backgroundColor:'#94B42D'}}></View>                       */}
                                            <SectionRow data={chequing.job} title={'Jobs'} value={chequing.job_income}
                                                transactions={chequing.job_trans}
                                                bottomBorder={true} />
                                            {/* <View style={{height:1.5, width:'100%', backgroundColor:'#94B42D'}}></View>                       */}
                                            <SectionRow data={chequing.deposit} title={'Deposits'} value={chequing.deposit_income}
                                                transactions={chequing.deposit_income_trans} />
                                        </SectionTable>

                                        <SectionTable title={'Expense'} value={chequing.expense} transactions={chequing.expense_trans}>
                                            <SectionRow data={chequing.online_purchase} title={'Online Purchases'}
                                                value={chequing.online_expense} transactions={chequing.online_expense_trans}
                                                bottomBorder={true} />
                                            <SectionRow data={chequing.credit_purchase} title={'Credit Purchases'}
                                                value={chequing.credit_expense} transactions={chequing.credit_expense_trans}
                                                bottomBorder={true} />
                                            {/* <View style={{height:1.5, width:'100%', backgroundColor:'#94B42D'}}></View> */}
                                            <SectionRow data={chequing.expence_fees_data} title={'Fees'} value={chequing.expence_fees}
                                                transactions={chequing.expence_fees_trans} />
                                        </SectionTable>

                                        <SectionTable title={'Saved'} value={chequing.saved} transactions={chequing.saved_trans}>
                                            <SectionRow data={chequing.savings_deposit} title={'Savings Deposits'} value={chequing.deposit_savings}
                                                transactions={chequing.deposit_savings_trans}
                                                bottomBorder={true} />
                                            {/* <View style={{height:1.5, width:'100%', backgroundColor:'#94B42D'}}></View>                       */}
                                            <SectionRow data={chequing.savings_withdrawal} title={'Savings Withdrawals'}
                                                value={chequing.withdraw_savings}
                                                transactions={chequing.withdraw_savings_trans}
                                            />
                                            {/* <SectionRow data={chequing.savings_fees_data} title={'Fees'}
                                                    value={chequing.savings_fees} transactions={chequing.savings_fees_trans}
                                                /> */}
                                        </SectionTable>

                                    </View>
                                        :
                                        <View style={{ alignItems: 'center' }}>

                                            <View style={{ width: '90%', maxWidth: 600, marginTop: 30, marginBottom: 20 }}>
                                                <Balance layout={layout} balance={savings.savings_balance} movement={savings.total_savings} />
                                                <Interest interest={savings.interest} rate={savings.interest_rate} />
                                            </View>

                                            <SectionTable title={'Saved'} value={savings.total_savings} transactions={savings.total_savings_trans}>
                                                <SectionRow data={savings.deposits_arr} title={'Deposits'} value={savings.deposits}
                                                    transactions={savings.deposits_trans} bottomBorder={true} />
                                                <SectionRow data={savings.withdrawals_arr} title={'Withdrawals'} value={savings.withdrawals}
                                                    transactions={savings.withdrawals_trans} bottomBorder={true} />
                                                <SectionRow data={savings.savings_fees} title={'Fees'}
                                                    value={savings.savings_fees_amount} transactions={savings.savings_fees_trans}
                                                />
                                            </SectionTable>

                                        </View>}
                                </View>


                            </View>
                        </ScrollView>
                    </View>
                }}
            </Resizable>)
    }
}

const styles = StyleSheet.create({

});

// export default Chequing
export default connect(state => {
    // console.log(Object.keys(state.vacancies));
    return ({
        auth: state.auth,
        parent: state.parent,
        chequing: state.chequing,
        savings: state.savings,
    })
},
    (dispatch) => ({
        actions: bindActionCreators({ ...authActions, ...chequingActions, ...savingsActions }, dispatch)
    })
)(Chequing);
