/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import {
    ActivityIndicator, Dimensions, Image, ImageBackground, KeyboardAvoidingView, StyleSheet, ScrollView, TextInput, View,
    Platform,
} from 'react-native';

import { withNavigation } from 'react-navigation';

import Text from '../CustomText'
// import { ConfirmationView, } from '../components/confirmation'
import { Resizable, Hoverable, useLayout } from 'react-native-web-hooks';

import TouchableNativeFeedback from '../../components/TouchableFeedback';

import Colors from '../../constants/Colors';
import globalStyle, { defaultStyles } from '../../constants/style';

export const InfoWrapper = ({ title, description, onPress, navigation, children }) => {
    // const {onLayout, layout} = useLayout();
    const layout = { width: 1000, height: 500 }
    const vmin = Math.min(...[layout.width, layout.height]);
    const top = vmin * 0.1 < 52 ? 52 : vmin * 0.105
    return <>

        <ScrollView
            style={{ zIndex: 1, backgroundColor: 'white' }}
            contentContainerStyle={{flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>

            <View style={{ position: 'absolute', top: 0.01 * vmin, right: 0.03 * vmin, zIndex: 5 }}>
                <TouchableNativeFeedback onPress={() => navigation.navigate('Settings')}>
                    <View elevation={10} style={{ alignSelf: 'center', padding: 5, margin: 10, }}>
                        <Image style={{ height: 30, width: 30 }} source={require('../../assets/Delete-X.png')} />
                    </View>
                </TouchableNativeFeedback>
            </View>
            <View style={{ width: '100%', marginVertical: 0.13 * vmin, alignItems: 'center', }}>
                <View style={{
                    position: 'absolute',
                    top: -0.06 * vmin,
                    // top:100,
                    zIndex: 1,
                    height: vmin * 0.2, width: vmin * 0.2, minWidth: 100, minHeight: 100,
                    left: '50%', transform: [{ translateX: Platform.OS==='web'?'-50%': -vmin * 0.1 }],
                    borderRadius: vmin * 0.1, backgroundColor: 'white',
                    ...defaultStyles.shadow
                }}>
                    <Image resizeMode={'contain'} style={{
                        // top: -top,
                        // zIndex: 100,
                        height: vmin * 0.2, width: vmin * 0.2,
                        minWidth: 100, minHeight: 100,
                        // 540x300
                    }} source={require('../../assets/signup_window_crop.png')} />
                </View>
                <View
                    style={{
                        zIndex: 2,
                        minWidth: 350,
                        maxWidth: 650,
                        ...defaultStyles.shadow,
                        // borderWidth:1,
                        // top: layout.height / 2,
                        // transform: [{ translateY: '-50%' }],
                        width: layout.width > 850 ? '70%' : '90%',
                        justifyContent: 'space-between', alignItems: 'center',
                        borderRadius: 15, backgroundColor: 'white',
                        // marginVertical: Math.max(50, vmin * 0.2),
                        // marginVertical: layout.height < 800 ? 50 : 0,
                        // marginBottom: top,
                        // padding: 10
                    }}>

                    <Image resizeMode={'contain'} style={{
                        position: 'absolute', top: -top,
                        height: vmin * 0.1, width: vmin * 0.1,
                        minWidth: 100, minHeight: 100,
                        // 540x300
                    }} source={require('../../assets/signup_window_crop.png')} />
                    <View style={{ marginBottom: 10 }}>
                        <Text style={{ ...defaultStyles.bold,textAlign: 'center', marginTop: vmin * 0.05, color: Colors.gray0, ...globalStyle.h3 }}>{title}</Text>
                        {description}
                    </View>
                    {children({ layout: layout })}
                </View>
            </View>
        </ScrollView>
    </>
}

export const BlockWrapper = withNavigation(InfoWrapper)

export class NewKid extends React.Component {

    static navigationOptions = ({ navigation }) => {

        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        }
    }


    constructor(props) {
        super(props);
        this.scroll = React.createRef()
        this.state = {
            loading: false
        };
    };



    componentDidMount() {
        const { childIds } = this.props.parent;

        console.log('didmount?')

        this.scroll.current.scrollTo({ y: 0, animated: true })

        // kidClearAuth()
    }

    createCustomToken() {
        this.props.actions.createCustomToken()
            .then(res => console.log('cUSTOM TOKEM', res));
    }

    render() {

        return (
            <Resizable>
                {layout => {
                    return <ScrollView
                        ref={this.scroll}
                        style={{}} contentContainerStyle={{
                            // flex:1,
                            flexGrow: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            // paddingTop:100,
                            // ...layout.height > 700 ? { paddingVertical:0, } : {paddingTop:50+30000/(layout.height),},
                        }}>
                        {/* <Stars layout={layout} color={'white'} /> */}
                        {this.state.loading ? <View style={{
                            position: 'absolute', zIndex: 1, backgroundColor: 'rgba(126, 193, 61, 0.5)',
                            justifyContent: 'center', height: '100%', width: '100%'
                        }}>
                            <ActivityIndicator color={Colors.green1} size={'large'} />
                        </View> : null}
                        {this.props.parent.childIds.length ? <View style={{ zIndex: 1, position: "absolute", right: 10, top: 10 }}>
                            <TouchableNativeFeedback onPress={() => { this.props.navigation.navigate('Dashboard') }}>
                                <Text style={{ fontSize: 40, ...defaultStyles.bold}}>X</Text>
                            </TouchableNativeFeedback>
                        </View> : null}

                        {this.props.children}


                    </ScrollView>
                    // </View>
                }}
            </Resizable>
        )
    }
}


//  BlockWrapper

