import * as actionTypes from './actionTypes'
// import {fetchWrapper} from "../lib/fetchWrapper";
import {AsyncStorage} from "react-native";
import { database } from 'firebase';
import config from '../../../constants/config'

// const parentId = 'XcbB7fIw6pgbjoqxhmb9FwZb4BL2';
// const childId = 'coMBDNYQM0XB5xPfZ1dbJ3cai6u2';

function requestGoals() {
    return {
        type: actionTypes.REQUEST_GOALS,
        data: [],
        isLoading:true
    }
}

function receiveGoals(json, childId, all, achieved) {

    return {
        type: actionTypes.RECEIVE_GOALS,
        payload:{
            // obj: json,
            data:json,
            childId,
            all, achieved,
            isLoading:false,
        }
    }
}
export function getGoals(parentId, childId) {
    return async dispatch => {
        dispatch(requestGoals())
        var data_source = database().ref('/users/' + parentId + '/' + childId + '/goal');
    
    return data_source.once('value')
            .then(snapshot => {return snapshot.val()})
            .then(snap => {
              // console.log('snap')
              // console.log(snap)

                let json1 = snap?Object.keys(snap).map(item => { return ({ key: item, ...snap[item] }) }):[]; 
                const all = json1
                const json = json1.filter(item=>{return item.active===1})
                const achieved = json1.filter(item=>{return (item.active===0 && item.activated===1)})
                json.sort(function (x, y) {return (x === y) ? 0 : x.activated === 1 ? -1 : 1;});
                // console.log('childId');
                // console.log(childId);
                
                return dispatch(receiveGoals(json, childId, all, achieved))})
    }
}


async function getGoal(url, dispatch) {
    // const testUrl = 'https://pennygem-dcc98.firebaseio.com/users/a2/a1/goal/-LsQU4buprSC_9fgugdZ'
    let goalId = url.split('\/')
    goalId = goalId[goalId.length - 1]
    const db = database().ref('/users/' + parentId + '/' + childId + '/goal/' + goalId)
    let item = (await db.once('value')).val();
    // console.log(action.payload)
    dispatch({
                        type: actionTypes.ADD_GOAL,
                        payload:{goal:item,
                                goalId:goalId
                                },                        
                    })
    // this.s 
    // this.state.data[goalId] = item;
    // this.setState({ data: [...this.state.data, { key: goalId, ...item }] })

    return item
  }




export function removeGoal(goalId, parentId, childId) {
    return async dispatch => {
        // dispatch(requestGoals())
    
    const body = { goalId: goalId, parentId, childId }

    return fetch(config.url + 'removeGoal', {
        method: 'POST',
        body: JSON.stringify(body)
      })
        .then((resp) => {
                return resp.json()})
        .then((resp) => {
            dispatch({type: actionTypes.REMOVE_GOAL,
                    payload:{goalId:goalId},})
          return resp
        })
    }
}


export function activateGoal(goalId, parentId, childId) {
    return async dispatch => {

    const body = { goalId: goalId, parentId, childId }
    return fetch(config.url + 'activateGoal', {
      method: 'POST',
      // mode: 'cors',  
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        // this.setState({ data: [] })
        // this.getGoals()
        return resp
      })
  }
}


export function purchaseGoal(goalId, parentId, childId) {
    return async dispatch => {

    const request_date = new Date();
    const goal = this.state.data.find(item=>item.key===goalId);

    const name = goal.name;
    const price = goal.amount;
    const body = { goalId: goalId, name,price,request_date, parentId, childId }
    return fetch(config.url+'purchaseGoal', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        this.setState({ data: [] })
        this.getGoals()
        return resp
      })
  }
}