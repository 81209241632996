import React from 'react';
import { Platform } from 'react-native';
import { createAppContainer, createSwitchNavigator, createBottomTabNavigator } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';

import { createBrowserApp } from '@react-navigation/web';


import Dashboard from "../screens/Kids/Dashboard";
import Settings from "../screens/Kids/Settings";
import Chequing from "../screens/Kids/Chequing";
import Jobs from "../screens/Kids/Jobs";
import Goals from "../screens/Kids/Goals";
import Purchases from "../screens/Kids/Purchases";
// import KidTestScreen from "../screens/TestScreen";
import Store from "../screens/Kids/Store";
import StoreItem from "../screens/Kids/StoreItem";

import title from "../helpers/generateTitle";


const createApp = Platform.select({
  web: createBrowserApp,
  default: createAppContainer,
})

const stackNavigator =
  Platform.OS === 'web' ?
    createStackNavigator({
      Dashboard,
      Goals,
      Jobs,
      Settings,
      Purchases,
      Store,
      Chequing: { screen: Chequing, path: ':cards', },

    },
      {
        headerMode: 'none',
        header: null,
        navigationOptions: ({ navigation }) => {
          const current = navigation.state.routes && navigation.state.routes.length && navigation.state.routes[navigation.state.routes.length - 1].routeName
          return { title: current && title(current) }
        },
      }
      // { initialRouteName: "Login",}
    )
    :
    createStackNavigator({
      Dashboard,
      Goals,
      Jobs,
      Chequing,
      Purchases,
      Settings,
      Store,

    },
      { initialRouteName: "Dashboard", }
    )

export default stackNavigator
