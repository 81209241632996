/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import {
  Animated,
  AsyncStorage,
  Dimensions,
  Image,
  ImageBackground,
  FlatList,
  ScrollView,
  StyleSheet,
//   Text as DefaultText,
  View,
  Platform,
  TouchableOpacity,
} from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome';

import { database, storage } from 'firebase';

import {connect} from "react-redux";
import {bindActionCreators} from 'redux';

import { useHover, useFocus, useActive, useDimensions, Resizable, Hoverable } from 'react-native-web-hooks';

import Text from '../../components/CustomText'

import Stars from '../../components/Stars';
import TouchableNativeFeedback from '../../components/TouchableFeedback';
import {SelectChildren} from '../../components/store/SelectChildren';

import * as storeActions from '../../store/actions/kids/storeActions'
import * as authActions from '../../store/actions/kids/authActions'
import * as goalsActions from '../../store/actions/kids/goalsActions'
import Colors from '../../constants/Colors';

import {defaultStyles} from '../../constants/style'

const Modal = Platform.OS==='web'?require('modal-enhanced-react-native-web').default:require('react-native-modal').default

const side_bar_items = [
    {title:'All',image:require('../../assets/Store/accessories.png')},
    {title:'Bauty',image:require('../../assets/Store/accessories.png')},
    {title:'Sports',image:require('../../assets/Store/accessories.png')},
    {title:'Arts',image:require('../../assets/Store/accessories.png')},
    {title:'Accessories',image:require('../../assets/Store/accessories.png')},

    {title:'Toys',image:require('../../assets/Store/accessories.png')},
    {title:'Electronics',image:require('../../assets/Store/accessories.png')},
    {title:'Activities',image:require('../../assets/Store/accessories.png')},
    {title:'Food',image:require('../../assets/Store/accessories.png')},
    {title:'Donations',image:require('../../assets/Store/accessories.png')},

]


const SortButton = ({title, onPress, active}) => {
  const vmin = 1000;
  return <TouchableNativeFeedback onPress={onPress}>
  <View style={{width:170, backgroundColor:Colors.green1,borderWidth:active?4:0,
  borderColor:'white', marginVertical:5,
  borderRadius:10, padding:5,
  boxShadow:'2px 2px 15px #272827',  marginHorizontal: 0.02*vmin, opacity: active?1:0.7,
  }}>
      <Text style={{textAlign:'center', color:'white',}}>{title}</Text>
  </View>
  </TouchableNativeFeedback>
}

const FlagButton = ({ onPress, active, title}) => {
  const vmin = 1000;
  return <Hoverable>
 {isHovered=> <View style={{position:'absolute', right:10, top:10, height:30, width:30, opacity:isHovered?1:0.7,
      borderRadius:15, backgroundColor:Colors.green1, transform:[{scale:isHovered?1.1:1}], }}>
          <TouchableNativeFeedback onPress={()=>onPress(title)}>
      <Image resizeMode={'contain'} style={{ width: 30, height:30 }} source={require('../../assets/Goals/Goal_Icon.png')} />
      </TouchableNativeFeedback>
      </View>}
      </Hoverable>
}

const BuyButton = ({ onPress, active}) => {
    const vmin = 1000;
    return <Hoverable>
   {isHovered=> <TouchableNativeFeedback onPress={onPress}>
          <View elevation={7} style={{width:100, backgroundColor:Colors.green1, borderRadius:10, padding:5,
                      boxShadow:'2px 2px 15px #272827', marginRight: 0.02*vmin,
                      marginLeft:0.02*vmin, marginTop: 0.02*vmin,transform:[{scale:isHovered?1.1:1}],}}>
              <Text style={{textAlign:'center', color:'white'}}>Buy</Text>
          </View>
          </TouchableNativeFeedback>}
        </Hoverable>
  }

const HeaderButton = ({title, onPress}) => {

  return <Hoverable>
      {isHovered=>
          <TouchableNativeFeedback onPress={onPress}>
              <View elevation={5} style={{  margin:10,
              backgroundColor:Colors.green1,borderRadius:10, boxShadow:'2px 2px 15px #272827',
              transform:[{scale:isHovered?1.1:1}], }}>
            <Text style={{color:'white', textAlign:'center',width:100, margin:5}}>{title}</Text>
          </View>
          </TouchableNativeFeedback>
 }
  </Hoverable>
}


const ButtonContainer = React.forwardRef(
  ({title, image, onPress, isFocused}, ref) => {
    const vmin = 1000;
    const [animation, setAnimation] = useState(new Animated.Value(0));

    const { isHovered } = useHover(ref);
    // console.log(ref)
    useEffect(() => {
      Animated.timing(animation, {
        toValue: isHovered?1:0,
        duration: 200,
      }).start();
    });


    let scale = animation.interpolate({
      inputRange: [0, 1],
      outputRange: [1, 1.1]
    })

    return <Resizable>
    {layout=>{
      const width = layout.width;
      return <TouchableNativeFeedback onPress={onPress}>
    <View style={{alignItems:'center'}}>
    <Animated.View
        ref={ref}
        style={{ backgroundColor:'white', borderRadius:80, width:width>600?80:width*0.15,
        height:width>600?80:width*0.15, marginTop:40,marginLeft:20,
        boxShadow: '2px 2px 15px #272827', opacity:isFocused?1:0.7, alignSelf:'center',
        transform:[{scale:scale}], marginTop:0.02*vmin}}>
        <Image resizeMode={'contain'} style={{ zIndex:4,borderRadius:80, width: '100%', height:'100%' }}
        source={image} />
        </Animated.View>
        <Text style={{marginTop:20,color:'white', alignSelf:'center', textAlign:'center', ...defaultStyles.bold, }}>{title}</Text>
        </View>
        </TouchableNativeFeedback>}}
        </Resizable>
})

const ButtonContainerMobile = ({title, image, onPress, isFocused, width}) => {
    const vmin = 1000;
    const [animation, setAnimation] = useState(new Animated.Value(0));

    let scale = animation.interpolate({
      inputRange: [0, 1],
      outputRange: [1, 1.1]
    })


      return <TouchableNativeFeedback onPress={onPress}>
      <View style={{alignItems:'center', justifyContent:'center', width:width*0.3}}>
      <Animated.View elevation={5}
          style={{ backgroundColor:'white', borderRadius:80, width:80,
          height:80, marginTop:40,borderWidth:isFocused?3:0, backgroundColor:'white',
          justifyContent:'center', alignItems:'center', borderColor:Colors.green1,
          boxShadow: '2px 2px 15px #272827', opacity:isFocused?1:0.7,
          transform:[{scale:scale}], marginTop:0.02*vmin}}>
          <Image resizeMode={'contain'} style={{alignSelf:'center',
          borderColor:Colors.green1, zIndex:4,borderRadius:180, width: '100%', height:'100%' }}
          source={image} />
          </Animated.View>
          <Text style={{marginTop:20,alignSelf:'center', textAlign:'center', ...defaultStyles.bold,}}>{title}</Text>
          </View>
        </TouchableNativeFeedback>

}

class RenderShopCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse:true,
    };
  };


  getTitleFontSize(width) {
    if (width<900  &&width>700) {
      return Platform.OS==='web'?'1.5em':20
    }
    else if (width<700) {
      return Platform.OS==='web'?'1.375em':18
    }
    else {
      return Platform.OS==='web'?'1.75em':22
    }
  }
  // const [collapse, setCollapse] = useState(true)
  render() {
    const {item, layout} = this.props;
    const vmin = 1000;
  const numberOfLines = this.state.collapse?{numberOfLines:4}:{}
  return(
    <Resizable>
  {layout=>{
    return<View elevation={5} style={{flex:1,marginTop:0.01*vmin,marginBottom:0.05*vmin, paddingHorizontal: '5%', paddingVertical:0.05*vmin,
      borderRadius:15, alignSelf:'center', backgroundColor:'rgba(255,255,255,0.9)', width:'90%', maxWidth:628,
      flexDirection:'row'}}>
      <FlagButton title={item.title} onPress={()=>this.props.onPress(item.title)}/>
      <View style={{flex:4}}>
      <Image resizeMode={'contain'} style={{zIndex:4, width: '100%', height:'100%' }} source={{uri: item.image}} />
      </View>
      <View style={{flex:6}}>
          <Text numberOfLines={3} ellipsizeMode={'tail'} style={{color:'#272827',
            fontSize:Platform.OS==='web'?this.getTitleFontSize(layout.width):20,
            // ...boldFontWeight,
           marginLeft:0.02*vmin, fontWeight:'400', marginBottom:0.02*vmin,}}>{item.title}</Text>

          <Text style={{color:Colors.green2, marginLeft:0.02*vmin, marginBottom:0.02*vmin}}>CDN ${item.price}</Text>
          <View >
            <View style={{paddingLeft:0.02*vmin, paddingBottom:0.02*vmin}}>
          <Text {...numberOfLines}  style={{color:'#272827' }}>{item.description}</Text>
          </View>
          <Text onPress={()=>{this.setState({collapse:!this.state.collapse})}} style={{color:'blue', marginLeft:0.02*vmin, marginBottom:0.02*vmin}}>
            {this.state.collapse?'Show more':'Show less'}
          </Text>

          </View>
          <BuyButton onPress={()=>this.props.onPressPurchase(item.title)} />
      </View>
  </View>}}
  </Resizable>)
  }
}



class Dashboard extends React.Component {
  static navigationOptions = ({ navigation }) => {
    return {
      // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
      headerTransparent: true,
      headerStyle: {
        zIndex: 2,
        // backgroundColor:'white',
        borderWidth: 0,
        borderBottomWidth:0,
        shadowColor: 'transparent',
        elevation: 0,
        boxShadow: '',
        shadowOpacity: 0,

      },

      header:null

      // headerRight: (<Dropdown></Dropdown>),

    }
  }

  constructor(props) {
    super(props);

    this.ref0 = React.createRef()
    this.ref1 = React.createRef()
    this.ref2 = React.createRef()
    this.ref3 = React.createRef()
    this.ref4 = React.createRef()
    this.ref5 = React.createRef()
    this.ref6 = React.createRef()
    this.ref7 = React.createRef()
    this.ref8 = React.createRef()
    this.ref9 = React.createRef()

    this.state = {
      data: [],
      isLoading: true,
      type:'new',
      avatar: null,
      modalVisible: false,
      settingsMobileVisible:false,
      sortButtons: [true, false, false],
      // categories:[false,false,false,false,false,false,false,false,false,false,],
      categories:{},
      tempCategories:{},
      scrolledHeader: new Animated.Value(0),
      scrolledPadding: new Animated.Value(0),
      sortMobile:0,
    };
  };



  async componentDidMount() {

    this.props.actions.getStoreItems(this.state.type, this.state.categories)
    const email = 'child_2@mail.com'
    const password = 'password'

    if (!this.props.auth.childId) {
			this.props.actions.clearAuth()
			.then(()=>{this.props.navigation.navigate('Auth')})
		}
    // this.focusListener = this.props.navigation.addListener('didFocus', () => {
    //   this.props.actions.login(email, password).then(async ()=>{await this.getData()});
    //   console.log()


    // });

  }

  componentWillUnmount() {
  }


  parseInt(value) {
    return value !== '' ? parseInt(value) : 0
  }


  defineValue(width, height, a, b, c) {
    if (width<850 && width<height) {
      return a
    }
    else if (width<850 && width> height) {
      return b
    }
    else {
      return c
    }
  }

  logout = async() => {
    // this.props.actions.logout();
    AsyncStorage.clear();
    // this.props.navigation.navigate('Login')
}

    renderCard({item, layout}) {
      const title = item.title;
      // console.log(item.price)
        return <RenderShopCard item={item}
        onPress={()=>{this.setState({modalVisible:true,confirmationGoal:true, title:title,
          price:item.price})}}
          onPressPurchase={()=>{this.setState({modalVisible:true,confirmationPurchase:true, title:title,
            price:item.price})}} />
    }

    handleScroll(e, height) {
      if (e.nativeEvent.contentOffset.y/height>0.1 &&this.state.header!==true) {
        // this.props.navigation.setParams({scrolledHeader:true})
        Animated.timing(this.state.scrolledHeader, {
          toValue: 1,
          duration: 1000,
        }).start();
        Animated.timing(this.state.scrolledPadding, {
          toValue: 1,
          duration: 200,
        }).start();
      this.setState({header:true})
      return 0
      }

      if (e.nativeEvent.contentOffset.y/height<0.1) {
        // this.props.navigation.setParams({scrolledHeader:false})
        Animated.timing(this.state.scrolledHeader, {
          toValue: 0,
          duration: 1000,
        }).start();
        Animated.timing(this.state.scrolledPadding, {
          toValue: 0,
          duration: 200,
        }).start();
        this.setState({header:false})
        return 0
      }
    }

    confirmationGoal() {
      const {parentId, childId} = this.props.auth;
      console.log('this.state.price,this.state.title')
      console.log(this.state.price,this.state.title)

      return <View style={{justifyContent:'center', alignItems:'center'}}>
        <Image style={{width: 70, height: 70, marginTop:'10%'}} source={require('../../assets/Goals/Goal_2.png')}/>
        <View style={{marginVertical:15, alignItems:'center'}}><Text style={{fontSize:18, margin:5, color:'#5aca3c'}}>Add</Text>
            <Text style={{fontSize:20, margin:5, color:'#5aca3c', }}>{this.state.title}</Text>
      </View>
      <View style={{flexDirection:'row', width:'100%', justifyContent:'center', marginHorizontal:15,}}>
        <View style={{width:'40%',}}>
        <TouchableNativeFeedback onPress={()=>{this.setState({modalVisible:false, confirmationGoal:false});
                            this.props.actions.addGoal(this.state.price.toString(), this.state.title, parentId, childId) }}>
          <View style={{justifyContent:'center', alignItems:'center', height:35, borderRadius:10,
          margin:5, backgroundColor:'rgba(126,193,61,1)'}}>
            <Text style={{fontSize:19,marginVertical:5, ...defaultStyles.bold, color:'white'}}>YES</Text></View>
            </TouchableNativeFeedback>
            </View>
            <View style={{width:'40%',}}>
            <TouchableNativeFeedback onPress={()=>this.setState({modalVisible:false, confirmationActivate:false})}>
          <View style={{justifyContent:'center', alignItems:'center',height:35,borderRadius:10,margin:5,
           backgroundColor:'#EE5060'}}>
            <Text style={{fontSize:19,marginVertical:5, ...defaultStyles.bold, color:'white'}}>NO</Text></View>
            </TouchableNativeFeedback>
            </View>
      </View>
      </View>
    }

    confirmationPurchase() {
      const {parentId, childId} = this.props.auth;
      // const link = ''
      const goal = {name:this.state.title, price:this.state.price, }
      return <View style={{justifyContent:'center', alignItems:'center'}}>
        <Image style={{width: 70, height: 70, marginTop:'10%'}} source={require('../../assets/Goals/Goal_2.png')}/>
        <View style={{marginVertical:15, alignItems:'center'}}><Text style={{fontSize:18, margin:5, color:'#5aca3c'}}>Buy</Text>
            <Text style={{fontSize:20, margin:5, color:'#5aca3c', }}>{this.state.title}</Text>
      </View>
      <View style={{flexDirection:'row', width:'100%', justifyContent:'center', marginHorizontal:15,}}>
        <View style={{width:'40%',}}>
        <TouchableNativeFeedback onPress={()=>{this.setState({modalVisible:false, confirmationPurchase:false});
                            this.props.actions.purchaseItem(goal, parentId, childId).then(()=>{this.setState({isLoading:true});this.props.actions.getGoals(parentId, childId).then(this.setState({isLoading:false}))}) }}>
          <View style={{justifyContent:'center', alignItems:'center', height:35, borderRadius:10,
          margin:5, backgroundColor:'rgba(126,193,61,1)'}}>
            <Text style={{fontSize:19,marginVertical:5, ...defaultStyles.bold, color:'white'}}>YES</Text></View>
            </TouchableNativeFeedback>
            </View>
            <View style={{width:'40%',}}>
            <TouchableNativeFeedback onPress={()=>this.setState({modalVisible:false, confirmationPurchase:false})}>
          <View style={{justifyContent:'center', alignItems:'center',height:35,borderRadius:10,margin:5,
           backgroundColor:'#EE5060'}}>
            <Text style={{fontSize:19,marginVertical:5, ...defaultStyles.bold, color:'white'}}>NO</Text></View>
            </TouchableNativeFeedback>
            </View>
      </View>
      </View>
    }

    settingsMobile(width){
      return <ScrollView style={{flex:1}}>
        <TouchableNativeFeedback onPress={()=>{let tmp = this.state.categories;
        this.setState({settingsMobileVisible:false, tempCategories:Object.assign({},this.state.categories) },
        ()=>{console.log('temp categories', this.state.tempCategories === this.state.categories)})
                                                  }}>
          <View style={{alignSelf:'flex-end', justifyContent:'flex-end'}}>
            <Text style={{fontSize:45, margin:15,}}>X</Text>
          </View>
        </TouchableNativeFeedback>

        <View style={{marginLeft:20}}>
          <Text>Order</Text>
        </View>
        <View style={{width:width*0.9, alignSelf:"center", flexDirection:'row',
      marginVertical:15, }}>
            <View style={{flex:1,justifyContent:'center', alignItems:'center',  width:width*0.3,}}>
              <TouchableNativeFeedback onPress={()=>{this.setState({sortButtons:[true,false,false], type:'Newest'})}}>
              <View elevation={5} style={{backgroundColor:'white', boxShadow:'2px 2px 15px #272827', height:80, width:80, borderRadius:80,
            justifyContent:'center', alignItems:'center', borderWidth:this.state.sortButtons[0]?3:0, borderColor:Colors.green1}}>
              <Text style={{textAlign:"center"}}>Newest first</Text>
              </View>
              </TouchableNativeFeedback>
            </View>
            <View  style={{flex:1,justifyContent:'center', alignItems:'center',width:width*0.3,}}>
              <TouchableNativeFeedback onPress={()=>{this.setState({sortButtons:[false,true,false], type:'PriceHigh'})}}>
              <View elevation={5}  style={{backgroundColor:'white', boxShadow:'2px 2px 15px #272827', height:80, width:80, borderRadius:80,
            justifyContent:'center', alignItems:'center', borderWidth:this.state.sortButtons[1]?3:0, borderColor:Colors.green1}}>
              <Text style={{textAlign:"center"}}>Price High to Low</Text>
              </View>
              </TouchableNativeFeedback>
            </View>
            <View style={{flex:1, justifyContent:'center', alignItems:'center', width:width*0.3,}}>
              <TouchableNativeFeedback onPress={()=>{this.setState({sortButtons:[false,false,true], type:'priceLow'})}}>
              <View elevation={5}  style={{backgroundColor:'white',boxShadow:'2px 2px 15px #272827', height:80, width:80, borderRadius:80,
            justifyContent:'center', alignItems:'center', borderWidth:this.state.sortButtons[2]?3:0, borderColor:Colors.green1}}>
              <Text style={{margin:5,textAlign:"center"}}>Price Low to High</Text>
              </View>
              </TouchableNativeFeedback>
            </View>
        </View>

        <View style={{marginLeft:20}}>
          <Text>Category</Text>
        </View>
        <View style={{flexDirection:'row', flex:1,justifyContent:'center', flexWrap:'wrap',}}>
        {side_bar_items.map((item, index)=>{
                      return <ButtonContainerMobile width={width}
                      onPress={()=>{
                        let newCategories = this.state.tempCategories //copy the array
                        if (newCategories[item.title]===true){
                          delete newCategories[item.title]
                        } //execute the manipulations
                        else {
                          newCategories[item.title] = true
                        }
                        this.setState({tempCategories:newCategories})
                      }}
                      isFocused={this.state.tempCategories[item.title]}
                       key={item.title} image={item.image} title={item.title}/>})}
        </View>
        <TouchableNativeFeedback onPress={()=>{let tmp = this.state.tempCategories;
          this.setState({settingsMobileVisible:false,
          categories:Object.assign({},this.state.tempCategories),
          },()=>{this.props.actions.getStoreItems(this.state.type, this.state.categories)})}}>
          <View style={{boxShadow:'2px 2px 15px #272827', margin:10, alignSelf:'center', borderRadius:10,width:100, padding:5, backgroundColor:Colors.green1}}>
            <Text style={{color:'white', textAlign:'center'}}>Apply</Text>
          </View>
        </TouchableNativeFeedback>
      </ScrollView>
    }



    dropdown =(vmin, width) =>{
      const {navigation} = this.props;
      var color = this.state.scrolledHeader.interpolate({
        inputRange: [0, 1],
        outputRange: ['transparent', 'rgba(255, 255, 255, 1)']
    });
    var paddingTop = this.state.scrolledPadding.interpolate({
      inputRange: [0, 1],
      outputRange: [0.02*vmin, 0]
  });
    let headerLeftImage = this.state.header?
    <Image resizeMode={'contain'} style={{flex:1,zIndex:4, minHeight:35,  alignItems:'flex-start',
    height:vmin*0.07, maxHeight: 50, alignSelf:'flex-start', justifyContent:'flex-start',
    width:Platform.OS==='web'?vmin*0.15:80,maxWidth:160, marginLeft:this.state.header?10:10, }}
    source={require('../../assets/Pennygem_Logo_Gem_Text_Onside_Greeen.png')} />
      :<Image resizeMode={'contain'} style={{flex:1,zIndex:4, minHeight:35,  alignItems:'flex-start',
      height:vmin*0.07, maxHeight: 50, alignSelf:'flex-start', justifyContent:'flex-start',
      width:Platform.OS==='web'?vmin*0.15:80,maxWidth:100, marginLeft:-10 }}
      source={require('../../assets/Store/gem.png')} />

      let headerRight = width>850?
      <View style={{flexDirection:'row', paddingRight:15}}>
        <HeaderButton title={'Dashboard'} onPress={()=>{this.props.navigation.navigate('Dashboard')}}/>
        <HeaderButton title={'Logout'} onPress={()=>{this.props.navigation.navigate('Home')}}/>
      </View>:
      <TouchableOpacity onPress={()=>{this.setState({ visible: !this.state.visible})}}>
      <Icon style={{alignSelf:'flex-end', zIndex:3,marginHorizontal:10,marginBottom:10, }} name="bars" size={30} color="#F9CD30" />
  </TouchableOpacity>

      return <Animated.View style={{flex:1,width:'100%',zIndex:4, position:'absolute',
      paddingTop:Platform.OS==='web'?paddingTop:30,backgroundColor:color, flexDirection:'row',justifyContent:'space-between'}}>
        <TouchableOpacity onPress={() => this.props.navigation.navigate('Dashboard')}>
            <View style={{width:200,}}>
                  {headerLeftImage}
            </View>
        </TouchableOpacity>
      {headerRight}

    {this.state.visible?<View elevation={5} style={{position:'absolute', top:70,  right:20, backgroundColor:'white',
    alignSelf:'flex-end', width:'30%', maxWidth:120, borderRadius:10, marginRight:10}}>
          <TouchableNativeFeedback onPress={()=>{this.props.navigation.navigate('Dashboard')}}><View style={{}}>
            <Text style={{margin:10,color:'#106433',}}>Dashboard</Text></View></TouchableNativeFeedback>
          <TouchableNativeFeedback  onPress={()=>{this.props.navigation.navigate('Home')}}><View style={{flex:1,}}>
            <Text style={{flex:1,margin:10,color:'#106433'}}>Logout</Text></View></TouchableNativeFeedback>
          </View>:null}
      </Animated.View>
    }


  render() {

    return (
      <Resizable>
      {layout => { const vmin = Math.min(...[layout.width,layout.height]);
        return <View style={{ flex: 1,  }}>
          {/* {this.header(vmin)} */}
          {this.dropdown(vmin,layout.width)}
          {<Modal
            animationIn={'zoomIn'}
            animationOut={'zoomOut'}
            animationInTiming={1500}
            animationOutTiming={1500}
            backdropColor={'darkgrey'}
            backdropOpacity={0.6}
            onBackdropPress={() => this.setState({ modalVisible: false, confirmationDelete:false,
                      confirmationPurchase:false, confirmationActivate:false})}
            isVisible={this.state.modalVisible}
            onRequestClose={() => {this.setState({ modalVisible: false, confirmationDelete:false,
              confirmationPurchase:false, confirmationActivate:false})}}>
              <View style={{borderRadius:15, backgroundColor:'white', width:'80%',alignSelf:'center',
              maxWidth: 400, paddingBottom:10}}>
                <View style={{}}>
              {this.state.confirmationGoal?this.confirmationGoal():null}
              {this.state.confirmationPurchase?this.confirmationPurchase():null}
              </View>
              </View>
            </Modal>}
            {<Modal
            animationIn={'zoomIn'}
            animationOut={'zoomOut'}
            animationInTiming={500}
            style={{margin: 0}}
            animationOutTiming={500}
            // backdropOpacity={0.6}
            // onBackdropPress={() => this.setState({settingsMobileVisible:false})}
            isVisible={this.state.settingsMobileVisible}
            onRequestClose={() => {this.setState({settingsMobileVisible:false})}}>
              <View style={{flex:1, margin:0, backgroundColor:'white'}}>
              {this.settingsMobile(layout.width)}
              </View>
            </Modal>}

            {Platform.OS!=='web' && this.state.header?<TouchableNativeFeedback onPress={()=>this._scroll.scrollTo({x: 0, y: 0, animated: true})}>
                   <View elevation={5} style={{position:'absolute', bottom:layout.height*0.1, right:10, width:40, height:40, borderRadius:30, backgroundColor:Colors.green1, zIndex:10,
                    boxShadow:'2px 2px 15px #272827', justifyContent:'center', alignItems:'center'}}>
                    <Icon style={{ zIndex:3,}} name="arrow-up" size={30} color="white" />
                    </View>
                  </TouchableNativeFeedback>:null}


        <ImageBackground resizeMode={'stretch'} style={{ flex: 1, width: '100%', height: '100%' }}
        source={require('../../assets/Store/Gradient_BG.jpg')}>
          <Stars />
          <ScrollView ref={ref=>this._scroll = ref} {...Platform.OS==='web'?{stickyHeaderIndices:[1,2]}:{}}  style={{ flex:1, zIndex:3}}
          onScroll={(e) => this.handleScroll(e, layout.height)}>
            {/* <View style={{zIndex:4, position:'absolute', top:0, }}> */}
            <Image resizeMode={'contain'} style={{width: '100%', top:0,
             height: vmin>900?180:120}}
            source={require('../../assets/Store/banner4.png')} />

            {Platform.OS==='web'?<View style={{width:layout.width>850?300:'100%', position:'absolute',
                 display:(layout.width>850 || this.state.showFilters )?'flex':('none'),
                borderRightColor:'white',marginTop:0.1*vmin,
                borderRightWidth:2,  justifyContent:'space-around', flexDirection:layout.width>850?'row':'column'}}>
                    <View style={{justifyContent:'center', flexDirection:layout.width>850?'column':'row'}}>
                      {side_bar_items.slice(0,5).map((item, index)=>{
                      return <View key={item.title} style={{flexDirection:'column'}}><ButtonContainer
                      onPress={()=>{
                        const newCategories = this.state.categories //copy the array
                        if (newCategories[item.title]===true){
                          delete newCategories[item.title]
                        } //execute the manipulations
                        else {
                          newCategories[item.title] = true
                        }
                        this.setState({categories:newCategories},
                          ()=>{this.props.actions.getStoreItems(this.state.type, this.state.categories)})
                      }}
                      isFocused={this.state.categories[item.title]}
                      ref={this[`ref${index}`]}  image={item.image} title={item.title}/>
                      </View>})}</View>
                    <View style={{justifyContent:'center',flexDirection:layout.width>850?'column':'row'}}>
                      {side_bar_items.slice(5,10).map((item, index)=>{
                      return <ButtonContainer
                      onPress={()=>{
                        const newCategories = this.state.categories //copy the array
                        if (newCategories[item.title]===true){
                          delete newCategories[item.title]
                        } //execute the manipulations
                        else {
                          newCategories[item.title] = true
                        }
                        this.setState({categories:newCategories},
                          ()=>{this.props.actions.getStoreItems(this.state.type, this.state.categories)})
                      }}
                      isFocused={this.state.categories[item.title]}
                      ref={this[`ref${index+5}`]} key={item.title} image={item.image} title={item.title}/>})}</View>
                </View>:null}

                {Platform.OS==='web'?<TouchableNativeFeedback onPress={()=>this._scroll.scrollTo({x: 0, y: 0, animated: true})}>
                   <View style={{position:'absolute', top:layout.height*0.9, right:10, width:40, height:40, borderRadius:30, backgroundColor:Colors.green1,
                    boxShadow:'2px 2px 15px #272827', justifyContent:'center', alignItems:'center'}}>
                    <Icon style={{ zIndex:3,}} name="arrow-up" size={30} color="white" />
                    </View>
                  </TouchableNativeFeedback>:null}

            <View style={{flex:1, flexDirection:layout.width>850?'row':'column', marginTop:0.1*vmin}}>

                <View style={{flex:1,marginTop:layout.width>850?0.05*vmin:0, alignItems:'center',
                marginLeft:layout.width>850?300:0}}>
                    {Platform.OS==='web'?<View elevation={5} style={{ display:layout.width>850?'flex':'none',
                      width:'100%',flexDirection:layout.width>850?'row':'column', justifyContent:'center', alignItems:'center'}}>

                            <SortButton title={'Newest'}
                            onPress={()=>{this.setState({sortButtons:[true,false,false], type:'new'},
                            ()=>{this.props.actions.getStoreItems(this.state.type, this.state.categories)})}}
                            active={this.state.sortButtons[0]}
                            />
                            <SortButton title={'Price High to Low'}
                            onPress={()=>{this.setState({sortButtons:[false,true,false], type:'priceHigh'},
                            ()=>{this.props.actions.getStoreItems(this.state.type, this.state.categories)})}}
                            active={this.state.sortButtons[1]}
                            />
                            <SortButton title={'Price Low to High'}
                            onPress={()=>{this.setState({sortButtons:[false,false,true], type:'priceLow'},
                            ()=>{this.props.actions.getStoreItems(this.state.type, this.state.categories)})}}
                            active={this.state.sortButtons[2]}
                            />
                    </View>:null}
                    <View style={{width:'90%',flexDirection:'row', justifyContent:layout.width>850?'center':'space-between'}}>
                      <Text style={{color:'white',  marginVertical: 0.03*vmin, fontSize:18, ...defaultStyles.bold,}}>
                        Displaying {this.props.store.data.length} {this.props.store.data.length===1?'item':'items'}
                      </Text>
                      <TouchableNativeFeedback onPress={()=>{
                        let tmp = this.state.categories;
                        console.log('temp categories START FILTER BEFORE', this.state.tempCategories,this.state.categories);
                      this.setState({settingsMobileVisible:true,},
                        ()=>{console.log('temp categories START FILTER', this.state.tempCategories,this.state.categories)})}}>
                      <View style={{alignSelf:'flex-end', display:layout.width>850?'none':'flex'}}>
                  <Image resizeMode={'contain'} style={{ zIndex:4,borderRadius:80, width: 40, height:40 }}
                  source={require('../../assets/Store/filter.png')} />
                </View>
                </TouchableNativeFeedback>
                    </View>


                    <FlatList
                    style={{flex:1, width:'100%', marginBottom:0.05*vmin}}
                    // data={this.state.data}
                    data={this.props.store.data}
                    keyExtractor={(item,index) => index.toString()}
                    renderItem={(item)=>this.renderCard(item)}
                    />

                    {/* {this.renderCard()} */}

                </View>
            </View>
            </ScrollView>
        </ImageBackground>
      </View>}}
      </Resizable>
    )
  }
}

const styles = StyleSheet.create({

});

// export default Dashboard;
export default connect(state =>
  {
      // console.log(Object.keys(state.vacancies));
      return ({
          auth: state.kidAuth,
          store: state.store,
      })},
  (dispatch) => ({
      actions: bindActionCreators({...storeActions,...authActions, ...goalsActions}, dispatch)
  })
)(Dashboard);
