/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { Image, ImageBackground, Keyboard, KeyboardAvoidingView, StyleSheet, ScrollView, TextInput, View, Platform } from 'react-native';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Resizable, Hoverable } from 'react-native-web-hooks';

import { LinearGradient } from 'expo-linear-gradient';

import firebase from 'firebase/app';
import 'firebase/database'
import 'firebase/auth'
// import 'firebase/analytics'
import * as Analytics from 'expo-firebase-analytics';

import TouchableNativeFeedback from '../components/TouchableFeedback'
// import FloatingLabelInput from '../components/FloatingLabelInput'
import Text from '../components/CustomText'
import { ConfirmationView, } from '../components/confirmation'
import Stars from '../components/Stars';

import * as authActions from '../store/actions/authActions';
// import * as kidAuthActions from '../store/actions/kids/authActions';

import Colors from '../constants/Colors';
import config from '../constants/config';
import globalStyle, { defaultStyles } from '../constants/style';

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const auth = firebase.auth;
const database = firebase.database;
// const analytics = firebase.analytics;

const error_codes = {
	'auth/email-already-in-use': 1, 'auth/invalid-email': 2,
	'auth/operation-not-allowed': 3, 'auth/weak-password': 4
}

var actionCodeSettings = {
	// URL you want to redirect back to. The domain (www.example.com) for this
	// URL must be whitelisted in the Firebase Console.
	url: 'https://pennygembank.com/',
	// This must be true.
	handleCodeInApp: true,
	// iOS: {
	//   bundleId: 'com.example.ios'
	// },
	// android: {
	//   packageName: 'com.example.android',
	//   installApp: true,
	//   minimumVersion: '12'
	// },
	// dynamicLinkDomain: 'pennygembank.com'
};

const RejectionView = ({ title, subtitles, okText, okPress }) => {


	return <View style={{ justifyContent: 'center', alignItems: 'center' }}>

		<View style={{ marginVertical: 15, alignItems: 'center' }}><Text style={{ textAlign: 'center', color: Colors.gray0, fontSize: 18, margin: 5, }}>{title}</Text>
			{subtitles.map((subtitle, index) => <Text key={index} style={{ fontSize: 20, margin: 5, color: '#5aca3c', }}>{subtitle}</Text>)}
		</View>

		<View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', marginHorizontal: 15, }}>
			<View style={{ width: '80%', }}>
				<TouchableNativeFeedback onPress={okPress}>
					<LinearGradient
						start={[0, 1]} end={[1, 0]}
						colors={[Colors.green2, Colors.green1]}
						style={{ justifyContent: 'center', alignItems: 'center', borderRadius: 5, margin: 5, borderRadius:20 }}>
						{/* <View style={{
				justifyContent: 'center', alignItems: 'center', borderRadius: 10, margin: 5, backgroundColor: 'rgba(126,193,61,1)'
			  }}> */}
						<Text style={[{ textAlign: 'center', marginVertical: 5, marginHorizontal: 15, color: 'white' }, defaultStyles.bold, globalStyle.h3]}>
							{okText ? okText : 'OK'}</Text>
						{/* </View> */}
					</LinearGradient>
				</TouchableNativeFeedback>
			</View>
		</View>
	</View>

}

export const AuthTextInput = ({ value, onSubmitEditing, onChangeText, placeholder, inputRef, secureTextEntry, keyboardType }) => {
	return <TextInput
		value={value}
		onSubmitEditing={onSubmitEditing}
		ref={inputRef}
		secureTextEntry={secureTextEntry || false}
		keyboardType={keyboardType || 'default'}
		onChangeText={text => onChangeText(text)}
		style={{
			color: Colors.gray1,
			...Platform.OS === 'web' ? {outlineWidth: 0 } : {outline: 'none'}, borderRadius:20, borderWidth: 3, borderColor:Colors.gray,
			backgroundColor: 'white', height: 40, width: '90%', fontFamily: 'Carmen Sans Regular',
			paddingLeft: 10, justifyContent: 'center', marginVertical: 10,
		}}
		multiline={false} placeholder={placeholder}
	/>
}

// const AuthTextInput = AuthTextInput({})

export const Button = ({ title, onPress }) => {
	return <TouchableNativeFeedback style={{ marginVertical: 12, width: '90%' }}
		onPress={onPress}>
		<LinearGradient start={[0, 1]} end={[1, 0]}
			colors={[Colors.green2, Colors.green1]}
			// colors={['#de3c95', '#ffd71b']}
			style={[{borderRadius:20,justifyContent: 'center', height: 40, width: '100%', backgroundColor: '#7ec13d', }]}>
			<Text style={[{ color: 'white', alignSelf: 'center',}, defaultStyles.bold]}>
				{title.toUpperCase()}</Text>
		</LinearGradient>

	</TouchableNativeFeedback>

}

class SignUp extends React.Component {

	constructor(props) {
		super(props);
		this.emailInput = React.createRef();
		this.passwordInput = React.createRef();
		this.confirmPasswordInput = React.createRef();

		this.state = {
			displayName: '',
			email: '',
			password: '',
			success: false,
			// final:true
		};
	};

	enterFunction = (event) => {
		if (event.keyCode === 13) {
			this.setState({ authModalVisible: false })
		}
	}

	componentDidMount() {
		Platform.OS === 'web' ? global.document.addEventListener("keydown", this.enterFunction, false) : null;
	}

	componentWillUnmount() {
		Platform.OS === 'web' ? global.document.removeEventListener("keydown", this.enterFunction, false) : null;
	}

	signup() {

		const email = this.state.email
		const password = this.state.password
		const displayName = this.state.displayName

		this.state.password === this.state.confirmPassword ?
			auth().createUserWithEmailAndPassword(email, password)
				// .then(user => user.user.updateProfile({ displayName: displayName }))
				.then(() => {
					const user = firebase.auth().currentUser;
					this.props.actions.signup(user)

					database().ref('emails/').update({
						[user.uid]: user.email
					});

					this.setState({
						success: true, authModalVisible: true,
						// userName: user.displayName, 
						email: user.email
					})
					database().ref().child('user_type/' + user.uid).update({ type: 0, parentId: user.uid })

					Analytics.logEvent('sign_up')
				})
				.catch((error) => {
					this.setState({ authModalVisible: true })
					var errorCode = error.code;
					var errorMessage = error.message;

					switch (errorCode) {
						case 'auth/email-already-in-use':
							this.setState({ errorBlock: true, errorText: errorMessage }); return 0
						case 'auth/invalid-email':
							this.setState({ errorBlock: true, errorText: errorMessage }); return 0
						case 'auth/operation-not-allowed':
							this.setState({ errorBlock: true, errorText: errorMessage }); return 0
						case 'auth/weak-password':
							this.setState({ errorBlock: true, errorPassword: true, errorText: errorMessage }); return 0
						default:
							this.setState({ errorBlock: true, errorText: 'Something went wrong =(' }); return 0
					}
				})
			:
			this.setState({ authModalVisible: true, errorBlock: true, errorText: 'Passwords don\'t match' });
	}


	render() {
		const { layout } = this.props;

		const vmin = Math.min(...[layout.width, layout.height]);

		return (<View style={{ minWidth: 300, justifyContent: "center", alignItems: 'center', borderRadius: 15, backgroundColor: Colors.transparentWhite }}>
			<Modal
				animationIn={'zoomIn'} animationOut={'zoomOut'} animationInTiming={300} animationOutTiming={300}
				backdropColor={'darkgrey'} backdropOpacity={0.1}
				onBackdropPress={() => this.setState({ authModalVisible: false })}
				isVisible={this.state.authModalVisible}
				onRequestClose={() => { this.setState({ authModalVisible: false }) }}>
				<View elevation={5} style={{
					borderRadius: 20, backgroundColor: 'white', width: '80%', alignSelf: 'center',
					maxWidth: 400, minHeight: 100, paddingVertical: 15, justifyContent: 'center', boxShadow: "2px 2px 15px #272827"
				}}>
					{this.state.success ? <ConfirmationView textStyle={{ fontSize: 30, color: Colors.gray, margin: 10, textAlign: 'center' }}
						image={null} title={'Welcome to Pennygem! To start using the app, please confirm your email'}
						subtitles={[]} confirmText={'Yes, perfect!'} rejectText={'No, thank you'}
						confirmPress={() => {
							// this.props.actions.sendRegistrationEmail(this.state.userName, this.state.email);
							// this.props.actions.sendVerificationEmail(this.state.userName, this.state.email);
							this.setState({ authModalVisible: false, final: true })
							this.props.actions.login({ user: firebase.auth().currentUser })
								.then(() => this.props.navigation.navigate('NewKid'))
						}}
						rejectPress={() => {
							var user = firebase.auth().currentUser;

							user.delete().then(function () {
								// User deleted.
								console.log('delete rly?')
							}).catch(function (error) {
								// An error happened.
								console.log('An error happened.')

							});
							this.setState({ authModalVisible: false })
						}}
					/> :
						<RejectionView title={this.state.errorText} subtitles={[]} okPress={() => { this.setState({ authModalVisible: false }) }} />}
				</View>
			</Modal>

			{this.state.final ?
				<View style={{ flex: 1, maxWidth: 280, marginVertical: 70, justifyContent: 'center', alignItems: 'center' }}>
					<Text style={{ margin: 20, fontSize: 30, textAlign: 'center' }}>
						Please check your email for confirmaion link.</Text>
				</View>
				:
				// <View style={{ justifyContent: 'center', alignItems: 'center' }}>
				<React.Fragment>
					<Text style={[{
						marginTop: 0.04 * vmin, color: Colors.black, margin: 0.02 * vmin, textAlign: 'center',
					}, defaultStyles.bold, globalStyle.h2]}>Sign Up for Free!</Text>
					<View style={{ width: '100%', alignItems: 'center' }}>
						{/* <AuthTextInput value={this.state.displayName}
							onSubmitEditing={() => { this.emailInput.current.focus() }}
							onChangeText={text => this.setState({ errorBlock: false, displayName: text })} 
							placeholder={'Parent User Name'}/> */}
						<AuthTextInput
							inputRef={this.emailInput} keyboardType={'email-address'} value={this.state.email}
							onChangeText={text => this.setState({ errorBlock: false, email: text })}
							onSubmitEditing={() => { this.passwordInput.current.focus() }}
							placeholder={'Email Address'}
						/>
						<AuthTextInput
							inputRef={this.passwordInput} onSubmitEditing={() => { this.confirmPasswordInput.current.focus() }}
							secureTextEntry={true} value={this.state.password}
							onChangeText={text => this.setState({ errorBlock: false, password: text })}
							placeholder={'Password'}
						/>
						<AuthTextInput
							inputRef={this.confirmPasswordInput} onSubmitEditing={() => { this.signup() }}
							secureTextEntry={true} value={this.state.confirmPassword}
							onChangeText={text => this.setState({ errorBlock: false, confirmPassword: text })}
							placeholder={'Confirm Password'}
						/>
					</View>
					<Button title={'Get Started'} onPress={() => {
						this.signup()
						// this.setState({final:true})
					}} />

				</React.Fragment>
				// </View>
			}
		</View>
		)
	}
}

class Login extends React.Component {

	constructor(props) {
		super(props);
		this.emailInput = React.createRef();
		this.passwordInput = React.createRef();

		this.state = {
			email: '',
			password: '',
			rememberMe: false
		};
	};

	enterFunction = (event) => {
		if (event.keyCode === 13) {
			this.setState({ authModalVisible: false })
		}
	}

	componentDidMount() {
		this.setState({ email: this.props.auth.email })

		Platform.OS === 'web' ? global.document.addEventListener("keydown", this.enterFunction, false) : null;
	}

	componentWillUnmount() {
		Platform.OS === 'web' ? global.document.removeEventListener("keydown", this.enterFunction, false) : null;
	}


	login = async () => {
		let email = this.state.email
		const password = this.state.password

		if (email.indexOf('@') === -1) {
			// let username = (await database().ref('children/').orderByChild('username').equalTo(email.toLowerCase()).once('value')).val()
			let username = (await database().ref('username/').orderByKey().equalTo(email.toLowerCase()).once('value')).val()

			if (username) {
				console.log('username', username)
				// email = Object.values(username)[0].email
				email = Object.values(username)[0]
			}
		}

		auth().signInWithEmailAndPassword(email, password).then(async user => {

			// if (auth().currentUser.emailVerified === false) {
			// 	if (__DEV__) { }
			// 	else { throw ({ message: 'Email not verified', code: 'auth/email-not-verified' }) }
			// }
			let user_type = (await database().ref().child('user_type/' + user.user.uid + '/type').once('value')).val()
			return { type: user_type, user: user };
		})
			.then((res) => {

				if (res.type === 0 || res.type === 1) {
					this.props.actions.login(res.user, res.type).then(() => {
						this.state.rememberMe ? this.props.actions.rememberMe(email) : this.props.actions.rememberMe('');
						res.type ? this.props.navigation.navigate('Kid') : this.props.navigation.navigate('Parent');
					})
					Analytics.logEvent('login')
				}
				else {
					throw ({ code: 'error/no-type', message: 'No user type' })
				}

			})
			.catch(error => {
				const errorMessage = error.message;
				console.log(error)
				this.setState({ authModalVisible: true })
				switch (error.code) {
					case 'auth/invalid-email':
						this.setState({ errorBlock: true, errorText: errorMessage }); return 0
					case 'auth/user-disabled':
						this.setState({ errorBlock: true, errorText: errorMessage }); return 0
					case 'auth/user-not-found':
						this.setState({ errorBlock: true, errorText: errorMessage }); return 0
					case 'auth/wrong-password':
						this.setState({ errorBlock: true, errorPassword: true, errorText: errorMessage }); return 0
					case 'auth/email-not-verified':
						this.setState({ verifyEmail: true, errorText: errorMessage }); return 0
					default:
						this.setState({ errorBlock: true, errorText: 'Something went wrong =(' }); return 0
				}
			})
	}

	render() {

		return (<View style={{ minWidth: 300, justifyContent: 'center', alignItems: 'center', borderRadius: 15, backgroundColor: Colors.transparentWhite }}>
			<Modal
				animationIn={'zoomIn'} animationOut={'zoomOut'} animationInTiming={300} animationOutTiming={300}
				backdropColor={'darkgrey'} backdropOpacity={0.1} isVisible={this.state.authModalVisible}
				onBackdropPress={() => this.setState({ authModalVisible: false })}
				onRequestClose={() => { this.setState({ authModalVisible: false }) }}>
				<View elevation={5} style={{
					borderRadius: 20, backgroundColor: 'white', width: '80%', alignSelf: 'center',
					maxWidth: 400, minHeight: 100, paddingVertical: 15, justifyContent: 'center', boxShadow: "2px 2px 15px #272827"
				}}>
					{this.state.verifyEmail ? <ConfirmationView textStyle={{ fontSize: 35, color: Colors.gray, margin: 10, textAlign: 'center' }}
						image={null} title={'Your email isn\'t verified, please check your email for confirmaion link.'}
						subtitles={['Should we send you another verificaiton email?']} confirmText={'Yes'} rejectText={'No'}
						confirmPress={() => {
							this.props.actions.sendVerificationEmail(this.state.userName, this.state.email);
							this.setState({ authModalVisible: false, verifyEmail: false })
						}}
						rejectPress={() => { this.setState({ authModalVisible: false, verifyEmail: false }) }} /> :
						<RejectionView
							title={this.state.errorText} subtitles={[]} okPress={() => { this.setState({ authModalVisible: false }) }} />}
				</View>
			</Modal>
			<React.Fragment>
				<Text style={[{
					color: Colors.black, textAlign: 'center', marginTop: 25, marginBottom: 15,
				}, defaultStyles.bold, globalStyle.h2]}>{'Sign In'.toUpperCase()}</Text>

				<View style={{ width: '100%', alignItems: 'center' }}>
					<AuthTextInput
						keyboardType={'email-address'} value={this.state.email}
						onChangeText={text => this.setState({ errorBlock: false, email: text })}
						onSubmitEditing={() => { this.passwordInput.current.focus() }}
						multiline={false} placeholder={'Email/Username'}
					/>
					<AuthTextInput
						inputRef={this.passwordInput}
						onSubmitEditing={() => { this.login(this.state.email, this.state.password) }} secureTextEntry={true} value={this.state.password}
						onChangeText={text => this.setState({ errorBlock: false, password: text })}
						placeholder={'Password'}
					/>
				</View>

				{/* <View style={{marginVertical:10, opacity:this.state.errorBlock?1:0}}>
      <Text>{this.state.errorText}</Text>
      </View> */}
				<Button title={'Log in'} onPress={() => this.login(this.state.email, this.state.password)} />

				<View style={{ flexDirection: 'row', marginVertical: 15, marginBottom: 30, justifyContent: 'space-between', }}>
					<TouchableNativeFeedback style={{ flexDirection: 'row' }} onPress={() => { this.setState({ rememberMe: !this.state.rememberMe }) }}>
						<View style={{ margin: 10, flexDirection: "row" }}>
							<View style={{ borderRadius: 15, borderColor:  Colors.black, borderWidth: 2, width: 15, height: 15, justifyContent: 'center', alignItems: 'center' }}>
								<View style={{ display: this.state.rememberMe ? 'flex' : "none", borderRadius: 30, backgroundColor:  Colors.black, width: '50%', height: '50%' }}></View>
							</View>
							<Text style={[{ marginLeft: 5, fontSize: 14, alignSelf: 'center', color: Colors.black, }, defaultStyles.bold]}>Remember me</Text>
						</View>
					</TouchableNativeFeedback>
					<TouchableNativeFeedback style={{ justifyContent: 'center' }} onPress={() => { this.props.navigation.navigate('RecoverPassword') }}>
						<Text style={[{ margin: 10, fontSize: 14, alignSelf: 'center', color: Colors.black, textDecorationLine: 'underline' }, defaultStyles.bold]}>Forgot Password?</Text>
					</TouchableNativeFeedback>
				</View>
			</React.Fragment>
		</View>)
	}
}


class LoginScreen extends React.Component {

	static navigationOptions = ({ navigation }) => {
		return {
			headerTransparent: true,
			header: null,
		}
	}


	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: '',
			login: true
		};
	};

	componentDidMount() {
		const { clearAuth, kidClearAuth } = this.props.actions;

		clearAuth();
		// kidClearAuth()

		this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this._keyboardDidShow);
		this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this._keyboardDidHide);
		// if (this.props.auth.parentId && !this.props.kidAuth.parentId) {
		// 	this.props.navigation.navigate('Parent')
		// }
		// else if (this.props.kidAuth.childId) {
		// 	this.props.navigation.navigate('Kid')
		// }
	}

	componentWillUnmount() {
		this.keyboardDidShowListener.remove();
		this.keyboardDidHideListener.remove();
	}

	_keyboardDidShow = () => {
		this.setState({
			keyboardState: true
		});
	}

	_keyboardDidHide = () => {
		this.setState({
			keyboardState: false
		});
	}

	findNested(obj, key, value) {
		if (obj[key] === value) {
			return obj;
		} else {
			for (var i = 0, len = Object.keys(obj).length; i < len; i++) {
				if (typeof obj[i] == 'object') {
					var found = this.findNested(obj[i], key, value);
					if (found) {
						console.log('FOUND')
						// If the object was found in the recursive call, bubble it up.
						return found;
					}
				}
			}
		}
	}


	render() {
		// if (!this.state.fontsLoaded) {
		// return <AppLoading />
		// }
		return (
			<Resizable>

				{layout => {
					const vmin = Math.min(...[layout.width, layout.height]);
					return <KeyboardAvoidingView behavior={'position'} style={{ flex: 1 }}>
						<View style={{ height: '100%' }}>
							{/* <ImageBackground resizeMode={'stretch'} style={{
								justifyContent: 'center', flex: 1, width: '100%',
							}} source={require('../assets/BG_1.jpg')}> */}
								{/* <Stars layout={layout} color={'white'} /> */}
								<View style={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
									<View style={{ height: '100%', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
										{layout.height > 500 ? <View style={{}}>
											<Image resizeMode={'contain'} style={{
												top: layout.height * 0.05,
												width: layout.height * 0.25, height: layout.height * 0.25 * 9 / 16, maxHeight: 160 * 4, maxWidth: 90 * 4
											}}
												source={require('../assets/Pennygem_Logo.png')} />
										</View> : null}
										<View style={[{ maxWidth: 850, paddingVertical: 10, justifyContent: 'center', borderRadius:20, }, defaultStyles.shadow]}>
											{/* <AuthTextInput></AuthTextInput> */}
											{this.state.login ? <Login layout={layout} {...this.props} /> : <SignUp layout={layout} {...this.props} />}
										</View>
										{/* <Text>test</Text> */}
										<View style={{width:'100%', ...vmin>700?{flex:0.6}:{}}}>
										{this.state.login ?
											<View style={{ marginBottom: 40, width: '100%', alignItems: 'center' }}>
												<View style={{ top: '20%', width: '100%', alignItems: 'center' }}>
													<Text style={{ color: Colors.black }}>Don't nave an account?</Text>
													<Button title={'Parent Sign Up'} onPress={() => this.setState({ login: false })} />
												</View>
											</View>
											:
											<View style={{ marginBottom: 40, width: '100%', alignItems: 'center', }}>
												<View style={{ top: '20%', width: '100%', alignItems: 'center' }}>

													<Text style={{ color:  Colors.black }}>Already have an account?</Text>
													<Button title={'Log in'} onPress={() => this.setState({ login: true })} />

												</View>
											</View>}
										</View>
									</View>
								</View>
							{/* </ImageBackground> */}
						</View>
					</KeyboardAvoidingView>
				}}
			</Resizable>
		)
	}
}

const styles = StyleSheet.create({
	sectionContainer: {
		marginTop: 32,
		paddingHorizontal: 24,
	},
});

export default connect(state => {
	return ({
		// fullState:state,
		auth: state.auth,
	})
},
	(dispatch) => ({
		actions: bindActionCreators({ ...authActions,}, dispatch)
	})
)(LoginScreen);
