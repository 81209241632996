import React from 'react';
import {
    Platform,
    StyleSheet,
    TouchableOpacity,
    TouchableNativeFeedback,
} from 'react-native';


class TouchableAwesomeFeedback extends React.Component {
    static navigationOptions = {
        header: null,
    };

    state = {
        isLoadingComplete: false,
        show: false,
        opacity: 0,
        phraseIndex: 0
    };

    render() {
        if (Platform.OS === 'android' ) {
            return (
                TouchableNativeFeedback.canUseNativeForeground()?
                    <TouchableNativeFeedback {...this.props}>
                        {this.props.children}
                    </TouchableNativeFeedback>:
                    <TouchableOpacity  {...this.props}>
                        {this.props.children}
                    </TouchableOpacity>
            );
        }
        else {
            return (
                <TouchableOpacity  {...this.props}>
                    {this.props.children}
                </TouchableOpacity>
            );
        }
    }

}

export default TouchableAwesomeFeedback;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // backgroundColor: '#eee',
        backgroundColor: 'white',

    },
});
