/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import {
    Image,
    Platform,
    TouchableOpacity,
    View,
} from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome5';

import Text from '../CustomText'
import TouchableNativeFeedback from '../TouchableFeedback';

import globalStyle, { defaultStyles } from '../../constants/style';
import Colors from '../../constants/Colors';
import { formatNumberFloat } from '../../helpers/formatNumber';

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const maxWidth = 700;
const widthThreshold = 600;
const cardBackgroundColor = 'rgba(255,255,255,0.5)'
const borderRadius = 25

const Button = ({ color, title, onPress, style, iconSize }) => {

    return <TouchableNativeFeedback style={Platform.OS === 'web' && [{}, style]} {...Platform.OS === 'web' ? { onClick: onPress } : { onPress: onPress }} >
        <View style={[{ marginVertical: 10, flex: 1, backgroundColor: color, justifyContent: 'center', alignItems: 'center', borderRadius: 5, }]}>
            {<View style={{
                flex: 1,
                // height: '100%',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Icon style={{ zIndex: 3, }} name={title} size={iconSize} color={'white'} />
            </View>}
        </View>
    </TouchableNativeFeedback>
}

const BottomButton = ({ color, title, onPress, image, style }) => {

    return <TouchableNativeFeedback style={[ { width: '100%' }, ]} {...Platform.OS === 'web' ? { onClick: onPress } : { onPress: onPress }} >
        <View style={{
            width: '100%', flexDirection: 'row',
            borderBottomLeftRadius: borderRadius, borderBottomRightRadius: borderRadius,
            backgroundColor: color, justifyContent: 'center', alignItems: 'center', height: 45,
        }}>
            {image
                ?
                <Image style={{ height: 35, width: 35, alignSelf: 'flex-start', }} resizeMode={'cover'} source={image} />
                :
                <Text style={[{ color: 'white', }, globalStyle.h1]}>{title}</Text>
            }
            {/* <Icon style={{ zIndex: 3, marginVertical: 10 }} name="plus" size={25} color={'white'} /> */}
        </View>
    </TouchableNativeFeedback>
}

const CornerIcon = ({ color, image, layout }) => {

    const resizeConst = (360 / 450);
    const width = layout.width * 0.1;
    const height = resizeConst * width;
    const minWidth = 45, minHeight = resizeConst * minWidth;
    const maxWidth = 65, maxHeight = resizeConst * maxWidth;

    return <View style={{
        zIndex: -1,
        position: 'absolute', left: 0, top: 0,
    }}>
        <Image resizeMode={'contain'} style={{ borderTopLeftRadius: borderRadius, width, height, minWidth, minHeight, maxHeight, maxWidth, alignSelf: 'flex-start' }} source={image} />
    </View>
}

const InfoBlock = ({ title, amount, onPress, style, amountStyle, titleStyle, center }) => {
    return <TouchableNativeFeedback style={style} onPress={onPress}>
        <View style={{ ...Platform.OS==='android' && {flex:1}, justifyContent: 'center', ...(center && { alignItems: 'center' }) }}>
            <Text style={[globalStyle.h2, defaultStyles.bold, amountStyle]}>{amount}</Text>
            <Text style={[{ marginTop: 5, }, titleStyle]}>{title}</Text>
        </View>
    </TouchableNativeFeedback>
}

export const ChildCard = ({ name, navigation, signOut, vmin, layout, clearAuth }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [changeImage, setChangeImage] = useState(false);

    const height = layout.height;
    const cardWidth = '90%'
    return <View style={{
        marginVertical: 10,
        flexDirection: 'row', borderRadius, width: cardWidth, maxWidth: maxWidth,
        backgroundColor: 'white', justifyContent: 'space-between', alignItems: 'center',
    }}>
        <Modal
            animationIn={'slideInLeft'} animationOut={'slideOutLeft'} animationInTiming={300} animationOutTiming={300}
            backdropColor={'darkgrey'} backdropOpacity={0.1}
            onBackdropPress={() => setChangeImage(false)}
            isVisible={changeImage}
            onRequestClose={() => setChangeImage(false)}>
            <View elevation={5} style={{
                alignSelf: 'center',
                flex: 1, justifyContent: 'center', maxHeight: 500, maxWidth: 500,
                backgroundColor: 'white', height: '100%', width: '100%',
                padding: 15, alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}>
                <TouchableNativeFeedback style={{ position: 'absolute', right: 20, top: 20 }}
                    onPress={() => setChangeImage(false)}>
                    <View>
                        <Text style={[{ color: Colors.rose, fontSize: 35, }, defaultStyles.bold]}>X</Text>
                    </View>
                </TouchableNativeFeedback>
                <View>
                    <Text style={[{ fontSize: 30, color: '#7ec13d' }, globalStyle.bold]}>Select</Text>
                </View>
                <TouchableNativeFeedback onPress={() => {
                    setChangeImage(true)
                    // this._launchCamera().then(() => { this.setState({ changeAvatarVisible: false, }) })
                }}>
                    <View style={{ width: '80%', borderRadius: 10, backgroundColor: '#7ec13d', padding: 10, paddingHorizontal: 15, margin: 10 }}>
                        <Text style={{ color: 'white' }}>Open Camera</Text>
                    </View>
                </TouchableNativeFeedback>
                <TouchableNativeFeedback onPress={() => {
                    setChangeImage(true)
                    // this._pickImage().then(() => { this.setState({ changeAvatarVisible: false, }) })
                }}>
                    <View style={{ width: '80%', borderRadius: 10, backgroundColor: '#7ec13d', padding: 10, paddingHorizontal: 15, margin: 10 }}>
                        <Text style={{ color: 'white' }}>Pick Image</Text>
                    </View>
                </TouchableNativeFeedback>
            </View>
        </Modal>
        <View>
            <TouchableNativeFeedback onPress={() => { }}>
                <Image style={{ width: 95, height: 95, alignSelf: 'flex-start' }} source={require('../../assets/Avatars/icon_2.png')} />
            </TouchableNativeFeedback>
            <Image style={{ position: 'absolute', right: -45, width: 40, height: 40, alignSelf: 'flex-start' }} source={require('../../assets/Pennygem_Logo.png')} />

            <View style={{
                zIndex: -1, position: 'absolute', bottom: '15%', left: '80%', backgroundColor: Colors.red,
                flexDirection: 'row', alignItems: 'center', height: 25,
                borderTopRightRadius: 10, borderBottomRightRadius: 10
            }}>
                <Text style={[{ paddingLeft: 40, paddingRight: 15, paddingVertical: 5, color: 'white' }, defaultStyles.bold,]}>{name}</Text>
                <Text style={[{ color: 'white', marginRight: 5, }, defaultStyles.bold]}>{'>'}</Text>
            </View>
        </View>
        <TouchableOpacity onPress={() => setModalVisible(true)}>
            <View>
                <Icon style={{ zIndex: 3, }} name="bars" size={40} color={Colors.green1} />
            </View>
        </TouchableOpacity>
        <Modal
            animationIn={'slideInLeft'} animationOut={'slideOutLeft'} animationInTiming={300} animationOutTiming={300}
            backdropColor={'darkgrey'} backdropOpacity={0.1}
            onBackdropPress={() => setModalVisible(false)}
            isVisible={modalVisible}
            style={{ margin: 0 }}
            onRequestClose={() => setModalVisible(false)}>
            <View elevation={5} style={{
                flex: 1, margin: 0, justifyContent: 'center',
                backgroundColor: 'white', height: '100%', width: '100%',
                padding: 15, alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}>
                <TouchableNativeFeedback style={{ position: 'absolute', right: 0.015 * vmin, top: 0.01 * height }}
                    onPress={() => setModalVisible(false)}>
                    <View style={{ position: 'absolute', right: 0.015 * vmin, top: 0.02 * height }}>
                        <Text style={[{ color: Colors.rose, fontSize: 35, }, defaultStyles.bold]}>X</Text>
                    </View>
                </TouchableNativeFeedback>

                <View style={{ marginTop: 0.12 * layout.height }}>
                    {['Settings', 'Dashboard', 'Chequing', 'Savings', 'Jobs', 'Goals', 'Purchases', 'Store'].map((item, index) => {
                        return <TouchableNativeFeedback key={index} onPress={() => { navigation.navigate(item); setModalVisible(false) }}>
                            <View style={{ margin: 10 }}>
                                <Text style={[{ textAlign: "center", color: Colors.green1, fontSize: 20 }, defaultStyles.bold]}>{item}</Text>
                            </View>
                        </TouchableNativeFeedback>
                    })}

                    <TouchableNativeFeedback onPress={() => {
                        signOut();
                        clearAuth().then(() => {
                            navigation.navigate('Login')
                        }).then(() => { });
                    }}>
                        <View style={{ margin: 15 }}>
                            <Text style={[{ textAlign: "center", color: Colors.green1, fontSize: 20 }, defaultStyles.bold]}>Logout</Text>
                        </View>
                    </TouchableNativeFeedback>
                </View>
            </View>
        </Modal>
    </View>
}


export const ChequingCard = ({ layout, upButton, downButton, style, navigation, earned, saved, spent, type }) => {
    const width = layout.width;
    const vmin = Math.min(...[layout.width, layout.height]);
    const squareSize = Platform.OS === 'web' ? '8vh' : '50%'
    const imageSize = Platform.OS === 'web' ? '18vh' : 100

    const cardWidth = '90%'

    return <View elevation={15} style={[{
        flexDirection: 'row', borderRadius, width: cardWidth, maxWidth: maxWidth,
        backgroundColor: 'white', justifyContent: 'space-between', alignItems: 'center',
        paddingHorizontal: 0.04 * vmin, paddingVertical: 0.03 * layout.height,
    }, defaultStyles.shadow, style]}>
        <View style={{ flex: 0.8, height: '100%',  justifyContent: 'space-between' }}>
            {/* <View style={{ flexDirection:'row', borderColor:'red'}}> */}
                <InfoBlock onPress={() => navigation.navigate('Chequing', { cards: 'Chequing' })} title={'Chequing'}
                    amount={'$'+formatNumberFloat(earned)} amountStyle={{ ...globalStyle.h2, color: Colors.green1 }} titleStyle={{}} />
            {/* </View> */}
            <View style={{ marginTop: 10, width: width > widthThreshold ? '60%' : '75%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <InfoBlock onPress={() => navigation.navigate('Chequing', { cards: 'Savings' })} title={'Savings'} amount={'$'+formatNumberFloat(saved)}
                    titleStyle={{ ...style.h3 }} amountStyle={{ ...globalStyle.h3,}} />
                <View style={{ height: '100%', width: 3, height: 50, backgroundColor: 'white', marginHorizontal: 15 }}></View>
                <InfoBlock onPress={() => navigation.navigate('Chequing', { cards: 'Chequing' })} title={'Spent'} amount={'$'+formatNumberFloat(spent)}
                    titleStyle={{}} amountStyle={{ ...globalStyle.h3,}} />
            </View>
        </View>
        {type==='child'?
        <Image style={{ position:'absolute', top:-20,
                        right:Platform.OS==='web'?-20:-20, height:imageSize, width:imageSize}}
        resizeMode={'contain'} source={require('../../assets/cape_new.gif')} />
        :
        <View style={{
            width: '100%', maxWidth: 70, flex: 0.2,
            justifyContent: 'space-between', alignItems: 'flex-end', height: '100%',
        }}>
            {/* <Button style={{ flex: 0.45, aspectRatio: 1 }} onPress={upButton} color={Colors.green1} title={'plus'} iconSize={25} /> */}
            {/* <Button style={{ flex: 0.45, aspectRatio: 1 }} onPress={downButton} color={Colors.rose} title={'minus'} iconSize={22} /> */}
            {[{ color: Colors.green1, sign: 'plus', onPress: upButton },{type:'sep'}, { color: Colors.red, sign: 'minus', onPress: downButton }]
                .map((item, index) =>
                    item.type==='sep'?<View key={index} style={{height:10}}></View>:<TouchableNativeFeedback key={index} onPress={item.onPress}>
                        <View key={index} style={[{
                            backgroundColor: item.color, borderRadius: 5, justifyContent: 'center', alignItems: 'center',
                            ...Platform.OS === 'web' ? { width: squareSize, height: squareSize, maxHeight: 70, maxWidth: 70 } : { flex: 0.4, aspectRatio: 1 },
                        }]}>
                            <Icon style={{ zIndex: 3, }} name={item.sign} size={25} color={'white'} />
                        </View>
                    </TouchableNativeFeedback>
                    )}
        </View>
        }
    </View>
}

export const ActivityCard = ({ layout, title, onPress, buttonTitle, onButtonPress, style, image, amount, color, cornerImage, type }) => {
    const width = layout.width;
    const vmin = Math.min(...[layout.width, layout.height]);

    const cardWidth = width > 850 ? '48%' : '90%'

    return <View elevation={15} style={[{flex: 1,
        borderRadius, width: cardWidth, alignItems: 'center', backgroundColor: 'white',
    }, defaultStyles.shadow, style]}>
        <CornerIcon color={color} image={cornerImage} layout={layout} />
        <InfoBlock style={{ flex: 2, justifyContent: 'center', }}
        onPress={onPress} title={title} center={true} amount={amount}
            titleStyle={{}} amountStyle={globalStyle.h3} />
        {type==='child'?null:<BottomButton style={{ flex: 1, borderWidth: 1, width: '100%', }} image={image} onPress={onButtonPress} color={color} title={buttonTitle} />}
    </View>
}

export const PurchasesCard = ({ layout, info, onPress, onButtonPress, style }) => {
    const vmin = Math.min(...[layout.width, layout.height]);

    return <TouchableNativeFeedback style={Platform.OS === 'web' ? { flex: 0.48 } : {flex: 0.48 }} onPress={onPress}>
        <View elevation={10} style={[{
            borderRadius, flex: 1, maxWidth: maxWidth, backgroundColor: 'white',
            flexDirection: 'row', paddingHorizontal: 0.05 * vmin, paddingTop: 0.05 * vmin, paddingBottom: 0.02 * vmin,
        }, defaultStyles.shadow,]}>
            <View style={{ flex: 1, }}>
                <View style={{ flex: 0.2, flexDirection: 'row',  alignItems: 'center' }}>
                    {/* <Icon style={{ zIndex: 3, marginRight: 10, }} name="dollar-sign" size={20} color={Colors.red} /> */}
                    <Text style={[{ color: Colors.red }, defaultStyles.bold]}>Purchases</Text>
                    <Image style={{ width: 20, height: 20, marginLeft: 5 }} source={require('../../assets/dashboard/Money_1.png')} />
                </View>
                <View style={{  flex: 1, justifyContent: 'center' }}>
                    {info.map((item, index) => <Text key={index} style={{marginVertical:5}}>
                        <Text style={{}}>{item[0]}</Text> {item[1]}</Text>
                    )}
                </View>
            </View>
        </View>
    </TouchableNativeFeedback>
}

export const StoreCard = ({ layout, onPress, style }) => {
    const vmin = Math.min(...[layout.width, layout.height]);
    const smallCircleSize = layout.height * 0.1
    const circleSize = layout.width > 600 ? layout.height * 0.12 : layout.height * 0.17
    const storeImageSize = layout.width > 600 ? layout.height * 0.13 : layout.height * 0.16
    const textTopMargin = layout.width > 600 ? layout.height * 0.09 : layout.height * 0.11

    return <>
        <TouchableNativeFeedback style={Platform.OS === 'web' ? { flex: 0.48, zIndex: 2, } : {flex: 0.48, zIndex: 2, }} onPress={onPress}>
            <View style={[{
                alignItems: 'center',
                backgroundColor: Colors.green1,
                width: '100%', flex: 1, zIndex: 1, borderRadius,
                // borderWidth: 3, borderColor: Colors.green1,
                // justifyContent: 'center',
                overflow: "hidden",
            }, defaultStyles.shadow,]}>
                <Text style={[{ color: 'white', marginTop: textTopMargin },  defaultStyles.bold]}>Store</Text>
                <View style={{
                    zIndex: 1,
                    position: 'absolute', left: -smallCircleSize / 4, top: -smallCircleSize / 2.5, overflow: 'visible',
                    height: smallCircleSize, width: smallCircleSize, borderRadius: smallCircleSize / 2, backgroundColor: '#9ee459',
                }}></View>
                <View style={{
                    zIndex: 1,
                    position: 'absolute', right: -circleSize / 4, bottom: -circleSize / 4, overflow: 'visible',
                    height: circleSize, width: circleSize, borderRadius: circleSize / 2, backgroundColor: '#9ee459',
                }}>
                </View>
            </View>
        </TouchableNativeFeedback>
        <Image style={{
            position: 'absolute', overflow: 'visible',
            right: -storeImageSize / 10, bottom: -storeImageSize / 10,
            height: storeImageSize, width: storeImageSize, zIndex: 10,
        }} resizeMode={'contain'} source={require('../../assets/dashboard/store_icon.png')} />
    </>
}


export function ParentHeader({ vmin, layout, title, signOut, connected, pressLeft, pressRight, navigation, actions }) {
    // const {navigation} = this.props;
    const [modalVisible, setModalVisible] = useState(false)
    let { width, height } = layout;

    let headerLeft = <View style={{ width: 10, height: 10 }} />


    let headerRight = <TouchableOpacity onPress={() => { setModalVisible(true) }}>
        <Icon style={{ alignSelf: 'flex-end', zIndex: 3, margin: 10, marginRight: 0.03 * vmin }} name="bars" size={30}
            color={Colors.green1} />
    </TouchableOpacity>

    return <View style={{
        height: height * 0.1, top: 0,
        flex: 1, width: '100%', zIndex: 5, position: 'absolute', alignItems: 'center',
        backgroundColor: 'white', flexDirection: 'row', justifyContent: 'space-between'
    }}>
        {headerLeft}
        {<View style={{ flexDirection: 'row', maxWidth: layout.width * 0.6 > 550 ? 550 : layout.width * 0.6, alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
            <TouchableNativeFeedback onPress={() => pressLeft()}>
                <Text style={[{ fontSize: 30, textAlign: 'center', color: Colors.green1 }, defaultStyles.bold]}>{'<'}</Text>
            </TouchableNativeFeedback>
            <Text style={[{
                textAlign: 'center', marginHorizontal: layout.width * 0.04,
                color: Colors.green1, fontSize: Platform.OS === 'web' ? (layout.width > 850 ? '1.75em' : '1.375em') : 30,
            }, defaultStyles.bold]}>{connected === false ? 'No connection' : title}</Text>
            <TouchableNativeFeedback onPress={() => pressRight()}>
                <Text style={[{ fontSize: 30, textAlign: 'center', color: Colors.green1, }, defaultStyles.bold]}>{'>'}</Text>
            </TouchableNativeFeedback>
        </View>}

        {headerRight}

        <Modal
            animationIn={'slideInLeft'} animationOut={'slideOutLeft'} animationInTiming={300} animationOutTiming={300}
            backdropColor={'darkgrey'} backdropOpacity={0.1}
            onBackdropPress={() => setModalVisible(false)}
            isVisible={modalVisible}
            style={{ margin: 0 }}
            onRequestClose={() => setModalVisible(false)}>
            <View elevation={5} style={{
                flex: 1, margin: 0, justifyContent: 'center',
                backgroundColor: 'white', height: '100%', width: '100%',
                padding: 15, alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}>
                <TouchableNativeFeedback style={{ position: 'absolute', right: 0.015 * vmin, top: 0.01 * height }}
                    onPress={() => setModalVisible(false)}>
                    <View style={{ position: 'absolute', right: 0.015 * vmin, top: 0.02 * height }}>
                        <Text style={[{ color: Colors.rose, fontSize: 35, }, defaultStyles.bold]}>X</Text>
                    </View>
                </TouchableNativeFeedback>

                <View style={{ marginTop: 0.12 * layout.height }}>
                    {['Settings', 'Dashboard', 'Chequing', 'Savings', 'Jobs', 'Goals', 'Purchases', 'Store'].map((item, index) => {
                        return <TouchableNativeFeedback key={index} onPress={() => { navigation.navigate(item); setModalVisible(false) }}>
                            <View style={{ margin: 10 }}>
                                <Text style={[{ textAlign: "center", color: Colors.green1, fontSize: 20 }, defaultStyles.bold]}>{item}</Text>
                            </View>
                        </TouchableNativeFeedback>
                    })}

                    <TouchableNativeFeedback onPress={() => {
                        signOut();
                        actions.clearAuth().then(() => {
                            navigation.navigate('Login')
                        }).then(() => { });
                    }}>
                        <View style={{ margin: 15 }}>
                            <Text style={[{ textAlign: "center", color: Colors.green1, fontSize: 20 }, defaultStyles.bold]}>Logout</Text>
                        </View>
                    </TouchableNativeFeedback>
                </View>
            </View>
        </Modal>
    </View>
}
