
/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Platform, Image } from 'react-native';

import {useSelector} from 'react-redux';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';

import TouchableNativeFeedback from '../TouchableFeedback'
import Text from '../CustomText'
// import { ConfirmationView, } from '../components/confirmation'
import Colors from '../../constants/Colors';
import avatars from '../../constants/avatars';

import {defaultStyles} from '../../constants/style'

const database = firebase.database;
const storage = firebase.storage;


function ChildSwitchOld({ name, changeIndex }) {

    return <React.Fragment>
        <View style={{ flexDirection: 'row', width: '80%', justifyContent: 'space-around', alignItems: "center" }}>
            <TouchableNativeFeedback onPress={() => { changeIndex(-1) }}><View><Text style={{ ...defaultStyles.bold, fontSize: 24, color: Colors.green1 }}>{'<'}</Text></View></TouchableNativeFeedback>
            <View style={{ width: 40, height: 40, borderRadius: 30, borderWidth: 3, borderColor: Colors.green1, justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ ...defaultStyles.bold, fontSize: 25, textAlign: 'center', marginRight: 1 }}>{name[0]}</Text>
            </View>
            <TouchableNativeFeedback onPress={() => { changeIndex(+1) }}><View><Text style={{ ...defaultStyles.bold, fontSize: 24, color: Colors.green1 }}>{'>'}</Text></View></TouchableNativeFeedback>
        </View>
        <View style={{ marginTop: 10 }}><Text style={{ ...defaultStyles.bold, fontSize: 20, color: Colors.green1 }}>{name}</Text></View>
    </React.Fragment>
}

async function getAvatar({parentId, childId,}) {

    // console.log('childId, parentId ', id, childIds, names, parentId)
    const avatar = (await database().ref(`users/${parentId}/${childId}/settings/avatar`).once('value')).val()
    console.log('avatar ', avatar)

    if (avatar === 5) {
        const avatarImage = await storage().ref('children/' + childId + '.png').getDownloadURL();
        console.log('avatarImage ', avatarImage)
        return {uri: avatarImage}
    }
    return avatars[avatar]
}

function getAvatarTest() {
    console.log(234);
}

function ChildSwitch({ name, changeIndex }) {
    const parentId = useSelector(state => state.auth.parentId)
    const {id, childIds, names} = useSelector(state => state.parent)
    const [avatar, setAvatar] = useState(null);
    const childId = childIds[id]

    useEffect(()=>{
        console.log("ASDFASDFJA")
        getAvatar({parentId, childId,}).then(res=>setAvatar(res))
    },[id])

    return <React.Fragment>
        <View style={{ flexDirection: 'row', width: '80%', justifyContent: 'space-around', alignItems: "center" }}>
            {/* <TouchableNativeFeedback onPress={() => { changeIndex(-1) }}><View><Text style={{ ...defaultStyles.bold, fontSize: 24, color: Colors.green1 }}>{'<'}</Text></View></TouchableNativeFeedback> */}
            <View style={{ width: 80, height: 80, borderRadius: 40, borderWidth: 2, borderColor: Colors.green1, justifyContent: 'center', alignItems: 'center' }}>
                {/* <Text style={{ ...defaultStyles.bold, fontSize: 25, textAlign: 'center', marginRight: 1 }}>{name[0]}</Text> */}
                <Image style={{ height: '100%', width: '100%' }} resizeMode={"cover"} source={avatar} />
            </View>
            {/* <TouchableNativeFeedback onPress={() => { changeIndex(+1) }}><View><Text style={{ ...defaultStyles.bold, fontSize: 24, color: Colors.green1 }}>{'>'}</Text></View></TouchableNativeFeedback> */}
        </View>
        <View style={{marginTop: 15, paddingVertical: 5, paddingHorizontal: 15,
        justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row',
        backgroundColor: Colors.rose, width: '50%', borderRadius: 20 }}>
            <TouchableNativeFeedback onPress={() => { changeIndex(-1) }}>
                <Image style={{ height: 20, width: 10, transform: [{ rotate: '180deg' }] }} source={require('../../assets/arrow_white.png')} />
            </TouchableNativeFeedback>
            <View style={{ }}><Text style={{ color: 'white' }}>{name}</Text></View>
            <TouchableNativeFeedback onPress={() => { changeIndex(+1) }}>
                <Image style={{ height: 20, width: 10, }} source={require('../../assets/arrow_white.png')} />
            </TouchableNativeFeedback>
        </View>
    </React.Fragment>
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default ChildSwitch