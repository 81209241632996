import React from 'react';
import {
  Animated,
  Platform,
  StyleSheet,
  Text,

} from 'react-native';
import {
  useREM,
  useScaledSize,
} from 'react-native-web-hooks';

import globalStyle from '../constants/style';
import Colors from '../constants/Colors';

function CustomText({style, fontScale, numberOfLines, children},props) {

  const fontSize = fontScale?useScaledSize(fontScale):null;

  // Platform.OS==='web'?{fontSize:'100%',}:null,
      return(<Text {...props} numberOfLines={numberOfLines} style={[{fontFamily:'Carmen Sans Regular', color:Colors.black}, globalStyle.page, style]}>
          {children}
      </Text>)

}

const styles = StyleSheet.create({
    section1: {
        flex:1,
        backgroundColor: "white",
        justifyContent:'flex-start'
    },
  });


// export default Home;
export default CustomText;
