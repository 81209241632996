
/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { AsyncStorage, ActivityIndicator, Image, ImageBackground, KeyboardAvoidingView, StyleSheet, ScrollView, TextInput, View, Platform } from 'react-native';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { Resizable, Hoverable } from 'react-native-web-hooks';

import Icon from 'react-native-vector-icons/FontAwesome5';

import firebase from 'firebase/app';
import 'firebase/database'
import 'firebase/auth'
import * as Analytics from 'expo-firebase-analytics';

import TouchableNativeFeedback from '../../../components/TouchableFeedback'
import FloatingLabelInput from '../../../components/FloatingLabelInput/FloatingLabelInput'
import Text from '../../../components/CustomText'
// import { ConfirmationView, } from '../components/confirmation'
import Stars from '../../../components/Stars';
import { DropdownElement } from '../../../components/Dropdown';
import { ConfirmationView } from '../../../components/confirmation';

import { BlockWrapper } from '../../../components/newChild/BlockWrapper'
import { Button, InfoButton } from '../../../components/newChild/Buttons'

import * as authActions from '../../../store/actions/parents/authActions';
import * as kidAuthActions from '../../../store/actions/kids/authActions';

import Colors from '../../../constants/Colors';
import { expenseInfo, savingsInfo } from '../../../constants/info';

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

function formatInput(text) {
    return text.replace(/\D/g, '')
}


function formatInterestRate(str) {
    console.log('str, ', str)
    return (str && str !== '%') ? parseFloat(str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".")) + '%' : ''
}

class Chequing extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...Object.keys(savingsInfo).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: savingsInfo[curr].default } }, {}),
            advanced: true
        };
    };

    componentDidMount() {
    }

    row({ info, item, formatInput }) {
        const data = info[item]
        console.log('LABEL ', info, item, data)
        return <View key={item} style={{ width: '100%', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
            <FloatingLabelInput {...data.params} containerStyle={{ width: '100%', alignItems: 'center', alignSelf: 'center' }} label={data.label} value={this.state[item]}
                onChangeText={text => this.setState({ [item]: data.format ? data.format(text) : text })} />
            <InfoButton text={data.text} />
        </View>
    }

    // formatInput? formatInput(text):

    modalConfirm() {
        const { int_rate, withdraw_limit, withdraw_fee, advanced } = this.state;
        const { parentId } = this.props.auth;
        const { newKid, childIds } = this.props.parent;

        const { profile, allowance, chequing, expense, expenses } = newKid;
        // const { name, userName, password, email, age } = newKid.profile;
        console.log('newKid ', newKid)
        // this.props.startLoading()
        this.setState({ loading: true })
        // this.props.kidData({ type: 'savings', data: { int_rate, withdraw_limit, withdraw_fee } })
        const savings = { int_rate: parseFloat(int_rate.replace('%', '')) / 100, withdraw_limit, withdraw_fee, advanced }

        // this.props.actions.clearKidData()
        //     .then(() => setTimeout(() => { this.setState({ loading: false, finishModal: false }); this.props.navigation.navigate('Profile') }, 1000))


        // this.props.actions.createNewKidComplete({ parentId, profile, allowance, expense, expenses,chequing, savings })
        this.props.actions.createNewKidMin({ parentId, profile, allowance, expense, expenses, chequing, savings })
            .then((childId) => {
                this.setState({ loading: false, finishModal: false });
                this.props.actions.sendAllowanceJob(parentId, childId, { salary_amt: allowance.amount, salary_day: allowance.day, salary_freq: allowance.frequency });
                this.props.actions.createExpence({ parentId, childId, amount: expense.amount, title: expense.title, frequency: expense.frequency, day: expense.day, });
                for (let i in expenses) {
                    let expense = expenses[i]
                    this.props.actions.createExpence({ parentId, childId, amount: expense.amount, title: expense.title, frequency: expense.frequency, day: expense.day, });
                }
                return this.props.actions.clearKidData();
            })
            .then(() => setTimeout(() => {
                this.setState({ loading: false, finishModal: false });
                this.props.navigation.navigate('Profile')
                // this.props.navigation.navigate('Walkthrough')
            }, 1000))
            .catch(() => {
                this.setState({ loading: false, finishModal: false });
                this.props.navigation.navigate('Profile')
                // this.props.navigation.navigate('Walkthrough')
            })

    }

    modalReject() {
        const { int_rate, withdraw_limit, withdraw_fee, advanced } = this.state;
        const { parentId } = this.props.auth;
        const { newKid } = this.props.parent;

        const { profile, allowance, chequing, expense, expenses } = newKid;
        // const { name, userName, password, email, age } = newKid.profile;
        console.log('newKid ', newKid)

        // this.props.startLoading()
        this.setState({ loading: true })
        // :parseInt(int_rate.replace('%',''))
        // this.props.kidData({ type: 'savings', data: { int_rate, withdraw_limit, withdraw_fee } })
        const savings = { int_rate: parseFloat(int_rate.replace('%', '')) / 100, withdraw_limit, withdraw_fee, advanced }


        // this.props.actions.createNewKidComplete({ parentId, profile, allowance, expense, expenses, chequing, savings })
        this.props.actions.createNewKidMin({ parentId, profile, allowance, expense, expenses, chequing, savings })
            .then((childId) => {
                this.setState({ loading: false, finishModal: false });
                console.log('NEW KID ', childId)
                this.props.actions.sendAllowanceJob(parentId, childId, { salary_amt: allowance.amount, salary_day: allowance.day, salary_freq: allowance.frequency });
                this.props.actions.createExpence({ parentId, childId, amount: expense.amount, title: expense.title, frequency: expense.frequency, day: expense.day, });
                for (let i in expenses) {
                    let expense = expenses[i]
                    this.props.actions.createExpence({ parentId, childId, amount: expense.amount, title: expense.title, frequency: expense.frequency, day: expense.day, });
                }
                this.props.actions.clearKidData();
                // this.props.navigation.navigate('Dashboard')
                // this.props.navigation.navigate('Walkthrough')
            })
            .catch((err) => {
                console.log('NEW KID ', err), this.setState({ loading: false, finishModal: false });
                // this.props.navigation.navigate('Walkthrough')
            })

        this.props.navigation.navigate('Walkthrough')

    }

    changeDay(text) {
        console.log('text', text)

        if (text === 'Monthly') {
            console.log('MONTHLY')
            return this.setState({ day: 'End of the month' })
        }
        if (text !== 'none') {
            return this.setState({ day: 'Friday' })
        }
    }

    checkConditions() {
        const { amount } = this.state;
        return amount
    }

    proceed() {
        const { int_rate, withdraw_limit, withdraw_fee } = this.state;
        const { newKid } = this.props.parent;
        const { allowance, chequing, } = newKid;

        const { name, userName, password, email, age } = newKid.profile;

        // this.props.startLoading()
        console.log('newKid ', newKid)

        this.props.kidData({ type: 'savings', data: { int_rate, withdraw_limit, withdraw_fee } })
        const savings = { int_rate: parseInt(int_rate.replace('%', '')), withdraw_limit, withdraw_fee }
        // this.props.actions.createNewKidComplete({ parentId, name, userName, password, email, age, allowance, chequing, savings })
        //     .then(() => { this.props.stopLoading(); this.props.navigation.navigate('Dashboard') })
        this.setState({ finishModal: true })
        // .then(()=>console.log('CURRENT NE KID', newKid))
        // .then(()=>this.props.actions.createNewKid({ parentId, name, userName, password, email, age, allowance, chequing, savings}))
        // this.props.actions.createNewKid({parentId, name, userName, password, email, age})
    }

    back() {
        const { int_rate, withdraw_limit, withdraw_fee } = this.state;
        const { newKid } = this.props.parent;
        const { allowance, chequing, savings } = newKid;

        this.props.kidData({ type: 'savings', data: { int_rate, withdraw_limit, withdraw_fee } })
        this.props.back()
    }

    render() {
        const { layout } = this.props;
        const { amount, frequency, day } = this.state;

        const title = 'Savings Account Setup'
        const description = <Text style={{ textAlign: 'center', marginVertical: 5, color: Colors.gray1 }}>
            Pennygem offers a lot of customization to allow parents simulate the real-world banking environment as closely as they’d like. Amounts entered below are our
            suggestions, feel free to change them.
		</Text>


        return (
            <BlockWrapper description={description} title={title}>
                {/* <View> */}

                {(props) => <>
                    <Modal
                        animationIn={'zoomIn'} animationOut={'zoomOut'}
                        animationInTiming={500} animationOutTiming={500}
                        backdropColor={'darkgrey'} backdropOpacity={0.6}
                        style={{ margin: 0 }}
                        onBackdropPress={() => this.setState({ finishModal: false })}
                        isVisible={this.state.finishModal}
                        onRequestClose={() => { this.setState({ finishModal: false }) }}>
                        <React.Fragment>
                            {this.state.loading ? <View style={{
                                position: 'absolute', zIndex: 1, backgroundColor: 'rgba(126, 193, 61, 0.5)',
                                justifyContent: 'center',
                                height: '100%', width: '100%'
                            }}>
                                <ActivityIndicator color={Colors.green1} size={'large'} />
                            </View> : null}
                            <View style={{
                                borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
                                maxWidth: 400, minHeight: 250, justifyContent: 'center',
                            }}>
                                <ConfirmationView title={'Add another child?'} subtitles={[]}
                                    confirmPress={() => { this.modalConfirm() }}
                                    rejectPress={() => { this.modalReject() }} />
                            </View>
                        </React.Fragment>
                    </Modal>
                    <View style={{
                        marginVertical: 20, padding: 30, borderRadius: 10, maxWidth: 400
                    }}>
                        <View style={{ zIndex: 1, }}>

                            {this.row({ info: savingsInfo, item: 'int_rate', formatInput: formatInterestRate })}
                            <View style={{ opacity: this.state.advanced ? 1 : 0.5 }}>
                                {this.state.advanced ? null : <View style={{
                                    zIndex: 3,
                                    // backgroundColor:'rgba(128,128,128,0.5)',
                                    left: -30,
                                    opacity: 0.5, position: 'absolute', height: '100%', width: '100%'
                                }} />}
                                {this.row({ info: savingsInfo, item: 'withdraw_limit' })}
                                {this.row({ info: savingsInfo, item: 'withdraw_fee' })}
                            </View>
                        </View>

                        <TouchableNativeFeedback onPress={() => this.setState({ advanced: !this.state.advanced })}>
                            <View style={{ zIndex: -1, marginLeft: -20, marginTop: 10, flex: 1, width: '100%', flexDirection: 'row', marginVertical: 10, alignItems: 'center' }}>
                                <View style={{
                                    borderRadius: 10, height: 30, width: 30, justifyContent: 'center', alignItems: 'center',
                                    borderColor: Colors.green1, borderWidth: 3
                                }}>
                                    <Icon style={{ zIndex: -1, opacity: this.state.advanced ? 0 : 1 }} name="check" size={16} color={Colors.green1} />
                                </View>
                                {/* <View style={{ borderWidth: 3, borderRadius: 5 }}><Text> ? null : '1'}</Text></View> */}
                                <View style={{ marginLeft: 10 }}>
                                    <Text style={{ color: Colors.green1,}}>No account limits or fees</Text>
                                </View>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                    <View style={{ flex: 1, width: '50%', zIndex: -1, justifyContent: "center", flexDirection: 'row' }}>
                        <Button title={'Back'} onPress={() => this.back()}></Button>
                        <Button title={'Finish'} onPress={() => this.proceed()}></Button>
                    </View></>}
                {/* </View> */}
            </BlockWrapper>)
    }
}


class ChequingScreen extends React.Component {

    static navigationOptions = ({ navigation }) => {

        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        }
    }


    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            section: 1,
            loading: false
        };
    };



    componentDidMount() {
        const { childIds } = this.props.parent;
        const { clearAuth, kidClearAuth } = this.props.actions;
        // clearAuth();

        if (childIds.length > 0) {
            this.setState({ section: 2 })
        }
        // kidClearAuth()
    }



    render() {
        return <Chequing auth={this.props.auth} parent={this.props.parent} actions={this.props.actions}
            navigation={this.props.navigation}
            kidData={this.props.actions.kidData}
            back={() =>
                this.props.navigation.navigate('ChequingSetup')
                // this.setState({ section: this.state.section - 1 })
            }
            // proceed={() => this.setState({ section: this.state.section })}
            proceed={() => { }}
            startLoading={() => this.setState({ loading: true })} stopLoading={() => this.setState({ loading: false })}
        />
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(state => {
    // console.log(Object.keys(state.vacancies));
    return ({
        auth: state.auth,
        parent: state.parent,
        store: state.store,
    })
},
    (dispatch) => ({
        actions: bindActionCreators({ ...authActions, ...kidAuthActions, }, dispatch)
    })
)(ChequingScreen);


