/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
    Image,
    Platform,
    TouchableOpacity,
    View,
} from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome5';
import { shallowEqual, useSelector } from 'react-redux'

import firebase from 'firebase/app';
import 'firebase/database';

import { useDispatch } from 'react-redux'

import Text from '../CustomText'
import TouchableNativeFeedback from '../TouchableFeedback';
import ModalContainer from '../Modal/Modal';

import { getChildName } from '../../store/actions/kids/authActions';

import globalStyle, { defaultStyles } from '../../constants/style';
import Colors from '../../constants/Colors';

import { _launchCamera, _pickImage } from '../../helpers/changeAvatar';

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const database = firebase.database;

const maxWidth = 700;
const borderRadius = 25
const avatars = [require('../../assets/Avatars/icon_2.png'), require('../../assets/Avatars/icon_3.png'),
require('../../assets/Avatars/icon_4.png'), require('../../assets/Avatars/icon_5.png')]

export const ParentHeaderCard = ({ style, name, navigation, signOut, vmin, layout, clearAuth, pressNext, dashboard }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [currentAvatar, setCurrentAvatar] = useState(0);
    const parentId = useSelector(state => state.auth.parentId);
    const {childIds, id} = useSelector(state => state.parent);

    const height = layout.height;
    const cardWidth = '90%'
    const childId = childIds[id]


    useEffect(() => {
        var database_ref = database().ref('/users/' + parentId + '/' + childId);

        const avatar_ref = database_ref.child('settings/avatar')
        // const avatar =
        avatar_ref.once('value').then(val => { console.log('VALUE ', val.val()); setCurrentAvatar(val.val() - 1) })


        return () => { }
    }, [childId])

    return <View style={[{
        paddingBottom: 15,
        flexDirection: 'row', borderRadius, width: cardWidth, maxWidth: maxWidth,
        backgroundColor: 'white', justifyContent: 'space-between', alignItems: 'flex-start', alignSelf: 'center'
    }, style]}>
        <View>
            <Image style={{ width: 80, height: 80, alignSelf: 'flex-start' }} source={avatars[currentAvatar]} />
            <TouchableNativeFeedback disabled={dashboard} style={{ position: 'absolute', right: -45,}} onPress={() => navigation.navigate('Dashboard')}>
                <Image style={{ ...Platform.OS==='android' && {position: 'absolute', right: -45,}, width: 40, height: 40, alignSelf: 'flex-start' }} source={require('../../assets/Pennygem_Logo.png')} />
            </TouchableNativeFeedback>
            <View style={{
                zIndex: -1, position: 'absolute', bottom: '15%', left: '80%', backgroundColor: Colors.red,
                flexDirection: 'row', alignItems: 'center', height: 25,
                minWidth: 120,
                borderTopRightRadius: 30, borderBottomRightRadius: 30
            }}>
                <Text style={[{ paddingLeft: 40, paddingRight: 15, paddingVertical: 5, color: 'white' }, defaultStyles.bold]}>{name}</Text>
                <TouchableNativeFeedback onPress={() => pressNext()}>
                    {/* <View style={{height:25, width:25, opacity:0.1, borderRadius:25, backgroundColor:'green'}}></View> */}
                    <Image source={require('../../assets/arrow_white.png')} resizeMode={'contain'}
                        style={{ height: globalStyle.page.fontSize, width: globalStyle.page.fontSize, marginRight: 5 }} />
                    {/* <Text style={[{ color: 'white', marginRight: 5, }, globalStyle.h2]}>{'>'}</Text> */}
                </TouchableNativeFeedback>
            </View>
        </View>
        <TouchableOpacity onPress={() => setModalVisible(true)}>
            <View style={{ flex: 1, justifyContent: 'flex-start', marginTop: 10 }}>
                <View style={{ justifyContent: 'space-between', height: 20 }}>
                    {[1, 2, 3].map((item, index) => <View key={index} style={{ width: 27, height: 2, margin: 1, backgroundColor: Colors.black }}></View>)}
                </View>
                {/* <Icon style={{ zIndex: 3, }} name="bars" size={40} color={Colors.black} /> */}
            </View>
        </TouchableOpacity>
        <Modal
            animationIn={'slideInLeft'} animationOut={'slideOutLeft'} animationInTiming={300} animationOutTiming={300}
            backdropColor={'darkgrey'} backdropOpacity={0.1}
            onBackdropPress={() => setModalVisible(false)}
            isVisible={modalVisible}
            style={{ margin: 0 }}
            onRequestClose={() => setModalVisible(false)}>
            <View elevation={5} style={{
                flex: 1, margin: 0, justifyContent: 'center',
                backgroundColor: 'white', height: '100%', width: '100%',
                padding: 15, alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}>
                <TouchableNativeFeedback style={{ position: 'absolute', right: 0.015 * vmin, top: 0.01 * height }}
                    onPress={() => setModalVisible(false)}>
                    <View style={{ position: 'absolute', right: 0.015 * vmin, top: 0.02 * height }}>
                        {/* <Text style={[{ color: Colors.rose, }, globalStyle.h1, defaultStyles.bold]}>X</Text> */}
                        <Image style={{ height: 30, width: 30 }} source={require('../../assets/Delete-X.png')} />

                    </View>
                </TouchableNativeFeedback>

                <View style={{ marginTop: -0.1 * layout.height }}>
                    {[{ title: 'Settings' }, { title: 'Dashboard' }, { title: 'Chequing', params: { cards: 'Chequing' } }, { title: 'Savings', path: 'Chequing', params: { cards: 'Savings' } }, { title: 'Jobs' },
                    { title: 'Goals' }, { title: 'Purchases' }, { title: 'Store' }].map((item, index) => {
                        return <TouchableNativeFeedback key={index} onPress={() => { navigation.push(item.path || item.title, item.params); setModalVisible(false) }}>
                            <View style={{ margin: 10 }}>
                                <Text style={[{ textAlign: "center", color: Colors.green1, }, globalStyle.h3, defaultStyles.bold]}>{item.title}</Text>
                            </View>
                        </TouchableNativeFeedback>
                    })}

                    <TouchableNativeFeedback onPress={() => {
                        signOut();
                        clearAuth().then(() => {
                            navigation.navigate('Login')
                        }).then(() => { });
                    }}>
                        <View style={{ margin: 15 }}>
                            <Text style={[{ textAlign: "center", color: Colors.green1 }, globalStyle.h3, defaultStyles.bold]}>Logout</Text>
                        </View>
                    </TouchableNativeFeedback>
                </View>
            </View>
        </Modal>
    </View>
}

function setNewAvatar({ index, parentId, childId }) {
    var database_ref = database().ref('/users/' + parentId + '/' + childId);

    return database_ref.child('settings').update({ avatar: index })

    // const avatar = avatar_ref.once('value').then(val => {console.log('VALUE ', val.val()); setCurrentAvatar(val.val()-1)})
}

export const ChildHeaderCard = ({ navigation, signOut, vmin, layout, clearAuth, pressNext, dashboard, style }) => {
    // const avatar = useSelector(state => state.child.avatar);

    const [modalVisible, setModalVisible] = useState(false);
    const [currentAvatar, setCurrentAvatar] = useState(undefined)
    const [changeAvatarVisible, setAvatarVisible] = useState(false);
    const [imagePickerVisible, setImagePicker] = useState(false);
    const parentId = useSelector(state => state.auth.parentId);
    const childId = useSelector(state => state.child.childId);
    const avatarImage = useSelector(state => state.child.avatarImage);
    const name = useSelector(state => state.child.name);

    console.log('avatarImage ', avatarImage)
    const dispatch = useDispatch()

    useEffect(() => {
        var database_ref = database().ref('/users/' + parentId + '/' + childId);

        const avatar_ref = database_ref.child('settings/avatar')
        // const avatar =
        avatar_ref.once('value').then(val => { console.log('VALUE ', val.val()); setCurrentAvatar(val.val() - 1) })

        if (!name) { dispatch(getChildName({ childId })); }

        return () => { }
    }, [])

    const height = layout.height;
    const cardWidth = '90%'
    return <View style={[{
        paddingBottom: 15,
        flexDirection: 'row', borderRadius, width: cardWidth, maxWidth: maxWidth,
        backgroundColor: 'white', justifyContent: 'space-between', alignItems: 'flex-start', alignSelf: 'center'
    }, style]}>
        <View style={{ flexDirection: 'row' }}>
            <TouchableNativeFeedback onPress={() => setAvatarVisible(!changeAvatarVisible)}>
                <Image resizeMode={'cover'} style={{ width: 80, height: 80, backgroundColor: Colors.gray, borderRadius: 80, alignSelf: 'flex-start' }}
                    source={currentAvatar === 4 ? { uri: avatarImage } : avatars[currentAvatar]}
                // source={{ uri: avatarImage } }

                />
            </TouchableNativeFeedback>
            {changeAvatarVisible ?
                <View style={{ marginLeft: 5, flexDirection: 'row', alignItems: 'center', }}>
                    {avatars.filter((_, index) => (index) !== currentAvatar).map((item, index) =>
                        <TouchableNativeFeedback key={index} onPress={() => { setNewAvatar({ index: index + 1, parentId, childId }); setCurrentAvatar(index); setAvatarVisible(false) }}>
                            <Image style={{ width: 50, height: 50, }} source={item} />
                        </TouchableNativeFeedback>)}
                    <TouchableNativeFeedback onPress={() => { setImagePicker(true) }}>
                        <Image style={{ width: 50, height: 50, }} source={require('../../assets/Avatars/icon_6.png')} />
                    </TouchableNativeFeedback>
                </View>
                :
                <>
                    <TouchableNativeFeedback disabled={dashboard} style={{ position: 'absolute', right: -45, }} onPress={() => navigation.navigate('Dashboard')}>
                        <Image style={{ width: 40, height: 40, alignSelf: 'flex-start' }} source={require('../../assets/Pennygem_Logo.png')} />
                    </TouchableNativeFeedback>
                    <View style={{
                        zIndex: -1, position: 'absolute', bottom: '15%', left: '50%', backgroundColor: Colors.red,
                        flexDirection: 'row', alignItems: 'center', height: 25,
                        borderTopRightRadius: 30, borderBottomRightRadius: 30
                    }}>
                        <Text style={[{ paddingLeft: 40, paddingRight: 15, paddingVertical: 5, color: 'white' }, defaultStyles.bold]}>{name}</Text>
                    </View>
                </>}
        </View>
        <ModalContainer visible={imagePickerVisible} onClose={() => setImagePicker(false)}>
            <View>
                <Text style={[{ fontSize: 30, color: '#7ec13d' }, defaultStyles.bold]}>Select</Text>
            </View>
            <TouchableNativeFeedback style={Platform.OS === 'web' ? { width: '80%', alignItems: 'center' } : {}} onPress={() => {
                setImagePicker(false)
                _launchCamera({ parentId, childId, dispatch }).then(() => { setCurrentAvatar(4); setImagePicker(false) })
            }}>
                <View style={{ width: '80%', borderRadius: 10, backgroundColor: '#7ec13d', padding: 10, paddingHorizontal: 15, margin: 10 }}>
                    <Text style={{ color: 'white', textAlign: 'center' }}>Open Camera</Text>
                </View>
            </TouchableNativeFeedback>
            <TouchableNativeFeedback style={Platform.OS === 'web' ? { width: '80%', alignItems: 'center' } : {}} onPress={() => {
                setImagePicker(false)
                _pickImage({ parentId, childId, dispatch }).then(() => { setCurrentAvatar(4); setImagePicker(false) })
            }}>
                <View style={{ width: '80%', borderRadius: 10, backgroundColor: '#7ec13d', padding: 10, paddingHorizontal: 15, margin: 10 }}>
                    <Text style={{ color: 'white', textAlign: 'center' }}>Pick Image</Text>
                </View>
            </TouchableNativeFeedback>
        </ModalContainer>
        <TouchableOpacity onPress={() => setModalVisible(true)}>
            <View style={{ flex: 1, justifyContent: 'flex-start', marginTop: 10 }}>
                <View style={{ justifyContent: 'space-between', height: 20 }}>
                    {[1, 2, 3].map((item, index) => <View key={index} style={{ width: 27, height: 2, margin: 1, backgroundColor: Colors.black }}></View>)}
                </View>
                {/* <Icon style={{ zIndex: 3, }} name="bars" size={40} color={Colors.black} /> */}
            </View>
        </TouchableOpacity>
        <Modal
            animationIn={'slideInLeft'} animationOut={'slideOutLeft'} animationInTiming={300} animationOutTiming={300}
            backdropColor={'darkgrey'} backdropOpacity={0.1}
            onBackdropPress={() => setModalVisible(false)}
            isVisible={modalVisible}
            style={{ margin: 0 }}
            onRequestClose={() => setModalVisible(false)}>
            <View elevation={5} style={{
                flex: 1, margin: 0, justifyContent: 'center',
                backgroundColor: 'white', height: '100%', width: '100%',
                padding: 15, alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}>
                <TouchableNativeFeedback style={{ position: 'absolute', right: 0.015 * vmin, top: 0.01 * height }}
                    onPress={() => setModalVisible(false)}>
                    <View style={{ position: 'absolute', right: 0.015 * vmin, top: 0.02 * height }}>
                        {/* <Text style={[{ color: Colors.rose, }, globalStyle.h1, defaultStyles.bold]}>X</Text> */}
                        <Image style={{ height: 30, width: 30 }} source={require('../../assets/Delete-X.png')} />
                    </View>
                </TouchableNativeFeedback>

                <View style={{ marginTop: -0.1 * layout.height }}>
                    {[{ title: 'Settings' }, { title: 'Dashboard' }, { title: 'Chequing', params: { cards: 'Chequing' } }, { title: 'Savings', path: 'Chequing', params: { cards: 'Savings' } }, { title: 'Jobs' },
                    { title: 'Goals' }, { title: 'Purchases' }, { title: 'Store' }].map((item, index) => {
                        return <TouchableNativeFeedback key={index} onPress={() => {
                            navigation?.state.routeName === item.title ? null : navigation.navigate(item.path || item.title); setModalVisible(false)
                        }}>
                            <View style={{ margin: 10 }}>
                                <Text style={[{ textAlign: "center", color: Colors.green1, }, globalStyle.h3, defaultStyles.bold]}>{item.title}</Text>
                            </View>
                        </TouchableNativeFeedback>
                    })}

                    <TouchableNativeFeedback onPress={() => {
                        signOut();
                        clearAuth().then(() => {
                            navigation.navigate('Login')
                        }).then(() => { });
                    }}>
                        <View style={{ margin: 15 }}>
                            <Text style={[{ textAlign: "center", color: Colors.green1 }, globalStyle.h3, defaultStyles.bold]}>Logout</Text>
                        </View>
                    </TouchableNativeFeedback>
                </View>
            </View>
        </Modal>
    </View>
}
