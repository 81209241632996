/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { AsyncStorage, ActivityIndicator, Image, ImageBackground, KeyboardAvoidingView, Linking, StyleSheet, ScrollView, TextInput, View, Platform } from 'react-native';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { Resizable, Hoverable } from 'react-native-web-hooks';

import Icon from 'react-native-vector-icons/FontAwesome5';

import firebase from 'firebase/app';
import 'firebase/database'
import 'firebase/auth'
import * as Analytics from 'expo-firebase-analytics';
import * as WebBrowser from 'expo-web-browser';


import TouchableNativeFeedback from '../../../components/TouchableFeedback'
import FloatingLabelInput from '../../../components/FloatingLabelInput/FloatingLabelInput'
import Text from '../../../components/CustomText'
// import { ConfirmationView, } from '../components/confirmation'
import Stars from '../../../components/Stars';
import { DropdownElement } from '../../../components/Dropdown';

import { BlockWrapper } from '../../../components/newChild/BlockWrapper'
import { Button, InfoButton, InfoButtonControlled } from '../../../components/newChild/Buttons'

import * as authActions from '../../../store/actions/parents/authActions';
import * as kidAuthActions from '../../../store/actions/kids/authActions';

import Colors from '../../../constants/Colors';
import { allowanceInfo, allowanceTable } from '../../../constants/info';
import { defaultStyles } from '../../../constants/style';

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const auth = firebase.auth;
const database = firebase.database;
// const analytics = firebase.analytics;
const salaryFreqs = { 'Monthly': 1, 'Bi-weekly': 2, 'Weekly': 4, }
const salaryDays = {
    '1st of the month': 0, 'End of the month': 1,
    'Monday': 1, 'Tuesday': 2, 'Wednesday': 3, 'Thursday': 4, 'Friday': 5, 'Saturday': 6, 'Sunday': 0
}

class Allowance extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            // ...Object.keys(kidInfo).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: '' } }, {}),
            day: 'Friday',
            frequency: 'Weekly',
            amount: 10,
            year: 10,
            regular: true
        };
    };

    componentDidMount() {
        const { newKid } = this.props.parent;
        const allowance = newKid.allowance
        newKid.profile.age ? this.setState({ year: newKid.profile.age, amount: newKid.profile.age }) : null
        this.setState({ ...allowance })

        // for (let i in allowance){
        //     console.log('i - ', i, allowance[i])
        //     this.setState({[i]:i==='amount'?parseFloat(allowance[i]):allowance[i]})
        // }
    }

    row(item) {
        const data = allowanceInfo[item]
        return <View key={item} style={{ borderWidth: 1, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
            <FloatingLabelInput {...data.params} label={data.label} value={this.state[item]} onChangeText={text => this.setState({ [item]: text })} />
            <InfoButton text={data.text} />
        </View>
    }

    formatAmount(text) {
        return text.replace(/([^(\d|.)])/g, '')
    }

    changeDay(text) {
        console.log('text', text)

        if (text === 'Monthly') {
            console.log('MONTHLY')
            return this.setState({ day: 'End of the month' })
        }
        if (text !== 'none') {
            return this.setState({ day: 'Friday' })
        }
    }

    checkConditions() {
        const { amount } = this.state;
        return 0
    }

    proceed() {
        const { amount, frequency, day, regular } = this.state;

        this.props.kidData({ type: 'allowance', data: { amount, frequency, day: salaryDays[day], regular } });
        this.props.proceed()

    }

    back() {
        const { amount, frequency, day, regular } = this.state;

        this.props.kidData({ type: 'allowance', data: { amount, frequency, day, regular } });
        this.props.back()
    }

    render() {
        const { layout } = this.props;
        const { amount, frequency, day } = this.state;

        const title = 'Allowance'
        const description = <Text style={{ textAlign: 'center', marginVertical: 5, color: Colors.gray1 }}>
            We took the liberty to suggest the allowance settings based on the information you provided and on what other parents do. Feel free to change it to your liking!
		</Text>

        let amountText = `The amount of recurring allowance for your child. You can always change it later. For your reference, an average family pays approximately 50 cents to $1 per week for each year of a child\'s age. For example, a ${this.state.year}-year-old would get $${this.state.year * 0.5}-$${this.state.year} per week. Refer to this table or blog post for more detail.`
        amountText = amountText.split(' ').map(item => item === 'this' ?
            <TouchableNativeFeedback onPress={() => { this.setState({ amountVisible: false, tableVisible: true }) }}><Text><Text style={{ textDecorationLine: 'underline' }}>this</Text> </Text></TouchableNativeFeedback>
            : <Text>{item} </Text>)


        return (
            <BlockWrapper layout={layout} description={description} title={title}>
                {/* <View> */}
                {(props) => <><Modal animationIn={'zoomIn'} animationOut={'zoomOut'} animationInTiming={300} animationOutTiming={300}
                    backdropColor={'darkgrey'} backdropOpacity={0.1}
                    onBackdropPress={() => this.setState({ tableVisible: false })}
                    isVisible={this.state.tableVisible}
                    onRequestClose={() => this.setState({ tableVisible: false })}>
                    <View elevation={5} style={{
                        borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center', alignItems: 'center',
                        maxWidth: 400, minHeight: 100, paddingVertical: 15, paddingHorizontal: 20, justifyContent: 'center', boxShadow: "2px 2px 15px #272827",
                    }}>
                        <View>
                            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>{'Weekly allowance ranges for kids aged 4 to 18, according to'.split(' ').map(item => <Text>{item} </Text>)}
                                <TouchableNativeFeedback onPress={() => {
                                    // Linking.openURL('https://www.cpacanada.ca/en/the-cpa-profession/financial-literacy/blog/2016/october/market-rates-on-kids-allowances')
                                    WebBrowser.openBrowserAsync('https://www.cpacanada.ca/en/the-cpa-profession/financial-literacy/blog/2016/october/market-rates-on-kids-allowances')
                                }}>
                                    <Text style={{ color: 'blue', textDecorationLine: 'underline' }}>CPA Canada:</Text></TouchableNativeFeedback></View>
                            <View style={{ alignSelf: 'center', marginTop: 20 }}>{allowanceTable.map(item => <Text>{item}</Text>)}</View>
                        </View>
                        <TouchableNativeFeedback onPress={() => this.setState({ tableVisible: false })}>
                            <View style={{ backgroundColor: Colors.green1, borderRadius: 5, marginVertical: 10, paddingHorizontal: 20, paddingVertical: 10, }}>
                                <Text style={[{ color: 'white' }, defaultStyles.bold]}>Got it</Text>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                </Modal>
                    <View style={{
                        marginVertical: 20, padding: 20, borderRadius: 10, maxWidth: 400
                    }}>
                        <View style={{
                            zIndex: 1,
                            opacity: this.state.regular ? 1 : 0.5
                        }}>
                            {this.state.regular ? null : <View style={{
                                zIndex: 3,
                                // backgroundColor:'rgba(128,128,128,0.5)',
                                left: -30,
                                opacity: 0.5, position: 'absolute', height: '100%', width: '120%'
                            }} />}
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <FloatingLabelInput additionalSign={'$'} containerStyle={{ width: '90%', alignItems: 'center', alignSelf: 'center' }} label={'Allowance amount *'}
                                    value={this.state.amount} onChangeText={text => this.setState({ amount: this.formatAmount(text) })} />
                                <InfoButtonControlled
                                    visible={this.state.amountVisible} setVisible={() => this.setState({ amountVisible: !this.state.amountVisible })}
                                    text={amountText} textComponent={<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>{amountText}</View>} />
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', zIndex: 2, alignItems: 'center' }}>
                                <DropdownElement
                                    dropdownStyle={{ width: '100%' }}
                                    containerStyle={{ zIndex: 2, width: '90%', }}
                                    mainElementStyle={{ width: '100%' }}
                                    onPress={(text) => { this.changeDay(text); this.setState({ frequency: text, }) }} defaultValue={this.state.frequency}
                                    categories={['Weekly', 'Bi-weekly', 'Monthly']} />
                                <InfoButton text={allowanceInfo.frequency.text} />
                            </View>
                            <View style={{ marginLeft: '10%' }}><Text style={{ color: Colors.gray1, marginTop: 2, fontSize: 14 }}>Frequency *</Text></View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', zIndex: 1, }}>
                                <DropdownElement
                                    dropdownStyle={{ width: '100%', zIndex: 1, maxHeight: 200 }}
                                    containerStyle={{ zIndex: 1, width: '90%', marginHorizontal: 0 }}
                                    mainElementStyle={{ width: '100%' }}
                                    onPress={(text) => this.setState({ day: text })} defaultValue={this.state.day}
                                    categories={frequency === 'Monthly' ? ['1st of the month', 'End of the month'] :
                                        ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
                                    } />
                                <InfoButton text={allowanceInfo.frequency.text} />
                            </View>
                            <View style={{ marginLeft: '10%' }}><Text style={{ color: Colors.gray1, marginTop: 2, fontSize: 14 }}>Day of the week to pay allowance *</Text></View>
                        </View>
                        <TouchableNativeFeedback onPress={() => this.setState({ regular: !this.state.regular })}>
                            <View style={{ zIndex: -1, marginLeft: 0, marginTop: 10, flex: 1, width: '100%', flexDirection: 'row', marginVertical: 10, alignItems: 'center' }}>
                                <View style={{
                                    borderRadius: 10, height: 30, width: 30, justifyContent: 'center', alignItems: 'center',
                                    borderColor: Colors.green1, borderWidth: 3
                                }}>
                                    <Icon style={{ zIndex: -1, opacity: this.state.regular ? 0 : 1 }} name="check" size={16} color={Colors.green1} />
                                </View>
                                {/* <View style={{ borderWidth: 3, borderRadius: 5 }}><Text> ? null : '1'}</Text></View> */}
                                <View style={{ marginLeft: 10 }}>
                                    <Text style={[{ color: Colors.green1,}, defaultStyles.bold]}>Don't set up regular allowance</Text>
                                </View>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                    <View style={{ flex: 1, width: '50%', zIndex: -1, justifyContent: "center", flexDirection: 'row' }}>
                        <Button title={'Back'} onPress={() => this.back()}></Button>
                        <Button title={'Proceed'} onPress={() => (this.state.amount || (!this.state.regular)) ? this.proceed() : null}></Button>
                    </View>
                </>}
                {/* </View> */}
            </BlockWrapper>)
    }
}


class LoginScreen extends React.Component {

    static navigationOptions = ({ navigation }) => {

        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        }
    }


    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            section: 1,
            loading: false
        };
    };



    componentDidMount() {
        const { childIds } = this.props.parent;

        if (childIds.length > 0) {
            this.setState({ section: 2 })
        }
        // kidClearAuth()
    }



    render() {

        return (
            <Resizable>
                {layout => {
                    const vmin = Math.min(...[layout.width, layout.height]);
                    return <View style={{ height: '100%', flex: 1 }}>

                        {this.state.loading ? <View style={{
                            position: 'absolute', zIndex: 1, backgroundColor: 'rgba(126, 193, 61, 0.5)',
                            justifyContent: 'center',
                            height: '100%', width: '100%'
                        }}>
                            <ActivityIndicator color={Colors.green1} size={'large'} />
                        </View> : null}

                        <Allowance layout={layout} auth={this.props.auth}
                            parent={this.props.parent} actions={this.props.actions}
                            navigation={this.props.navigation}
                            kidData={this.props.actions.kidData}
                            back={() =>
                                this.props.navigation.navigate('Profile')
                                // this.setState({ section: this.state.section - 1 })
                            }
                            // proceed={() => this.setState({ section: this.state.section })}
                            proceed={() => this.props.navigation.navigate('Expense')}
                            startLoading={() => this.setState({ loading: true })} stopLoading={() => this.setState({ loading: false })}
                        />

                    </View>
                }}
            </Resizable>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(state => {
    // console.log(Object.keys(state.vacancies));
    return ({
        auth: state.auth,
        parent: state.parent,
        store: state.store,
    })
},
    (dispatch) => ({
        actions: bindActionCreators({ ...authActions, ...kidAuthActions, }, dispatch)
    })
)(LoginScreen);
