/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import {
	Animated,
	// AsyncStorage,
	Image,
	FlatList,
	ScrollView,
	StyleSheet,
	Text as Text1,
	TextInput,
	//   Text as DefaultText,
	View,
	Platform,
	TouchableOpacity,
} from 'react-native';

import Slider from 'rc-slider';
import Icon from 'react-native-vector-icons/FontAwesome';


import { useHover, useFocus, useActive, useDimensions, Resizable, Hoverable } from 'react-native-web-hooks';

import Text from '../CustomText'

import TouchableNativeFeedback from '../TouchableFeedback';

import Colors from '../../constants/Colors';

export function AddChildren({kids, setKid, removeKid, names}) {
	const [childrenVisible, setChildrenVisible] = useState(false);

	function SelectedChild({ title, onPress }) {
		return <View style={{
			margin: 5, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', marginLeft: 5, padding: 5, borderRadius: 10,
			backgroundColor: 'white'
		}}>
			<View><Text style={{ color: Colors.gray1 }}>{title}</Text></View>
			<TouchableNativeFeedback onPress={onPress}>
				<View style={{
					marginHorizontal: 5, width: 18, height: 18, borderRadius: 10, marginLeft: 5, backgroundColor: Colors.rose,
					justifyContent: 'center', alignItems: 'center', opacity:0.7
				}}><Text style={{color: 'white'}}>x</Text></View>
			</TouchableNativeFeedback>
		</View>
	}


	return <TouchableNativeFeedback style={{ zIndex: 2, width: '80%', }} onPress={() => setChildrenVisible(!childrenVisible)}>
	{/* <View style={{ }}> */}
	<View style={{marginBottom:30,
		marginTop: 30, minHeight: 40, borderWidth: 2, borderColor: Colors.gray1, borderRadius: 20, justifyContent: 'center',
		borderBottomLeftRadius: childrenVisible?0:20, borderBottomRightRadius: childrenVisible?0:20,
		alignItems: 'center',
	}}>
		<View style={{ width: '100%', flexWrap: 'wrap', display: 'flex', flexDirection: 'row', paddingRight:30 }}>
			{Object.keys(kids).filter(item => kids[item]).length ?
				Object.keys(kids).map(item => {
					return kids[item] ? <SelectedChild title={names[item]} onPress={() => { removeKid(item) }} /> : null
				})
				: <Text style={{ marginLeft: '10%', color: Colors.gray1 }}>Please select children</Text>}
		</View>
		<View style={[{
			right: '5%', width: 0, height: 0, position: 'absolute', backgroundColor: 'transparent', borderStyle: 'solid',
			borderTopWidth: 10, borderRightWidth: 5, borderBottomWidth: 0, borderLeftWidth: 5,
			borderTopColor: Colors.gray1, borderRightColor: 'transparent', borderBottomColor: 'transparent', borderLeftColor: 'transparent',
		}]}></View>
		{childrenVisible && <View style={{
			borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderColor: Colors.gray1,
			backgroundColor: 'white', width: '101%', zIndex: 2, position: 'absolute', top: '98%', borderWidth: 2,
			marginBottom: 10
		}}>
			{Object.keys(kids).map((item) => {
				return <TouchableNativeFeedback key={item} onPress={() => setKid(item)}>
					<View style={{ width:'100%', justifyContent: 'flex-start', flexDirection: 'row', marginVertical: 5, alignItems: 'center' }}>
						<View style={{ marginLeft: 10, borderWidth: 2, borderRadius: 5, borderColor: Colors.gray1, }}>
							<Icon style={{ zIndex: 3, opacity: kids[item] ? 1 : 0 }} name="check" size={16} color={Colors.gray1} />
						</View>
						<Text style={{ marginLeft: 10, color: Colors.gray1 }}>{names[item]}</Text></View>
				</TouchableNativeFeedback>
			})}
			{/* {[1,2,3,4,5,6,7].map(item=><Text style={{margin:10}}>qweqwe</Text>)} */}
		</View>}
	</View>
	{/* </View> */}
</TouchableNativeFeedback>
}
