
/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { AsyncStorage, ActivityIndicator, Image, ImageBackground, KeyboardAvoidingView, StyleSheet, ScrollView, TextInput, View, Platform } from 'react-native';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { Resizable, Hoverable } from 'react-native-web-hooks';

import Icon from 'react-native-vector-icons/FontAwesome5';

import firebase from 'firebase/app';
import 'firebase/database'
import 'firebase/auth'
import * as Analytics from 'expo-firebase-analytics';

import TouchableNativeFeedback from '../../../components/TouchableFeedback'
import FloatingLabelInput from '../../../components/FloatingLabelInput/FloatingLabelInput'
import Text from '../../../components/CustomText'
// import { ConfirmationView, } from '../components/confirmation'
import Stars from '../../../components/Stars';
import { DropdownElement } from '../../../components/Dropdown';

import { BlockWrapper } from '../../../components/newChild/BlockWrapper'
import { Button, InfoButton } from '../../../components/newChild/Buttons'

import * as authActions from '../../../store/actions/parents/authActions';
import * as kidAuthActions from '../../../store/actions/kids/authActions';

import Colors from '../../../constants/Colors';
import { expenseInfo } from '../../../constants/info';
import { defaultStyles } from '../../../constants/style';

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const auth = firebase.auth;
const database = firebase.database;
// const analytics = firebase.analytics;
const salaryFreqs = { 'Monthly': 1, 'Bi-weekly': 2, 'Weekly': 4, }
const salaryDays = {
    '1st of the month': 0, 'End of the month': 1,
    'Monday': 1, 'Tuesday': 2, 'Wednesday': 3, 'Thursday': 4, 'Friday': 5, 'Saturday': 6, 'Sunday': 0
}
const defaultFrequency = 'Weekly'
const defaultDay = 'Friday'


function formatAmount(text) {
    return text.replace(/([^(\d|.)])/g, '')
}

function setTabTitle({ title }) {
    if (Platform.OS === 'web') {
        return global.document.title = title
    }
    return null
}

const ExpenseComponent = ({ title, amount, frequency, day, onDelete, setValue, expense_amount_error, expense_title_error }) => {
    // const [title, setTitle] = useState(initial.title)
    // const [amount, setAmount] = useState(initial.amount)
    // const [frequency, setFrequency] = useState(initial.frequency)
    // const [day, setDay] = useState(initial.day)

    function row({ item, value, }) {
        const data = expenseInfo[item]
        const err = item === 'amount' ? expense_amount_error : expense_title_error
        console.log('err, item ', err, item)
        return <View key={item} style={{ width: '100%', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
            <FloatingLabelInput {...data.params} style={{ borderColor: err ? Colors.rose : Colors.green1 }} containerStyle={{ width: '100%', alignItems: 'center', alignSelf: 'center' }}
                label={data.label} value={value} onChangeText={text => setValue({ item, text })} />
            <InfoButton text={data.text} />
        </View>
    }

    function changeDay(text) {
        if (text === 'Monthly') {
            return setValue({ item: 'day', text: 'End of the month', })
        }
        if (text !== 'none') {
            return setValue({ item: 'day', text: 'Friday', })
        }
    }

    return <View style={{ zIndex: 1, }}>
        <Text style={[{ marginVertical: 20, color: Colors.green1, fontSize: 19 }, defaultStyles.bold]}>Another Expense</Text>
        {row({ info: expenseInfo, item: 'title', value: title })}
        {row({ info: expenseInfo, item: 'amount', value: formatAmount(amount) })}
        <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 2, alignItems: 'center' }}>
            <DropdownElement
                dropdownStyle={{ width: '100%' }} containerStyle={{ zIndex: 2, width: '100%', }} mainElementStyle={{ width: '100%' }}
                onPress={(text) => { changeDay(text); setValue({ item: 'frequency', text }) }} defaultValue={frequency}
                categories={['Weekly', 'Bi-weekly', 'Monthly']} />
            <InfoButton text={expenseInfo.frequency.text} />
        </View>
        <View><Text style={[{ color: Colors.green1, marginTop: 2, fontSize: 14 }, defaultStyles.bold]}>{expenseInfo.frequency.label}</Text></View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', zIndex: 1, }}>
            <DropdownElement
                dropdownStyle={{ width: '100%', zIndex: 1, maxHeight: 200 }}
                containerStyle={{ zIndex: 1, width: '100%', marginHorizontal: 0 }}
                mainElementStyle={{ width: '100%' }}
                onPress={(text) => setValue({ item: 'day', text })} defaultValue={day}
                categories={frequency === 'Monthly' ? ['1st of the month', 'End of the month'] :
                    ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
                } />
            <InfoButton text={expenseInfo.day.text} />
        </View>
        <View><Text style={[{ color: Colors.green1, marginTop: 2,}, defaultStyles.bold]}>{expenseInfo.day.label}</Text></View>
        <TouchableNativeFeedback onPress={() => onDelete()}>
            <View style={{ flexDirection: 'row', marginVertical: 15, justifyContent: 'space-between', alignItems: 'center' }}><Text style={[{ color: Colors.green1, marginTop: 2,  },defaultStyles.bold]}>Delete Expense</Text>
                <View style={{ backgroundColor: Colors.rose, justifyContent: 'center', alignItems: 'center', borderRadius: 15, width: 25, height: 25 }}><Text style={{ color: "white" }}>x</Text></View>
            </View>
        </TouchableNativeFeedback>

    </View>
}

class Expense extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            // ...Object.keys(kidInfo).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: '' } }, {}),
            day: 'Friday',
            frequency: 'Weekly',
            title: '',
            amount: '10',
            year: 10,
            regular: true,
            expenses: [],

            expense_title_error: false,
            expense_amount_error: false
        };
    };

    componentDidMount() {
        const expense = this.props.parent.newKid.expense;
        this.setState({ ...expense })
    }

    row({ info, item }) {
        const data = info[item]
        console.log('LABEL ', info, item, data)
        return <View key={item} style={{ width: '100%', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
            <FloatingLabelInput {...data.params} containerStyle={{ width: '100%', alignItems: 'center', alignSelf: 'center' }}
                style={{ borderColor: this.state['expense_' + item + '_error'] ? Colors.rose : Colors.gray }}
                label={data.label} value={this.state[item]} onChangeText={text => this.setState({ ['expense_' + item + '_error']: false, [item]: text })} />
            <InfoButton text={data.text} />
        </View>
    }

    formatAmount(text) {
        return text.replace(/([^(\d|.)])/g, '')
    }

    changeDay(text) {
        console.log('text', text)

        if (text === 'Monthly') {
            console.log('MONTHLY')
            return this.setState({ day: 'End of the month' })
        }
        if (text !== 'none') {
            return this.setState({ day: 'Friday' })
        }
    }

    checkConditions() {
        const { amount, title, regular, expenses } = this.state;
        this.setState({ 'expense_title_error': !title, 'expense_amount_error': !amount })
        for (let i in expenses) {
            if (!(expenses[i].title && expenses[i].amount) && regular) {
                console.log('expenses   ', i, expenses[i])
                console.log('expenses   ', expenses[i].title, expenses[i].amount)
                console.log('expenses   ', !expenses[i].title, !expenses[i].amount)

                this.setState({
                    expenses: expenses.map((item, index) => { return index === i ? { ...item, expense_title_error: true, expense_amount_error: true } : item })
                    // expenses:expenses.map((item, index)=>index===i?{...item, expense_title_error:!expenses[i].title, expense_amount_error:!expenses[i].amount}:item)
                })

                return false
            }
        }
        return (amount && title) || (!regular)
    }

    proceed() {
        const { title, amount, frequency, day, regular } = this.state;

        this.props.kidData({ type: 'expense', data: { title, amount, frequency, day: salaryDays[day], regular } });
        this.props.kidData({ type: 'expenses', data: this.state.expenses });

        this.props.proceed()
    }

    back() {
        const { title, amount, frequency, day, regular } = this.state;

        this.props.kidData({ type: 'expense', data: { title, amount, frequency, day, regular } });
        this.props.back()
    }

    render1() {
        return <View>
            {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1,].map((item, index) => <Text style={{ marginVertical: 100 }}>{index}</Text>)}
        </View>
    }

    render() {
        const { layout } = this.props;

        const { amount, frequency, day, expenses } = this.state;
        const vmin = Math.min(...[layout.width, layout.height]);
        const title = 'Expense'
        const description = <Text style={{ textAlign: 'center', marginVertical: 5, color: Colors.gray1 }}>
            Budgeting is one of the most important personal money management skills (see our blog). We recommend to setup recurring expenses in order for your child to practice budgeting for them.
		</Text>


        return (
            <BlockWrapper layout={layout} description={description} title={title}>
                {/* <View> */}
                {(props) => <><View style={{
                    marginVertical: 20, padding: 30, borderRadius: 10, maxWidth: 400
                }}>
                    <View style={{ zIndex: 1, opacity: this.state.regular ? 1 : 0.5 }}>

                        {this.state.regular ? null : <View style={{
                            zIndex: 3,
                            // backgroundColor:'rgba(128,128,128,0.5)',
                            left: -30,
                            opacity: 0.5, position: 'absolute', height: '100%', width: '120%'
                        }} />}
                        <View style={{ zIndex: 1, }}>

                            {this.row({ info: expenseInfo, item: 'title' })}
                            {this.row({ info: expenseInfo, item: 'amount' })}
                            <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 2, alignItems: 'center' }}>
                                <DropdownElement
                                    dropdownStyle={{ width: '100%' }}
                                    containerStyle={{ zIndex: 2, width: '100%', }}
                                    mainElementStyle={{ width: '100%' }}
                                    onPress={(text) => { this.changeDay(text); this.setState({ frequency: text, }) }} defaultValue={this.state.frequency}
                                    categories={['Weekly', 'Bi-weekly', 'Monthly']} />
                                <InfoButton text={expenseInfo.frequency.text} />
                            </View>
                            <View><Text style={[{ color: Colors.green1, marginTop: 2,  fontSize: 14 }, defaultStyles.bold]}>{expenseInfo.frequency.label}</Text></View>
                            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', zIndex: 1, }}>
                                <DropdownElement
                                    dropdownStyle={{ width: '100%', zIndex: 1, maxHeight: 200 }}
                                    containerStyle={{ zIndex: 1, width: '100%', marginHorizontal: 0 }}
                                    mainElementStyle={{ width: '100%' }}
                                    onPress={(text) => this.setState({ day: text })} defaultValue={this.state.day}
                                    categories={frequency === 'Monthly' ? ['1st of the month', 'End of the month'] :
                                        ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
                                    } />
                                <InfoButton text={expenseInfo.day.text} />
                            </View>
                            <View><Text style={[{ color: Colors.green1, marginTop: 2, }, defaultStyles.bold]}>{expenseInfo.day.label}</Text></View>
                            {/* <View><Text style={{ color: Colors.green1, marginTop: 2, fontSize: 14 }}>Day of the week to pay allowance *</Text></View> */}
                        </View>

                        {expenses.map((item, index) => {
                            const { title, amount, frequency, day, expense_title_error, expense_amount_error } = expenses[index]
                            return <ExpenseComponent key={index}
                                title={title} amount={amount} frequency={frequency} day={day}
                                expense_amount_error={expense_amount_error} expense_title_error={expense_title_error}
                                setValue={({ item, text }) => {
                                    console.log('title, text ', item, text)
                                    expenses[index][item] = text; this.setState({ expenses: expenses })
                                }}
                                onDelete={() => { let temp = this.state.expenses.splice(index, 1); this.setState({ expenses: this.state.expenses }) }}
                                initial={{ title: '', amount: '10', frequency: 'Weekly', day: 'Friday' }} />
                        })}

                        <TouchableNativeFeedback onPress={() => {
                            this.setState({ expenses: [...this.state.expenses, { expense_amount_error: false, expense_title_error: false, title: '', amount: '10', frequency: defaultFrequency, day: defaultDay }] })
                            // this.props.navigation.navigate('AdditionalExpense')
                        }}>
                            <View style={{ zIndex: -1, marginLeft: -20, flex: 1, width: '100%', flexDirection: 'row', marginVertical: 10, alignItems: 'center' }}>
                                <View style={{
                                    borderRadius: 10, height: 30, width: 30, justifyContent: 'center', alignItems: 'center',
                                    borderColor: Colors.green1,
                                }}>
                                    {<Text style={[{ paddingTop: 3, justifyContent: 'center', fontSize: 30, color: Colors.green1 }, defaultStyles.bold]}>+</Text>}
                                </View>
                                <View style={{ marginLeft: 10 }}>
                                    <Text style={[{ color: Colors.green1,  }, defaultStyles.bold]}>Add another expense</Text>
                                </View>
                            </View>
                        </TouchableNativeFeedback>

                    </View>


                    <TouchableNativeFeedback onPress={() => this.setState({ regular: !this.state.regular })}>
                        <View style={{ zIndex: -1, marginLeft: -20, marginTop: 10, flex: 1, width: '100%', flexDirection: 'row', marginVertical: 10, alignItems: 'center' }}>
                            <View style={{
                                borderRadius: 10, height: 30, width: 30, justifyContent: 'center', alignItems: 'center',
                                borderColor: Colors.green1, borderWidth: 3
                            }}>
                                <Icon style={{ zIndex: -1, opacity: this.state.regular ? 0 : 1 }} name="check" size={16} color={Colors.green1} />
                            </View>
                            {/* <View style={{ borderWidth: 3, borderRadius: 5 }}><Text> ? null : '1'}</Text></View> */}
                            <View style={{ marginLeft: 10 }}>
                                <Text style={[{ color: Colors.green1, width: '80%', }, defaultStyles.bold]}>I don’t want to set up any expense</Text>
                            </View>
                        </View>
                    </TouchableNativeFeedback>

                    {/* <TouchableNativeFeedback onPress={()=>this.props.actions.additionalExpenses({parentId, childId:childIds[id],expenses:this.state.expenses, regular:this.state.regular})}>
                    <View>
                        <Text>TEst check additional expenses</Text>
                    </View>
                </TouchableNativeFeedback> */}

                </View>
                    <View style={{ flex: 1, width: '50%', zIndex: -1, justifyContent: "center", flexDirection: 'row' }}>
                        <Button title={'Back'} onPress={() => this.back()}></Button>
                        <Button title={'Proceed'} onPress={() => this.checkConditions() ? this.proceed() : null}></Button>
                    </View>
                </>}
                {/* </View> */}
            </BlockWrapper>)
    }
}


class ExpenseScreen extends React.Component {

    static navigationOptions = ({ navigation }) => {

        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        }
    }


    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            section: 1,
            loading: false
        };
    };



    componentDidMount() {
        const { childIds } = this.props.parent;

        // clearAuth();

        if (childIds.length > 0) {
            this.setState({ section: 2 })
        }
        // kidClearAuth()
    }



    render() {

        return (
            <Resizable>
                {layout => {
                    const vmin = Math.min(...[layout.width, layout.height]);
                    return <View style={{ height: '100%', flex: 1 }}>

                        {this.state.loading ? <View style={{
                            position: 'absolute', zIndex: 1, backgroundColor: 'rgba(126, 193, 61, 0.5)',
                            justifyContent: 'center',
                            height: '100%', width: '100%'
                        }}>
                            <ActivityIndicator color={Colors.green1} size={'large'} />
                        </View> : null}
                        {this.props.parent.childIds.length ? <View style={{ zIndex: 1, position: "absolute", right: 10, top: 10 }}>
                            <TouchableNativeFeedback onPress={() => { this.props.navigation.navigate('Dashboard') }}>
                                <Text style={[{ fontSize: 40, },defaultStyles.bold]}>X</Text>
                            </TouchableNativeFeedback>
                        </View> : null}

                        <Expense layout={layout} auth={this.props.auth} parent={this.props.parent} actions={this.props.actions}
                            navigation={this.props.navigation}
                            back={() =>
                                this.props.navigation.navigate('Allowance')
                                // this.setState({ section: this.state.section - 1 })
                            }
                            kidData={this.props.actions.kidData}
                            // proceed={() => this.setState({ section: this.state.section })}
                            proceed={() => this.props.navigation.navigate('ChequingSetup')}
                            startLoading={() => this.setState({ loading: true })} stopLoading={() => this.setState({ loading: false })}
                        />
                    </View>
                }}
            </Resizable>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(state => {
    // console.log(Object.keys(state.vacancies));
    return ({
        auth: state.auth,
        parent: state.parent,
        store: state.store,
    })
},
    (dispatch) => ({
        actions: bindActionCreators({ ...authActions, ...kidAuthActions, }, dispatch)
    })
)(ExpenseScreen);


