/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import {
    Image,
    ScrollView,
    StyleSheet,
    View,
    Platform,
} from 'react-native';

import Text from '../CustomText'
import TouchableNativeFeedback from '../TouchableFeedback';

import Colors from '../../constants/Colors';
import globalStyles, { defaultStyles } from '../../constants/style';


function FeaturedShopCard({ item, layout, navigation, itemKey }) {

    let vmin = Math.min(...[layout.width, layout.height]);
    let imageSize = '100%'

    // return <Text>sdfsdf</Text>
    return (item ? <TouchableNativeFeedback style={{ zIndex: -1 }} onPress={() => navigation.navigate('StoreItem', { store: item.merchantname || 'Amazon', index: item.key })}>
        <View style={[{
            zIndex: -1,
            minWidth: 120, minHeight: 120, width: layout.width * 0.1, height: layout.width * 0.1,
            justifyContent: 'center', margin: 15, backgroundColor: 'white', borderRadius: 20
        }, defaultStyles.shadow]}>
            <Image resizeMode={'cover'} style={{ alignSelf: 'center', borderRadius: 20, width: imageSize, height: imageSize }}
                source={{ uri: item ? item.image : item }} />

        </View>
    </TouchableNativeFeedback> : null)
}


const FeaturedItems = ({ getFeaturedStoreItems, featuredData, layout, navigation, }) => {
    const [position, setPosition] = useState(0);
    const _horizontalScroll = useRef(null);

    useEffect(() => getFeaturedStoreItems(), [])

    function handleFeaturedScroll(isForward, blockSize, numOfBlocks) {
        let swipe = isForward ? blockSize : -1 * blockSize;
        if (isForward) {
            if (position + blockSize > blockSize * (numOfBlocks - 4))
                return;
        } else {
            if (position - blockSize < 0)
                return;
        }
        _horizontalScroll.current.scrollTo({ x: position + swipe, y: 0, animated: true })
        setPosition(position + swipe)
    }


    return (<View style={{ width: '100%', }}>
        <View style={{ zIndex: 2, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
            <Text style={[{ color: Colors.green1, marginRight: 10 }, defaultStyles.bold, globalStyles.h4]}>Featured Items</Text>
            <Image style={{ width: 30, height: 30 }} source={require('../../assets/Store/Features-Items.png')} />
        </View>

        <View style={{ backgroundColor: 'transparent', zIndex: 2, flexDirection: 'row', alignSelf: 'center', alignItems: 'center' }}>


            <View style={{ zIndex: 1, width: layout.width * 0.9, alignSelf: 'center' }} contentContainerStyle={{}}>
                <ScrollView contentContainerStyle={{ zIndex: -1 }} style={{ zIndex: -1 }} ref={_horizontalScroll} horizontal={true} showsHorizontalScrollIndicator={false}>
                    {featuredData.map((item, index) => <FeaturedShopCard key={index} itemKey={item} item={item} navigation={navigation} layout={layout} />)}
                </ScrollView>
            </View>


        </View>
    </View>
    )
}

const styles = StyleSheet.create({
});

export default FeaturedItems