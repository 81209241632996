import React, { Component } from 'react';
import { View, StatusBar, TextInput, Animated, Platform } from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome5';

import TouchableNativeFeedback from '../components/TouchableFeedback'
import Text from '../components/CustomText';

import { TouchableOpacity } from 'react-native-gesture-handler';
// import { Platform } from 'react-native-web';
import Colors from '../constants/Colors'


export default class FloatingLabelInput extends Component {
  state = {
    isFocused: false,
    _animatedIsFocused: new Animated.Value(
      this.props.value === '' ? 0 : 1
    )
  };

  //   componentDidMount() {
  //     this._animatedIsFocused = new Animated.Value(
  //       this.props.value === '' ? 0 : 1
  //     );
  //   }

  componentDidUpdate() {
    Animated.timing(this.state._animatedIsFocused, {
      toValue: this.state.isFocused || this.props.value !== '' ? 1 : 0,
      duration: 200,
    }).start();
  }

  render() {
    const { label, onPressDown, onPressUp, centered, inputRef, ...otherProps } = this.props;
    const labelStyle = {
      position: 'absolute',
      left: 0, right: 0,
      justifyContent: 'center',
      alignItems: centered ? 'center' : 'flex-start',
      top: this.state._animatedIsFocused.interpolate({
        inputRange: [0, 1],
        outputRange: [13, 42],
      }),
    };
    const textStyle = {
      fontSize: 14,
      // color:'#5aca3c',
      fontFamily: 'Carmen Sans Regular',
      color: Colors.gray1
    };
    const textStyle1 = {
      fontSize: this.state._animatedIsFocused.interpolate({
        inputRange: [0, 1],
        outputRange: [20, 14],
      }),
      color: this.state._animatedIsFocused.interpolate({
        inputRange: [0, 1],
        outputRange: ['#aaa', '#000'],
      }),
    };
    return (
      <View style={{ width: '80%', zIndex: 1 }}>
        <Animated.View style={labelStyle}>
          <Animated.Text style={textStyle}>
            {this.props.label}
          </Animated.Text>
        </Animated.View>
        <View style={{
          justifyContent: 'center', flexDirection: 'row', height: 40, width: '100%', borderWidth: 2,
          borderRadius: 20, marginBottom: 20, borderColor: Colors.gray
        }}>
          <TextInput
            {...otherProps}
            keyboardType='numeric'
            ref={inputRef}
            // onSubmitEditing={()=>{ this.passwordTextInput.focus()}}
            keyboardType={'email-address'}
            style={{
              outlineWidth: 0, height: 40, color: '#7ec13d', color: Colors.gray1, fontFamily: 'Carmen Sans Regular', fontSize: 20,
              width: '100%', textAlign: 'center', fontSize: 20, marginBottom: 20,
            }}
            multiline={false}
            keyboardType={Platform.OS === 'web' ? 'default' : 'numeric'}
            // placeholder={'Email'}
            onFocus={() => this.setState({ isFocused: true })}
            onBlur={() => this.setState({ isFocused: false })}
          />
          <View style={{ flex:1,right:25, justifyContent:'space-between'}}>
            <TouchableNativeFeedback onPress={this.props.onPressUp}>
            {/* position: 'absolute', alignSelf: 'flex-end', right: 10, top: 0, zIndex: 10  */}
              <View style={{ }}>
                <Icon style={{ margin: 0, padding: 0, }} name="angle-up" size={15} color={Colors.gray} /></View>
            </TouchableNativeFeedback>
            <TouchableNativeFeedback onPress={this.props.onPressDown}>
            {/* position: 'absolute', alignSelf: 'flex-end', right: 10, top: 15, zIndex: 10  */}
              <View style={{ }}>
                <Icon style={{ margin: 0, padding: 0, }} name="angle-down" size={15} color={Colors.gray} /></View>
            </TouchableNativeFeedback>
          </View>
          {/* </View> */}
        </View>
      </View>
    );
  }
}


// green1 5aca3c
// red1 ffa0ad
// green2 6fc55e
// 6fc55e

