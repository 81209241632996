import React, { useState, useEffect } from 'react';
import {
	Animated,
	Button,
	Dimensions,
	ImageBackground,
	Image,
	View,
	Platform,
	ScrollView,
	StyleSheet,
	// Text,
	// TouchableNativeFeedback,
	TouchableOpacity,
	WebView
} from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome';
import { useHover, useFocus, useActive, useDimensions, Resizable, Hoverable } from 'react-native-web-hooks';

import TouchableNativeFeedback from '../TouchableFeedback'
import Text from '../CustomText'

import avatars from '../../constants/avatars'
import Colors from '../../constants/Colors';

import firebase from 'firebase/app';
import 'firebase/auth';

import { NavigationActions, StackActions, SwitchActions } from 'react-navigation';
import { defaultStyles } from '../../constants/style';

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const auth = firebase.auth;
// const resetAction = StackActions.reset({
//   index: 0,
//   actions: [
//     NavigationActions.navigate({ routeName: 'Login'})
//   ] })

const resetAction = SwitchActions.jumpTo({ routeName: 'Auth' })

export function handleScroll(e, height) {
	if (e.nativeEvent.contentOffset.y / height > 0.1 && this.state.header !== true) {
		// this.props.navigation.setParams({scrolledHeader:true})
		Animated.timing(this.state.scrolledHeader, {
			toValue: 1,
			duration: 1000,
		}).start();
		Animated.timing(this.state.scrolledPadding, {
			toValue: 1,
			duration: 200,
		}).start();
		this.setState({ header: true })
		return 0
	}

	if (e.nativeEvent.contentOffset.y / height < 0.1) {
		// this.props.navigation.setParams({scrolledHeader:false})
		Animated.timing(this.state.scrolledHeader, {
			toValue: 0,
			duration: 1000,
		}).start();
		Animated.timing(this.state.scrolledPadding, {
			toValue: 0,
			duration: 200,
		}).start();
		this.setState({ header: false })
		return 0
	}
}


const Settings = ({ onPress }) => {
	return <TouchableNativeFeedback onPress={onPress}>
		<View elevation={5} style={{
			alignSelf: 'center', padding: 5, backgroundColor: '#7ec13d', marginVertical: 10,
			borderRadius: 30, width: 30, height: 30, justifyContent: 'center',
			shadowColor: "#272827",
			shadowOffset: { width: 2, height: 2, }, shadowOpacity: 0.75, shadowRadius: 15,
		}}>
			<Image style={{ alignSelf: 'center', width: 22, height: 22 }} source={require('../../assets/Settings.png')} />
		</View>
	</TouchableNativeFeedback>
}
const HeaderButton = ({ title, onPress }) => {
	return <Hoverable>
		{isHovered => <TouchableNativeFeedback onPress={onPress}>
			<View elevation={5} style={{
				margin: 10,
				backgroundColor: Colors.green1, borderRadius: 10, boxShadow: '2px 2px 15px #272827',
				transform: [{ scale: isHovered ? 1.1 : 1 }],
			}}>
				<Text style={{ color: 'white', textAlign: 'center', width: 100, margin: 5 }}>{title}</Text>
			</View>
		</TouchableNativeFeedback>
		}
	</Hoverable>
}

const Dropdown = ({ visibleOutside }) => {
	const [isVisible, setVisibility] = useState(false);

	useEffect(() => {
		setVisibility(visibleOutside)
	}, [visibleOutside]);

	return <React.Fragment>

		<TouchableOpacity onPress={() => { setVisibility(!isVisible) }}>
			<Icon style={{ alignSelf: 'flex-end', zIndex: 3, marginHorizontal: 10, marginBottom: 10, }} name="bars" size={30} color="#F9CD30" />
		</TouchableOpacity>

		{isVisible ? <View elevation={5} style={{ position: 'absolute', maxWidth: 100, top: 70, right: 20, backgroundColor: 'white', alignSelf: 'flex-end', width: '30%', borderRadius: 10, marginRight: 10 }}>
			<TouchableNativeFeedback onPress={() => { }}><View style={{}}>
				<Text style={{ margin: 10, color: '#106433', }}>Dashboard</Text></View></TouchableNativeFeedback>
			<TouchableNativeFeedback onPress={() => { this.props.navigation.navigate('Login') }}><View style={{ flex: 1, }}>
				<Text style={{ flex: 1, margin: 10, color: '#106433' }}>Logout</Text></View></TouchableNativeFeedback>
		</View> : null}
	</React.Fragment>
	{/* </Animated.View> */ }
}

export default Dropdown


export function dropdown(vmin, width, settings, store) {
	// const {navigation} = this.props;
	var color = this.state.scrolledHeader.interpolate({
		inputRange: [0, 1],
		outputRange: ['transparent', 'rgba(255, 255, 255, 1)']
	});
	var paddingTop = this.state.scrolledPadding.interpolate({
		inputRange: [0, 1],
		outputRange: [0.02 * vmin, 0]
	});
	let headerLeftImage = this.state.header ?
		<Image resizeMode={'contain'} style={{
			flex: 1, zIndex: 4, minHeight: 35, alignItems: 'flex-start',
			height: vmin * 0.07, maxHeight: 50, alignSelf: 'flex-start', justifyContent: 'flex-start',
			width: Platform.OS === 'web' ? vmin * 0.15 : 80, maxWidth: 160, marginLeft: 10,
		}} source={require('../../assets/Pennygem_Logo_Gem_Text_Onside_Greeen.png')} />
		:
		(store ? <Image resizeMode={'contain'} style={{
			flex: 1, zIndex: 4, minHeight: 35, alignItems: 'flex-start',
			height: vmin * 0.07, maxHeight: 50, opacity: store === 'kids' ? 0 : 1, alignSelf: 'flex-start', justifyContent: 'flex-start',
			width: Platform.OS === 'web' ? vmin * 0.15 : 80, maxWidth: 100, marginLeft: -10
		}} source={require('../../assets/Store/gem.png')} /> :
			<Image resizeMode={'contain'} style={{
				flex: 1, zIndex: 4, minHeight: 35, alignItems: 'flex-start',
				height: vmin * 0.07, maxHeight: 50, alignSelf: 'flex-start', justifyContent: 'flex-start',
				width: Platform.OS === 'web' ? vmin * 0.15 : 80, maxWidth: 100, marginLeft: 10
			}} source={require('../../assets/Pennygem_Logo_Text_and_Gem_White.png')} />)

	let headerRight = width > 850 ?
		<View style={{ flexDirection: 'row', paddingRight: 15 }}>
			{settings ? <Settings onPress={() => { this.props.navigation.navigate('Settings') }} /> :
				<HeaderButton title={'Dashboard'} onPress={() => { this.props.navigation.navigate('Dashboard') }} />}
			<HeaderButton title={'Logout'} onPress={() => {
				firebase.auth().signOut();
				this.props.actions.clearAuth().then(() => {
					// global.window.location.href = '/#/Login'
					console.log('NavigationActions', NavigationActions)
					this.props.navigation.dispatch(resetAction);
					// console.log("WINDOW", global.window.location.href)
					// this.props.navigation.navigate('Login')
				}).then(() => { });

			}} />
		</View> :
		settings ?
			<View style={{ flexDirection: 'row', paddingRight: 15 }}>
				{settings ? <Settings onPress={() => { this.props.navigation.navigate('Settings') }} /> :
					<HeaderButton title={'Dashboard'} onPress={() => { this.props.navigation.navigate('Dashboard') }} />}
				<HeaderButton title={'Logout'} onPress={() => {
					firebase.auth().signOut();
					this.props.actions.clearAuth().then(() => {
						this.props.navigation.dispatch(resetAction);
						// this.props.navigation.navigate('Login')
					}).then(() => { });
				}} />
			</View> :
			<TouchableOpacity onPress={() => { this.setState({ visible: !this.state.visible }) }}>
				<Icon style={{ alignSelf: 'flex-end', zIndex: 3, marginHorizontal: 10, marginBottom: 10, }} name="bars" size={30} color="#F9CD30" />
			</TouchableOpacity>

	return <Animated.View style={{
		flex: 1, width: '100%', zIndex: 4, position: 'absolute',
		paddingTop: Platform.OS === 'web' ? paddingTop : 30, backgroundColor: color, flexDirection: 'row', justifyContent: 'space-between'
	}}>
		<TouchableOpacity onPress={() => this.props.navigation.navigate('Dashboard')}>
			<View style={{ width: 200, }}>
				{headerLeftImage}
			</View>
		</TouchableOpacity>
		{headerRight}

		{this.state.visible ? <View elevation={5} style={{
			position: 'absolute', top: 40, right: 20, backgroundColor: 'white',
			alignSelf: 'flex-end', width: '30%', maxWidth: 120, borderRadius: 10, marginRight: 10
		}}>
			<TouchableNativeFeedback onPress={() => { this.props.navigation.navigate('Dashboard') }}><View style={{}}>
				<Text style={{ margin: 10, color: '#106433', }}>Dashboard</Text></View></TouchableNativeFeedback>
			<TouchableNativeFeedback onPress={() => {
				firebase.auth().signOut();
				this.props.actions.clearAuth().then(() => {
					this.props.navigation.dispatch(resetAction);
					// this.props.navigation.navigate('Login')
				}).then(() => { this.props.actions.logout });
			}}><View style={{ flex: 1, }}>
					<Text style={{ flex: 1, margin: 10, color: '#106433' }}>Logout</Text></View></TouchableNativeFeedback>
		</View> : null}
	</Animated.View>
}





const DropdownElementWeb = ({ elementTextStyle, disabled, categories, defaultValue, onPress, mainElementStyle, mainTextStyle, containerStyle, elementContainerStyle, arrowStyle, dropdownStyle }) => {
	const [isVisible, setVisibility] = useState(false);


	return <View style={[containerStyle, {opacity: disabled ? 0.7 : 1}]}>
		<TouchableNativeFeedback disabled={disabled} onPress={() => { setVisibility(!isVisible) }}
		onBlur={()=>setVisibility(false)}
		>
			<View style={[{
				flexDirection: 'row', alignItems: 'center',
				width: '80%', marginTop: 10, alignSelf: 'center', borderColor: Colors.gray, borderWidth: 2, borderRadius: 20, height: 40,
				justifyContent: 'center', borderBottomLeftRadius: isVisible ? 0 : 20, borderBottomRightRadius: isVisible ? 0 : 20
			}, mainElementStyle]}>
				<Text style={[{ marginLeft: 18, width: '100%', color: Colors.green1,
			color: Colors.gray1 }, mainTextStyle]}>{defaultValue}</Text>
				<View style={[{
					marginRight: '5%',
					width: 0,
					height: 0,
					backgroundColor: 'transparent',
					borderStyle: 'solid',
					borderTopWidth: 10,
					borderRightWidth: 5,
					borderBottomWidth: 0,
					borderLeftWidth: 5,
					borderTopColor: Colors.gray1,
					borderRightColor: 'transparent',
					borderBottomColor: 'transparent',
					borderLeftColor: 'transparent',
				}, arrowStyle]}></View>
			</View>
		</TouchableNativeFeedback>
		{/* {isVisible&&<View style={{position:'absolute', top:50}}>
			<TouchableNativeFeedback onPress={()=>{console.log('I AM HERE HELP ME!')}}><Text>sdfsdfsdf</Text></TouchableNativeFeedback>
			</View>} */}
		{isVisible  && <View persistentScrollbar={true} style={[{
			borderRightWidth: 4,
			maxHeight: 400, borderWidth: 1, borderBottomRightRadius: 10, borderColor: Colors.gray1, borderBottomLeftRadius: 10, borderTopWidth: 1,
			alignSelf: 'center', position:'absolute',
			// position: isVisible ? 'absolute' : 'relative',
			top: 45, width: '100%', flex: 1, backgroundColor: 'white'
			, ...dropdownStyle
		},]}>
			{categories.map((item, index) => {
				return <TouchableNativeFeedback key={index} onPress={() => { onPress(item);}}>
					<View style={[{ height: 40, justifyContent: 'center' }, elementContainerStyle]}>
						<Text style={{ marginHorizontal: 20,
						color: Colors.green1,
						color: Colors.gray1 ,...elementTextStyle }}>{item}</Text>
						</View>
						</TouchableNativeFeedback>
			})}
		</View>}

	</View>
}

const DropdownElementMobile = ({ categories, defaultValue, onPress, containerStyle }) => {
	const [isVisible, setVisibility] = useState(false);

	return <View style={{ marginTop: 10, width: '80%' }}>
		<TouchableNativeFeedback onPress={() => { setVisibility(!isVisible) }}>
			<View style={{
				justifyContent: 'center', alignItems: 'center', flexDirection: 'row', height: 40, width: '95%', borderWidth: 2,
				borderRadius: 10, borderColor: '#7ec13d'
			}}>
				<Text style={{ marginLeft: 20, width: '100%', color: Colors.green1 }}>{defaultValue}</Text>
			</View>
		</TouchableNativeFeedback>
		{ isVisible && <ScrollView style={{
			borderWidth: 1, zIndex: 10, borderBottomRightRadius: 10, borderColor: Colors.green1, borderBottomLeftRadius: 10, borderTopWidth: 1, alignSelf: 'center',
			position: isVisible ? 'absolute' : 'relative', top: 40, width: '100%', flex: 1, backgroundColor: 'white', height: 100,
		}}>
			{categories.map((item, index) => {
				return <TouchableOpacity key={index} onPress={() => { onPress(item); setVisibility(false) }}>
					<View style={{ justifyContent: 'center', height: 40, }}><Text style={{ marginLeft: 20, color: Colors.green1 }}>{item}</Text></View></TouchableOpacity>
			})}
		</ScrollView>}

	</View>


}

export const DropdownElement = Platform.OS === 'web' ? DropdownElementWeb : DropdownElementMobile






export function dropdownRedesign({ vmin, layout, title, dashboard, connected, transparent, hideIcon }) {
	const { avatar, avatarImage } = this.props.child;
	// const {navigation} = this.props;
	const headerColor = transparent? 'transparent':Colors.transparentWhite
	let { width, height } = layout;
	var color = this.state.scrolledHeader.interpolate({
		inputRange: [0, 1],
		outputRange: [headerColor, 'rgba(255, 255, 255, 1)']
	});
	var paddingTop = this.state.scrolledPadding.interpolate({
		inputRange: [0, 1],
		outputRange: [0.02 * vmin, 0]
	});
	let headerLeft = <TouchableOpacity onPress={() => this.props.navigation.navigate('Dashboard')}>
		{hideIcon?<View></View>: dashboard ? <Image resizeMode={'cover'} style={{
			flex: 1, zIndex: 4, minWidth: 35, minHeight: 35, alignItems: 'flex-start',
			height: vmin * 0.07, maxHeight: 40, alignSelf: 'flex-start', justifyContent: 'flex-start',
			width: Platform.OS === 'web' ? vmin * 0.15 : 80, maxWidth: 40, margin: 10, marginLeft: 0.03 * vmin
		}} source={require('../../assets/Pennygem_Logo.png')} /> :
			<Text style={{ ...defaultStyles.bold, color: this.state.header ? Colors.green : 'white', alignSelf: 'flex-end', zIndex: 3, margin: 10, marginLeft: 0.03 * vmin, fontSize: 35 }}>{'<'}</Text>

		}
	</TouchableOpacity>


	let headerRight = <TouchableOpacity onPress={() => { this.setState({ visible: !this.state.visible }) }}>
		<Icon style={{ alignSelf: 'flex-end', zIndex: 3, margin: 10, marginRight: 0.03 * vmin }} name="bars" size={30}
			color={this.state.header ? Colors.green : "white"} />
	</TouchableOpacity>

	return <Animated.View style={{
		height: height * 0.1, top: 0,
		flex: 1, width: '100%', zIndex: 5, position: 'absolute', alignItems: 'center',
		backgroundColor: connected === false ? 'rgba(238,80,96,0.5)' : color, flexDirection: 'row', justifyContent: 'space-between'
	}}>
		{/* <View style={{ width: 200, }}> */}
		{headerLeft}
		{/* </View> */}
		<Text style={{
			color: this.state.header ? Colors.green : 'white', fontSize: Platform.OS === 'web' ? (layout.width > 850 ? '1.75em' : '1.375em') : 30,
		}}>{connected === false ? 'No connection' : title}</Text>
		{headerRight}

		<Modal
			animationIn={'slideInLeft'} animationOut={'slideOutLeft'} animationInTiming={300} animationOutTiming={300}
			backdropColor={'darkgrey'} backdropOpacity={0.1}
			onBackdropPress={() => this.setState({ modalVisible: false, visible: false })}
			isVisible={this.state.visible}
			style={{ margin: 0 }}
			onRequestClose={() => { this.setState({ modalVisible: false, visible: false }) }}>
			<View elevation={5} style={{
				flex: 1, margin: 0, justifyContent: 'center',
				backgroundColor: 'white', height: '100%', width: '100%',
				padding: 15, alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.9)'
			}}>
				<TouchableNativeFeedback style={{ position: 'absolute', right: 0.015 * vmin, top: 0.01 * height }}
					onPress={() => this.setState({ modalVisible: false, visible: false })}>
					<View style={{ position: 'absolute', right: 0.015 * vmin, top: 0.02 * height }}>
						<Text style={[{ color: Colors.rose, fontSize: 35, }, defaultStyles.bold]}>X</Text>
					</View>
				</TouchableNativeFeedback>
				<View elevation={5} style={{
					position: 'absolute', top: layout.height * 0.085, alignSelf: 'center', zIndex: 3,
					aspectRatio: 1, maxHeight: 90, maxWidth: 90, width: 0.15 * vmin, height: 0.15 * vmin,
					borderRadius: Platform.OS === 'web' ? '50%' : 0.15 * vmin,
					alignSelf: 'center', backgroundColor: 'white', borderWidth: this.props.isFocused ? 3 : 0,
					borderColor: '#7ec13d', justifyContent: 'center', alignItems: 'center'
				}}>
					<Image resizeMode={'cover'} style={{
						borderRadius: vmin * 0.15,
						// width: vmin * 0.1,  height: vmin * 0.1
						width: '110%', height: '110%'
					}}
						source={avatarImage ? { uri: avatarImage } : avatars[avatar]} />
				</View>
				<View style={{ marginTop: 0.12 * layout.height }}>
					{['Settings', 'Dashboard', 'Chequing', 'Savings', 'Jobs', 'Goals', 'Purchases', 'Store'].map(item => {
						return <TouchableNativeFeedback onPress={() => { this.props.navigation.navigate(item); this.setState({ visible: false }) }}>
							<View style={{ margin: 10 }}>
								<Text style={[{ textAlign: "center", color: Colors.green1, fontSize: 20 }, defaultStyles.bold]}>{item}</Text>
							</View>
						</TouchableNativeFeedback>
					})}

					<TouchableNativeFeedback onPress={() => {
						firebase.auth().signOut();
						this.props.actions.clearAuth().then(() => {
							this.props.navigation.navigate('Login')
						}).then(() => { });
					}}>
						<View style={{ margin: 15 }}>
							<Text style={[{ textAlign: "center", color: Colors.green1, fontSize: 20 }, defaultStyles.bold]}>Logout</Text>
						</View>
					</TouchableNativeFeedback>
				</View>
			</View>
		</Modal>
		{/* {this.state.visible ? <View elevation={5} style={{ zIndex:4,
			position: 'absolute', top: layout.height*0.08, right: 0, backgroundColor: Colors.transparentWhite,
			alignSelf: 'flex-end', width: '30%', maxWidth: 120, marginRight: 10
		}}>
			<TouchableNativeFeedback onPress={() => { this.props.navigation.navigate('Settings') }}><View style={{}}>
				<Text style={{ margin: 10, color: '#106433', }}>Settings</Text></View></TouchableNativeFeedback>
			<TouchableNativeFeedback onPress={() => {
				firebase.auth().signOut();
				this.props.actions.clearAuth().then(() => {
					this.props.navigation.dispatch(resetAction);
					// this.props.navigation.navigate('Login')
				}).then(() => { this.props.actions.logout });
			}}><View style={{ flex: 1, }}>
					<Text style={{ flex: 1, margin: 10, color: '#106433' }}>Logout</Text></View></TouchableNativeFeedback>
		</View> : null} */}
	</Animated.View>
}


export function dropdownParent({ vmin, layout, title, dashboard, connected, pressLeft, pressRight, hideTitle, transparent }) {
	const { avatar, avatarImage } = this.props.auth;
	// const {navigation} = this.props;
	let { width, height } = layout;
	var color = this.state.scrolledHeader.interpolate({
		inputRange: [0, 1],
		outputRange: [transparent?'transparent':Colors.transparentWhite, 'rgba(255, 255, 255, 1)']
	});
	var paddingTop = this.state.scrolledPadding.interpolate({
		inputRange: [0, 1],
		outputRange: [0.02 * vmin, 0]
	});
	let headerLeft = <TouchableOpacity onPress={() => this.props.navigation.navigate('Dashboard')}>
		{dashboard ? <Image resizeMode={'cover'} style={{
			flex: 1, zIndex: 4, minWidth: 35, minHeight: 35, alignItems: 'flex-start',
			height: vmin * 0.07, maxHeight: 40, alignSelf: 'flex-start', justifyContent: 'flex-start',
			width: Platform.OS === 'web' ? vmin * 0.15 : 80, maxWidth: 40, margin: 10, marginLeft: 0.03 * vmin
		}} source={require('../../assets/Pennygem_Logo.png')} /> :
			<Text style={{ ...defaultStyles.bold, color: this.state.header ? Colors.green : 'white', alignSelf: 'flex-end', zIndex: 3, margin: 10, marginLeft: 0.03 * vmin, fontSize: 35 }}>{'<'}</Text>
		}
	</TouchableOpacity>


	let headerRight = <TouchableOpacity onPress={() => { this.setState({ visible: !this.state.visible }) }}>
		<Icon style={{ alignSelf: 'flex-end', zIndex: 3, margin: 10, marginRight: 0.03 * vmin }} name="bars" size={30}
			color={this.state.header ? Colors.green : "white"} />
	</TouchableOpacity>

	return <Animated.View style={{
		height: height * 0.1, top: 0,
		flex: 1, width: '100%', zIndex: 5, position: 'absolute', alignItems: 'center',
		backgroundColor: connected === false ? 'rgba(238,80,96,0.5)' : color, flexDirection: 'row', justifyContent: 'space-between'
	}}>
		{/* <View style={{ width: 200, }}> */}
		{headerLeft}
		{/* </View> */}
		{hideTitle?<View></View>:<View style={{flexDirection:'row', maxWidth:layout.width*0.6>550?550:layout.width*0.6,alignSelf:'center', alignItems:'center', justifyContent:'center'}}>
			<TouchableNativeFeedback onPress={() => pressLeft()}>
					<Text style={{ ...defaultStyles.bold,fontSize:30, textAlign: 'center', color: this.state.header ? Colors.green : 'white', }}>{'<'}</Text>
			</TouchableNativeFeedback>
			<Text style={{ textAlign:'center',marginHorizontal:layout.width*0.04,
				color: this.state.header ? Colors.green : 'white', fontSize: Platform.OS === 'web' ? (layout.width > 850 ? '1.75em' : '1.375em') : 30,
				...defaultStyles.bold,
			}}>{connected === false ? 'No connection' : title}</Text>
			<TouchableNativeFeedback onPress={() => pressRight()}>
					<Text style={{ ...defaultStyles.bold, fontSize:30, textAlign: 'center', color: this.state.header ? Colors.green : 'white', }}>{'>'}</Text>
			</TouchableNativeFeedback>
		</View>}

		{headerRight}

		<Modal
			animationIn={'slideInLeft'} animationOut={'slideOutLeft'} animationInTiming={300} animationOutTiming={300}
			backdropColor={'darkgrey'} backdropOpacity={0.1}
			onBackdropPress={() => this.setState({ modalVisible: false, visible: false })}
			isVisible={this.state.visible}
			style={{ margin: 0 }}
			onRequestClose={() => { this.setState({ modalVisible: false, visible: false }) }}>
			<View elevation={5} style={{
				flex: 1, margin: 0, justifyContent: 'center',
				backgroundColor: 'white', height: '100%', width: '100%',
				padding: 15, alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.9)'
			}}>
				<TouchableNativeFeedback style={{ position: 'absolute', right: 0.015 * vmin, top: 0.01 * height }}
					onPress={() => this.setState({ modalVisible: false, visible: false })}>
					<View style={{ position: 'absolute', right: 0.015 * vmin, top: 0.02 * height }}>
						<Text style={{ color: Colors.rose, fontSize: 35, ...defaultStyles.bold }}>X</Text>
					</View>
				</TouchableNativeFeedback>

				<View style={{ marginTop: 0.12 * layout.height }}>
					{['Settings', 'Dashboard', 'Chequing', 'Savings', 'Jobs', 'Goals', 'Purchases', 'Store'].map((item, index) => {
						return <TouchableNativeFeedback key={index} onPress={() => { this.props.navigation.navigate(item); this.setState({ visible: false }) }}>
							<View style={{ margin: 10 }}>
								<Text style={{ textAlign: "center", color: Colors.green1, ...defaultStyles.bold, fontSize: 20 }}>{item}</Text>
							</View>
						</TouchableNativeFeedback>
					})}

					<TouchableNativeFeedback onPress={() => {
						firebase.auth().signOut();
						this.props.actions.clearAuth().then(() => {
							this.props.navigation.navigate('Login')
						}).then(() => { });
					}}>
						<View style={{ margin: 15 }}>
							<Text style={{ textAlign: "center", color: Colors.green1, ...defaultStyles.bold, fontSize: 20 }}>Logout</Text>
						</View>
					</TouchableNativeFeedback>
				</View>
			</View>
		</Modal>
		{/* {this.state.visible ? <View elevation={5} style={{ zIndex:4,
			position: 'absolute', top: layout.height*0.08, right: 0, backgroundColor: Colors.transparentWhite,
			alignSelf: 'flex-end', width: '30%', maxWidth: 120, marginRight: 10
		}}>
			<TouchableNativeFeedback onPress={() => { this.props.navigation.navigate('Settings') }}><View style={{}}>
				<Text style={{ margin: 10, color: '#106433', }}>Settings</Text></View></TouchableNativeFeedback>
			<TouchableNativeFeedback onPress={() => {
				firebase.auth().signOut();
				this.props.actions.clearAuth().then(() => {
					this.props.navigation.dispatch(resetAction);
					// this.props.navigation.navigate('Login')
				}).then(() => { this.props.actions.logout });
			}}><View style={{ flex: 1, }}>
					<Text style={{ flex: 1, margin: 10, color: '#106433' }}>Logout</Text></View></TouchableNativeFeedback>
		</View> : null} */}
	</Animated.View>
}

