/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import {
	Animated,
	AsyncStorage,
	Dimensions,
	Image,
	ImageBackground,
	Platform,
	StyleSheet,
	View,
	TouchableOpacity,
} from 'react-native';

import { useHover, useFocus, useActive, useDimensions, Resizable } from 'react-native-web-hooks';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import firebase from 'firebase/app';

import { database, auth, storage } from 'firebase';

import { ParentHeaderCard } from '../../components/Header/HeaderBlock'

import Text from '../../components/CustomText'
import TouchableNativeFeedback from '../../components/TouchableFeedback';
import ChangeAvatarBlock from '../../components/settings/ChangeAvatar';

import { ChildCard, ChequingCard, ActivityCard, PurchasesCard, StoreCard } from '../../components/Dashboard/DashboardBlocks';
import { ChildHeaderCard } from '../../components/Header/HeaderBlock';

import * as goalsActions from '../../store/actions/kids/goalsActions'
import * as authActions from '../../store/actions/kids/authActions'
import * as jobsActions from '../../store/actions/kids/jobsActions'
import * as chequingActions from '../../store/actions/kids/chequingActions'

import style, { defaultStyles } from '../../constants/style';

import Colors from '../../constants/Colors';

import { Avatar } from './Settings';

const maxWidth = 700;
const widthThreshold = 600;

class Dashboard extends React.Component {
	static navigationOptions = ({ navigation }) => {
		return {
			headerTransparent: true,
			header: null,
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			data: [],
			isLoading: true,
			width: 0,
			height: 0,
			chequing: 0,
			savings: 0,
			jobs: 0,
			goals: 0,
			purchases: 0,
			spent: 0,
			avatar: null,
		};
	};


	componentDidMount() {
		const { parentId, childId } = this.props.auth;

		const { getChildName } = this.props.actions;

		const userid = this.props.navigation.getParam('userid', null)
		console.log('userid', userid)
		console.log('fullState', this.props.fullState)

		if (!this.props.auth.childId) {
			this.props.actions.clearAuth()
				.then(() => { this.props.navigation.navigate('Auth') })
		}

		this.focusListener = this.props.navigation.addListener('didFocus', () => {
			// this.props.actions.login(email, password).then(async ()=>{await this.getData()})
			// this.getData()
			this.props.actions.getDashboardData({ parentId, childId })
		});

	}

	componentWillUnmount() {
		// Remove the event listener
		this.focusListener.remove();
	}


	async getData() {
		const { parentId, childId } = this.props.auth;
		// const { parentId, childId } = this.props.child;
		let interval = new Date()
		// interval.setMonth(interval.getMonth()-1)

		console.log('parentId, childId', parentId, childId)

		this.props.actions.getAvatar(parentId, childId);

		var dbs_ref = database().ref('/children/' + childId);
		var database_ref = database().ref('/users/' + parentId + '/' + childId);

		const avatar_ref = database_ref.child('settings/avatar')
		const avatar = (await avatar_ref.once('value')).val();

		let image = await AsyncStorage.getItem('avatar', null)
		if (!image && avatar === 5) {
			// if (true) {
			image = await storage().ref('children/' + childId + '.png').getDownloadURL()

			var xhr = new XMLHttpRequest();
			xhr.responseType = 'blob';
			var reader = new FileReader();
			let blob = ''

			xhr.onload = (event) => {
				blob = xhr.response;
				reader.readAsDataURL(blob);
				reader.onloadend = () => {
					var base64data = reader.result;
					this.setState({ image: avatar === 5 ? base64data : undefined, avatar: avatar })
					AsyncStorage.setItem('avatar', base64data)
				}

			};
			xhr.open('GET', image);
			xhr.send();

			console.log('blob -', blob)
		}
		else {
			// let metadata = await storage().ref('children/' + childId + '.png').getMetadata()
			// let image1 = new Image(image, childId + '.png' )
			// let file1 = new FileReader(image, childId + '.png' )

			// // file1.src = image

			// var hash1 = CryptoJS.MD5(image).toString();
			// var hash2 = CryptoJS.MD5(file1).toString();
			// var hash3 = CryptoJS.MD5(image1).toString();

			// console.log('metadata - ', metadata.md5Hash)
			// console.log(Buffer.from(metadata.md5Hash, 'base64').toString('hex'))
			// // console.log(Buffer.from(image, 'base64').toString('hex'))

			// console.log('HASH - ',hash1, hash2)
			// console.log(hash3)

			this.setState({ image: avatar === 5 ? image : undefined, avatar: avatar })
		}

		const name = (await dbs_ref.once('value')).val().name;

		const chequing_ref = database_ref.child('chequing/balance')
		const chequing = (await chequing_ref.once('value')).val();
		const saving_ref = database_ref.child('savings/balance')
		const savings = (await saving_ref.once('value')).val();

		let goals_balance = 0;
		await this.props.actions.getGoals(parentId, childId)
		goals_balance = this.props.goals.data.length

		var jobs_balance = 0;
		await this.props.actions.getJobs(parentId, childId)
		jobs_balance = this.props.jobs.data.length

		/* Purchases balance */
		let purchases_balance = 0;
		let month_before = new Date();
		month_before.setMonth(month_before.getMonth() - 1);
		const pending_purchases = database_ref.child('purchases/requested').orderByChild('request_date').startAt(month_before.toISOString())
		const pending_purchases_val = (await pending_purchases.once('value')).val();
		purchases_balance = pending_purchases_val ? Object.keys(pending_purchases_val).length : 0

		this.props.actions.getChequing(month_before, parentId, childId, this.props.navigation)

		this.setState({
			avatar: avatar,
			chequing: chequing,
			savings: savings,
			spent: this.props.chequing.expense,
			jobs: jobs_balance,
			goals: goals_balance,
			purchases: purchases_balance,
			name: name
		}, () => { console.log('finish') })
	}

	testIntRate() {
		return fetch('https://us-central1-website-ed816.cloudfunctions.net/' + 'updateInterestRate', {
			method: 'POST',
			body: JSON.stringify({})
		})
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				return resp
			})
	}

	render() {
		const { navigation } = this.props;
		const { parentId, childId, } = this.props.auth;
		const { avatar, avatarImage } = this.props.child;

		const { name } = this.state;
		const { chequing, savings, spent, goals, jobs, purchases_approved, purchases_pending } = this.props.child.dashboard;

		const purchasesPending = purchases_approved
		const purchasesApproved = purchases_pending
		return (
			<Resizable>
				{layout => {
					const vmin = Math.min(...[layout.width, layout.height]);
					const width = layout.width;
					return <View style={{ flex: 1, backgroundColor: 'white', alignItems: 'center', paddingVertical: layout.height * 0.015, }}>

						<ChildHeaderCard name={name} navigation={navigation} signOut={() => { }} vmin={vmin} layout={layout} clearAuth={this.props.actions.clearAuth} dashboard={true} />

						{/* <TouchableNativeFeedback onPress={this.testIntRate}>
							<View><Text>TEST INT RASTE</Text></View>
						</TouchableNativeFeedback> */}

						<ChequingCard type={'child'} navigation={navigation} layout={layout} style={{ flex: 0.5, }} title={'Accounts'} additional={'Add Money'}
							upButton={() => { }}
							downButton={() => { }}
							earned={chequing} saved={savings} spent={spent} />

						<View style={{ height: 10, width: 1 }}></View>

						<View style={{
							flex: 1.2, flexDirection: layout.width > widthThreshold ? 'row' : 'column', width: '90%', justifyContent: 'space-between',
							maxWidth: maxWidth,
						}}>
							<View style={{
								flex: 0.5, justifyContent: 'space-between',
								maxWidth: maxWidth, flexDirection: layout.width > widthThreshold ? 'column' : 'row'
							}}>
								<ActivityCard color={Colors.blue}
									style={{ width: '100%', flex: 0.48 }} amount={jobs}
									type={'child'}
									layout={layout} title={'Jobs'} buttonTitle={'+'} onPress={() => navigation.navigate('Jobs')}
									onButtonPress={() => navigation.navigate('Jobs')}
									cornerImage={require('../../assets/dashboard/Jobs_2.png')} />
								<ActivityCard color={Colors.yellow1}
									style={{ width: '100%', flex: 0.48 }} amount={goals}
									// image={storeImage}
									type={'child'}
									layout={layout} title={'Goals'} buttonTitle={'Store'} onPress={() => navigation.navigate('Goals')}
									onButtonPress={() => navigation.navigate('Goals')}
									cornerImage={require('../../assets/dashboard/Goals_2.png')} />
							</View>
							<View style={{ height: 10, width: 10 }}></View>
							<View style={{ flex: 0.6, flexDirection: layout.width > widthThreshold ? 'column' : 'row', alignItems: 'stretch', justifyContent: 'space-between', }}>
								<PurchasesCard layout={layout} onPress={() => navigation.navigate('Purchases')}
									onButtonPress={() => this.props.navigation.navigate('Store')}
									info={[[purchases_pending + purchases_approved, 'Made'], [purchases_approved, 'Approved'], [purchases_pending, 'Pending',]]} />
								<StoreCard layout={layout} onPress={() => navigation.navigate('Store')} style={{}} />
							</View>
						</View>

						{/* </ImageBackground> */}
					</View>
				}}
			</Resizable>
		)
	}
}

const styles = StyleSheet.create({

});

export default connect(state => {
	return ({
		fullState: state,
		child: state.child,
		chequing: state.kidChequing,
		auth: state.auth,
		goals: state.kidGoals,
		jobs: state.kidJobs,
	})
},
	(dispatch) => ({
		actions: bindActionCreators({ ...authActions, ...goalsActions, ...chequingActions, ...jobsActions }, dispatch)
	})
)(Dashboard);
