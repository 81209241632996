
/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { AsyncStorage, ActivityIndicator, Image, ImageBackground, KeyboardAvoidingView, StyleSheet, ScrollView, TextInput, View, Platform } from 'react-native';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { Resizable, Hoverable } from 'react-native-web-hooks';

import Icon from 'react-native-vector-icons/FontAwesome5';

import firebase from 'firebase/app';
import 'firebase/database'
import 'firebase/auth'
import * as Analytics from 'expo-firebase-analytics';

import TouchableNativeFeedback from '../../../components/TouchableFeedback'
import FloatingLabelInput from '../../../components/FloatingLabelInput/FloatingLabelInput'
import Text from '../../../components/CustomText'
// import { ConfirmationView, } from '../components/confirmation'
import Stars from '../../../components/Stars';
import { DropdownElement } from '../../../components/Dropdown';

import { BlockWrapper } from '../../../components/newChild/BlockWrapper'
import { Button, InfoButton } from '../../../components/newChild/Buttons'

import * as authActions from '../../../store/actions/parents/authActions';
import * as kidAuthActions from '../../../store/actions/kids/authActions';

import Colors from '../../../constants/Colors';
import { expenseInfo, chequingInfo } from '../../../constants/info';
import {defaultStyles} from '../../../constants/style';

class Chequing extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...Object.keys(chequingInfo).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: chequingInfo[curr].default } }, {}),
            advanced: true,
        };
    };

    componentDidMount() {
        const chequing = this.props.parent.newKid.chequing;
        this.setState({ ...chequing })
    }

    row({ info, item }) {
        const data = info[item]
        console.log('LABEL ', info, item, data)
        return <View key={item} style={{ width: '100%', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
            <FloatingLabelInput {...data.params} containerStyle={{ width: '100%', alignItems: 'center', alignSelf: 'center' }} label={data.label}
                value={this.state[item]}
                onChangeText={text => this.setState({ [item]: data.format ? data.format(text) : text })} />
            <InfoButton text={data.text} />
        </View>
    }

    changeDay(text) {
        console.log('text', text)

        if (text === 'Monthly') {
            console.log('MONTHLY')
            return this.setState({ day: 'End of the month' })
        }
        if (text !== 'none') {
            return this.setState({ day: 'Friday' })
        }
    }

    checkConditions() {
        const { amount } = this.state;
        return 0
    }

    proceed() {
        const { min_balance, monthly_fee, transact_limit, transact_fee, advanced } = this.state;

        this.props.kidData({ type: 'chequing', data: { min_balance, monthly_fee, transact_limit, transact_fee, advanced } });
        this.props.proceed()
    }

    back() {
        const { min_balance, monthly_fee, transact_limit, transact_fee } = this.state;

        this.props.kidData({ type: 'chequing', data: { min_balance, monthly_fee, transact_limit, transact_fee } });
        this.props.back()
    }

    render() {
        const { layout } = this.props;
        const { amount, frequency, day } = this.state;

        const title = 'Chequing Account Setup'
        const description = <Text style={{ textAlign: 'center', marginVertical: 5, color: Colors.gray1 }}>
            Pennygem offers a lot of customization to allow parents to simulate the real-world banking environment as closely as they’d like. Amounts entered below are our suggestions, feel free
            to change them.
		</Text>

        return (
            <BlockWrapper description={description} title={title}>
                {/* <View> */}
                {(props) => <>
                    <View style={{
                        marginVertical: 20, padding: 30, borderRadius: 10, borderColor: 'lightgray', maxWidth: 400
                    }}>
                        <View style={{ zIndex: 1, opacity: this.state.advanced ? 1 : 0.5, }}>
                            {this.state.advanced ? null : <View style={{
                                zIndex: 3,
                                // backgroundColor:'rgba(128,128,128,0.5)',
                                left: -30,
                                opacity: 0.5, position: 'absolute', height: '100%', width: '100%'
                            }} />}
                            {this.row({ info: chequingInfo, item: 'min_balance' })}
                            {this.row({ info: chequingInfo, item: 'monthly_fee' })}
                            {this.row({ info: chequingInfo, item: 'transact_limit' })}
                            {this.row({ info: chequingInfo, item: 'transact_fee' })}
                        </View>
                        <TouchableNativeFeedback onPress={() => this.setState({ advanced: !this.state.advanced })}>
                            <View style={{ zIndex: -1, marginLeft: -20, marginTop: 10, flex: 1, width: '100%', flexDirection: 'row', marginVertical: 10, alignItems: 'center' }}>
                                <View style={{
                                    borderRadius: 10, height: 30, width: 30, justifyContent: 'center', alignItems: 'center',
                                    borderColor: Colors.green1, borderWidth: 3
                                }}>
                                    <Icon style={{ zIndex: -1, opacity: this.state.advanced ? 0 : 1 }} name="check" size={16} color={Colors.green1} />
                                </View>
                                {/* <View style={{ borderWidth: 3, borderRadius: 5 }}><Text> ? null : '1'}</Text></View> */}
                                <View style={{ marginLeft: 10 }}>
                                    <Text style={[{ color: Colors.green1, }, defaultStyles.bold]}>No account limits</Text>
                                </View>
                            </View>
                        </TouchableNativeFeedback>
                    </View>
                    <View style={{ flex: 1, width: '50%', zIndex: -1, justifyContent: "center", flexDirection: 'row' }}>
                        <Button title={'Back'} onPress={() => this.back()}></Button>
                        <Button title={'Proceed'} onPress={() => this.proceed()}></Button>
                    </View></>}
                {/* </View> */}
            </BlockWrapper>)
    }
}


class ChequingScreen extends React.Component {

    static navigationOptions = ({ navigation }) => {

        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        }
    }


    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            section: 1,
            loading: false
        };
    };



    componentDidMount() {
        const { childIds } = this.props.parent;

        if (childIds.length > 0) {
            this.setState({ section: 2 })
        }

    }



    render() {

        return <View style={{ height: '100%', flex: 1 }}>

            {this.state.loading ? <View style={{
                position: 'absolute', zIndex: 1, backgroundColor: 'rgba(126, 193, 61, 0.5)',
                justifyContent: 'center',
                height: '100%', width: '100%'
            }}>
                <ActivityIndicator color={Colors.green1} size={'large'} />
            </View> : null}

            <Chequing auth={this.props.auth} parent={this.props.parent} actions={this.props.actions}
                navigation={this.props.navigation}
                back={() =>
                    this.props.navigation.goBack()
                    // this.setState({ section: this.state.section - 1 })
                }
                kidData={this.props.actions.kidData}
                // proceed={() => this.setState({ section: this.state.section })}
                proceed={() => this.props.navigation.navigate('SavingsSetup')}
                startLoading={() => this.setState({ loading: true })} stopLoading={() => this.setState({ loading: false })}
            />
        </View>
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(state => {
    // console.log(Object.keys(state.vacancies));
    return ({
        auth: state.auth,
        parent: state.parent,
        store: state.store,
    })
},
    (dispatch) => ({
        actions: bindActionCreators({ ...authActions, ...kidAuthActions, }, dispatch)
    })
)(ChequingScreen);


