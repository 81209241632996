import React from 'react';
import { Platform } from 'react-native';
import { createAppContainer, createSwitchNavigator, createBottomTabNavigator } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import { createBrowserApp } from '@react-navigation/web';

import KidNavigator from './KidNavigator';

// import withGlobalStyles from '../components/globalStyles/globalStyles';

import Login from "../screens/Login";
import RecoverPassword from "../screens/RecoverPassword";
import SetPassword from "../screens/SetPassword";
import VerifyEmail from "../screens/VerifyEmail";
import SignUp from "../screens/SignUp";

import Store from "../screens/Store";

import Dashboard from "../screens/Parents/Dashboard";
import Settings from "../screens/Parents/Settings";
import Chequing from "../screens/Parents/Chequing";
import Jobs from "../screens/Parents/Jobs";
import Goals from "../screens/Parents/Goals";
import Purchases from "../screens/Parents/Purchases";
// import TestScreen from "../screens/TestScreen";
// import StoreParents from "../screens/Parents/Store";
import StoreItem from "../screens/Parents/StoreItem";
// import NewKid from "../screens/Parents/NewKid";

import Welcome from "../screens/Parents/NewKid/Welcome";
import Parent from "../screens/Parents/NewKid/Parent";
import Profile from "../screens/Parents/NewKid/Profile";
import Allowance from "../screens/Parents/NewKid/Allowance";
import Expense from "../screens/Parents/NewKid/Expense";
import AdditionalExpense from "../screens/Parents/NewKid/AdditionalExpense";

import ChequingSetup from "../screens/Parents/NewKid/Chequing";
import SavingsSetup from "../screens/Parents/NewKid/Savings";
import Walkthrough from "../screens/Parents/NewKid/Walkthrough";

import Security from "../screens/Parents/Settings/Security";
import ChequingSettings from "../screens/Parents/Settings/Chequing";
import SavingsSettings from "../screens/Parents/Settings/Savings";
import RegularSettings from "../screens/Parents/Settings/Regular";
import NewParent from "../screens/Parents/Settings/NewParent";


import AuthLoadingScreen from "../screens/AuthLoadingScreen";

import title from "../helpers/generateTitle";

import style, {styleHook} from "../constants/style";


const NewKid = createSwitchNavigator({
	Welcome,
	ParentSetup: { screen: Parent },
	Profile,
	Allowance,
	Expense,
	AdditionalExpense,
	ChequingSetup,
	SavingsSetup,
	Walkthrough
},
	{
		// cardStyle: { backgroundColor: 'white'},
		navigationOptions: () => ({
			headerMode: 'none',
			header: null
		})
	}
)



const createApp = Platform.select({
	web: createBrowserApp,
	default: createAppContainer,
})

const AuthStack = createSwitchNavigator({
	Login,
	SignUp,
	SetPassword,
	RecoverPassword,
	VerifyEmail,

})

const StoreStack = createSwitchNavigator({
	Store:  { screen: Store, path: '', },
	StoreItem: { screen: StoreItem, path: ':index', },


})

const ParentSettingsStack = createSwitchNavigator({
	Settings: { screen: Settings, path: '' },
	Security,
	ChequingSettings: { screen: ChequingSettings, path: 'Chequing' },
	SavingsSettings: { screen: SavingsSettings, path: 'Savings' },
	RegularSettings: { screen: RegularSettings, path: 'Regular' },
	NewParent
}, {
	navigationOptions: () => ({
		headerMode: 'none',
		header: null
	})
})

const stackNavigator =
	Platform.OS === 'web' ?
		createStackNavigator({
			Dashboard: {screen: props=> { return <Dashboard {...props} globalStyle={styleHook()} />}},
			Goals,
			Jobs,
			// Settings,
			Purchases,
			// Store,
			NewKid,
			Settings: { screen: ParentSettingsStack, path: 'Settings', },
			Chequing: { screen: Chequing, path: ':cards', },
			StoreItem: { screen: StoreItem, path: 'Store/:store/:index', },
			// Home,
			// TestScreen,
		},
			{
				cardStyle: { backgroundColor: 'white'},
				headerMode:'none',
				header:null,
				navigationOptions: ({ navigation }) => {
					const current = navigation.state.routes && navigation.state.routes.length && navigation.state.routes[navigation.state.routes.length - 1].routeName
					return { title: current && title(current) }
				},
				initialRouteName: "Dashboard",
			}
		)
		:
		createStackNavigator({
			Dashboard: {screen: props=><Dashboard {...props} globalStyle={{hello:'World'}} />, navigationOptions:{header:null,}},
			Goals,
			Jobs,
			Chequing,
			Purchases,
			Settings,
			Store,
			StoreItem,
			NewKid,
			// TestScreen,
			// SignUp
		},
		{ initialRouteName: "Dashboard", navigationOptions:{ headerMode:'none', header:null,} }
		)
// stackNavigator.path = '';

const AppContainer = createAppContainer(
	createSwitchNavigator(
		{
			AuthLoading: { screen: AuthLoadingScreen, path: "" },
			Auth: { screen: AuthStack, path: "", navigationOptions: { title: title('Login/Sign Up') } },
			Parent: { screen: stackNavigator, path: "parents" },
			Kid: { screen: KidNavigator, path: 'kids' },
			Store: { screen: StoreStack, path: 'Store' }
		},
		{
			initialRouteName: 'AuthLoading',
			// path:''
		},
	),
)

// export default AppContainer

export default createApp(
	AppContainer
)
// export default createApp(
// 	createSwitchNavigator(
// 		{Login}
// 	)
// )


// export default Platform.OS==='web'?
// createBrowserApp(stackNavigator, { })
// :
// createAppContainer(
//   stackNavigator
// )


