/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
	// AsyncStorage,
	// Button,
	Dimensions,
	Image,
	ImageBackground,
	KeyboardAvoidingView,
	Platform,
	SafeAreaView,
	TouchableOpacity,
	// Platform,
	TouchableWithoutFeedback,
	StyleSheet,
	ScrollView,
	View,
	StatusBar,
} from 'react-native';

import {
	useDimensions,
	useActive,
	useFocus,
	useHover,
	useREM,
	useScaledSize,
	Hoverable, Resizable
} from 'react-native-web-hooks';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import DatePicker from 'react-native-datepicker'


import * as ImagePicker from 'expo-image-picker';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';


import TouchableNativeFeedback from '../../components/TouchableFeedback'
import FloatingLabelInput from '../FloatingLabelInput/FloatingLabelInput'
import Text from '../../components/CustomText'
import { Button } from '../../components/Buttons'
import ParentComponentHeader from '../../components/ComponentHeader'
import { ConfirmationView, RejectionView } from '../../components/confirmation'
import { DropdownElement } from '../../components/Dropdown'

import * as chequingActions from '../../store/actions/parents/chequingActions'
import * as authActions from '../../store/actions/parents/authActions'
import Colors from '../../constants/Colors';

const auth = firebase.auth;
const database = firebase.database;

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const salaryFreqs = { 'Monthly': 1, 'Bi-weekly': 2, 'Weekly': 4, }
const salaryFreqsReversed = { 1: 'Monthly', 2: 'Bi-weekly', 4: 'Weekly', }

function formatInputValue(str) {
	return (str || str === 0) ? str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".") : ''
}

function formatInterestRate(str) {
	return (str && str !== '%') ? parseFloat(str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".")) + '%' : ''
}


Date.prototype.yyyymmdd = function () {
	var mm = this.getMonth() + 1; // getMonth() is zero-based
	var dd = this.getDate();

	return [this.getFullYear(),
	(mm > 9 ? '' : '0') + mm,
	(dd > 9 ? '' : '0') + dd
	].join('-');
};


class Settings extends React.Component {
	static navigationOptions = ({ navigation }) => {
		return {
			// headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>, 
			headerTransparent: true,
			headerLeft: null,
			header: null
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			data: [],
			isLoading: true,
			isFocused: false,
			hasCameraPermission: null,
			//  image: '0',
			image: null,
			emailChange: false,
			nameChange: false,
			oldPassword: '',
			password: '',
			confirmPassword: '',
			id: 0,
			allowanceStart: (new Date()).yyyymmdd()
			//  image: 'file:///data/user/0/host.exp.exponent/cache/ExperienceData/%2540dtsvetkov%252Fpennygembank/ImagePicker/cc28e74d-706e-4020-a1f0-00192ee0b38c.jpg',
			// type: Camera.Constants.Type.back,
		};
	};

	componentDidMount() {
		const { parentId } = this.props.auth;
		const { childIds } = this.props.parent;

		this.props.actions.getSettings(parentId, childIds[this.props.id])
			.then(settings => { console.log('settings!!', settings); this.setState({ ...settings }) })
	}

	componentDidUpdate(prevProps) {

		if ((this.props.id) !== (prevProps.id)) {
			const { parentId } = this.props.auth;
			const { childIds } = this.props.parent

			this.props.actions.getSettings(parentId, childIds[this.props.id])
			.then(settings => { console.log('settings!!', settings); this.setState({ ...settings }) })
		}
	}

	render() {
		const { layout, actions, id, settings } = this.props;

		const { parentId, } = this.props.auth;
		const { childIds, names, isLoading,  } = this.props.parent;

		let width = layout.width;
		const closeFontSize = Platform.OS === 'web' ? (width > 850 ? '2.25em' : '1.625em') : (40)
		// const {int_rate, withdraw_fee, withdraw_limit, min_balance, monthly_fee, salary_amt, salary_freq,
		//     transact_fee, transact_limit} = childIds[this.state.id]?settings[childIds[this.state.id]]:{}
		return (<View>
                        {<Modal
								animationIn={'zoomIn'} animationOut={'zoomOut'}
								animationInTiming={500} animationOutTiming={500}
								backdropColor={'darkgrey'} backdropOpacity={0.6}
								onBackdropPress={() => this.setState({ modalVisible: false })}
								isVisible={this.state.modalVisible}
								onRequestClose={() => { this.setState({ modalVisible: false }) }}>
								<View style={{
									borderRadius: 15, backgroundColor: 'white', width: '80%', height: '50%', alignSelf: 'center',
									maxHeight: 500, maxWidth: 400, minHeight: 250, justifyContent: 'center', alignItems: 'center'
								}}>
                                    <ConfirmationView image={require('../../assets/Avatars/icon_2.png')} title={'Are you sure you want to remove child?'}
                                    subtitles={['This action cannot be undone.']}
                                    confirmPress={() => {
                                        this.setState({ modalVisible: false, });
                                    }}
                                    rejectPress={() => { this.setState({ modalVisible: false }) }} />
								</View>
							</Modal>}
			{<View onLayout={e => { this.setState({ childPos: e.nativeEvent.layout.y }) }} style={{flex:1,alignItems:'center', width: '100%',marginBottom:200 }}>

				<TouchableNativeFeedback style={{width:'100%'}}>
                    <View style={{padding:10,marginTop:50, alignSelf:'center', alignItems:'center',width:'80%', borderWidth:2, borderRadius:10, borderColor:Colors.red}}>
                        <Text style={{color:Colors.red}}>Reset</Text>
                    </View>
                </TouchableNativeFeedback>

                <TouchableNativeFeedback style={{width:'100%'}} onPress={()=>{this.setState({modalVisible:true})}}>
                    <View style={{margin:20, alignSelf:'center', padding:10, alignItems:'center',width:'80%', borderRadius:10, backgroundColor:Colors.red}}>
                        <Text style={{color:'white'}}>Remove Child</Text>
                    </View>
                </TouchableNativeFeedback>

			</View>}
		</View>
		)
	}
}

const styles = StyleSheet.create({

});

export default Settings;
// export default connect(state => {
//     // console.log(Object.keys(state.vacancies));
//     return ({
//         auth: state.auth,
//     })
// },
//     (dispatch) => ({
//         actions: bindActionCreators({ ...authActions, ...chequingActions }, dispatch)
//     })
// )(Settings);
