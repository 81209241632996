/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import {
    // AsyncStorage,
     ActivityIndicator, Image, ImageBackground, KeyboardAvoidingView, StyleSheet, ScrollView, TextInput, View, Platform } from 'react-native';

import { LinearGradient } from 'expo-linear-gradient';

import * as Analytics from 'expo-firebase-analytics';

import TouchableNativeFeedback from '../TouchableFeedback'
import Text from '../CustomText'

import {defaultStyles} from '../../constants/style';

import Colors from '../../constants/Colors';

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default


export const InfoButton = ({ text, textComponent, style }) => {
    const [visible, setVisible] = useState(false);
    return <React.Fragment>
        <TouchableNativeFeedback onPress={() => setVisible(!visible)}>
            <View style={[{
            // margin: 10,
            height: 25, width: 25, borderRadius: 35, backgroundColor: Colors.green1,
            justifyContent: 'center', alignItems: 'center'
        }, style]}><Text style={{ ...defaultStyles.bold, color: 'white' }}>?</Text></View></TouchableNativeFeedback>
        {/* {visible ? <View style={{ left: '90%', width: 300, position: 'absolute' }}><Text>Knowing your kid’s age will help us better customize the app features. Only you and your kid will be able to see this
information. This is not a mandatory field.</Text></View> : null} */}
        <Modal animationIn={'zoomIn'} animationOut={'zoomOut'} animationInTiming={300} animationOutTiming={300}
            backdropColor={'darkgrey'} backdropOpacity={0.1}
            onBackdropPress={() => setVisible(false)}
            isVisible={visible}
            onRequestClose={() => { setVisible(false) }}>
            <View elevation={5} style={{
                borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center', alignItems: 'center',
                maxWidth: 400, minHeight: 100, paddingVertical: 15, paddingHorizontal: 20, justifyContent: 'center', boxShadow: "2px 2px 15px #272827",
            }}>
                {textComponent ||
                    <Text style={{ textAlign: 'center', marginTop: 20, color: Colors.gray1 }}>{text}</Text>}
                <TouchableNativeFeedback onPress={() => setVisible(false)}>
                    <View style={{ backgroundColor: Colors.green1, borderRadius: 5, marginVertical: 10, paddingHorizontal: 20, paddingVertical: 10, }}>
                        <Text style={{ ...defaultStyles.bold, color: 'white' }}>Got it</Text>
                    </View>
                </TouchableNativeFeedback>
            </View>
        </Modal>
    </React.Fragment>
}

export const InfoButtonControlled = ({ text, textComponent, visible, setVisible }) => {
    // const [visible, setVisible] = useState(false);
    return <React.Fragment>
        <TouchableNativeFeedback onPress={() => setVisible(!visible)}><View style={{
            // margin:10,
            height: 25, width: 25, borderRadius: 35, backgroundColor: Colors.green1,
            justifyContent: 'center', alignItems: 'center'
        }}><Text style={{ ...defaultStyles.bold, color: 'white' }}>?</Text></View></TouchableNativeFeedback>
        {/* {visible ? <View style={{ left: '90%', width: 300, position: 'absolute' }}><Text>Knowing your kid’s age will help us better customize the app features. Only you and your kid will be able to see this
information. This is not a mandatory field.</Text></View> : null} */}
        <Modal animationIn={'zoomIn'} animationOut={'zoomOut'} animationInTiming={300} animationOutTiming={300}
            backdropColor={'darkgrey'} backdropOpacity={0.1}
            onBackdropPress={() => setVisible(false)}
            isVisible={visible}
            onRequestClose={() => { setVisible(false) }}>
            <View elevation={5} style={{
                borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center', alignItems: 'center',
                maxWidth: 400, minHeight: 100, paddingVertical: 15, paddingHorizontal: 20, justifyContent: 'center', boxShadow: "2px 2px 15px #272827",
            }}>
                {textComponent ||
                    <Text style={{ textAlign: 'center', marginTop: 20, color: Colors.gray1 }}>{text}</Text>}
                <TouchableNativeFeedback onPress={() => setVisible(false)}>
                    <View style={{ backgroundColor: Colors.green1, borderRadius: 5, marginVertical: 10, paddingHorizontal: 20, paddingVertical: 10, }}>
                        <Text style={{ ...defaultStyles.bold, color: 'white' }}>Got it</Text>
                    </View>
                </TouchableNativeFeedback>
            </View>
        </Modal>
    </React.Fragment>
}

export const Button = ({ title, onPress, style }) => {

    // onPress={onPress}
    return <TouchableNativeFeedback onPress={onPress}
    // {...Platform.OS === 'web' ? { onClick: onPress } : { onPress: onPress }}
    >
        <LinearGradient start={[0, 1]} end={[1, 0]}
            colors={[Colors.green2, Colors.green1]}
            // colors={['#de3c95', '#ffd71b']}
            style={[{
                zIndex: 1, width: 120, margin: 10,
                justifyContent: 'center', height: 40, backgroundColor: '#7ec13d', borderRadius: 5,
            }, style]}>
            <Text style={{ fontSize: 19, color: 'white', alignSelf: 'center', margin: 5,...defaultStyles.bold }}>
                {title.toUpperCase()}</Text>
        </LinearGradient>
    </TouchableNativeFeedback>

}
