/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
	// AsyncStorage,
	// Button,
	Dimensions,
	Image,
	ImageBackground,
	KeyboardAvoidingView,
	Platform,
	SafeAreaView,
	TouchableOpacity,
	// Platform,
	TouchableWithoutFeedback,
	StyleSheet,
	ScrollView,
	View,
	StatusBar,
} from 'react-native';

import {
	useDimensions,
	useActive,
	useFocus,
	useHover,
	useREM,
	useScaledSize,
	Hoverable, Resizable
} from 'react-native-web-hooks';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import DatePicker from 'react-native-datepicker'

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';


import TouchableNativeFeedback from '../../components/TouchableFeedback'
import FloatingLabelInput from '../FloatingLabelInput/FloatingLabelInput'
import Text from '../../components/CustomText'
import { Button } from '../../components/Buttons'
import ParentComponentHeader from '../../components/ComponentHeader'
import { ConfirmationView, RejectionView } from '../../components/confirmation'
import { DropdownElement } from '../../components/Dropdown'

import * as chequingActions from '../../store/actions/parents/chequingActions'
import * as authActions from '../../store/actions/parents/authActions'
import Colors from '../../constants/Colors';

import { defaultStyles } from "../../constants/style";

const auth = firebase.auth;
const database = firebase.database;

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default


function emailIsValid(email) {
	return /\S+@\S+\.\S+/.test(email)
}

Date.prototype.yyyymmdd = function () {
	var mm = this.getMonth() + 1; // getMonth() is zero-based
	var dd = this.getDate();

	return [this.getFullYear(),
	(mm > 9 ? '' : '0') + mm,
	(dd > 9 ? '' : '0') + dd
	].join('-');
};

const HeaderElement = ({ title, focused, onPress, layout }) => {
	const vmin = Math.min(layout.width, layout.height);
	return <TouchableNativeFeedback style={{ width: '100%', flex: 1, }} onPress={onPress}>
		<View style={{
			margin: vmin * 0.01, padding: vmin * 0.01, borderRadius: 10, backgroundColor: focused ? Colors.green1 : 'white',
			borderWidth: 3, borderColor: Colors.green1, alignItems: 'center',
			// borderBottomColor:Colors.green1, borderBottomWidth:3
		}}>
			<Text style={{ fontSize: 18, color: focused ? 'white' : Colors.green1 }}>{title}</Text>
		</View>
	</TouchableNativeFeedback>
}

class Settings extends React.Component {
	static navigationOptions = ({ navigation }) => {
		return {
			// headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
			headerTransparent: true,
			headerLeft: null,
			header: null
		}
	}

	constructor(props) {
		super(props);
		this.childIdRef = React.createRef()
		this.childAgeRef = React.createRef()
		this.state = {
			data: [],
			isLoading: true,
			email: '',
			username: '',
			age: '',
			isFocused: false,
			//  image: 'file:///data/user/0/host.exp.exponent/cache/ExperienceData/%2540dtsvetkov%252Fpennygembank/ImagePicker/cc28e74d-706e-4020-a1f0-00192ee0b38c.jpg',
			// type: Camera.Constants.Type.back,
		};
	};


	async componentDidMount() {
		const { parentId, user } = this.props.auth;
		const { childIds, settings, id } = this.props.parent;


	}

	render() {
		const { parentId, } = this.props.auth;
		const { childIds, names, isLoading, settings, id } = this.props.parent;

		const { navigation, layout } = this.props;

		console.log('Object.keys(childIds).length')
		console.log(Object.keys(childIds).length)
		console.log((childIds))
		console.log('parentId - ', parentId)

		const vmin = Math.min(...[layout.width, layout.height]);
		let width = layout.width;
		const closeFontSize = Platform.OS === 'web' ? (width > 850 ? '2.25em' : '1.625em') : (40)

		// const {int_rate, withdraw_fee, withdraw_limit, min_balance, monthly_fee, salary_amt, salary_freq,
		//     transact_fee, transact_limit} = childIds[id]?settings[childIds[id]]:{}
		return <View style={{ width: '100%' }}>
			{<Modal
				animationIn={'zoomIn'} animationOut={'zoomOut'}
				animationInTiming={500} animationOutTiming={500}
				backdropColor={'darkgrey'} backdropOpacity={0.6}
				onBackdropPress={() => this.setState({ addChildModal: false })}
				isVisible={this.state.addChildModal}
				onRequestClose={() => { this.setState({ addChildModal: false }) }}>
				<View style={{
					borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
					maxWidth: 400, minHeight: 250, justifyContent: 'center'
				}}>
					{(this.state.username && emailIsValid(this.state.email)) ?
						<ConfirmationView image={require('../../assets/Avatars/icon_2.png')} title={'Add'}
							subtitles={[this.state.username, this.state.email, this.state.age ? (this.state.age + ' years old') : null]}
							confirmPress={() => {
								this.setState({ addChildModal: false, });

								this.props.actions.sendRegistrationChildEmail(parentId, this.state.username, this.state.email)
									.then((resp) => {
										console.log('childId from REG', resp);
										// if (childI)
										if (resp.data === 'Success') {
											let childId = resp.childId
											console.log('parentId, childId, childIds.length, childIds, ', parentId, childId, childIds.length, childIds)
											// this.props.actions.sendRegistrationChildEmail(this.state.username, this.state.email, childId);
											this.props.actions.getSettings(parentId, childId)
												.then((settings) => {
													this.props.actions.changeChildIndex(childIds.length - id)
													this.setState({ id: childIds.length, username: '', email: '', age: '', ...settings });
													// Platform.OS === 'web' ?
													// 	global.window.scrollTo({ top: 800 + 0.20 * layout.height, behavior: 'smooth' }) :
													// this._scrollView.scrollTo({ y: 850 + 0.25 * layout.height })
													// this._scrollView.scrollTo({y:this.state.childPos + 0.1*(1000+layout.height)});
												})
										}
										else {
											// this.setState({addChildModal: true,})
										}

									})
									.then(() => this.props.navigateToChild());
							}}
							rejectPress={() => { this.setState({ addChildModal: false }) }}
						/> :
						<RejectionView title={'Please add child name and valid email'} subtitles={[]} okPress={() => { this.setState({ addChildModal: false }) }} />}
				</View>
			</Modal>}


			<View style={{
				paddingTop: 0.01 * vmin, alignItems: 'center', width: '100%', maxWidth: 600, minWidth: 200, alignSelf: 'center',
				justifyContent: 'center', alignItems: 'center'
			}}>

				<Text style={{ fontSize: 30, ...defaultStyles.bold, color: Colors.gray0, marginBottom: 10 }}>Add child</Text>

				{/* <FloatingLabelInput label={"Child's Name"} value={this.state.username}
										onChangeText={text => this.setState({ username: text })}
										onSubmitEditing={() => { this.childIdRef.current.focus() }}
									/>
									<View style={{ margin: 5 }}></View>
									<FloatingLabelInput inputRef={this.childIdRef} keyboardType={'email-address'} label={"Child's Email"} value={this.state.email}
										onChangeText={text => this.setState({ email: text })}
										onSubmitEditing={() => { this.childAgeRef.current.focus() }}
									/>
									<View style={{ margin: 5 }}></View>
									<FloatingLabelInput inputRef={this.childAgeRef} label={"Child's Age (optional)"} value={this.state.age.replace(/\D/g, '')}
										onChangeText={text => this.setState({ age: text })} /> */}

				{/* <Button styles={{ width: '80%', alignSelf: 'center', marginVertical: vmin * 0.05 }} textStyle={{ width: '100%', textAlign: 'center' }}
										title={'Add Child'} onPress={() => { this.setState({ addChildModal: true }) }} /> */}

				<Button
					textStyle={{ width: '100%', textAlign: 'center' }}
					styles={{ width: '80%', alignSelf: 'center', marginVertical: vmin * 0.05 }}
					title={'Add new kid'} onPress={() => {
						this.props.navigation.navigate('Profile')
					}} />
				{/* <Button title={'Clear Children'} onPress={() => { this.clearChildren() }} /> */}
				{/* <Button title={'Clear Children'} onPress={() => { this.props.actions.testCustomUser() }} /> */}

			</View>
		</View>
	}
}

const styles = StyleSheet.create({

});

// export default Settings;
export default connect(state => {
	// console.log(Object.keys(state.vacancies));
	return ({
		auth: state.auth,
	})
},
	(dispatch) => ({
		actions: bindActionCreators({ ...authActions, ...chequingActions }, dispatch)
	})
)(Settings);
