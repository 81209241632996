

export function filterSavings(data) {
    let deposits = 0, deposits_trans = 0;
    let interest = 0, interest_trans = 0;
    let withdrawals = 0, withdrawals_trans = 0;
    let savings_fees_amount = 0, savings_fees_trans = 0;

    let withdrawals_arr = [];
    let deposits_arr = [];
    let savings_fees = [];

    for (let i in data) {

        if (data[i] === null) continue

        switch (data[i].type) {
            case 'deposit':
                deposits_arr.push(data[i])
                deposits += data[i].amount;
                deposits_trans++;
                break;
            case 'interest':
                deposits_arr.push(data[i])
                // interest_arr.push(data[i])
                deposits += data[i].amount;
                deposits_trans++;
                interest += data[i].amount;
                break;
            case 'refund':
                deposits_arr.push(data[i])
                // interest_arr.push(data[i])
                deposits += data[i].amount;
                deposits_trans++;
                // interest += data[i].amount;
                break;

            case 'withdrawal':
                withdrawals_arr.push(data[i]);
                withdrawals += data[i].amount;
                withdrawals_trans++;
                break;

            case 'credit purchase':
                withdrawals_arr.push(data[i]);
                withdrawals += data[i].amount;
                withdrawals_trans++;
                break;

            case 'savings credit purchase':
                withdrawals_arr.push(data[i]);
                withdrawals += data[i].amount;
                withdrawals_trans++;
                break;

            case 'online purchase':
                withdrawals_arr.push(data[i]);
                withdrawals += data[i].amount;
                withdrawals_trans++;
                break;

            case 'withdraw fee':
                savings_fees.push(data[i]);
                savings_fees_amount += data[i].amount;
                savings_fees_trans++;
                break;

            case 'transaction fee':
                savings_fees.push(data[i]);
                savings_fees_amount += data[i].amount;
                savings_fees_trans++;
                break;
        }
    }



    return ({
        total_savings: deposits - withdrawals - savings_fees_amount,
        total_savings_trans: deposits_trans + withdrawals_trans + savings_fees_trans,
        data: data,
        deposits_arr: deposits_arr,
        withdrawals_arr: withdrawals_arr,
        deposits: deposits,
        deposits_trans: deposits_trans,
        withdrawals: withdrawals,
        interest: interest,
        interest_trans: interest_trans,
        withdrawals: withdrawals,
        withdrawals_trans: withdrawals_trans,
        savings_fees: savings_fees,
        savings_fees_amount: savings_fees_amount,
        savings_fees_trans: savings_fees_trans,
        isLoading: false
    })
}
