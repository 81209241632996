/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { ActivityIndicator, Image, ImageBackground, KeyboardAvoidingView, StyleSheet, ScrollView, TextInput, View, Platform } from 'react-native';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { Resizable, Hoverable } from 'react-native-web-hooks';

import { LinearGradient } from 'expo-linear-gradient';

import firebase from 'firebase/app';
import 'firebase/database'
import 'firebase/auth'
// import 'firebase/analytics'
import * as Analytics from 'expo-firebase-analytics';

import TouchableNativeFeedback from '../components/TouchableFeedback'
import FloatingLabelInput from '../components/FloatingLabelInput/FloatingLabelInput'
import Text from '../components/CustomText'
import { ConfirmationView, } from '../components/confirmation'
import Stars from '../components/Stars';
import { DropdownElement } from '../components/Dropdown';


import * as authActions from '../store/actions/parents/authActions';
import * as kidAuthActions from '../store/actions/kids/authActions';
import {defaultStyles} from '../constants/style'

import Colors from '../constants/Colors';

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const auth = firebase.auth;
const database = firebase.database;
// const analytics = firebase.analytics;
const salaryFreqs = { 'Monthly': 1, 'Bi-weekly': 2, 'Weekly': 4, }
const salaryDays = {'1st of the month':0, 'End of the month':1,
'Monday':0, 'Tuesday':2, 'Wednesday':3, 'Thursday':4, 'Friday':5, 'Saturday':6, 'Sunday':0 }

const avatars = {
    0: require('../assets/Avatars/icon_1.png'), 1: require('../assets/Avatars/icon_2.png'),
    2: require('../assets/Avatars/icon_3.png'), 3: require('../assets/Avatars/icon_4.png'),
    4: require('../assets/Avatars/icon_5.png'), 5: require('../assets/Avatars/icon_6.png')
}

const kidInfo = {
    'name': { label: 'Kid\'s name *', text: 'This will be used to greet your kid in the app. It does not need to be the official name.', params: {} },
    userName: { label: 'Kid\'s Username *', text: 'This is the username your kid will use to login.' },
    password: { label: 'Password *', text: 'This is the password your kid will use to login. It can be changed anytime after the first login.', params: { secureTextEntry: true } },
    email: {
        label: 'Email', text: 'If you share your kid’s email, they will be able to receive notifications and reminders about the activities in the app\
    (like new jobs posted, approval notifications, etc.) by email. We will also send a first greeting email to your kid. This is not a\
    mandatory field.', params: { keyboardType: 'email-address' }
    },
    age: {
        label: 'Kid\'s Age', text: 'Knowing your kid\’s age will help us better customize the app features. Only you and your kid will be able to see this \
    information. This is not a mandatory field.'}
}

const allowanceInfo = {
    'amount': {
        label: 'Allowance amount *', text: 'The amount of recurring allowance for your kid. You can always change it later. For your reference,\
    an average American family pays approximately 50 cents to $1 per week for each year of a child\'s age. For example, a 10-year-old\
    would get $5-$10 per week. Refer to this table or blog post for more detail.', params: {}
    },
    frequency: {
        label: 'Allowance frequency *', text: 'Typically, kids receive allowance once a week. Long intervals between\
    allowance payments might be discouraging to a kid.' },
}


const RejectionView = ({ title, subtitles, okText, okPress }) => {

    return <View
        onkeypress={(event) => {
            console.log('event!!!!', event)
        }} style={{ justifyContent: 'center', alignItems: 'center' }}>

        <View style={{ marginVertical: 15, alignItems: 'center' }}><Text style={{ textAlign: 'center', color: Colors.gray0, fontSize: 18, margin: 5, }}>{title}</Text>
            {subtitles.map((subtitle, index) => <Text key={index} style={{ fontSize: 20, margin: 5, color: '#5aca3c', }}>{subtitle}</Text>)}
        </View>

        <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', marginHorizontal: 15, }}>
            <View style={{ width: '80%', }}>
                <TouchableNativeFeedback
                    onKeyPress={(event) => {
                        console.log('event!!!!', event)
                    }}
                    onPress={okPress}>
                    <LinearGradient
                        start={[0, 1]} end={[1, 0]}
                        colors={[Colors.green2, Colors.green1]}
                        style={{ justifyContent: 'center', alignItems: 'center', borderRadius: 5, margin: 5, }}>
                        {/* <View style={{
				justifyContent: 'center', alignItems: 'center', borderRadius: 10, margin: 5, backgroundColor: 'rgba(126,193,61,1)'
			  }}> */}
                        <Text style={{ fontSize: 19, textAlign: 'center', marginVertical: 5, marginHorizontal: 15,...defaultStyles.bold, color: 'white' }}>
                            {okText ? okText : 'OK'}</Text>
                        {/* </View> */}
                    </LinearGradient>
                </TouchableNativeFeedback>
            </View>
        </View>
    </View>

}

const InfoButton = ({ text }) => {
    const [visible, setVisible] = useState(false);
    return <React.Fragment>
        <TouchableNativeFeedback onPress={() => setVisible(!visible)}><View style={{
            margin: 10, height: 25, width: 25, borderRadius: 35, backgroundColor: Colors.green1,
            justifyContent: 'center', alignItems: 'center'
        }}><Text style={{...defaultStyles.bold, color: 'white' }}>?</Text></View></TouchableNativeFeedback>
        {/* {visible ? <View style={{ left: '90%', width: 300, position: 'absolute' }}><Text>Knowing your kid’s age will help us better customize the app features. Only you and your kid will be able to see this
information. This is not a mandatory field.</Text></View> : null} */}
        <Modal animationIn={'zoomIn'} animationOut={'zoomOut'} animationInTiming={300} animationOutTiming={300}
            backdropColor={'darkgrey'} backdropOpacity={0.1}
            onBackdropPress={() => setVisible(false)}
            isVisible={visible}
            onRequestClose={() => { setVisible(false) }}>
            <View elevation={5} style={{
                borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center', alignItems: 'center',
                maxWidth: 400, minHeight: 100, paddingVertical: 15, justifyContent: 'center', boxShadow: "2px 2px 15px #272827",
            }}>
                <Text style={{ textAlign: 'center', marginTop:20 }}>{text}</Text>
                <TouchableNativeFeedback onPress={() => setVisible(false)}>
                    <View style={{ backgroundColor: Colors.green1, borderRadius: 5, marginVertical: 10, paddingHorizontal: 20, paddingVertical: 10, }}>
                        <Text style={{...defaultStyles.bold, color: 'white' }}>Got it</Text>
                    </View>
                </TouchableNativeFeedback>
            </View>
        </Modal>
    </React.Fragment>
}

export const AuthTextInput = ({ value, onSubmitEditing, onChangeText, placeholder, inputRef, secureTextEntry, keyboardType }) => {
    return <TextInput
        value={value}
        onSubmitEditing={onSubmitEditing}
        ref={inputRef}
        secureTextEntry={secureTextEntry || false}
        keyboardType={keyboardType || 'default'}
        onChangeText={text => onChangeText(text)}
        style={{
            fontSize: 18,
            ...Platform.OS === 'web' ? { outlineColor: Colors.green1 } : {}, fontSize: 14,
            backgroundColor: 'white', height: 40, width: '90%', fontFamily: 'Carmen Sans Regular', color: '#7ec13d',
            paddingLeft: 10, justifyContent: 'center', marginVertical: 10,
        }}
        multiline={false} placeholder={placeholder}
    />
}

export const Button = ({ title, onPress }) => {
    return <TouchableNativeFeedback style={{ margin: 12, width: '90%' }}
        onPress={onPress}>
        <LinearGradient start={[0, 1]} end={[1, 0]}
            colors={[Colors.green2, Colors.green1]}
            // colors={['#de3c95', '#ffd71b']}
            style={{
                zIndex: 1,
                justifyContent: 'center', height: 40, width: '100%', backgroundColor: '#7ec13d', borderRadius: 5,
            }}>
            <Text style={{ fontSize: 19, color: 'white', alignSelf: 'center', margin: 5, ...defaultStyles.bold}}>
                {title.toUpperCase()}</Text>
        </LinearGradient>
    </TouchableNativeFeedback>

}

const BlockWrapper = ({ title, description, onPress, layout, children }) => {
    const vmin = Math.min(...[layout.width, layout.height]);
    const top = vmin * 0.1 < 52 ? 52 : vmin * 0.105
    return (<View style={{minWidth: 350, width: '70%', height:layout.height*0.8, maxHeight:650,
    justifyContent: 'center', alignItems: 'center', borderRadius: 15, backgroundColor: 'white', padding: 20}}>
        <Image resizeMode={'contain'} style={{
            position: 'absolute', top: -top,
            height: vmin * 0.2, width: vmin * 0.2,
            minWidth: 100, minHeight: 100,
            // 540x300
        }} source={require('../assets/signup_window_crop.png')} />
        <Text style={{...defaultStyles.bold, textAlign: 'center', fontSize: 25, marginVertical: 10, marginTop: vmin * 0.05 }}>{title}</Text>
        {description}
        {children}
    </View>)

}

class Welcome extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rememberMe: false
        };
    };


    render() {
        const { layout } = this.props;
        const vmin = Math.min(...[layout.width, layout.height]);
        // const titleFontSize = Platform.OS === 'web' ? (layout.width > 850 ? '3em' : '2.5em') : 48
        let titleFontSize = layout.width > 850 ? (layout.width > 1500 ? 30 : layout.width / 25) : 30
        titleFontSize = 25


        return (<View style={{
            minWidth: 300, width: '70%', justifyContent: 'center', alignItems: 'center',
            borderRadius: 15, backgroundColor: Colors.transparentWhite, padding: 20
        }}>
            {/* <View> */}
            <Text style={{...defaultStyles.bold, textAlign: 'center', fontSize: 25, marginVertical: 10 }}>Welcome!</Text>
            <Text style={{ textAlign: 'center', marginVertical: 5 }}>Welcome to Pennygem, a smart parenting app for your child’s financial
            well-being! Thank you for being such a awesome parent to your kids
            and taking the steps to help them learn the invaluable skills of money
            management.
                </Text>
            <Text style={{ textAlign: 'center', marginVertical: 5 }}>
                We will now proceed to the account setup process. It takes about 10
                minutes, on average.
                </Text>
            <View style={{ flex: 1, justifyContent: "flex-end", flexDirection: 'row' }}>
                <Button title={'Proceed'} onPress={() => this.props.proceed()}></Button>
            </View>
            {/* </View> */}
        </View>)
    }
}

class CreateProfile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...Object.keys(kidInfo).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: '' } }, {}),
        };
    };

    componentDidMount() {
        const { newKid } = this.props.auth;
        // Object.keys(newKid).map(item=>this.setState)
        this.setState({...Object.keys(newKid.profile).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: newKid.profile[curr] } }, {}),
                available:newKid.profile.userName && this.checkIfAvailable(newKid.profile.userName)})
        // this.checkIfAvailable(newKid.profile.username)

    }

    row({item}) {
        return <View key={item} style={{ flexDirection: 'row',justifyContent:'center', alignItems: 'center' }}>
            <FloatingLabelInput {...kidInfo[item].params} label={kidInfo[item].label} value={this.state[item]} onChangeText={(text => this.setState({ [item]: text }))} />
            <InfoButton text={kidInfo[item].text} />
        </View>
    }

    checkIfAvailable(name) {
        return database().ref('children/').orderByChild('username')
        .equalTo(name.toLowerCase())
        // .startAt(name.toUpperCase())
        // .endAt(name.toLowerCase())
        .once('value')
        .then(snap=>{
            console.log('snap.val() - ',snap.val() , !snap.val());
            (!snap.val()&&name)!==this.state.available?this.setState({available:!snap.val()&&name}):null;
            return snap.val()})

    }

    formatUsername(text) {
        return text.replace(/( |-|,)/g, '')
    }

    formatAge(text) {
        return text.replace(/\D/g, '')
    }

    checkConditions() {
        const { name, userName, password, available, email, age } = this.state;
        console.log('name, userName, password, available, ', name, userName, password, available,);
        return (name && userName && password && available)?true:false
    }

    render() {
        const { layout } = this.props;
        const { name, userName, password, email, age } = this.state;
        const title = 'Create Profile'
        const description = <Text style={{ textAlign: 'center', marginVertical: 5 }}>
            Let’s start by creating your kid’s profile. Refer to
            the <Text style={{ fontSize:12, color:'white',...defaultStyles.bold, paddingVertical:5, paddingHorizontal:7, backgroundColor: Colors.green1, width: 30, borderRadius: 30, }}>?</Text> symbol for the input explanation.
        </Text>

        const vmin = Math.min(...[layout.width, layout.height]);
        const top = vmin * 0.1 < 50 ? 50 : vmin * 0.1
        console.log(vmin, vmin * 0.1)
        return (<BlockWrapper layout={layout} title={title} description={description}>

            <View style={{
                flexDirection: layout.width>850?'row':'column'
            }}>
                <View style={{borderWidth:1, justifyContent:'center', alignItems:'center'}}>
                    <Image resizeMode={'contain'} style={{
                        marginTop: 0.02 * vmin, paddingBottom: 0.02 * vmin, marginLeft: 10,
                        height: vmin * 0.5,  width: vmin * 0.5, maxHeight: 100, maxWidth: 100,
                    }} source={avatars[0]} />
                </View>
                <View style={{borderWidth:1,alignItems:'center'}}>
                    {/* <Text>Kids</Text> */}
                    {/* {Object.keys(kidInfo).map(item => this.row(item))} */}
                    <View style={{ flexDirection: 'row',justifyContent:'center', alignItems: 'center' }}>
                        <FloatingLabelInput label={kidInfo['name'].label} value={this.state.name} onChangeText={text => {
                            this.checkIfAvailable(text);
                            this.setState({ name:text,userName: this.formatUsername(text) })}} />
                        <InfoButton text={kidInfo['name'].text} />
                    </View>
                    <View style={{ flexDirection: 'row',justifyContent:'center', alignItems: 'center' }}>
                        <FloatingLabelInput label={kidInfo['userName'].label} value={this.state.userName} onChangeText={text => {
                            console.log('chaingin?');
                            // this.checkIfAvailable(text);
                            this.setState({ userName: this.formatUsername(text) }, ()=>{this.checkIfAvailable(text)})}} />
                        <InfoButton text={kidInfo['userName'].text} />
                    </View>
                    <View style={{marginTop:7, marginBottom:5}}>{this.state.available?<Text style={{color:Colors.green1}}>Is available</Text>:<Text style={{color:Colors.red}}>Wrong</Text>}</View>
                    {this.row({item:'password'})}
                    {this.row({item:'email'})}
                    <View style={{ flexDirection: 'row',justifyContent:'center', alignItems: 'center' }}>
                        <FloatingLabelInput label={kidInfo['age'].label} value={this.state.age} onChangeText={text => {this.setState({ age: this.formatAge(text) })}} />
                        <InfoButton text={kidInfo['age'].text} />
                    </View>

                </View>
            </View>
            <View style={{ flex: 1, justifyContent: "flex-end", flexDirection: 'row' }}>
                <Button title={'Proceed'} onPress={() => {
                    this.checkConditions()?
                    this.props.proceed():null
                    this.props.kidData({type:'profile', data: {name, userName, password, email, age}});
                    }}></Button>
            </View>
            {/* </View> */}
        </BlockWrapper>)

    }

}

class Allowance extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            // ...Object.keys(kidInfo).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: '' } }, {}),
            day: 'Friday',
            frequency: 'Weekly',
            amount: ''
        };
    };

    row(item) {
        const data = allowanceInfo[item]
        return <View key={item} style={{ borderWidth: 1, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
            <FloatingLabelInput {...data.params} label={data.label} value={this.state[item]} onChangeText={text => this.setState({ [item]: text })} />
            <InfoButton text={data.text} />
        </View>
    }

    formatAmount(text) {
        return text.replace(/([^(\d|.)])/g,'')
    }

    changeDay(text) {
        console.log('text', text)

        if (text==='Monthly') {
            console.log('MONTHLY')
            return this.setState({day:'End of the month'})
        }
        if (text!=='none') {
            return this.setState({day:'Friday'})
        }
    }

    checkConditions() {
        const { amount } = this.state;
        return 0
    }

    proceed() {
        const { amount, frequency, day } = this.state;
        const { parentId, newKid } = this.props.auth;
        const { name, userName, password, email, age } = newKid.profile;

        // this.setState({loading:})
        this.props.startLoading()
        // .then(()=>this.props.actions.createNewKid({ parentId, name, userName, password, email, age}))
        this.props.actions.createNewKid({ parentId, name, userName, password, email, age})
        .then((resp)=>{
            const childId = resp.childId;
            console.log('childId , ', childId)
            const allowance = {salary_freq:frequency,salary_amt:amount, day:salaryDays[day]}
            return childId?this.props.actions.sendAllowanceJob(parentId, childId, allowance):{data:'error'}})
            .then(res=>{
                this.props.stopLoading()
                if (res.data!=='error') {
                    this.props.navigation.navigate('Dashboard')
                }
            })
    }

    render() {
        const { layout } = this.props;
        const { amount, frequency, day } = this.state;
        const vmin = Math.min(...[layout.width, layout.height]);
        const title = 'Customize Profile - Allowance'
        const description = <Text style={{ textAlign: 'center', marginVertical: 5 }}>
            Now, let’s customize your kid’s profile! Let’s start by adding some recurring allowance for your kid to keep them excited and provide some initial funds to practice money management. We took the liberty to input the suggested amounts based on the information you provided previously and what other parents do. Feel free to change it to your liking!
        </Text>

        return (
            <BlockWrapper layout={layout} description={description} title={title} >
                    {/* <View> */}
                    <View style={{
                        width: '90%',maxWidth: 400
                    }}>
                        <View style={{ width: '100%' }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <FloatingLabelInput containerStyle={{ width: '100%', alignItems: 'center', alignSelf: 'center' }} label={'Allowance amount *'}
                                value={this.state.amount} onChangeText={text => this.setState({ amount: this.formatAmount(text) })} />
                                <InfoButton text={allowanceInfo.amount.text} />
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 2, alignItems: 'center' }}>
                                <DropdownElement
                                    dropdownStyle={{ width: '100%' }}
                                    containerStyle={{ zIndex: 2, width: '100%', }}
                                    mainElementStyle={{ width: '100%' }}
                                    onPress={(text) => {this.changeDay(text);this.setState({ frequency: text, })}} defaultValue={this.state.frequency}
                                    categories={['None', 'Weekly', 'Bi-weekly', 'Monthly']} />
                                <InfoButton text={allowanceInfo.frequency.text} />
                            </View>
                            <View><Text style={{ color: Colors.green1, marginTop: 2,...defaultStyles.bold, fontSize: 14 }}>Frequency *</Text></View>
                            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', zIndex: 1, }}>
                                <DropdownElement
                                    dropdownStyle={{ width: '100%', maxHeight: 200 }}
                                    containerStyle={{ zIndex: 1, width: '100%', marginHorizontal: 0 }}
                                    mainElementStyle={{ width: '100%' }}
                                    onPress={(text) => this.setState({ day: text })} defaultValue={this.state.day}
                                    categories={frequency==='Monthly'?['1st of the month', 'End of the month']:
                                    ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', ' Sunday']
                                    } />
                                <InfoButton text={allowanceInfo.frequency.text} />
                            </View>
                            <View><Text style={{ color: Colors.green1, marginTop: 2,...defaultStyles.bold, fontSize: 14 }}>Day of the week to pay allowance *</Text></View>
                        </View>
                    </View>
                    <View style={{ zIndex: -1, flex: 1, justifyContent: "space-around", flexDirection: 'row' }}>
                        <Button title={'Back'} onPress={() => this.props.back()}></Button>

                        <Button title={'Proceed'} onPress={() => this.state.amount?this.proceed():null}></Button>
                    </View>
                    {/* </View> */}
                </BlockWrapper>)
    }
}


class LoginScreen extends React.Component {

    static navigationOptions = ({ navigation }) => {

        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        }
    }


    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            section: 1,
            loading: false
        };
    };



    componentDidMount() {
        const { clearAuth, kidClearAuth } = this.props.actions;
        // clearAuth();
        // kidClearAuth()
    }

    createCustomToken() {
        this.props.actions.createCustomToken()
            .then(res => console.log('cUSTOM TOKEM', res));
    }

    render() {
        // console.log('this.props.state', this.props.fullState)
        // if (!this.state.fontsLoaded) {
        // return <AppLoading />
        // }
        return (
            <Resizable>
                {layout => {
                    const vmin = Math.min(...[layout.width, layout.height]);
                    return <View style={{ height: '100%', flex: 1 }}>

                        <ImageBackground resizeMode={'stretch'} style={{
                            justifyContent: 'center', flex: 1, width: '100%',
                        }} source={require('../assets/BG_1.jpg')}>
                            <Stars layout={layout} color={'white'} />
                            {this.state.loading?<View style={{ position:'absolute', zIndex:1, backgroundColor: 'rgba(126, 193, 61, 0.5)',
                            justifyContent:'center',
                            height: '100%', width: '100%'}}>
                            <ActivityIndicator color={Colors.green1} size={'large'} />
                        </View>:null}
                            <View style={{ flex: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <View style={{ flex: 1, width: '100%', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>

                                    {{
                                        1: (
                                            <Welcome layout={layout} proceed={() => this.setState({ section: this.state.section + 1 })} />
                                        ),
                                        2: (
                                            <CreateProfile layout={layout} auth={this.props.auth}
                                            kidData={this.props.actions.kidData} proceed={() => this.setState({ section: this.state.section + 1 })} />
                                        ),
                                        3: (
                                            <Allowance layout={layout} auth={this.props.auth} actions={this.props.actions}
                                                navigation={this.props.navigation}
                                                back={() => this.setState({ section: this.state.section - 1 })}
                                                // proceed={() => this.setState({ section: this.state.section })}
                                                proceed={()=>console.log(this.props.auth.newKid)}
                                                startLoading={()=>this.setState({loading:true})} stopLoading={()=>this.setState({loading:false})}
                                                />
                                        ),
                                        default: (
                                            <View style={{ backgroundColor: 'white', height: 200, width: 100 }}><Text>default</Text></View>
                                        )
                                    }[this.state.section || 'default']}


                                </View>
                            </View>
                        </ImageBackground>
                    </View>
                }}
            </Resizable>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(state => {
    // console.log(Object.keys(state.vacancies));
    return ({
        fullState: state,
        auth: state.auth,
        kidAuth: state.kidAuth,
        store: state.store,
    })
},
    (dispatch) => ({
        actions: bindActionCreators({ ...authActions, ...kidAuthActions, }, dispatch)
    })
)(LoginScreen);
