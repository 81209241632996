/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
	AsyncStorage,
	Button,
	Dimensions,
	Image,
	ImageBackground,
	KeyboardAvoidingView,
	Platform,
	SafeAreaView,
	TouchableOpacity,
	// Platform,
	TouchableWithoutFeedback,
	StyleSheet,
	ScrollView,
	View,
	StatusBar,
} from 'react-native';

import { Buffer } from 'buffer'
import * as FileSystem from 'expo-file-system';
// import crypto from 'crypto';

import {
	useDimensions,
	useActive,
	useFocus,
	useHover,
	useREM,
	useScaledSize,
	Hoverable, Resizable
} from 'react-native-web-hooks';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import ModalMobile from 'react-native-modal';
import ModalWeb from 'modal-enhanced-react-native-web';

import { Asset } from 'expo-asset';

import * as ImagePicker from 'expo-image-picker';
import { database, storage, auth } from 'firebase';
// import * as firebase from 'firebase';
import * as ImageManipulator from 'expo-image-manipulator';


import TouchableNativeFeedback from '../../components/TouchableFeedback'
import FloatingLabelInput from '../../components/FloatingLabelInput/FloatingLabelInput'
import Text from '../../components/CustomText'

import * as authActions from '../../store/actions/kids/authActions'
import Colors from '../../constants/Colors';
import globalStyle, { defaultStyles } from '../../constants/style';

const Modal = Platform.OS === 'web' ? ModalWeb : ModalMobile

// const storage = firebase.storage()

const creditCards = [require('../../assets/Accounts/savings_1.png'), require('../../assets/Accounts/savings_2.png'),
require('../../assets/Accounts/savings_3.png'), require('../../assets/Accounts/savings_4.png')]

const debitCards = [require('../../assets/Accounts/debit_1.png'), require('../../assets/Accounts/debit_2.png'),
require('../../assets/Accounts/debit_3.png'), require('../../assets/Accounts/debit_4.png')]

export const avatars = [require('../../assets/Avatars/icon_1.png'), require('../../assets/Avatars/icon_2.png'),
require('../../assets/Avatars/icon_3.png'),
require('../../assets/Avatars/icon_4.png'), require('../../assets/Avatars/icon_5.png'),
require('../../assets/Avatars/icon_6.png')
]

export class Avatar extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			scale: 1,
			isFocused: false,
		};
	};

	scale() {
		return this.props.isFocused ? 1.2 : 1
	}
	scaleImage() {
		return this.props.isFocused ? 1.4 : 1
	}
	render() {
		// const {isFocused} = this.props;
		return (
			<Resizable>
				{layout => {
					let width = layout.width;
					const vmin = (Math.min(...[layout.height, width]));

					return <TouchableNativeFeedback {...this.props}>
						<View style={{
							aspectRatio: 1, alignSelf: 'center',
							justifyContent: 'center', alignItems: 'center'
						}}>
							<View elevation={5} style={[{
								aspectRatio: 1, maxHeight: 100, maxWidth: 100,
								width: 0.15 * vmin, height: 0.15 * vmin,
								borderRadius: Platform.OS === 'web' ? '50%' : 0.15 * vmin,
								alignSelf: 'center', opacity: this.props.isFocused ? 1 : 0.5,
								transform: [{ scale: this.scale() }],
								// boxShadow: '2px 2px 15px #272827',
								backgroundColor: 'white', borderWidth: this.props.isFocused ? 3 : 0,
								borderColor: '#7ec13d', justifyContent: 'center', alignItems: 'center'
							}, this.props.style]}>
								<Image resizeMode={'cover'} style={{
									aspectRatio: 1, borderRadius: Platform.OS === 'web' ? '50%' : 80,
									width: '110%', height: '110%'
								}} source={this.props.avatar} />
								{/* <Text style={{}}>{this.props.isFocused.toString()}</Text> */}
							</View>
						</View>
					</TouchableNativeFeedback>
				}}
			</Resizable>
		)
	}
}

class Card extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			scale: 1,
			isFocused: false,
		};
	};

	scale() {
		return this.props.isFocused ? 1.3 : 1
	}

	render() {
		// const {isFocused} = this.props;
		return (
			<Resizable>
				{layout => {
					const vmin = Math.min(...[layout.width, layout.height]);
					return <TouchableNativeFeedback {...this.props}>
						<View style={{
							maxWidth: 240,
							width: 0.13 * vmin, height: 0.21 * vmin,
							alignSelf: 'center', marginVertical: 10,
							justifyContent: 'center', alignItems: 'center'
						}}>
							{/* <View elevation={5} style={{ width:'100%', alignSelf:'center',
                backgroundColor:'white',  borderWidth:this.props.isFocused?3:0,
                justifyContent:'center', alignItems:'center'}}> */}
							<Image resizeMode={'contain'} style={{
								borderColor: '#7ec13d', opacity: this.props.isFocused ? 1 : 0.5,
								height: 0.13 * vmin, width: 0.21 * vmin
							}} source={this.props.card} />
						</View>
						{/* </View> */}
					</TouchableNativeFeedback>
				}}
			</Resizable>
		)
	}
}



class Settings extends React.Component {
	static navigationOptions = ({ navigation }) => {
		return {
			// headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
			headerTransparent: true,
			headerLeft: null,
			headerRight: (
				<TouchableNativeFeedback onPress={() => navigation.navigate('Dashboard')}>
					<View elevation={10} style={{ alignSelf: 'center', padding: 5, margin: 10, }}>
						<Text style={{ fontSize: 25, ...defaultStyles.bold, }}>X</Text></View>
				</TouchableNativeFeedback>
				// <Image style={{width:20, height:20}} source={require('../../assets/Pennygem_Logo_Text_and_Gem_White.png')}/>
			),
			// headerStyle: {
			//   shadow:null,
			//   borderBottomWidth: 0,
			//   backgroundColor: 'transparent'
			//  },
			header: null
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			data: [],
			isLoading: true,
			avatars: [false, false, false, false, false, false],
			debitCards: [false, false, false, false],
			creditCards: [false, false, false, false],
			email: '',
			username: '',
			isFocused: false,
			hasCameraPermission: null,
			//  image: '0',
			image: null,
			emailChange: false,
			nameChange: false,
			password: '',
			confirmPassword: '',
			oldPassword: ''
			//  image: 'file:///data/user/0/host.exp.exponent/cache/ExperienceData/%2540dtsvetkov%252Fpennygembank/ImagePicker/cc28e74d-706e-4020-a1f0-00192ee0b38c.jpg',
			// type: Camera.Constants.Type.back,
		};
	};


	async componentDidMount() {
		const { parentId, childId } = this.props.auth;
		let child_ref = database().ref('/children/' + childId);
		const child_image_url = 'children/' + childId + '.png'
		const child_snap = (await child_ref.once('value')).val()
		const username = child_snap.name
		const email = child_snap.email

		if (!this.props.auth.childId) {
			this.props.actions.clearAuth()
				.then(() => { this.props.navigation.navigate('Auth') })
		}

		var settings_ref = database().ref('/users/' + parentId + '/' + childId + '/settings');
		const snapshot = await settings_ref.once('value')

		let settings_val = snapshot.val()
		this.state.avatars[settings_val.avatar] = true;
		this.state.debitCards[settings_val.debit_card] = true;
		this.state.creditCards[settings_val.credit_card] = true;

		let avatar = settings_val.avatar
		// let localAvatarMeta = image?(await FileSystem.getInfoAsync(image, {md5:true})):{md5:null}
		// if (Platform.OS !== 'web' && avatar === 5) {
		if (avatar === 5) {
			let image = await AsyncStorage.getItem('avatar', null)
			console.log('image')
			console.log(image)

			// var avatarMeta = await storage().ref('children/'+childId+'.png').getMetadata()
			// var avatarMeta =
			storage().ref(child_image_url).getMetadata()
				.then(async avatarMeta => {

					let localAvatarMeta
					if (Platform.OS === 'web') {
						localAvatarMeta = { md5: null }
					}
					else {
						localAvatarMeta = image ? (await FileSystem.getInfoAsync(image, { md5: true })) : { md5: null }
					}
					console.log('localAvatarMeta')
					console.log(localAvatarMeta)

					if (Buffer.from(avatarMeta.md5Hash, 'base64').toString('hex') !== localAvatarMeta.md5) {

						var spaceRef = await storage().ref(child_image_url).getDownloadURL()
						if (Platform.OS === 'web') {

						}
						else {
							await FileSystem.deleteAsync(FileSystem.documentDirectory + childId + '.png', { idempotent: true })
							image = (await FileSystem.downloadAsync(
								spaceRef,
								FileSystem.documentDirectory + childId + '.png'
							)).uri
						}

						console.log('ITA NOT EQUAL')
						console.log(image)
						AsyncStorage.setItem('avatar', image)
						this.setState({ image: image })

						// await AsyncStorage.setItem('avatar', image.uri)
					}
					else {
						this.setState({ image: image })
					}
				})
				.catch((error) => { console.log('storage error - ', error) })

			// let localAvatarMeta = image?(await FileSystem.getInfoAsync(image, {md5:true})):{md5:null}
			// let localAvatarMeta = image ? (await FileSystem.getInfoAsync(image, { md5: true })) : { md5: null }

		}


		this.setState({
			avatar: settings_val.avatar,
			debit_card: settings_val.debit_card,
			credit_card: settings_val.credit_card,
			// image:image,
			username: username,
			old_username: username,
			email: email,
			old_email: email,
			// avatars:this.state.avatars.map((localItem,localIndex)=>localIndex===index?true:false),
		})
	}

	save_settings(type, data) {
		const { parentId, childId } = this.props.auth;

		database().ref('/users/' + parentId + '/' + childId + '/settings').update({
			[type]: data
		});
	}

	_launchCamera = async () => {
		const { childId } = this.props.auth;
		let result = await ImagePicker.launchCameraAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Image,
			allowsEditing: true,
			aspect: [1, 1],
			quality: 0.1
		});

		console.log(result);

		if (!result.cancelled) {
			this.uriToBlob(result.uri).then(blob => {
				storage().ref('children/' + childId + '.png').put(blob)
					.then((res) => {
						return res
					})
			});
			this.setState({ image: result.uri });
			AsyncStorage.setItem('avatar', result.uri)
		}
	};

	_pickImage = async () => {
		const { childId } = this.props.auth;

		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: true,
			aspect: [1, 1],
			quality: 0
		});

		console.log(result);
		// ImageManipulator.manipulateAsync(uri, actions, saveOptions)

		if (!result.cancelled) {
			let uri = result.uri
			console.log(result)
			if (Platform.OS === 'web') {
				const manipResult = await ImageManipulator.manipulateAsync(result.uri,
					//  [{ crop: { originX:0, originY:0, width:'100%', height:'100%' } }, ],
					{ compress: 0, format: ImageManipulator.SaveFormat.PNG })
				console.log('manipResult, ', manipResult)
				uri = manipResult.uri
			}

			this.uriToBlob(uri).then(blob => {
				storage().ref('children/' + childId + '.png').put(blob)
					.then((res) => {
						return res
					})
			});
			this.setState({ image: uri });
			AsyncStorage.setItem('avatar', uri)
		}
	};

	uriToBlob = (uri) => {
		return new Promise((resolve, reject) => {
			const xhr = new XMLHttpRequest();

			xhr.onload = function () {
				// return the blob
				console.log(xhr.response)
				resolve(xhr.response);
			};

			xhr.onerror = function () {
				// something went wrong
				reject(new Error('uriToBlob failed'));
			};

			// this helps us get a blob
			xhr.responseType = 'blob';

			xhr.open('GET', uri, true);
			xhr.send(null);

		});

	}

	changeEmailTransaction() {
		const { parentId, childId } = this.props.auth;

		// let name_ref = database().ref('/children/'+childId);
		database().ref('/children/' + childId).update({
			"email": this.state.email
		});
		this.setState({ old_email: this.state.email })

		auth().currentUser.updateEmail(this.state.email).then(() => { auth().currentUser.sendEmailVerification(); });
	}


	changeEmail() {
		// this.state.emailChange?this.setState({emailConfirmVisible:true}):null
		return <View style={{ justifyContent: 'center', alignItems: 'center' }}>
			<Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>Changing Email to</Text>
			<Text style={{ fontSize: 20, margin: 5, color: '#5aca3c' }}>{this.state.email}</Text>

			<View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
				<View style={{ width: '40%' }}>
					<TouchableNativeFeedback onPress={() => { this.changeEmailTransaction(); this.setState({ emailChange: false }) }}>
						<View style={{
							justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
							margin: 5, backgroundColor: 'rgba(126,193,61,1)'
						}}>
							<Text style={{ fontSize: 19, marginVertical: 5, ...defaultStyles.bold, color: 'white' }}>YES</Text></View>
					</TouchableNativeFeedback>
				</View>
				<View style={{ width: '40%', }}>
					<TouchableNativeFeedback onPress={() => this.setState({ emailChange: false })}>
						<View style={{
							justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10, margin: 5,
							backgroundColor: '#EE5060'
						}}>
							<Text style={{ fontSize: 19, marginVertical: 5, ...defaultStyles.bold,color: 'white' }}>NO</Text></View>
					</TouchableNativeFeedback>
				</View>
			</View>
		</View>
	}

	changeNameTransaction() {
		const { parentId, childId } = this.props.auth;

		let name_ref = database().ref('/children/' + childId);
		database().ref('/children/' + childId).update({
			"name": this.state.username
		});
		this.setState({ old_username: this.state.username })
	}

	changeName() {
		// this.state.nameChange?this.setState({nameConfirmVisible:true}):null
		return <View style={{ justifyContent: 'center', alignItems: 'center' }}>
			<Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>Changing User Name to</Text>
			<Text style={{ fontSize: 20, margin: 5, color: '#5aca3c' }}>{this.state.username}</Text>
			<View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignSelf: 'center', alignItems: 'center', }}>
				<View style={{ width: '40%' }}>
					<TouchableNativeFeedback onPress={() => { this.changeNameTransaction(); this.setState({ nameChange: false }) }}>
						<View style={{
							justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
							margin: 5, backgroundColor: 'rgba(126,193,61,1)'
						}}>
							<Text style={{ fontSize: 19, marginVertical: 5,...defaultStyles.bold, color: 'white' }}>YES</Text></View>
					</TouchableNativeFeedback>
				</View>
				<View style={{ width: '40%', }}>
					<TouchableNativeFeedback onPress={() => this.setState({ nameChange: false })}>
						<View style={{
							justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10, margin: 5,
							backgroundColor: '#EE5060'
						}}>
							<Text style={{ fontSize: 19, marginVertical: 5,...defaultStyles.bold, color: 'white' }}>NO</Text></View>
					</TouchableNativeFeedback>
				</View>
			</View>
		</View>
	}

	checkPasswordConditions() {
		if (this.state.password === this.state.confirmPassword && this.state.password != '') {
			// this.setState({})
			return true
		}
		if (this.state.password === this.state.confirmPassword && this.state.password === '') {
			// this.setState({})
			this.setState({ errorOpacity: 1, errorText: "Password cannot be empty" })
			return false
		}
		else {
			this.setState({ errorOpacity: 1, errorText: "Passwords don't match" })
			return false
		}
	}

	changePasswordTransaction() {
		var user = auth().currentUser;
		const credential = auth.EmailAuthProvider.credential(
			user.email,
			this.state.oldPassword
		);
		const newPassword = this.state.password
		console.log('user!!!! -', user)
		// Prompt the user to re-provide their sign-in credentials

		user.reauthenticateWithCredential(credential).then(() => {
			// User re-authenticated.
			console.log('Here changing')
			auth().currentUser.updatePassword(newPassword)
			this.setState({ password: '', confirmPassword: '', passwordChangeVisible: false, startPasswordChange: false })

		}).catch((error) => {
			console.log('error, ', error)
			this.setState({ errorOpacity: 1, errorText: "Old password is incorrect" })
			// An error happened.
		});

		// AsyncStorage.setItem('password', )
	}


	changePassword() {
		// this.state.nameChange?this.setState({nameConfirmVisible:true}):null
		return this.state.startPasswordChange ?
			<View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
				<Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>Change Password</Text>

				<View style={{ width: '100%', alignItems: 'center', marginBottom: 10 }}>
					<FloatingLabelInput secureTextEntry={true} label={'Old Password'} value={this.state.oldPassword}
						onChangeText={text => this.setState({ errorOpacity: 0, oldPassword: text })} /></View>

				<View style={{ width: '100%', alignItems: 'center', marginBottom: 10 }}>
					<FloatingLabelInput secureTextEntry={true} label={'New Password'} value={this.state.password}
						onChangeText={text => this.setState({ errorOpacity: 0, password: text })} /></View>

				<View style={{ width: '100%', alignItems: 'center', marginBottom: 10 }}>
					<FloatingLabelInput secureTextEntry={true} label={'Confirm New Password'} value={this.state.confirmPassword}
						onChangeText={text => this.setState({ errorOpacity: 0, confirmPassword: text })} /></View>

				<Text style={{ marginVertical: 4, color: '#EE5060', opacity: this.state.errorOpacity }}>{this.state.errorText}</Text>

				<View style={{ width: '50%', alignSelf: 'center', }}>
					<TouchableNativeFeedback onPress={() => { if (this.checkPasswordConditions()) { this.changePasswordTransaction() } }}>
						<View style={{
							width: '80%', marginBottom: 30, backgroundColor: '#5aca3c', height: 40, borderRadius: 10,
							justifyContent: 'center', alignSelf: 'center'
						}}>
							<Text style={{ textAlign: 'center', fontSize: 25, color: 'white' }}>OK</Text></View>
					</TouchableNativeFeedback>
				</View>
			</View>
			:
			<View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
				<Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>Change Password</Text>

				<View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
					<View style={{ width: '40%' }}>
						<TouchableNativeFeedback onPress={() => { this.setState({ startPasswordChange: true }) }}>
							<View style={{
								justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
								margin: 5, backgroundColor: 'rgba(126,193,61,1)'
							}}>
								<Text style={{ fontSize: 19, marginVertical: 5,...defaultStyles.bold, color: 'white' }}>YES</Text></View>
						</TouchableNativeFeedback>
					</View>
					<View style={{ width: '40%', }}>
						<TouchableNativeFeedback onPress={() => this.setState({ passwordChangeVisible: false })}>
							<View style={{
								justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10, margin: 5,
								backgroundColor: '#EE5060'
							}}>
								<Text style={{ fontSize: 19, marginVertical: 5,...defaultStyles.bold, color: 'white' }}>NO</Text></View>
						</TouchableNativeFeedback>
					</View>
				</View>
			</View>

	}


	closeModal() {
		// this.setState({modalVisible:false})
		// this.state.nameChange?this.setState({nameConfirmVisible:true}):null
		return <View></View>
	}

	render() {
		return (
			<Resizable>
				{layout => {
					const vmin = Math.min(...[layout.width, layout.height]);
					let width = layout.width;
					const closeFontSize = Platform.OS === 'web' ? (width > 850 ? '2.25em' : '1.625em') : (35)
					return <KeyboardAvoidingView style={{ flex: 1 }}>
						<View style={{ position: 'absolute', top: 0.05 * vmin, right: 0.05 * vmin, zIndex: 5, backgroundColor: 'transparent', }}>
							<TouchableNativeFeedback onPress={() => this.props.navigation.navigate('Dashboard')}>
								<View elevation={10} style={{ backgroundColor: 'transparent', alignSelf: 'center', padding: 5, margin: 10, }}>
									<Image resizeMode={'center'} style={{ height: 30, width: 30 }} source={require('../../assets/Delete-X.png')} />
								</View>
							</TouchableNativeFeedback>
						</View>
						<ScrollView style={{ backgroundColor: 'white', flex: 1, zIndex: 2 }}
							onLayout={(e) => {
								const { width, height } = e.nativeEvent.layout;
								this.setState({
									width: width,
									height: height
								});
							}}>
							{/* <Snow/> */}
							{<Modal
								animationIn={'zoomIn'} animationOut={'zoomOut'}
								animationInTiming={1000} animationOutTiming={1000}
								backdropColor={'darkgrey'} backdropOpacity={0.6}
								onBackdropPress={() => this.setState({ imagePickerVisible: false })}
								isVisible={this.state.imagePickerVisible}
								onRequestClose={() => { this.setState({ imagePickerVisible: false }) }}>
								<View style={{
									borderRadius: 15, backgroundColor: 'white', width: '100%', height: '50%', alignSelf: 'center',
									maxHeight: 500, maxWidth: 400, minHeight: 250, minWidth: 250, justifyContent: 'center', alignItems: 'center'
								}}>
									<View>
										<Text style={{...defaultStyles.bold, fontSize: 30, color: '#7ec13d' }}>
											Select</Text>
									</View>
									<View style={{ width: '80%' }}>
										{/* style={Platform.OS === 'web' ? { width: '80%' } : {}} */}
										{Platform.OS !== 'web' ? <TouchableNativeFeedback onPress={() => { this.setState({ imagePickerVisible: false }); this._launchCamera() }}>
											<View style={{ width: '100%', borderRadius: 10, backgroundColor: '#7ec13d', padding: 10, marginVertical: 10 }}>
												<Text style={{ textAlign: 'center', color: 'white' }}>Open Camera</Text>
											</View>
										</TouchableNativeFeedback> : null}
										<TouchableNativeFeedback onPress={() => { this.setState({ imagePickerVisible: false }); this._pickImage() }}>
											<View style={{ width: '100%', borderRadius: 10, backgroundColor: '#7ec13d', padding: 10, paddingHorizontal: 15, marginVertical: 10 }}>
												<Text style={{ textAlign: 'center', color: 'white' }}>Pick Image</Text>
											</View>
										</TouchableNativeFeedback>
									</View>
								</View>
							</Modal>}
							{<Modal
								animationIn={'zoomIn'}
								animationOut={'zoomOut'}
								animationInTiming={1000}
								animationOutTiming={1000}
								backdropColor={'darkgrey'}
								backdropOpacity={0.6}
								onBackdropPress={() => this.setState({ modalVisible: false })}
								isVisible={this.state.modalVisible && (this.state.emailChange || this.state.nameChange)}
								onRequestClose={() => { this.setState({ modalVisible: false }) }}>
								<View style={{
									borderRadius: 15, backgroundColor: 'white', width: '80%', height: '50%', alignSelf: 'center',
									maxHeight: 500, maxWidth: 400, minHeight: 250, justifyContent: 'center', alignItems: 'center'
								}}>
									{this.state.nameChange ? this.changeName() :
										this.state.emailChange ? this.changeEmail() : this.closeModal()
									}
								</View>
							</Modal>}
							{<Modal
								animationIn={'zoomIn'}
								animationOut={'zoomOut'}
								animationInTiming={1000}
								animationOutTiming={1000}
								backdropColor={'darkgrey'}
								backdropOpacity={0.6}
								onBackdropPress={() => this.setState({ passwordChangeVisible: false, startPasswordChange: false })}
								isVisible={this.state.passwordChangeVisible}
								onRequestClose={() => { this.setState({ passwordChangeVisible: false, startPasswordChange: false }) }}>
								<View style={{
									borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
									maxWidth: 400, minHeight: 250, justifyContent: 'center', alignItems: 'center'
								}}>
									{this.changePassword()}
								</View>
							</Modal>}

							<View>
								<Text style={[{ alignSelf: 'center', marginTop: 0.05 * vmin + 10,}, globalStyle.h1, defaultStyles.bold]}>Settings</Text>

								<View style={{
									paddingTop: 0.135 * vmin, alignItems: 'center', width: '100%', maxWidth: 600, minWidth: 200, alignSelf: 'center',
									justifyContent: 'center',
								}}>

									<FloatingLabelInput keyboardType={'email-address'} label={'Email'} value={this.state.email}
										onChangeText={text => this.setState({ email: text })} />
									<View style={{ margin: 5 }}></View>
									<FloatingLabelInput label={'User Name'} value={this.state.username}
										onChangeText={text => this.setState({ username: text })} />

									<TouchableWithoutFeedback onPress={() => { this.setState({ passwordChangeVisible: true }) }}>
										<View style={{ width: '100%', }}>
											<View style={{ justifyContent: 'center', alignSelf: 'center', height: 40, marginTop: 20, width: '80%', borderRadius: 20, borderWidth: 2, borderColor: Colors.gray, }}>
												{/* <TouchableOpacity onPress={()=>{}}> */}
												<View style={{ justifyContent: 'center', }}>
													<Text style={{ paddingLeft: 20, fontSize: 14, color: Colors.gray1 }}>******</Text>
												</View>
												{/* </TouchableOpacity> */}
											</View>
											<View style={{ marginTop: 2, width: '80%', alignSelf: 'center' }}>
												<Text style={{ paddingLeft: 20, fontSize: 14, color: Colors.gray }}>Change password</Text>
											</View>
										</View>
									</TouchableWithoutFeedback>

									<TouchableNativeFeedback style={Platform.OS === 'web' ? { width: '80%' } : {}} onPress={() => {
										this.setState({
											modalVisible: true,
											nameChange: this.state.username !== this.state.old_username,
											emailChange: this.state.email !== this.state.old_email
										})
									}}>
										<View style={{
											width: '100%', height: 40,
											marginVertical: 15, backgroundColor: Colors.green1, paddingVertical: 6, justifyContent: 'center',
											alignItems: 'center', borderRadius: 20,
										}}>
											<Text style={{textAlign: 'center', color: 'white', paddingHorizontal: 15, width: '100%', }}>
												{'Save Changes'}
              								</Text>
										</View>
									</TouchableNativeFeedback>

									<View style={{ flexWrap: 'wrap', width: '80%', marginTop: 10 }}>
										<Text style={[{ marginBottom: 10 }, defaultStyles.bold]}>Avatar</Text>
										<View style={{ flexWrap: 'wrap', alignItems: 'stretch', justifyContent: 'center' }}>

											<View style={{
												flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center',
												marginTop: 0.04 * vmin
											}}>{[1, 2, 3].map((item, index) => {
												return <Avatar key={index} avatar={avatars[index]}
													onPress={() => {
														this.setState({ avatars: this.state.avatars.map((localItem, localIndex) => localIndex === index ? true : false) })
														// this.state.avatars[index] = true
														this.save_settings('avatar', index)
														// this.props.actions.saveAvatar(index, null)
														console.log(this.state.avatars)
													}}
													// ref={(c) => { this.avatar1 = c; }}
													isFocused={this.state.avatars[index]}
												/>
											})}
											</View>
											<View style={{
												flexDirection: 'row', marginVertical: 0.05 * vmin, marginBottom: 0.07 * vmin,
												justifyContent: 'space-around', alignItems: 'center'
											}}>
												{[3, 4].map((item, index) => {
													return <Avatar key={index} avatar={avatars[item]}
														onPress={() => {

															this.setState({ avatars: this.state.avatars.map((localItem, localIndex) => localIndex === item ? true : false) })
															// this.state.avatars[index] = true
															this.save_settings('avatar', item)

															console.log(this.state.avatars)
														}}
														// ref={(c) => { this.avatar1 = c; }}
														isFocused={this.state.avatars[item]}
													/>
												})}
												<Avatar avatar={(this.state.image && this.state.avatars[5]) ? { uri: this.state.image } : avatars[5]}
													onPress={() => {
														this.setState({
															avatars: this.state.avatars.map((localItem, localIndex) => localIndex === 5 ? true : false),
															imagePickerVisible: true
														})
														this.save_settings('avatar', 5)
														console.log(this.state.avatars)
													}}
													// ref={(c) => { this.avatar1 = c; }}
													isFocused={this.state.avatars[5]}
												/>
											</View>

										</View>
									</View>


									<View style={{ flexWrap: 'wrap', width: '80%' }}>
										<Text style={[{ marginBottom: 10 }, defaultStyles.bold]}>Debit Card Style </Text>
										<View style={{ flex: 1, flexWrap: 'wrap', justifyContent: 'center' }}>
											<View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around' }}>
												{debitCards.slice(0, 2).map((item, index) => {
													return <Card key={index} onPress={() => {

														this.setState({ debitCards: this.state.debitCards.map((localItem, localIndex) => localIndex === index ? true : false) })
														this.save_settings('debit_card', index)
													}}
														card={item}
														isFocused={this.state.debitCards[index]}
													/>
												})}
											</View>
											<View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
												{debitCards.slice(2, 4).map((item, index) => {
													return <Card key={index} onPress={() => {

														this.setState({ debitCards: this.state.debitCards.map((localItem, localIndex) => localIndex === index + 2 ? true : false) })
														this.save_settings('debit_card', index + 2)
													}}
														card={item}
														isFocused={this.state.debitCards[index + 2]}
													/>
												})}
											</View>
										</View>
									</View>

									<View style={{ flexWrap: 'wrap', width: '80%' }}>
										<Text style={[{ marginBottom: 10 }, defaultStyles.bold]}>Credit Card Style </Text>
										<View style={{ flex: 1, flexWrap: 'wrap', justifyContent: 'center' }}>
											<View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around' }}>
												{creditCards.slice(0, 2).map((item, index) => {
													return <Card key={index} onPress={() => {

														this.setState({ creditCards: this.state.creditCards.map((localItem, localIndex) => localIndex === index ? true : false) })
														this.save_settings('credit_card', index)
													}}
														card={item}
														isFocused={this.state.creditCards[index]}
													/>
												})}
											</View>
											<View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
												{creditCards.slice(2, 4).map((item, index) => {
													return <Card key={index} onPress={() => {

														this.setState({ creditCards: this.state.creditCards.map((localItem, localIndex) => localIndex === index + 2 ? true : false) })
														this.save_settings('credit_card', index + 2)
													}}
														card={item}
														isFocused={this.state.creditCards[index + 2]}
													/>
												})}
											</View>
										</View>
									</View>

									<View style={{ flexWrap: 'wrap', width: layout.width }}>

									</View>


								</View>
							</View>
							{/* </ImageBackground> */}
						</ScrollView>
					</KeyboardAvoidingView>
				}}
			</Resizable>)
	}
}

const styles = StyleSheet.create({

});

// export default Settings;
export default connect(state => {
	// console.log(Object.keys(state.vacancies));
	return ({
		auth: state.auth,
		child: state.child
	})
},
	(dispatch) => ({
		actions: bindActionCreators({ ...authActions }, dispatch)
	})
)(Settings);
