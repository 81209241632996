/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
// import '@expo/match-media'
// import { ScreenOrientation } from 'expo'
import React, { useEffect } from 'react';
import {
	ActivityIndicator,
	Animated,
	Dimensions,
	FlatList,
	Image,
	ImageBackground,
	StyleSheet,
	View,
	Platform,
	TouchableOpacity,
	TouchableWithoutFeedback,
} from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome';
import NetInfo, { useNetInfo } from "@react-native-community/netinfo";
import { LinearGradient } from 'expo-linear-gradient';

import { Resizable, Hoverable } from 'react-native-web-hooks';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import firebase from 'firebase/app';
import 'firebase/database';

import TouchableNativeFeedback from '../../components/TouchableFeedback'
import Stars from '../../components/Stars'
import FloatingLabelInput from '../../components/FloatingLabelInput/FloatingLabelInput'
import CounterTextInput from '../../components/CounterTextInput'
import Text from '../../components/CustomText'
import { dropdown, handleScroll, dropdownRedesign } from '../../components/Dropdown'
import { ConfirmationView, RejectionView } from '../../components/confirmation'

import { ChildHeaderCard } from '../../components/Header/HeaderBlock'
import FooterBlock from '../../components/Footer/FooterBlock'

import ModalContainer from '../../components/Modal/Modal'

import Colors from '../../constants/Colors'

import * as goalsActions from '../../store/actions/kids/goalsActions'
import * as authActions from '../../store/actions/kids/authActions'
import GoalCard from '../../components/Goals/GoalCard';

import globalStyle, { defaultStyles } from '../../constants/style';

const database = firebase.database;

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

function formatInputValue(str) {
	return (str || str === 0) ? str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".") : ''
}


class Goals extends React.Component {
	static navigationOptions = ({ navigation }) => {
		return {
			headerTransparent: true,
			headerLeft: (
				null
			),
			headerLayoutPreset: 'center',
			header: null

		}
	}

	constructor(props) {
		super(props);
		this.goalAmountRef = React.createRef()

		// this.unsubscribe = NetInfo.isConnected.addEventListener('connectionChange', this.netInfoChange)

		this.state = {
			data: [],
			savings_balance: 0.0,
			isLoading: true,
			setGoalVisible: false,
			email: '',
			modalVisible: false,
			confirmGoalId: '',
			goalName: '',
			goalAmount: '',
			headerColor: 'rgba(0,0,0,0)',
			header: false
		};
	};

	netInfoChange = (state) => {
		console.log("Connection type", state.type);
		this.setState({ connected: state })
		// this.props.navigation.setParams({ 'headerColor': state.isConnected ? 'transparent' : 'rgba(238,80,96,0.5)' })
		// console.log("Is connected?", state.isConnected);
	}

	async componentDidMount() {
		const { parentId, childId } = this.props.auth;
		let savings_source = database().ref('/users/' + parentId + '/' + childId + '/savings/balance');
		console.log('TEST ', parentId, childId)
		let savings_balance = (await savings_source.once('value')).val();
		this.setState({
			// data:snapshot.val(),
			savings_balance: savings_balance.toFixed(2),
			// isLoading:false
		})

		if (!this.props.auth.childId) {
			this.props.actions.clearAuth()
				.then(() => { this.props.navigation.navigate('Auth') })
		}

		// Platform.OS === 'web' ? null : NetInfo.addEventListener(this.netInfoChange);
		console.log('this.props.data', this.props.data)
		this.props.actions.getGoals(parentId, childId).then(() => this.setState({ isLoading: false }));

	}

	componentWillUnmount() {
		// this.unsubscribe.remove()
		// Platform.OS === 'web' ? null : NetInfo.removeEventListener(this.netInfoChange);
	}

	renderItem = ({ item, index }, layout) => {
		const { parentId, childId } = this.props.auth;

		return <GoalCard item={item} index={index} type={'child'} data={[]} balance={''}
			openModal={(type, goalId) => this.openModal(type, goalId)} balance={this.state.savings_balance}
			layout={layout} deleteGoal={(goalId) => this.props.actions.removeGoal(goalId, parentId, childId)} />
	}

	openModal(type, goalId) {
		if (type === 'delete') {
			this.setState({ modalVisible: true, confirmationDelete: true, confirmGoalId: goalId })
		}
		else if (type === 'activate') {
			this.setState({ modalVisible: true, confirmationActivate: true, confirmGoalId: goalId })
		}
		else if (type === 'purchase') {
			this.setState({ modalVisible: true, confirmationPurchase: true, confirmGoalId: goalId })
		}
		else {
			return 0
		}
	}

	confirmationDelete() {
		const { parentId, childId } = this.props.auth;
		const goal = this.props.data.data.find(item => item.key === this.state.confirmGoalId);

		return <ConfirmationView image={require('../../assets/Goals/Goal_2.png')} title={'Remove Goal'} subtitles={[goal.name]}
		confirmPress={() => {
			this.setState({ modalVisible: false, confirmationDelete: false });
			this.props.actions.removeGoal(this.state.confirmGoalId, parentId, childId).then(() => this.props.actions.getGoals(parentId, childId))
		}}
			rejectPress={() => this.setState({ modalVisible: false, confirmationDelete: false, confirmationActivate: false })}
		/>
	}


	confirmationActivate() {
		const { parentId, childId } = this.props.auth;
		const goal = this.props.data.data.find(item => item.key === this.state.confirmGoalId);

		return <ConfirmationView image={require('../../assets/Goals/Goal_2.png')} title={'Activate Goal'} subtitles={[goal.name]} confirmPress={() => {
			this.setState({ modalVisible: false, confirmationActivate: false });
			this.props.actions.activateGoal(this.state.confirmGoalId, parentId, childId).then(() => { this.setState({ isLoading: true }); this.props.actions.getGoals(parentId, childId).then(this.setState({ isLoading: false })) })
		}}
			rejectPress={() => this.setState({ modalVisible: false, confirmationActivate: false })}
		/>

	}

	confirmationView(title,) {
		const { parentId, childId } = this.props.auth;

		return <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
			<Image style={{ flex: 1, width: 70, height: 70, marginTop: '10%' }} source={require('../../assets/Goals/Goal_2.png')} />
			<View style={{ marginVertical: 15, alignItems: 'center' }}><Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>Activate</Text>
				<Text style={{ fontSize: 20, margin: 5, color: '#5aca3c', }}>Goal</Text>
			</View>
			<View style={{ flexDirection: 'row', flex: 1, width: '100%', justifyContent: 'center', marginHorizontal: 15, }}>
				<View style={{ width: '40%', }}>
					<TouchableNativeFeedback onPress={() => {
						this.setState({ modalVisible: false, confirmationActivate: false });
						this.props.actions.activateGoal(this.state.confirmGoalId, parentId, childId).then(() => { this.setState({ isLoading: true }); this.props.actions.getGoals(parentId, childId).then(this.setState({ isLoading: false })) })
					}}>
						<View style={{
							justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
							margin: 5, backgroundColor: 'rgba(126,193,61,1)'
						}}>
							<Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>YES</Text></View>
					</TouchableNativeFeedback>
				</View>
				<View style={{ width: '40%', }}>
					<TouchableNativeFeedback onPress={() => this.setState({ modalVisible: false, confirmationActivate: false })}>
						<View style={{
							justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10, margin: 5,
							backgroundColor: '#EE5060'
						}}>
							<Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>NO</Text></View>
					</TouchableNativeFeedback>
				</View>
			</View>
		</View>
	}

	confirmationPurchase() {
		const { parentId, childId } = this.props.auth;
		const goal = this.props.data.data.find(item => item.key === this.state.confirmGoalId);
		// console.log('goal', goal)

		return <ConfirmationView image={require('../../assets/Goals/Goal_2.png')} title={'Purchase'} subtitles={[goal.name]} confirmPress={async () => {
			this.setState({ loading: true });
						let condition = await this.props.actions.checkPurchaseGoal(goal, parentId, childId)
						console.log('condition - ', condition)
						condition ?
							this.props.actions.purchaseGoal(goal, parentId, childId)
								.then(() => {
									this.setState({ isLoading: true });
									this.setState({ loading: false, modalVisible: false, confirmationPurchase: false, })
									this.props.actions.sendRequestPurchaseEmail(parentId, childId, goal.name);
									this.props.actions.getGoals(parentId, childId)
								})
								.then(() => { this.setState({ isLoading: false }); }) : this.setState({ loading: false,  confirmationPurchase: false, rejectPurchaseGoal: true, });
		}}
			rejectPress={() => this.setState({ modalVisible: false,confirmationPurchase: false, confirmationActivate: false })}
		/>

		return <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
			<Image style={{ flex: 1, width: 70, height: 70, marginTop: '10%' }} source={require('../../assets/Goals/Goal_2.png')} />
			<View style={{ marginVertical: 15, alignItems: 'center' }}><Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>Purchase</Text>
				<Text style={{ fontSize: 20, margin: 5, color: '#5aca3c' }}>{goal.name}</Text>
			</View>
			<View style={{ flexDirection: 'row', flex: 1, width: '100%', justifyContent: 'center', marginHorizontal: 15, }}>
				<View style={{ width: '40%', }}>
					<TouchableNativeFeedback onPress={async () => {
						this.setState({ loading: true });
						let condition = await this.props.actions.checkPurchaseGoal(goal, parentId, childId)
						console.log('condition - ', condition)
						condition ?
							this.props.actions.purchaseGoal(goal, parentId, childId)
								.then(() => {
									this.setState({ isLoading: true });
									this.setState({ loading: false, modalVisible: false, confirmationPurchase: false, })
									this.props.actions.sendRequestPurchaseEmail(parentId, childId, goal.name);
									this.props.actions.getGoals(parentId, childId)
								})
								.then(() => { this.setState({ isLoading: false }); }) : this.setState({ loading: false, confirmationPurchase: false, rejectPurchaseGoal: true, });
					}}>
						<View style={{
							justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
							margin: 5, backgroundColor: 'rgba(126,193,61,1)'
						}}>
							<Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>YES</Text></View>
					</TouchableNativeFeedback>
				</View>
				<View style={{ width: '40%', }}>
					<TouchableNativeFeedback onPress={() => this.setState({ modalVisible: false, confirmationPurchase: false })}>
						<View style={{
							justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10, margin: 5,
							backgroundColor: '#EE5060'
						}}>
							<Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>NO</Text></View>
					</TouchableNativeFeedback>
				</View>
			</View>
		</View>
	}

	rejectPurchase() {
		return <RejectionView title={'Not enough money to buy item'} subtitles={[]}
			okPress={() => { this.setState({ modalVisible: false, rejectPurchaseGoal: false, confirmationGoal: false, confirmationPurchase: false, }) }} />
	}


	parseInt(value) {
		return value !== '' ? parseInt(value) : 0
	}

	emptyList = () => {
		return this.state.isLoading ? null : <View style={{ alignItems: 'center', marginBottom: 50 }}>
			<Text style={{  }}>No goals yet</Text>
		</View>
	}


	render() {
		let { data, navigation } = this.props;
		const { parentId, childId } = this.props.auth;

		let webModalStyles = Platform.OS === 'web' ? { justifyContent: 'flex-start', alignItems: 'center', width: '100%', } : {}

		return (
			<Resizable>
				{layout => {
					const vmin = Math.min(...[layout.width, layout.height]);
					let width = layout.width;

					return <View style={{ flex: 1, backgroundColor: 'white' }}>

						<ModalContainer visible={this.state.setGoalVisible}
							onClose={() => this.setState({ setGoalVisible: false })}>
							<View style={{ alignItems: 'center', justifyContent: 'center' }}>
								<View style={{
									flexDirection: 'row', justifyContent: 'center',
									marginTop: '5%',
								}}>
									{/* <Image style={{ width: 95, height: 95, alignSelf: 'flex-start' }} source={require('../../assets/Saving_color.png')} /> */}
									<Text style={[globalStyle.h4, { alignSelf: 'center', fontFamily: 'Carmen Sans Bold' }]}>Set Goal</Text>
								</View>
								<View style={{ width: '100%', alignSelf: 'flex-start', justifyContent: 'flex-start', alignItems: 'center' }}>
									<View style={{ width: '100%', alignItems: 'center', marginBottom: 10 }}>
										<FloatingLabelInput centered={true} label={'Goal Name'} value={this.state.goalName}
											onChangeText={text => this.setState({ goalName: text })}
											onSubmitEditing={() => { this.goalAmountRef.current.focus() }} /></View>
									<CounterTextInput inputRef={this.goalAmountRef} onPressUp={() => this.setState({ goalAmount: ('$' + (this.parseInt(this.state.goalAmount.toString().replace('$', '')) + 1)) })}
										//  onPressDown={()=>this.setState({goalAmount:('$'+(this.parseInt(this.state.goalAmount.toString().replace('$',''))-1))})}
										onPressDown={() => this.parseInt(this.state.goalAmount.toString().replace('$', '')) > 0 ? this.setState({ goalAmount: ('$' + (this.parseInt(this.state.goalAmount.toString().replace('$', '')) - 1)) }) : null}
										centered={true} value={this.state.goalAmount ? '$' + formatInputValue(this.state.goalAmount) : ''}
										label={'Goal Amount'}
										onSubmitEditing={() => {
											(this.state.goalName && formatInputValue(this.state.goalAmount.replace(/\D/, ''))) ? this.props.actions.addGoal(this.state.goalAmount, this.state.goalName, parentId, childId)
												.then(() => { this.props.actions.getGoals(parentId, childId); this.setState({ goalAmount: '', goalName: '', setGoalVisible: !this.state.setGoalVisible }) }) : null;
										}}
										onChangeText={text => this.setState({ goalAmount: (text || text !== '$' ? ('$' + text.toString().replace('$', '')) : ('')) })} keyboardType={'number-pad'} />
									<View style={{ width: '100%', }}>
										<TouchableNativeFeedback onPress={() => {
											(this.state.goalName && formatInputValue(this.state.goalAmount)) ?
												this.props.actions.addGoal(this.state.goalAmount, this.state.goalName, parentId, childId)
													.then(() => { this.props.actions.getGoals(parentId, childId); this.setState({ goalAmount: '', goalName: '', setGoalVisible: !this.state.setGoalVisible }) }) : null;
										}}>
											<View style={{
												width: '80%', alignSelf: 'center', marginBottom: 30, backgroundColor: Colors.green1, height: 40, borderRadius: 20,
												justifyContent: 'center', alignItems: 'center'
											}}>
												<Text style={[{ alignSelf: 'center', color: 'white' }, defaultStyles.bold]}>{'Add Goal'.toUpperCase()}</Text></View>
										</TouchableNativeFeedback>
									</View>
								</View>
							</View>
						</ModalContainer>

						{<Modal
							animationIn={'zoomIn'} animationOut={'zoomOut'}
							animationInTiming={500} animationOutTiming={500}
							backdropColor={'darkgrey'} backdropOpacity={0.6}
							onBackdropPress={() => this.setState({
								modalVisible: false, confirmationDelete: false, confirmationPurchase: false, confirmationActivate: false
							})}
							isVisible={this.state.modalVisible}
							onRequestClose={() => {
								this.setState({
									modalVisible: false, confirmationDelete: false, confirmationPurchase: false, confirmationActivate: false
								})
							}}>
							<View style={{
								borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
								maxWidth: 400, paddingBottom: 10,
							}}>
								<View style={{ display: this.state.loading ? 'flex' : 'none', position: 'absolute', zIndex: 1, justifyContent: 'center', height: '100%', width: '100%', backgroundColor: 'rgba(126, 193, 61, 0.1)', }}>
									<ActivityIndicator size={'large'} color={Colors.green1} style={{}} />
								</View>
								<View style={{paddingHorizontal: '5%',}}>
									{this.state.confirmationDelete ? this.confirmationDelete() : null}
									{this.state.confirmationActivate ? this.confirmationActivate() : null}
									{this.state.confirmationPurchase ? this.confirmationPurchase() : null}
									{this.state.rejectPurchaseGoal ? this.rejectPurchase() : null}
								</View>
							</View>
						</Modal>}


						<FooterBlock onPress={() => this.setState({ setGoalVisible: true })}
							label={<Image resizeMode={'contain'} style={{ width: '80%', height: '80%', }} source={require('../../assets/Goals/Goal_Icon.png')} />} />

						<FlatList
							style={{ flex: 1, zIndex: 1,}}
							data={this.state.isLoading ? [] : data.data}
							renderItem={(item) => this.renderItem(item, layout)}
							keyExtractor={(item, index) => index.toString()}
							ListHeaderComponent={() => <ChildHeaderCard style={{ marginBottom: 40, paddingTop: layout.height * 0.015, }} name={''} signOut={() => { }} vmin={vmin}
								layout={layout} clearAuth={this.props.actions.clearAuth} navigation={navigation} />}
							ListHeaderComponentStyle={{ zIndex: 1 }}
							ListEmptyComponent={this.emptyList} />
						{/* </ImageBackground> */}
					</View>
				}}
			</Resizable>
		)
	}
}

const styles = StyleSheet.create({
});

// export default Goals
export default connect(state => {
	return ({
		data: state.kidGoals,
		auth: state.auth,
		child: state.child
	})
},
	(dispatch) => ({
		actions: bindActionCreators({ ...goalsActions, ...authActions }, dispatch)
	})
)(Goals);
