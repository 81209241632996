/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useRef, useEffect } from 'react';
import {
    ActivityIndicator,
    Animated,
    FlatList,
    Image,
    ImageBackground,
    StyleSheet,
    View,
    UIManager,
    Platform,
} from 'react-native';

import { Resizable, Hoverable } from 'react-native-web-hooks';
import { LinearGradient } from 'expo-linear-gradient';

import TouchableNativeFeedback from '../../components/TouchableFeedback'
import Text from '../../components/CustomText'

import Colors from '../../constants/Colors';
import globalStyle, { defaultStyles } from '../../constants/style';

const moment = require('moment');

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

UIManager.setLayoutAnimationEnabledExperimental && UIManager.setLayoutAnimationEnabledExperimental(true);

Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1;
    var dd = this.getDate();

    return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
    ].join('-');
};

const jobs = {
    'Chores': require('../../assets/Jobs/Chores.png'),
    'Education': require('../../assets/Jobs/Education.png'),
    'Arts': require('../../assets/Jobs/Art.png'),
    'Saving': require('../../assets/Jobs/Saving.png'),
    'Other': require('../../assets/Jobs/Other.png')
}
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Dec']


function checkDate(date) {
    const today = new Date()
    return (new Date(date).valueOf() + today.getTimezoneOffset() * 60 * 1000) >= today.setHours(0, 0, 0, 0)
}

const XButton = ({ onPress }) => {
    const size = 12
    return <Hoverable onHoverIn={() => { console.log('start!') }} onHoverOut={() => { console.log('finish!!') }}>
        {isHovered => <TouchableNativeFeedback style={{ zIndex: 2 }} onPress={onPress}>
            <View style={{
                zIndex: 20, opacity: isHovered ? 0.5:1, transform: [{ scale: isHovered ? 1.1 : 1 }],
                position: 'absolute', right: size*1.25, top: size*1.25,
            }}>
                <Image resizeMode={'cover'} style={{ width: size, height: size, }} source={require('../../assets/Delete-X.png')} />

            {/* <Text adjustsFontSizeToFit={true} numberOfLines={1}
                style={{ textAlign: 'center', }}>
                X</Text> */}
            </View>
        </TouchableNativeFeedback>
}
    </Hoverable >
}

const CompleteJobButton = ({ onPress }) => {
    const isHovered = false;

    return <TouchableNativeFeedback onPress={onPress}>
        <View elevation={-2} style={{
            width: '90%', boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08)',
            backgroundColor: 'rgba(126, 193, 61, 0.5)', marginBottom: 5,
            padding: 4, borderRadius: 30, justifyContent: 'flex-start', alignItems: 'center'
        }}>
            <View style={{ justifyContent: 'center' }}><Text style={{ color: 'white' }}>Job done!</Text></View>
        </View>
    </TouchableNativeFeedback>
}

const ChangeButton = ({ onPress }) => {
    return <Hoverable onHoverIn={() => { console.log('start!') }} onHoverOut={() => { console.log('finish!!') }}>
        {isHovered => <TouchableNativeFeedback accessible onPress={onPress}>
            <View style={{ zIndex: 10, opacity: isHovered ? 1 : 0.7, transform: [{ scale: isHovered ? 1.1 : 1 }], }}>
                <Image resizeMode={'contain'} style={{ width: 20, height: 20, }} source={require('../../assets/Jobs/Edit.png')} /></View>
        </TouchableNativeFeedback>}
    </Hoverable>
}


function datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.floor((second - first) / (1000 * 60 * 60 * 24));
}


// class JobCard extends React.Component {
const JobCard = ({ type, layout, category, openModal, removeJob, editJob, frequencyJob, isLarge, item, index }) => {

    const progress_val = useRef(new Animated.Value(0)).current
    const scaleValue = useRef(new Animated.Value(0)).current

    useEffect(() => {
        Animated.timing(scaleValue, {
            toValue: 1,
            duration: 350,
            useNativeDriver: true,
            delay: index * 100
        }).start();
        Animated.timing(progress_val, {
            toValue: 1,
            duration: 1200,
        }).start();
        return () => { }
    }, [])


    // let days_total = Math.abs(datediff(deadlineDate, createdDate))
    let days_total = Math.abs(moment(item.deadline).diff(moment(item.created), 'days')) + 1
    // let days_passed = Math.abs(datediff(today, createdDate));
    let days_passed = Math.abs(moment().startOf('day').diff(moment(item.created).startOf('day'), 'days')) + 1

    let progress_value = days_total !== 0 ? Math.floor((days_passed) / (days_total) * 100) + '%' : '100%';
    // let progress = progress_val
    let progress = progress_val.interpolate({
        inputRange: [0, 1],
        outputRange: ['0%', progress_value]
    })

    const vmin = Math.min(...[layout.width, layout.height]);
    let repeatRight = 30 + 0.01 * layout.width
    if (repeatRight > 40) { repeatRight = 40 }
    const imageSize = layout.width * 0.27
    const maxImageSize = 120

    return (<Animated.View style={[{
        elevation:5,
        opacity: scaleValue, marginBottom: 25,
        width: isLarge > 1200 ? '50%' : '90%', maxWidth: 550, margin: 10, borderRadius: 20,
        backgroundColor: 'white', alignSelf: 'center', justifyContent: 'flex-start'
    }, defaultStyles.shadow]}>
        <View style={{
            zIndex: 2, position: 'absolute', width: 70, height: 75, alignItems: 'center', justifyContent: 'center', backgroundColor: 'white',
            borderColor: Colors.gray, borderRadius: 20, borderWidth: 3,
            bottom: -10, right: -10,
        }}>
            <Text style={[{ fontFamily: 'Carmen Sans Bold' },]}>{moment(item.deadline).date()}</Text>
            <Text style={{}}>{months[moment(item.deadline).month()]}</Text>
        </View>
        {category === 'data' ? <XButton onPress={() => { removeJob() }} /> : null}

        <View style={{ left: -20, flex: 1, width: '100%', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', }}>
            <View style={{ top: -20, }}>
                <Image resizeMode={'contain'}
                    style={{ width: imageSize, height: imageSize, maxHeight: maxImageSize, maxWidth: maxImageSize, }} source={jobs[item.category]} />
                {/* <Image resizeMode={'contain'} style={{ width: '90%', height: '90%', marginTop: 10 }} source={jobs[item.category]} /> */}
            </View>
            <View style={{ flex: 1, marginTop: 10, alignItems: 'flex-start', justifyContent: 'center', }}>
                <Text style={[{ color: Colors.navy, }, defaultStyles.bold, globalStyle.h3]}>${item.amount}</Text>
                <View style={{ marginVertical:7.5, flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={[{ }, defaultStyles.bold]}>{item.category}</Text>
                    {category === 'data' ? <ChangeButton onPress={() => { editJob() }} /> : null}
                </View>
                <View style={{ flex: 1, marginBottom: 20, }}><Text style={{ marginBottom: 10, }}>{item.description}</Text></View>
            </View>
        </View>
        {category === 'completed' ? null : (
            // deadlineDate >= newValue
            (days_total >= 0 && item.completed === 0) ?
                <View style={{ top: -10, left: 10, width: '100%', paddingRight: 85, padding: 6, }}>
                    <Text numberOfLines={1} ellipsizeMode={'clip'}
                        style={{ zIndex: 1, marginTop: 2, textAlign: 'left', }}>
                        {days_total - days_passed + 1} {days_total - days_passed ? 'days' : 'day'} left</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <View style={{ height: 8, width: '90%', backgroundColor: Colors.gray, borderRadius: 5, }}>
                            <Animated.View style={{ backgroundColor: Colors.navy, width: progress, minWidth: '2%', height: 8, borderRadius: 8, }} />
                        </View>
                        {(item.frequency && item.frequency !== 'None' && type!=='child') ? <View style={{ marginHorizontal: 10 }}>
                            <TouchableNativeFeedback onPress={() => { frequencyJob(item) }}>
                                <Image resizeMode={'contain'} style={{ width: 15, height: 15, }} source={require('../../assets/Jobs/Edit_2.png')} />
                            </TouchableNativeFeedback>
                        </View> : null}
                    </View>
                </View> :
                <TouchableNativeFeedback onPress={() => { openModal(item.key) }}>
                    <View elevation={2} style={{
                        width: '80%',
                        // boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08)',
                        backgroundColor: 'rgba(126, 193, 61, 0.5)', marginBottom: 15, marginRight: 85, marginLeft: 10,
                        padding: 5, borderRadius: 30, justifyContent: 'flex-start', alignItems: 'center'
                    }}>
                        <View style={{ justifyContent: 'center' }}><Text style={{ color: 'white' }}>Approve Completion</Text></View>
                    </View>
                </TouchableNativeFeedback>
        )}

        {type === 'child' && <View style={{ top: -10, left: 10, width: '100%', paddingRight: 85, padding: 6, }}>
            <CompleteJobButton onPress={() => { openModal(item.key) }} /></View>}
    </Animated.View>)

}


const styles = StyleSheet.create({

});

export default JobCard
