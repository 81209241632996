/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
// import '@expo/match-media'
// import { ScreenOrientation } from 'expo'
import React, { useEffect } from 'react';
import {
	Animated,
	Dimensions,
	FlatList,
	Image,
	ImageBackground,
	StyleSheet,
	View,
	Platform,
	TouchableOpacity,
	TouchableWithoutFeedback,
} from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome';
// import NetInfo, { useNetInfo } from "@react-native-community/netinfo";
import { LinearGradient } from 'expo-linear-gradient';

import { Resizable, Hoverable } from 'react-native-web-hooks';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { database } from 'firebase';

import TouchableNativeFeedback from '../../components/TouchableFeedback'
import Stars from '../../components/Stars'
import Text from '../../components/CustomText'
import ParentComponentHeader from '../../components/ComponentHeader';
import { dropdown, handleScroll, dropdownParent } from '../../components/Dropdown';

import { ParentHeaderCard } from '../../components/Header/HeaderBlock'

import GoalCard from '../../components/Goals/GoalCard';
import FooterBlock from '../../components/Footer/FooterBlock';

import * as goalsActions from '../../store/actions/parents/goalsActions'
import * as authActions from '../../store/actions/parents/authActions'


class Goals extends React.Component {
	static navigationOptions = ({ navigation }) => {
		return {
			headerTransparent: true,
			header: null
		}
	}

	constructor(props) {
		super(props);
		// this.unsubscribe = NetInfo.addEventListener(this.netInfoChange);
		// this.unsubscribe = NetInfo.isConnected.addEventListener('connectionChange', this.netInfoChange)

		this.state = {
			data: [],
			savings_balance: 0.0,
			isLoading: true,
			header: false,
			id: 0,
			connected: true
		};
	};



	async componentDidMount() {
		const { parentId, } = this.props.auth;
		const { childIds } = this.props.parent;

		let savings_source = database().ref('/users/' + parentId + '/' + childIds[0] + '/savings/balance');
		let savings_balance = (await savings_source.once('value')).val();
		
		this.setState({
			savings_balance: savings_balance,
		})

		if (!this.props.auth.parentId) {
			this.props.actions.clearAuth().then(() => { this.props.navigation.navigate('Auth') })
		}

		for (let i in childIds) {
			this.props.actions.getChildName(childIds[i])
		}

		this.props.actions.getGoals(parentId, childIds[0]).then(() => this.setState({ isLoading: false }));

	}

	componentWillUnmount() {
		// this.unsubscribe.remove()
		// Platform.OS==='web'?null:NetInfo.removeEventListener(this.netInfoChange);
	}

	renderItem = ({ item, index, layout }) => {
		return <GoalCard item={item} index={index} type={'parent'} data={this.state.data} balance={this.state.savings_balance} layout={layout} />
	}

	emptyList = () => {
		return this.state.isLoading ? null : <View style={{ alignItems: 'center', marginBottom: 50 }}>
			<Text style={{ }}>No goals yet</Text>
		</View>
	}


	getGoals1(move) {
		const { parentId, } = this.props.auth;
		const { childIds, id } = this.props.parent;

		const { getGoals, changeChildIndex } = this.props.actions;
		let index = id + move;
		changeChildIndex(move)
		if (index > childIds.length - 1) { this.setState({ id: 0 }); getGoals(parentId, childIds[0]); return 0 }
		if (index < 0) { this.setState({ id: childIds.length - 1 }); getGoals(parentId, childIds[childIds.length - 1]); return 0 }
		this.setState({ id: index }); getGoals(parentId, childIds[index]); return 0
	}

	render() {
		let { data, navigation } = this.props;
		const { childIds, id, names } = this.props.parent;

		return (
			<Resizable>
				{layout => {
					const vmin = Math.min(...[layout.width, layout.height]);
					let width = layout.width;

					return <View style={{ backgroundColor: 'white', flex: 1, }}>

						<FooterBlock onPress={() => navigation.navigate('Store')}
							label={<Image resizeMode={'contain'} style={{ width: '50%', height: '50%', }} source={require('../../assets/Store/Shopping-Basket.png')} />} />

						<FlatList
							layout={layout}
							// contentContainerStyle={{ flex: 1, flexGrow: 1 }}
							style={{ flex: 1, zIndex: 1,  paddingVertical: 0.015 * layout.height,  }}
							// onScroll={(e) => handleScroll.call(this, e, layout.height)}
							data={this.state.isLoading ? [] : data.data[childIds[id]]}
							renderItem={(item) => this.renderItem({ ...item, layout })}
							ListHeaderComponent={()=><ParentHeaderCard name={names[childIds[id]]} pressNext={()=>this.getGoals1(+1)} navigation={navigation} signOut={() => { }} vmin={vmin} layout={layout} clearAuth={this.props.actions.clearAuth} />}
							// renderItem={(item) => <View><Text>sdf</Text></View>	}
							keyExtractor={(item, index) => index.toString()}
							ListHeaderComponentStyle={{ zIndex: 1, marginVertical: 30, }}
							ListEmptyComponent={this.emptyList} />

					</View>
				}}
			</Resizable>
		)
	}
}

const styles = StyleSheet.create({
});


export default connect(state => {
	return ({
		data: state.goals,
		auth: state.auth,
		parent: state.parent
	})
},
	(dispatch) => ({
		actions: bindActionCreators({ ...goalsActions, ...authActions }, dispatch)
	})
)(Goals);
