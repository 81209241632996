/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {

    KeyboardAvoidingView,
    Platform,

    TouchableWithoutFeedback,
    StyleSheet,
    ScrollView,
    View,
    StatusBar,
} from 'react-native';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';


import TouchableNativeFeedback from '../../components/TouchableFeedback'
import FloatingLabelInput from '../FloatingLabelInput/FloatingLabelInput'
import Text from '../../components/CustomText'
import { Button } from '../../components/Buttons'
import ParentComponentHeader from '../../components/ComponentHeader'
import { ConfirmationView, RejectionView } from '../../components/confirmation'
import { DropdownElement } from '../../components/Dropdown'

import {defaultStyles} from '../../constants/style'


import Colors from '../../constants/Colors';

const auth = firebase.auth;
const database = firebase.database;

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const salaryFreqs = { 'Monthly': 1, 'Bi-weekly': 2, 'Weekly': 4, }
const salaryFreqsReversed = { 1: 'Monthly', 2: 'Bi-weekly', 4: 'Weekly', }

function formatInputValue(str) {
    return (str || str === 0) ? str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".") : ''
}

function formatInterestRate(str) {
    return (str && str !== '%') ? parseFloat(str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".")) + '%' : ''
}

function emailIsValid(email) {
    return /\S+@\S+\.\S+/.test(email)
}

Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
    ].join('-');
};

const HeaderElement = ({ title, focused, onPress, layout }) => {
    const vmin = Math.min(layout.width, layout.height);
    return <TouchableNativeFeedback style={{ width: '100%', flex: 1, }} onPress={onPress}>
        <View style={{
            margin: vmin * 0.01, padding: vmin * 0.01, borderRadius: 10, backgroundColor: focused ? Colors.green1 : 'white',
            borderWidth: 3, borderColor: Colors.green1, alignItems: 'center',
            // borderBottomColor:Colors.green1, borderBottomWidth:3
        }}>
            <Text style={{ fontSize: 18, color: focused ? 'white' : Colors.green1 }}>{title}</Text>
        </View>
    </TouchableNativeFeedback>
}

class Settings extends React.Component {
    static navigationOptions = ({ navigation }) => {
        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            headerLeft: null,
            header: null
        }
    }

    constructor(props) {
        super(props);
        this.childIdRef = React.createRef()
        this.childAgeRef = React.createRef()
        this.state = {
            data: [],
            isLoading: true,
            email: '',
            username: '',
            age: '',
            isFocused: false,
            hasCameraPermission: null,
            //  image: '0',
            image: null,
            emailChange: false,
            nameChange: false,
            oldPassword: '',
            password: '',
            confirmPassword: '',
            id: 0,
            focused: 0
            //  image: 'file:///data/user/0/host.exp.exponent/cache/ExperienceData/%2540dtsvetkov%252Fpennygembank/ImagePicker/cc28e74d-706e-4020-a1f0-00192ee0b38c.jpg',
            // type: Camera.Constants.Type.back,
        };
    };


    async componentDidMount() {
        // const { parentName, parentEmail } = this.props;
        const { parentId, user } = this.props.auth;
		const {  childIds, settings, id } = this.props.parent;


        let parentEmail = user.user.email
		let parentName = user.user.displayName

        this.setState({parentEmail, parentName})
		this.setState({ parentName, parentEmail, old_parentname: parentName, old_parentemail: parentEmail })

    }


    changeEmailTransaction() {
        const { parentId, } = this.props.auth;

        // let name_ref = database().ref('/children/'+childId);
        database().ref('/emails/').update({
            [parentId]: this.state.parentEmail
        });
        var user = auth().currentUser;
        console.log('this.state.parentEmail', this.state.parentEmail)
        user.updateEmail(this.state.parentEmail).then(() => {
            let userParam = { user: user }
            this.props.actions.login(userParam)
            console.log('is this even working, ', user)
        }).catch(function (error) {
            // An error happened.
            console.log('errror updating EMAIL, - ', error)
        });
        this.setState({ old_parentemail: this.state.parentEmail })

        this.props.actions.sendVerificationEmail(this.state.old_parentname, this.state.parentEmail)
        // auth().currentUser.updateEmail(this.state.email).then(() => { auth().currentUser.sendEmailVerification(); });
    }


    changeEmail() {
        // this.state.emailChange?this.setState({emailConfirmVisible:true}):null
        return <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>Changing Email to</Text>
            <Text style={{ fontSize: 20, margin: 5, color: '#5aca3c' }}>{this.state.parentEmail}</Text>

            <View style={{ flexDirection: 'row', justifyContent: 'center', width: '100%', marginHorizontal: 15, }}>
                <View style={{ width: '40%' }}>
                    <TouchableNativeFeedback onPress={() => { this.changeEmailTransaction(); this.setState({ emailChange: false }) }}>
                        <View style={{
                            justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
                            margin: 5, backgroundColor: 'rgba(126,193,61,1)'
                        }}>
                            <Text style={{ fontSize: 19, marginVertical: 5, ...defaultStyles.bold, color: 'white' }}>YES</Text></View>
                    </TouchableNativeFeedback>
                </View>
                <View style={{ width: '40%', }}>
                    <TouchableNativeFeedback onPress={() => this.setState({ emailChange: false })}>
                        <View style={{
                            justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10, margin: 5,
                            backgroundColor: '#EE5060'
                        }}>
                            <Text style={{ fontSize: 19, marginVertical: 5, ...defaultStyles.bold, color: 'white' }}>NO</Text></View>
                    </TouchableNativeFeedback>
                </View>
            </View>
        </View>
    }

    changeNameTransaction() {
        const { parentId, childId } = this.props.auth;

        var user = auth().currentUser;

        user.updateProfile({
            displayName: this.state.parentName,
        }).then(() => {
            let userParam = { user: user }
            this.props.actions.login(userParam)

        }).catch(function (error) {
            // An error happened.
            console.log('errror updating name, - ', error)
        });
        this.setState({ old_parentname: this.state.parentName })
        this.props.navigation.navigate('Settings')
    }

    changeName() {
        // this.state.nameChange?this.setState({nameConfirmVisible:true}):null
        return <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>Changing User Name to</Text>
            <Text style={{ fontSize: 20, margin: 5, color: '#5aca3c' }}>{this.state.parentName}</Text>
            <View style={{ flexDirection: 'row', justifyContent: 'center', width: '100%', marginHorizontal: 15, }}>
                <View style={{ width: '40%' }}>
                    <TouchableNativeFeedback onPress={() => { this.changeNameTransaction(); this.setState({ nameChange: false }); this.props.navigation.navigate('Settings')  }}>
                        <View style={{
                            justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
                            margin: 5, backgroundColor: 'rgba(126,193,61,1)'
                        }}>
                            <Text style={{ fontSize: 19, marginVertical: 5, ...defaultStyles.bold, color: 'white' }}>YES</Text></View>
                    </TouchableNativeFeedback>
                </View>
                <View style={{ width: '40%', }}>
                    <TouchableNativeFeedback onPress={() => this.setState({ nameChange: false })}>
                        <View style={{
                            justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10, margin: 5,
                            backgroundColor: '#EE5060'
                        }}>
                            <Text style={{ fontSize: 19, marginVertical: 5, ...defaultStyles.bold, color: 'white' }}>NO</Text></View>
                    </TouchableNativeFeedback>
                </View>
            </View>
        </View>
    }

    checkPasswordConditions() {
        if (this.state.password === this.state.confirmPassword && this.state.password != '') {
            // this.setState({})
            return true
        }
        if (this.state.password === this.state.confirmPassword && this.state.password === '') {
            // this.setState({})
            this.setState({ errorOpacity: 1, errorText: "Password cannot be empty" })
            return false
        }
        else {
            this.setState({ errorOpacity: 1, errorText: "Passwords don't match" })
            return false
        }
    }

    changePasswordTransaction() {
        // this.setState({ oldPassword:'', password: '', confirmPassword: '', passwordChangeVisible: false, })
        var user = auth().currentUser;
        const credential = auth.EmailAuthProvider.credential(
            user.email,
            this.state.oldPassword
        );
        const newPassword = this.state.password
        console.log('user!!!! -', user)
        // Prompt the user to re-provide their sign-in credentials


        user.reauthenticateWithCredential(credential).then(() => {
            // User re-authenticated.
            console.log('Here changing')
            auth().currentUser.updatePassword(newPassword)
            this.setState({ password: '', confirmPassword: '', oldPassword:'', passwordChangeVisible: false, startPasswordChange: false })

        }).catch((error) => {
            console.log('error, ', error)
            this.setState({ errorOpacity: 1, errorText: "Old password is incorrect" })
            // An error happened.
        });

        this.props.navigation.navigate('Settings')

    }


    changePassword() {
        // this.state.nameChange?this.setState({nameConfirmVisible:true}):null
        return <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>Change Password</Text>

            <View style={{ width: '100%', alignItems: 'center', marginBottom: 10 }}>
                <FloatingLabelInput secureTextEntry={true} label={'Old Password'} value={this.state.oldPassword}
                    onChangeText={text => this.setState({ errorOpacity: 0, oldPassword: text })} /></View>

            <View style={{ width: '100%', alignItems: 'center', marginBottom: 10 }}>
                <FloatingLabelInput secureTextEntry={true} label={'New Password'} value={this.state.password}
                    onChangeText={text => this.setState({ errorOpacity: 0, password: text })} /></View>

            <View style={{ width: '100%', alignItems: 'center', marginBottom: 10 }}>
                <FloatingLabelInput secureTextEntry={true} label={'Confirm New Password'} value={this.state.confirmPassword}
                    onChangeText={text => this.setState({ errorOpacity: 0, confirmPassword: text })} /></View>

            <Text style={{ marginVertical: 4, color: '#EE5060', opacity: this.state.errorOpacity }}>{this.state.errorText}</Text>

            <View style={{ width: '100%', alignSelf: 'center', }}>
                <TouchableNativeFeedback onPress={() => { if (this.checkPasswordConditions()) { this.changePasswordTransaction() } }}>
                    <View style={{
                        width: '80%', marginBottom: 30, backgroundColor: '#5aca3c', height: 40, borderRadius: 10,
                        justifyContent: 'center', alignSelf: 'center'
                    }}>
                        <Text style={{ textAlign: 'center', fontSize: 25, color: 'white' }}>OK</Text></View>
                </TouchableNativeFeedback>
            </View>
        </View>

    }


    closeModal() {
        return <View></View>
    }


    render() {
        const { layout } = this.props;

        const { navigation } = this.props;

        const vmin = Math.min(...[layout.width, layout.height]);


        return <View style={{ alignItems: 'center', width: '100%', maxWidth: 600, minWidth: 200, alignSelf: 'center',
            justifyContent: 'center',
        }}>
            <Modal animationIn={'zoomIn'} animationOut={'zoomOut'}
                animationInTiming={500} animationOutTiming={500}
                backdropColor={'darkgrey'} backdropOpacity={0.6}
                onBackdropPress={() => this.setState({ parentModalVisible: false })}
                isVisible={this.state.parentModalVisible && (this.state.emailChange || this.state.nameChange)}
                onRequestClose={() => { this.setState({ parentModalVisible: false }) }}>
                <View style={{
                    borderRadius: 15, backgroundColor: 'white', width: '80%', height: '30%', alignSelf: 'center',
                    maxHeight: 500, maxWidth: 400, minHeight: 250, justifyContent: 'center', alignItems: 'center'
                }}>
                    {this.state.nameChange ? this.changeName() :
                        this.state.emailChange ? this.changeEmail() : this.closeModal()
                    }
                </View>
            </Modal>
            {<Modal
                animationIn={'zoomIn'} animationOut={'zoomOut'}
                animationInTiming={1000} animationOutTiming={1000}
                backdropColor={'darkgrey'} backdropOpacity={0.6}
                onBackdropPress={() => this.setState({ passwordChangeVisible: false, })}
                isVisible={this.state.passwordChangeVisible}
                onRequestClose={() => { this.setState({ passwordChangeVisible: false, }) }}>
                <View style={{
                    borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
                    maxWidth: 400, minHeight: 250, justifyContent: 'center', alignItems: 'center'
                }}>
                    {this.changePassword()}
                </View>
            </Modal>}

            <FloatingLabelInput label={"User Name"} value={this.state.parentName} inputStyle={{fontSize: 16,}}
                onChangeText={text => this.setState({ parentName: text })} />
            <View style={{ margin: 5 }}></View>
            <FloatingLabelInput keyboardType={'email-address'} label={"Email"} value={this.state.parentEmail} inputStyle={{fontSize: 16,}}
                onChangeText={text => this.setState({ parentEmail: text })} />
            <View style={{ margin: 5 }}></View>

            <TouchableWithoutFeedback onPress={() => { this.setState({ passwordChangeVisible: true }) }}>
                <View style={{ width: '100%', }}>
                    <View style={{ justifyContent: 'center', alignSelf: 'center', height: 40, marginTop: 20, width: '80%', borderRadius: 10, borderWidth: 2, borderColor: '#7ec13d', }}>
                        {/* <TouchableOpacity onPress={()=>{}}> */}
                        <View style={{ justifyContent: 'center', }}>
                            <Text style={{ paddingLeft: 20, ...defaultStyles.bold, fontSize: 14, color: Colors.gray0 }}>******</Text>
                        </View>
                        {/* </TouchableOpacity> */}
                    </View>
                    <View style={{ width: '80%', alignSelf: 'center' }}>
                        <Text style={{marginTop:3, paddingLeft: 20, fontSize: 14, color: Colors.gray }}>Change password</Text>
                    </View>
                </View>
            </TouchableWithoutFeedback>


            <Button title={'Save Changes'}
                styles={{ width: '80%', alignSelf: 'center', marginTop: vmin * 0.05 }} textStyle={{ width: '100%', textAlign: 'center', ...defaultStyles.bold }}
                onPress={() => {
                    this.setState({
                        parentModalVisible: true, nameChange: this.state.parentName !== this.state.old_parentname,
                        emailChange: this.state.parentEmail !== this.state.old_parentemail
                    })
                }} />


        </View>
    }
}

const styles = StyleSheet.create({

});

export default Settings;

// export default connect(state => {
//     // console.log(Object.keys(state.vacancies));
//     return ({
//         auth: state.auth,
//     })
// },
//     (dispatch) => ({
//         actions: bindActionCreators({ ...authActions, ...chequingActions }, dispatch)
//     })
// )(Settings);
