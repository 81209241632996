import React, { Component } from 'react';
import { View, StatusBar, TextInput, Animated, Platform } from 'react-native';

// import FloatingLabelInput from './FloatingLabelInput';


import './FloatingLabelInput.css';
import Text from '../CustomText';

import Colors from '../../constants/Colors';


export default class FloatingLabelInput extends Component {
	state = {
		isFocused: false,
		_animatedIsFocused: new Animated.Value(
			this.props.value === '' ? 0 : 1
		)
	};

	//   componentDidMount() {
	//     this._animatedIsFocused = new Animated.Value(
	//       this.props.value === '' ? 0 : 1
	//     );
	//   }

	componentDidUpdate() {
		Animated.timing(this.state._animatedIsFocused, {
			toValue: this.state.isFocused || this.props.value !== '' ? 1 : 0,
			duration: 200,
		}).start();
	}

	render() {
		const { label, additionalSign, inputRef, centered, containerStyle, inputStyle, ...otherProps } = this.props;
		const labelStyle = {
			position: 'absolute',
			left: 0, right: 0,
			marginHorizontal: 22,
			justifyContent:'center',
			alignItems: this.props.centered ? 'center' : 'flex-start',
			top: this.state._animatedIsFocused.interpolate({
				inputRange: [0, 1],
				outputRange: [13, 44],
			}),
		};

		const textStyle = {
			fontFamily: 'Carmen Sans Regular',
			// color: '#7ec13d'
			fontSize: 15,
			color:Colors.gray1
		}
		let paddingValue = 1


		// let paddingValue = Platform.OS==='web'?((this.props.additionalSign && this.props.value)?12:1) :1
		return (
			<View style={[{ width: '80%', zIndex: 1, marginVertical: 12 }, containerStyle]}>
				<Animated.View style={labelStyle}>
					<Animated.Text numberOfLines={1} style={textStyle}>
						{this.props.label}
					</Animated.Text>
				</Animated.View>
				<View style={[{
					justifyContent: 'center', alignItems:'center', flexDirection: 'row', height: 40, width: '100%', borderWidth: 2, borderRadius: 20,
					borderColor: Colors.gray
				}, this.props.style]}>
					{this.props.centered?null:<View style={{ justifyContent: 'center', marginLeft: Platform.OS === 'web' ? 20 : 40 }}>
						<Text style={{
							// color: '#7ec13d',
							color: Colors.gray1,
							fontSize: 20, marginTop: 4, }}>{(this.props.additionalSign && this.props.value) ? this.props.additionalSign : ''}
						</Text></View>}
					<TextInput className={'floating-label-input'}
						{...otherProps}
						ref={this.props.inputRef}
						secureTextEntry={this.props.secureTextEntry}
						// onSubmitEditing={()=>{ this.passwordTextInput.focus()}}
						keyboardType={this.props.keyboardType ? this.props.keyboardType : 'default'}
						style={[{justifyContent:'center', paddingTop:5, marginBottom:1,
							...Platform.OS === 'web' ? { outlineWidth: 0, outlineStyle:'none' } : {}, height: 34,width: '100%',
							fontFamily: 'Carmen Sans Regular', marginLeft: this.props.centered ? 0 : paddingValue, fontSize: 20,
							color: Colors.gray1,
							// color: '#7ec13d',
							textAlign: this.props.centered ? 'center' : 'left',
						},inputStyle]}
						multiline={false}
						// placeholder={'Email'}
						onFocus={() => this.setState({ isFocused: true })}
						onBlur={() => this.setState({ isFocused: false })}
					/>
				</View>
			</View>
		);
	}
}