/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { AsyncStorage, ActivityIndicator, Image, ImageBackground, KeyboardAvoidingView, StyleSheet, ScrollView, TextInput, View, Platform } from 'react-native';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { withNavigationFocus } from 'react-navigation';

import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';

import { Resizable, Hoverable, useDimensions as useLayout } from 'react-native-web-hooks';

import { LinearGradient } from 'expo-linear-gradient';

import Icon from 'react-native-vector-icons/FontAwesome5';

import firebase from 'firebase/app';
import 'firebase/database'
import 'firebase/auth'
// import 'firebase/sto'

// import 'firebase/analytics'
import * as Analytics from 'expo-firebase-analytics';

import TouchableNativeFeedback from '../../../components/TouchableFeedback'
import FloatingLabelInput from '../../../components/FloatingLabelInput/FloatingLabelInput'
import Text from '../../../components/CustomText'
// import { ConfirmationView, } from '../components/confirmation'
import Stars from '../../../components/Stars';
import { DropdownElement } from '../../../components/Dropdown';
import ChangeAvatarBlock from '../../../components/settings/ChangeAvatar';
import { BlockWrapper, NewKid } from '../../../components/newChild/BlockWrapper';


import * as authActions from '../../../store/actions/parents/authActions';
import * as kidAuthActions from '../../../store/actions/kids/authActions';

import Colors from '../../../constants/Colors';
import avatars from '../../../constants/avatars';
import { kidInfo } from '../../../constants/info';
import { defaultStyles } from '../../../constants/style';

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const auth = firebase.auth;
const database = firebase.database;
// const analytics = firebase.analytics;

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const InfoButton = ({ text }) => {
    const [visible, setVisible] = useState(false);
    return <React.Fragment>
        <TouchableNativeFeedback onPress={() => setVisible(!visible)}><View style={{
            marginLeft: 10, height: 25, width: 25, borderRadius: 35, backgroundColor: Colors.green1,
            justifyContent: 'center', alignItems: 'center'
        }}><Text style={[{ color: 'white' }, defaultStyles.bold]}>?</Text></View></TouchableNativeFeedback>
        {/* {visible ? <View style={{ left: '90%', width: 300, position: 'absolute' }}><Text>Knowing your kid’s age will help us better customize the app features. Only you and your kid will be able to see this
information. This is not a mandatory field.</Text></View> : null} */}
        <Modal animationIn={'zoomIn'} animationOut={'zoomOut'} animationInTiming={300} animationOutTiming={300}
            backdropColor={'darkgrey'} backdropOpacity={0.1}
            onBackdropPress={() => setVisible(false)}
            isVisible={visible}
            onRequestClose={() => { setVisible(false) }}>
            <View elevation={5} style={{
                borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center', alignItems: 'center',
                maxWidth: 400, minHeight: 100, paddingVertical: 15, paddingHorizontal: 20, justifyContent: 'center', boxShadow: "2px 2px 15px #272827",
            }}>
                <Text style={{ textAlign: 'center', marginTop: 20, color: Colors.gray1 }}>{text}</Text>
                <TouchableNativeFeedback onPress={() => setVisible(false)}>
                    <View style={{ backgroundColor: Colors.green1, borderRadius: 5, marginVertical: 10, paddingHorizontal: 20, paddingVertical: 10, }}>
                        <Text style={[{color: 'white' }, defaultStyles.bold]}>Got it</Text>
                    </View>
                </TouchableNativeFeedback>
            </View>
        </Modal>
    </React.Fragment>
}

export const Button = ({ title, onPress }) => {

    return <TouchableNativeFeedback onPress={onPress}>
        <LinearGradient start={[0, 1]} end={[1, 0]}
            colors={[Colors.green2, Colors.green1]}
            // colors={['#de3c95', '#ffd71b']}
            style={{
                // width: '100%',
                zIndex: 1, marginVertical: 10,
                justifyContent: 'center', height: 40, backgroundColor: '#7ec13d', borderRadius: 20,
            }}>
            <Text style={[{ fontSize: 19, color: 'white', alignSelf: 'center', margin: 5, }, defaultStyles.bold]}>
                {title.toUpperCase()}</Text>
        </LinearGradient>
        {/* <View style={{justifyContent: 'center', height: 40, width: '100%', backgroundColor: '#7ec13d', borderRadius: 5,}}>
			<Text style={{ fontSize: 19, color: 'white', alignSelf: 'center', margin: 5, }}>
				{title.toUpperCase()}</Text></View> */}
    </TouchableNativeFeedback>

}

const BlockWrapperOld = ({ title, description, onPress, layout, children }) => {
    const vmin = Math.min(...[layout.width, layout.height]);
    const top = vmin * 0.1 < 52 ? 52 : vmin * 0.105
    return (<View style={{
        minWidth: 350,
        top: layout.height / 2,
        transform: [{ translateY: '-50%' }],
        width: layout.width > 850 ? '70%' : '90%',
        justifyContent: 'space-between', alignItems: 'center',
        borderRadius: 15, backgroundColor: 'white',
        marginVertical: layout.height < 800 ? 50 : 0,
        // marginBottom: top,
        padding: 20
    }}>
        <Image resizeMode={'contain'} style={{
            position: 'absolute', top: -top,
            height: vmin * 0.2, width: vmin * 0.2,
            minWidth: 100, minHeight: 100,
            // 540x300
        }} source={require('../../../assets/signup_window_crop.png')} />
        <View>
            <Text style={{ ...defaultStyles.bold, textAlign: 'center', fontSize: 25, marginVertical: 10, marginTop: vmin * 0.05, color: Colors.gray1 }}>{title}</Text>
            {description}
        </View>
        {children}
    </View>)

}


class CreateProfile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...Object.keys(kidInfo).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: '' } }, {}),
            confirmPassword: '',
            avatar: 1,
            nameInput: true,
            usernameInput: true,
            passwordInput: true,
            confirmPasswordInput: true,
            // usernameInput: true,
            emailInput: true,
            avatarImage: '',
        };
    };

    componentDidMount() {
        const { newKid } = this.props.parent;
        console.log('newKid Profile ', newKid)
        this.setState({
            ...Object.keys(newKid.profile).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: newKid.profile[curr] } }, {}),
            available: newKid.profile.userName && this.checkIfAvailable(newKid.profile.userName),
            confirmPassword: newKid.profile.password || ''
        })
        // Object.keys(newKid).map(item=>this.setState)

        // this.checkIfAvailable(newKid.profile.username)

    }

    componentDidUpdate(prevProps) {
        if (prevProps.isFocused !== this.props.isFocused) {

            if (this.props.isFocused) {
                const { newKid } = this.props.parent;
                console.log('newKid Profile IS FOCUSED', newKid, Object.keys(newKid.profile))

                Object.keys(newKid.profile).length ? this.setState({
                    ...Object.keys(newKid.profile).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: newKid.profile[curr] } }, {}),
                    available: newKid.profile.userName && this.checkIfAvailable(newKid.profile.userName),
                    confirmPassword: newKid.profile.password || ''
                }) :
                    this.setState({
                        name: '', userName: '', password: '', confirmPassword: '', email: '', age: '',
                        // available: newKid.profile.userName && this.checkIfAvailable(newKid.profile.userName),
                        // confirmPassword: newKid.profile.password || ''
                    })
            }
            // Use the `this.props.isFocused` boolean
            // Call any action
        }
    }

    componentWillUnmount() {
        // Remove the event listener
        // this.focusListener.remove();
    }


    row({ item, onChange }) {
        return <View key={item} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <FloatingLabelInput style={{ borderColor: this.state[item + 'Input'] ? Colors.gray : Colors.rose }} {...kidInfo[item].params}
                label={kidInfo[item].label} value={this.state[item]} onChangeText={(text => this.setState({ [item + 'Input']: true, [item]: text }))} />
            <InfoButton text={kidInfo[item].text} />
        </View>
    }

    checkIfAvailable(name) {
        // return database().ref('children/').orderByChild('username')
        return database().ref('username/').orderByKey()
            .equalTo(name.toLowerCase())
            // .startAt(name.toUpperCase())
            // .endAt(name.toLowerCase())
            .once('value')
            .then(snap => {
                console.log('snap.val() - ', snap.val(), !snap.val());
                (!snap.val() && name) !== this.state.available ? this.setState({ available: !snap.val() && name }) : null;
                return snap.val()
            })

    }


    uriToBlob = (uri) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();

            xhr.onload = function () {
                // return the blob
                console.log(xhr.response)
                resolve(xhr.response);
            };

            xhr.onerror = function () {
                // something went wrong
                reject(new Error('uriToBlob failed'));
            };

            // this helps us get a blob
            xhr.responseType = 'blob';

            xhr.open('GET', uri, true);
            xhr.send(null);

        });

    }

    _launchCamera = async () => {

        let result = await ImagePicker.launchCameraAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Image,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 0.1
        });

        console.log(result);

        if (!result.cancelled) {
            this.uriToBlob(result.uri).then(blob => {
                // storage().ref('children/' + childId + '.png').put(blob)
                // 	.then((res) => {
                // 		return res
                // 	})
            });
            this.setState({ avatarImage: result.uri });
            AsyncStorage.setItem('avatar', result.uri)
            // this.props.actions.saveAvatar(parentId, childId, 5, result.uri)
        }
    };

    _pickImage = async () => {

        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 0
        });

        console.log(result);
        // ImageManipulator.manipulateAsync(uri, actions, saveOptions)

        if (!result.cancelled) {
            let uri = result.uri
            console.log(result)
            if (Platform.OS === 'web') {
                const manipResult = await ImageManipulator.manipulateAsync(result.uri,
                    //  [{ crop: { originX:0, originY:0, width:'100%', height:'100%' } }, ],
                    { compress: 0, format: ImageManipulator.SaveFormat.PNG })
                console.log('manipResult, ', manipResult)
                uri = manipResult.uri
            }

            this.uriToBlob(uri).then(blob => {
                // storage().ref('children/' + childId + '.png').put(blob)
                // 	.then((res) => {
                // 		return res
                // 	})
            });
            this.setState({ avatarImage: uri });
            AsyncStorage.setItem('avatar', uri)
            // this.props.actions.saveAvatar(parentId, childId, 5, uri)

        }
    };

    formatUsername(text) {
        return text.replace(/( |-|,)/g, '')
    }

    formatAge(text) {
        return text.replace(/\D/g, '')
    }

    checkConditions() {
        const { name, userName, password, confirmPassword, available, email, age } = this.state;
        console.log('name, userName, password, available, ', name, userName, password, available);
        this.setState({
            nameInput: name, usernameInput: userName && available, passwordInput: password && password.length > 5,
            confirmPasswordInput: confirmPassword && password === confirmPassword && confirmPassword.length >= 5,
            emailInput: email ? validateEmail(email) : true
        })

        return (name && userName && password && password.length >= 6 && confirmPassword && (password === confirmPassword) && available) ? true : false
    }

    async generateAvailable(name) {
        console.log('Starting!')

        let tmpName = name
        let counter = 0;
        while (await this.checkIfAvailable(tmpName.toLowerCase())) {
            console.log('new name  - ', counter, tmpName)
            tmpName = name + counter
            counter += 1
        }
        return this.setState({ userName: tmpName })
    }

    render() {
        const { layout, navigation } = this.props;
        const { name, userName, password, email, age, confirmPasswordInput,
            nameInput, usernameInput, passwordInput, emailInput } = this.state;
        const title = 'Create Child Profile'
        const description = <Text style={{ textAlign: 'center', marginVertical: 5, color: Colors.gray1 }}>
            Let’s now create your child’s profile.
		</Text>

        const vmin = Math.min(...[layout.width, layout.height]);
        const top = vmin * 0.1 < 50 ? 50 : vmin * 0.1
        console.log(vmin, vmin * 0.1)
        return (<BlockWrapper navigation={navigation} layout={layout} title={title} description={description}>
            {props=>(<><Modal
                animationIn={'slideInLeft'} animationOut={'slideOutLeft'} animationInTiming={300} animationOutTiming={300}
                backdropColor={'darkgrey'} backdropOpacity={0.1}
                onBackdropPress={() => this.setState({ changeAvatarVisible: false })}
                isVisible={this.state.changeAvatarVisible}
                style={{ margin: 0 }}
                onRequestClose={() => { this.setState({ changeAvatarVisible: false }) }}>
                <View elevation={5} style={{
                    flex: 1, margin: 0, justifyContent: 'center',
                    backgroundColor: 'white', height: '100%', width: '100%',
                    padding: 15, alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 1)'
                }}>
                    <TouchableNativeFeedback style={{ position: 'absolute', right: 20, top: 20 }}
                        onPress={() => this.setState({ changeAvatarVisible: false })}>
                        <View>
                            <Text style={[{ color: Colors.rose, fontSize: 35, }, defaultStyles.bold]}>X</Text>
                        </View>
                    </TouchableNativeFeedback>
                    <View elevation={5} style={{
                        position: 'absolute', top: layout.height * 0.085, alignItems: 'center'
                    }}>
                        <Text style={[{ textAlign: 'center', fontSize: 25, }, defaultStyles.bold]}>Change Avatar</Text>
                    </View>
                    <ChangeAvatarBlock image={this.state.image} avatar={this.state.avatar} layout={layout} onPress={(index) => {
                        this.setState({
                            imagePickerVisible: index === 5 ? true : false,
                            changeAvatarVisible: index === 5 ? true : false
                        })
                        // this.save_avatar(index)
                        this.setState({ avatar: index })
                    }} />
                </View>
            </Modal>
            <Modal
                animationIn={'slideInLeft'} animationOut={'slideOutLeft'} animationInTiming={300} animationOutTiming={300}
                backdropColor={'darkgrey'} backdropOpacity={0.1}
                onBackdropPress={() => this.setState({ imagePickerVisible: false })}
                isVisible={this.state.imagePickerVisible}
                onRequestClose={() => { this.setState({ imagePickerVisible: false }) }}>
                <View elevation={5} style={{
                    alignSelf: 'center',
                    flex: 1, justifyContent: 'center', maxHeight: 500, maxWidth: 500,
                    backgroundColor: 'white', height: '100%', width: '100%',
                    padding: 15, alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 1)'
                }}>
                    <TouchableNativeFeedback style={{ position: 'absolute', right: 20, top: 20 }}
                        onPress={() => this.setState({ imagePickerVisible: false })}>
                        <View>
                            <Text style={[{ color: Colors.rose, fontSize: 35, },defaultStyles.bold]}>X</Text>
                        </View>
                    </TouchableNativeFeedback>
                    <View>
                        <Text style={[{fontSize: 30, color: '#7ec13d' }, defaultStyles.bold]}>
                            Select</Text>
                    </View>
                    <TouchableNativeFeedback onPress={() => {
                        this.setState({ imagePickerVisible: false });
                        this._launchCamera().then(() => { this.setState({ changeAvatarVisible: false, }) })
                    }}>
                        <View style={{ width: '80%', borderRadius: 10, backgroundColor: '#7ec13d', padding: 10, paddingHorizontal: 15, margin: 10 }}>
                            <Text style={{ color: 'white' }}>Open Camera</Text>
                        </View>
                    </TouchableNativeFeedback>
                    <TouchableNativeFeedback onPress={() => {
                        this.setState({ imagePickerVisible: false });
                        this._pickImage().then(() => { this.setState({ changeAvatarVisible: false, }) })
                    }}>
                        <View style={{ width: '80%', borderRadius: 10, backgroundColor: '#7ec13d', padding: 10, paddingHorizontal: 15, margin: 10 }}>
                            <Text style={{ color: 'white' }}>Pick Image</Text>
                        </View>
                    </TouchableNativeFeedback>
                </View>
            </Modal>
            <View style={{
                width: '100%', maxWidth: 450,
                borderRadius: 10, paddingBottom: 10,
                flexDirection: layout.width > 850 ? 'row' : 'column'
            }}>
                <View style={{ margin: 10, justifyContent: 'center', alignItems: 'center' }}>
                    <TouchableNativeFeedback onPress={() => this.setState({ changeAvatarVisible: true })}>
                        <View style={{
                            overflow: 'hidden', height: 100, width: 100,
                            justifyContent: "center", alignItems: 'center',
                            borderWidth: 0,
                            borderRadius: vmin * 0.5, padding: 0, margin: 0
                        }}>
                            <ImageBackground resizeMode={'contain'} style={{
                                height: 110, width: 110, overflow: "hidden", borderRadius: 55,
                                alignItems: 'center', justifyContent: 'flex-end',
                                // height: vmin * 0.5, width: vmin * 0.5, maxHeight: 100, maxWidth: 100,
                            }} source={this.state.avatarImage ? { uri: this.state.avatarImage } : avatars[this.state.avatar]} >
                                <View style={{
                                    bottom: 0,
                                    backgroundColor: 'rgba(128,128,128,0.5)', width: '90%', height: '40%', alignItems: 'center',
                                    // justifyContent:'center'
                                }}>
                                    <Icon style={{ marginTop: 5 }} name="pen" size={22} color="white" />
                                </View>
                            </ImageBackground>
                        </View>
                    </TouchableNativeFeedback>
                </View>
                <View style={{ paddingHorizontal: 35, flex: 1, width: '100%', alignItems: 'center', justifyContent: 'space-around' }}>
                    {/* <Text>Kids</Text> */}
                    {/* {Object.keys(kidInfo).map(item => this.row(item))} */}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <FloatingLabelInput style={{ borderColor: nameInput ? Colors.gray : Colors.rose }} label={kidInfo['name'].label} value={this.state.name} onChangeText={text => {
                            this.checkIfAvailable(text)
                                .then((res) => { res ? this.generateAvailable(text) : null });
                            this.setState({ usernameInput: true, nameInput: true, name: text, userName: this.formatUsername(text) })
                        }} />
                        <InfoButton text={kidInfo['name'].text} />
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <FloatingLabelInput style={{ borderColor: usernameInput ? Colors.gray : Colors.rose }} label={kidInfo['userName'].label} value={this.state.userName} onChangeText={text => {
                            console.log('chaingin?');
                            // this.checkIfAvailable(text);
                            this.setState({ usernameInput: true, userName: this.formatUsername(text) }, () => { this.checkIfAvailable(text) })
                        }} />
                        <InfoButton text={kidInfo['userName'].text} />
                    </View>
                    {this.state.userName ? <View style={{ marginTop: 7, marginBottom: 5, alignSelf: 'flex-start', marginLeft: 20 }}>{this.state.available ? <Text style={{ color: Colors.green1 }}>Available</Text> :
                        <Text style={{ color: Colors.red }}>Not Available</Text>}</View> : null}
                    {this.row({ item: 'password' })}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <FloatingLabelInput style={{ borderColor: confirmPasswordInput ? Colors.gray : Colors.rose }}
                            secureTextEntry={true} label={kidInfo['confirmPassword'].label} value={this.state.confirmPassword}
                            onChangeText={text => { this.setState({ confirmPasswordInput: true, confirmPassword: (text) }) }} />
                        <InfoButton text={kidInfo['confirmPassword'].text} />
                    </View>
                    {this.row({ item: 'email' })}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <FloatingLabelInput label={kidInfo['age'].label} value={this.state.age} onChangeText={text => { this.setState({ age: this.formatAge(text) }) }} />
                        <InfoButton text={kidInfo['age'].text} />
                    </View>

                </View>

            </View>

            <View style={{ width: '100%', maxWidth: 381, paddingHorizontal: 35 }}>
                <Button title={'Proceed'} onPress={() => {
                    this.checkConditions() ? this.props.proceed() : null
                    this.props.kidData({ type: 'profile', data: { name, userName, password, email, age } });
                }} />
            </View></>)}
            {/* <View style={{justifyContent: "flex-end", flexDirection: 'row' }}>
			</View> */}
            {/* </View> */}
        </BlockWrapper>)

    }

}

const WithNavigationProfile = withNavigationFocus(CreateProfile);


class Profile extends React.Component {

    static navigationOptions = ({ navigation }) => {

        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        }
    }


    constructor(props) {
        super(props);
        this.scroll = React.createRef()
        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            section: 1,
            loading: false
        };
    };



    componentDidMount() {
        const { childIds } = this.props.parent;

        if (childIds.length > 0) {
            this.setState({ section: 2 })
        }

        console.log('didmount?')

        // this.scroll.current.scrollTo({ y: 0, animated: true })

        // kidClearAuth()
    }

    createCustomToken() {
        this.props.actions.createCustomToken()
            .then(res => console.log('cUSTOM TOKEM', res));
    }

    render() {
        // console.log('this.props.state', this.props.fullState)
        // if (!this.state.fontsLoaded) {
        // return <AppLoading />
        // }
        return (
            <Resizable>
                {layout => {
                    const vmin = Math.min(...[layout.width, layout.height]);
                    return <View style={{ height: '100%', flex: 1 }}>

                                {this.state.loading ? <View style={{
                                    position: 'absolute', zIndex: 1, backgroundColor: 'rgba(126, 193, 61, 0.5)',
                                    justifyContent: 'center',
                                    height: '100%', width: '100%'
                                }}>
                                    <ActivityIndicator color={Colors.green1} size={'large'} />
                                </View> : null}

                                <WithNavigationProfile navigation={this.props.navigation} layout={layout} auth={this.props.auth}
                                    parent={this.props.parent}
                                    back={() =>
                                        // this.setState({ section: this.state.section - 1 })
                                        this.props.navigation.goBack()
                                    }
                                    kidData={this.props.actions.kidData} proceed={() => {
                                        this.props.navigation.navigate('Allowance')
                                        this.setState({ section: this.state.section + 1 })
                                    }} />

                    </View>
                }}
            </Resizable>
        )
    }
}

function ProfileNew({ navigation, auth, parent, actions, children }) {
    const {
        window: { width, height, fontScale, scale },
        screen,
    } = useLayout();
    console.log('width, height, fontScale, scale ', width, height, fontScale, scale)
    console.log(screen)

    return <View></View>
    // <NewKid>
    //     <WithNavigationProfile navigation={navigation}
    //     layout={{width:1000, height:1000}} auth={auth}
    //                                 parent={parent}
    //                                 back={() =>
    //                                     // this.setState({ section: this.state.section - 1 })
    //                                     navigation.goBack()
    //                                 }
    //                                 kidData={actions.kidData} proceed={() => {
    //                                     navigation.navigate('Allowance')
    //                                     // this.setState({ section: this.state.section + 1 })
    //                                 }} />
    // </NewKid>
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(state => {
    // console.log(Object.keys(state.vacancies));
    return ({
        auth: state.auth,
        parent: state.parent,
        store: state.store,
    })
},
    (dispatch) => ({
        actions: bindActionCreators({ ...authActions, ...kidAuthActions, }, dispatch)
    })
)(Profile);
