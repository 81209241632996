/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
	ActivityIndicator,
	Platform,
	StyleSheet,
	View,
} from 'react-native';


import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import DatePicker from 'react-native-datepicker'


import * as ImagePicker from 'expo-image-picker';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';


import TouchableNativeFeedback from '../../components/TouchableFeedback'
import FloatingLabelInput from '../FloatingLabelInput/FloatingLabelInput'
import Text from '../../components/CustomText'
import { Button } from '../../components/Buttons'
import ParentComponentHeader from '../../components/ComponentHeader'
import { ConfirmationView, RejectionView } from '../../components/confirmation'
import { DropdownElement } from '../../components/Dropdown'

import * as chequingActions from '../../store/actions/parents/chequingActions'
import * as authActions from '../../store/actions/parents/authActions'

import {defaultStyles} from '../../constants/style'

import Colors from '../../constants/Colors';
import { expenseInfo } from '../../constants/info';

const auth = firebase.auth;
const database = firebase.database;

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const salaryFreqs = { 'Monthly': 1, 'Bi-weekly': 2, 'Weekly': 4, }
const salaryFreqsReversed = { 1: 'Monthly', 2: 'Bi-weekly', 4: 'Weekly', }
const salaryDays = {
    '1st of the month': 0, 'End of the month': 1,
    'Monday': 1, 'Tuesday': 2, 'Wednesday': 3, 'Thursday': 4, 'Friday': 5, 'Saturday': 6, 'Sunday': 0
}

function formatInputValue(str) {
	return (str || str === 0) ? str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".") : ''
}

function formatInterestRate(str) {
	console.log('str, ', str)
	return (str && str !== '%') ? parseFloat(str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".")) + '%' : ''
}


Date.prototype.yyyymmdd = function () {
	var mm = this.getMonth() + 1; // getMonth() is zero-based
	var dd = this.getDate();

	return [this.getFullYear(),
	(mm > 9 ? '' : '0') + mm,
	(dd > 9 ? '' : '0') + dd
	].join('-');
};

const iconMargin = (layout) => {
	const style = {}
	if (layout.width > 1600) return 30
	if (layout.width <= 1600 && layout.width > 800) return 10 + layout.width * 0.01
	return 10
}

function Information({ layout, text, visible, onPress }) {
	// let [visible, setVisible]= useState(true)
	// let visible1= this.state[value]

	return <React.Fragment>
		<TouchableNativeFeedback
			onPress={onPress}
			// onPress={() => { this.setState({[value]:true}); setVisible(!visible) }}
			style={{ position: 'absolute', left: iconMargin(layout), marginRight: 10, height: 20, width: 20, borderRadius: 15, justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.green1 }}>
			<View>
				<Text style={{ fontSize: 14, ...defaultStyles.bold, color: 'white' }}>i</Text>

			</View>
		</TouchableNativeFeedback>
		<View style={{
			opacity: 0.9,
			margin: 5, maxWidth: 200, borderRadius: 5, backgroundColor: Colors.green1, position: 'absolute',
			display: visible ? 'flex' : 'none', left: 0, bottom: 55, paddingHorizontal: 10, paddingTop: 5,
		}}>
			<Text style={{ color: 'white', marginHorizontal: 5, marginTop: 5, textAlign: 'center' }}>{text}</Text>
			<View style={{
				left: iconMargin(layout) - 15, bottom: -10, width: 10, borderTopWidth: 10, borderRightWidth: 10, borderBottomWidth: 0, borderLeftWidth: 10,
				borderTopColor: Colors.green1, borderRightColor: 'transparent', borderBottomColor: 'transparent', borderLeftColor: 'transparent',
			}}></View>
		</View>
	</React.Fragment>
}

const iconsVisible = ['interestRateVisible',]


class Settings extends React.Component {
	static navigationOptions = ({ navigation }) => {
		return {
			// headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
			headerTransparent: true,
			headerLeft: null,
			header: null
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			data: [],
			isLoading: true,
			isFocused: false,
			hasCameraPermission: null,
			//  image: '0',
			image: null,
			emailChange: false,
			nameChange: false,
			oldPassword: '',
			password: '',
			confirmPassword: '',
			id: 0,
			allowanceStart: (new Date()).yyyymmdd(),
			expence_title: '',
			expence_amount: 0,
			expence_frequency:'Weekly',
			day:'Friday',
			//  image: 'file:///data/user/0/host.exp.exponent/cache/ExperienceData/%2540dtsvetkov%252Fpennygembank/ImagePicker/cc28e74d-706e-4020-a1f0-00192ee0b38c.jpg',
			// type: Camera.Constants.Type.back,
		};
	};

	componentDidMount() {

		const { parentId, } = this.props.auth;
		const { childIds } = this.props.parent;

		// const settings = this.props.auth.settings
		// this.setState({ ...this.props.auth.settings[childIds[this.props.id]] })
		this.props.actions.getSettings(parentId, childIds[this.props.id])
			.then(settings => { console.log('settings!!', settings); this.setState({ ...settings, isLoading: false }) })
	}

	componentDidUpdate(prevProps) {
		if ((this.props.id) !== (prevProps.id)) {
			const { parentId } = this.props.auth;
			const {childIds} = this.props.parent

			// this.setState({ ...this.props.auth.settings[childIds[this.props.id]] })
			this.props.actions.getSettings(parentId, childIds[this.props.id])
				.then(settings => { console.log('settings!!', settings); this.setState({ ...settings }) })
		}
	}


	// rgba(126, 193, 61, 0.1)

	clearChildren() {
		const { parentId } = this.props.auth;

		database().ref('/users/' + parentId).remove();
		database().ref('/parents/' + parentId).remove();

		this.props.actions.getChildNodes(parentId)
	}

	changeDay(text) {
		console.log('text', text)

		if (text === 'Monthly') {
			console.log('MONTHLY')
			return this.setState({ day: 'End of the month' })
		}
		if (text !== 'none') {
			return this.setState({ day: 'Friday' })
		}
	}

	expence_row({info}) {
		return info?<View style={{width:'100%',flexDirection:'row', marginVertical:10,justifyContent:"space-around"}}><Text>Title: {info.title}</Text>
					 <Text>Amount: ${info.amount}</Text>
					 <Text>Frequency: {salaryFreqsReversed[info.frequency]}</Text>
				</View>:null
	}


	render() {
		const { layout, actions, id, settings } = this.props;

		const { parentId, } = this.props.auth;
		const { childIds, names, isLoading, } = this.props.parent;



		const vmin = Math.min(...[layout.width, layout.height]);
		let width = layout.width;
		const closeFontSize = Platform.OS === 'web' ? (width > 850 ? '2.25em' : '1.625em') : (40)
		// const {int_rate, withdraw_fee, withdraw_limit, min_balance, monthly_fee, salary_amt, salary_freq,
		//     transact_fee, transact_limit} = childIds[this.state.id]?settings[childIds[this.state.id]]:{}
		return (<View onStartShouldSetResponder={() => {
			this.setState({ ...iconsVisible.reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: false } }, {}) });
		}}>
			<View style={{
				opacity: 0.5, zIndex: 1, position: 'absolute', borderRadius: 15, display: this.state.isLoading ? 'flex' : 'none',
				backgroundColor: 'transparent', width: '100%', height: '100%',
			}}>
				<ActivityIndicator style={{ margin: 85, top: 10, alignSelf: 'center', display: this.state.isLoading ? 'flex' : 'none', top: 0.04 * vmin, }} size={vmin > 650 ? "large" : "small"} color={Colors.green1} ></ActivityIndicator>
			</View>
			{<View onLayout={e => { this.setState({ childPos: e.nativeEvent.layout.y }) }} style={{ opacity: this.state.isLoading ? 0.5 : 1, width: '100%' }}>

				<View style={{ flexWrap: 'wrap', width: '100%', alignItems: 'center', marginTop: 10 }}>


					<Text style={{
						fontSize: 20, marginTop: 20, alignSelf: 'flex-start', marginLeft: '10%',
						...defaultStyles.bold, color: '#7ec13d', marginBottom: 10
					}}>Savigns Account</Text>

					<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
						<Information onPress={() => {
							let obj = iconsVisible.reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: false } }, {})
							obj['interestRateVisible'] = true
							this.setState({ ...obj })
						}} visible={this.state.monthlyFeeVisible}
							layout={layout} text={'Monthly Fee'} />

						<FloatingLabelInput label={'Interest Rate'} value={formatInterestRate(this.state.int_rate)}
							onChangeText={text => this.setState({ int_rate: text })} />
					</View>
					<View style={{ margin: 5 }}></View>
					<View style={{ display: this.state.interestRateVisible ? 'flex' : 'none', width: '80%', padding: 10, borderRadius: 10, backgroundColor: 'rgba(126, 193, 61, 0.5)' }}>
						<Text style={{ textAlign: 'center', color: 'white' }}>Amount of interest due per period, as a proportion of the amount lent, deposited or borrowed (called the principal sum).</Text>
					</View>
					<Button styles={{ width: '80%', alignSelf: 'center', marginVertical: vmin * 0.05 }} textStyle={{ width: '100%', textAlign: 'center' }}
						title={'Save Settings'} onPress={() => {
							console.log('INT RATE,', this.state.int_rate)
							this.props.actions.updateSettings(parentId, childIds[id],
								{},
								{ int_rate: parseFloat((this.state.int_rate).replace('$', '')) / 100 }
							); this.props.navigation.navigate('Dashboard')
						}} />
				</View>

				<View style={{ flexWrap: 'wrap', width: '100%', alignItems: 'center', marginTop: 10 }}>
					<Text style={{ fontSize: 30, ...defaultStyles.bold, color: Colors.gray0, marginVertical: 0.04 * vmin }}>Account Allowance</Text>
					<FloatingLabelInput additionalSign={'$'} label={'Allowance Amount'}
						value={formatInputValue(this.state.salary_amt)} onChangeText={text => this.setState({ salary_amt: text })} />
					<View style={{ margin: 5 }}></View>
					{/* <FloatingLabelInput label={'Allowance Frequency'} value={this.state.salary_freq ?
													this.state.salary_freq.toString() : ''}
														onChangeText={text => this.setState({ salary_freq: text })} /> */}
					<DropdownElement containerStyle={{ zIndex: 2, width: '100%', alignSelf: 'center' }}
						onPress={(val) => { this.setState({ salary_freq: val }) }} defaultValue={this.state.salary_freq}
						categories={Object.keys(salaryFreqs)}></DropdownElement>
					<View style={{ width: '80%', paddingLeft: 20, }}>
						<Text style={{ alignSelf: 'flex-start', ...defaultStyles.bold, fontSize: 14, color: '#7ec13d' }}>Allowance Frequency</Text>
					</View>
					<View style={{ margin: 5 }}></View>
					{Platform.OS === 'web' ? <React.Fragment>
						<View style={{ width: '80%', marginTop: 10, alignItems: 'center', borderColor: Colors.green1, borderWidth: 2, borderRadius: 10, height: 40, justifyContent: 'center' }}>
							<input min={(new Date()).yyyymmdd()} onChange={(e) => { this.setState({ allowanceStart: e.target.value }) }}
								style={{ color: Colors.green1, width: '100%', background: 0, boxShadow: 'none', borderStyle: 'none', borderTopStyle: 'none', borderBottomStyle: 'none' }}
								type="date" value={this.state.allowanceStart} placeholder="YYYY DD MM" />
						</View>
					</React.Fragment> :
						<View style={{ width: '100%', height: 40, marginTop: 5, zIndex: 1, borderWidth: 2, borderRadius: 10, borderColor: Colors.green1 }}>
							<DatePicker
								style={{ marginLeft: 0, width: 220, }}
								date={this.state.allowanceStart}
								mode="date" placeholder="select date" format="YYYY-MM-DD"
								minDate={(new Date()).toISOString()}
								confirmBtnText="Confirm" cancelBtnText="Cancel"
								showIcon={false}
								customStyles={{
									dateInput: { alignSelf: 'center', marginRight: '5%', borderWidth: 0 }, dateText: { color: Colors.green1 },
									// ... You can check the source to find the other keys.
								}}
								onDateChange={(date) => { this.setState({ jobDeadline: date }) }}
							/>
						</View>}

					<View style={{ width: '80%', paddingLeft: 20, }}>
						<Text style={{ alignSelf: 'flex-start', ...defaultStyles.bold, fontSize: 14, color: '#7ec13d' }}>Allowance Frequency</Text>
					</View>
				</View>


				<View style={{ zIndex: -11, }}>
					<Button styles={{ width: '80%', alignSelf: 'center', marginVertical: vmin * 0.05 }} textStyle={{ width: '100%', textAlign: 'center' }}
						title={'Save Allowance Changes'} onPress={() => {
							if (parseFloat(this.state.salary_amt.toString().replace(/\D/g, ''))) {
								this.props.actions.updateAllowance(parentId, childIds[id],
									{ salary_amt: this.state.salary_amt, salary_freq: this.state.salary_freq, allowanceStart: this.state.allowanceStart }
								).then(() => {
									let newDate = new Date()
									newDate.setMonth(newDate.getMonth() - 1);
									this.props.actions.getChequing(newDate, parentId, childIds[id], this.props.navigation)
								});; this.props.navigation.navigate('Dashboard');

								this.props.actions.sendAllowanceJob(parentId, childIds[id],
									{
										salary_amt: this.state.salary_amt, salary_freq: this.state.salary_freq,
										allowanceStart: this.state.allowanceStart
									})
							}
							else {
								this.props.navigation.navigate('Dashboard');
							}
						}} />
				</View>

				<View style={{ flexWrap: 'wrap', width: '100%', alignItems: 'center', marginTop: 10 }}>
					<Text style={{ fontSize: 30, ...defaultStyles.bold, color: Colors.gray0, marginVertical: 0.04 * vmin }}>Account Expences</Text>
					{this.state.expences?<View style={{width:'80%'}}>{Object.values(this.state.expences).map(item=>this.expence_row({info:item.info}))}</View>:<Text>No expences</Text>}
					<Text style={{ fontSize: 30, ...defaultStyles.bold, color: Colors.gray0, marginVertical: 0.04 * vmin }}>Add New Expence</Text>

					<FloatingLabelInput label={'Expence Title'} value={this.state.expence_title} onChangeText={text => this.setState({ expence_title: text })} />
					<View style={{ margin: 5 }} />
					<FloatingLabelInput additionalSign={'$'} label={'Expence Amount'}
						value={formatInputValue(this.state.expence_amount)} onChangeText={text => this.setState({ expence_amount: text })} />
					<View style={{ margin: 5 }}></View>
					<View style={{ width: '80%', flexDirection: 'row', justifyContent: 'center', zIndex: 2, alignItems: 'center' }}>
						<DropdownElement dropdownStyle={{ width: '100%' }} containerStyle={{ zIndex: 2, width: '100%', }}
							mainElementStyle={{ width: '100%' }} defaultValue={this.state.expence_frequency}
							onPress={(text) => { this.changeDay(text); this.setState({ expence_frequency: text, }) }} categories={['Weekly', 'Bi-weekly', 'Monthly']} />
						{/* <InfoButton text={expenseInfo.frequency.text} /> */}
					</View>
					<View style={{width:'80%', left:20}}><Text style={{ color: Colors.green1, marginTop: 2, ...defaultStyles.bold, fontSize: 14 }}>{expenseInfo.frequency.label}</Text></View>
					<View style={{  width: '80%',flexDirection: 'row', justifyContent: 'center', alignItems: 'center', zIndex: 1, }}>
						<DropdownElement dropdownStyle={{ width: '100%', zIndex: 1, maxHeight: 200 }} containerStyle={{ zIndex: 1, width: '100%', marginHorizontal: 0 }}
							mainElementStyle={{ width: '100%' }} onPress={(text) => this.setState({ day: text })} defaultValue={this.state.day}
							categories={this.state.expence_frequency === 'Monthly' ? ['1st of the month', 'End of the month'] :
								['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
							} />
						{/* <InfoButton text={expenseInfo.day.text} /> */}
					</View>
					<View style={{width:'80%', left:20}}><Text style={{ color: Colors.green1, marginTop: 2, ...defaultStyles.bold, fontSize: 14 }}>{expenseInfo.day.label}</Text></View>
				</View>

				<View style={{ zIndex: -11, }}>
					<Button styles={{ width: '80%', alignSelf: 'center', marginVertical: vmin * 0.05 }} textStyle={{ width: '100%', textAlign: 'center' }}
						title={'Create Expence'} onPress={() => {
							const { parentId, childIds, id } = this.props.auth;
							const { expence_amount, expence_title, expence_frequency, day } = this.state;
							expence_amount && expence_title ?
							this.props.actions.createExpenceSchedule({ parentId, childId:childIds[id], amount:expence_amount, title:expence_title, frequency:salaryFreqs[expence_frequency], day:salaryDays[day] })
							:null
							// console.log('shedule!', schedule, body)
						}} />
				</View>

			</View>}
		</View>
		)
	}
}

const styles = StyleSheet.create({

});

export default Settings;
// export default connect(state => {
//     // console.log(Object.keys(state.vacancies));
//     return ({
//         auth: state.auth,
//     })
// },
//     (dispatch) => ({
//         actions: bindActionCreators({ ...authActions, ...chequingActions }, dispatch)
//     })
// )(Settings);
