/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components'
import {
	Animated,
	Image,
	Platform,
	ScrollView,
	StyleSheet,
	View,
} from 'react-native';

import './JobStyle.css';

import 'firebase/database';

import DatePicker from 'react-native-datepicker'
import Icon from 'react-native-vector-icons/FontAwesome';


import { dropdown, handleScroll, DropdownElement } from '../Dropdown';

import Text from '../CustomText'
import TouchableNativeFeedback from '../TouchableFeedback';
import FloatingLabelInput from '../FloatingLabelInput/FloatingLabelInput';

import Colors from '../../constants/Colors';
import globalStyle, {defaultStyles} from '../../constants/style';

export const WebDatepicker = styled.input.attrs((props) => ({
	min: (new Date()).yyyymmdd(),
	onChange: props.onChange,
	value:props.jobDeadline ? props.jobDeadline : '',
	onFocus: () => { console.log('sdfsdf'); }, placeholder: 'Select Date', type: 'date', tabIndex: 0
}))`
user-select: none;
outline:none;
font-family: Carmen Sans Regular;
color: ${Colors.gray1};
font-size:${props => props.jobDeadline ? '15px' : '0px'};
height:40px;
padding-left:20px;
width: 100%;
background: 0;
-webkit-appearance: none;
box-shadow: none;
border-style: none;
border-top-style: none;
border-bottom-style: none;
&::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}
::selection {
	color: red;
	background: yellow;
  }

`

const descriptionRef = React.createRef()


const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default


const jobFreqs = { 'None': 0, 'Daily': 1, 'Weekly': 2, 'Bi-weekly': 3, 'Monthly': 4 }

Date.prototype.yyyymmdd = function () {
	var mm = this.getMonth() + 1; // getMonth() is zero-based
	var dd = this.getDate();

	return [this.getFullYear(),
	(mm > 9 ? '' : '0') + mm,
	(dd > 9 ? '' : '0') + dd
	].join('-');
};

function formatInputValue(str) {
	return (str || str === 0) ? str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".") : ''
}

function getDateFromStr(str) {
	if (str) {
		let tmp = str.split('-')
		let today = new Date()
		// return new Date(tmp[0], tmp[1] - 1, tmp[2])
		return Date(str).valueOf() + today.getTimezoneOffset() * 60 * 1000

	}
	else return new Date()
}

function SelectedChild({ title, onPress }) {
	return <View style={{
		// margin: 5,
		alignItems: 'center', flexDirection: 'row', justifyContent: 'center', padding: 5, borderRadius: 10,
		// backgroundColor: Colors.green1
	}}>
		<View><Text style={{ color: Colors.gray1 }}>{title}</Text></View>
		<TouchableNativeFeedback onPress={onPress}>
			<View style={{
				opacity: 0.7,
				marginHorizontal: 5, width: 18, height: 18, borderRadius: 10, marginLeft: 5, backgroundColor: Colors.rose,
				justifyContent: 'center', alignItems: 'center', paddingLeft:1,
			}}><Text style={{ color: 'white', textAlign:'center', alignSelf:'center' }}>x</Text></View>
		</TouchableNativeFeedback>
	</View>
}


function AddJob({ jobCategory, setJobCategory, jobValue, setJobValue, jobTitle, setJobTitle, jobDeadline, setJobDeadline,
	jobStopRepeat, setStopRepeat,
	jobFrequency, setJobFrequency, checkNewJobConditions, layout, kids, setKid, removeKid, names }) {
	let today = new Date()
	const [childrenVisible, setChildrenVisible] = useState(false)
	const [blockHeight, setBlockHeight] = useState(40)

	return <View>
		<View style={{
			flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '10%', marginBottom:'2%',
		}}>
				<Text style={[{color: Colors.black }, globalStyle.h4, defaultStyles.bold]}>Create Job</Text>
		</View>

		<View style={{ width: '100%', alignSelf: 'flex-start', justifyContent: 'flex-start', alignItems: 'center' }}>
			<DropdownElement containerStyle={{ zIndex: 4, width: '100%', alignSelf: 'center' }}
				onPress={setJobCategory} defaultValue={jobCategory}
				categories={['Chores', 'Education', 'Arts', 'Saving', 'Other',]}></DropdownElement>
			<View style={{ alignSelf: Platform.OS !== 'web' ? 'flex-start' : 'center', width: '80%' }}>
				<Text style={{marginTop:3, alignSelf: 'flex-start', marginLeft: 20, fontSize: 14, color: Colors.gray1 }}>Category</Text></View>
			<FloatingLabelInput additionalSign={'$'} label={'Payment'} value={formatInputValue(jobValue)}
				onChangeText={setJobValue}
				onSubmitEditing={() => { descriptionRef.current.focus() }} />
			<FloatingLabelInput inputRef={descriptionRef} label={'Description'} value={jobTitle}
				onChangeText={setJobTitle} />

			<DropdownElement containerStyle={{ zIndex: 3, width: '100%', alignSelf: 'center' }}
				onPress={setJobFrequency} defaultValue={jobFrequency}
				categories={Object.keys(jobFreqs)} dropdownStyle={{ maxHeight: layout.height / 5 }} />

			<View style={{ alignSelf: Platform.OS !== 'web' ? 'flex-start' : 'center', width: '80%' }}>
				<Text style={{marginTop:3, alignSelf: 'flex-start', marginLeft: 22, fontSize: 14, color: Colors.gray1 }}>Frequency</Text></View>

			{jobFreqs[jobFrequency] ?
				<React.Fragment>
					<View style={{ width: '80%', marginTop: 10, alignItems: 'center', borderColor: Colors.gray, borderWidth: 2, borderRadius: 20, height: 40, justifyContent: 'center' }}>
						<WebDatepicker jobDeadline={jobStopRepeat}
							onChange={e => { setStopRepeat(e.target.value) }} />
						<label htmlFor="myInput"><span style={{
							display: jobStopRepeat ? 'none' : 'flex', zIndex: -1, position: 'absolute',
							top: 10, left: 20, color: Colors.gray1,fontFamily: 'Carmen Sans Regular'
						}} className="place-holder">Enter a date</span></label>
					</View>
					<View style={{ alignSelf: Platform.OS !== 'web' ? 'flex-start' : 'center', width: '80%' }}>
						<Text style={{marginTop:3, alignSelf: 'flex-start', marginLeft: 20, fontSize: 14, color: Colors.gray1 }}>When to stop recurrence</Text></View>
					<View style={{
						marginTop: 3,
						opacity: getDateFromStr(jobStopRepeat) < today.setHours(0, 0, 0, 0) ? 1 : 0,
						display: getDateFromStr(jobStopRepeat) < today.setHours(0, 0, 0, 0) ? "flex" : "none"
					}}><Text style={{ color: Colors.red }}>Please select correct date</Text></View>

				</React.Fragment>
				:
				<React.Fragment>
					<View style={{ width: '80%', marginTop: 10, alignItems: 'center', borderColor: Colors.gray, borderWidth: 2, borderRadius: 20, height: 40, justifyContent: 'center' }}>
						<WebDatepicker jobDeadline={jobDeadline}
							onChange={e => { console.log('e.target.value', e.target.value, getDateFromStr(e.target.value), new Date().setHours(0, 0, 0, 0)); setJobDeadline(e.target.value) }} />
						<label htmlFor="myInput"><span style={{
							display: jobDeadline ? 'none' : 'flex', zIndex: -1, position: 'absolute',
							top: 10, left: 20, color: Colors.gray1, fontFamily: 'Carmen Sans Bold'
						}} className="place-holder">Enter a date</span></label>
					</View>
					<View style={{ alignSelf: Platform.OS !== 'web' ? 'flex-start' : 'center', width: '80%' }}>
						<Text style={{marginTop:3, alignSelf: 'flex-start', marginLeft: 22,fontSize: 14, color: Colors.gray1}}>By when to complete job</Text></View>
					<View style={{
						marginTop: 3,
						opacity: getDateFromStr(jobDeadline) < today.setHours(0, 0, 0, 0) ? 1 : 0,
						display: getDateFromStr(jobDeadline) < today.setHours(0, 0, 0, 0) ? "flex" : "none"
					}}><Text style={{ color: Colors.red }}>Please select correct date</Text></View>

				</React.Fragment>}



			{/* OMG SELECT SHILD BLOCK */}
			<TouchableNativeFeedback style={{ zIndex: 2, width: '80%', }} onPress={() => setChildrenVisible(!childrenVisible)}>
				{/* <View style={{ }}> */}
				<View onLayout={e => { console.log('eeeee', e); setBlockHeight(e.nativeEvent.layout.height) }} style={{
					marginTop: 10, minHeight: 40, borderWidth: 2, borderColor: Colors.gray, borderRadius: 20, justifyContent: 'center',
					borderBottomLeftRadius: childrenVisible ? 0 : 20, borderBottomRightRadius: childrenVisible ? 0 : 20,
					// borderBottomLeftRadius: childrenVisible?0:10, borderBottomRightRadius: childrenVisible?0:10,
					alignItems: 'center'
				}}>
					<View style={{ width: '100%', flexWrap: 'wrap', display: 'flex', flexDirection: 'row',paddingLeft: 18, }}>
						{Object.keys(kids).filter(item => kids[item]).length ?
							Object.keys(kids).map((item, index) => {
								return kids[item] ? <SelectedChild key={index} title={names[item]} onPress={() => { removeKid(item) }} /> : null
							})
							: <Text style={{ marginLeft: '5%', color: Colors.gray1 }}>Please select children</Text>}
					</View>
					<View style={[{
						right: '5%', width: 0, height: 0, position: 'absolute', backgroundColor: 'transparent', borderStyle: 'solid',
						borderTopWidth: 10, borderRightWidth: 5, borderBottomWidth: 0, borderLeftWidth: 5,
						borderTopColor: Colors.gray1, borderRightColor: 'transparent', borderBottomColor: 'transparent', borderLeftColor: 'transparent',
					}]}></View>
					<View style={{
						flex: 1,
						borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderColor: Colors.gray1,
						backgroundColor: 'white', width: '100%', zIndex: 2, position: 'absolute', top: '98%', borderWidth: 2,
						display: childrenVisible ? 'flex' : 'none', marginBottom: 10,
					}}>
						{Object.keys(kids).map((item, index) => {
							return <TouchableNativeFeedback key={index} onPress={() => setKid(item)}>
								<View style={{ justifyContent: 'flex-start', flexDirection: 'row', marginVertical: 5, alignItems: 'center' }}>
									<View style={{ marginLeft: 10, borderWidth: 2, borderRadius: 5, borderColor: Colors.gray1, }}>
										<Icon style={{ zIndex: 3, opacity: kids[item] ? 1 : 0 }} name="check" size={16} color={Colors.gray1} />
									</View>
									<Text style={{ marginLeft: 10, color: Colors.gray1 }}>{names[item]}</Text></View>
							</TouchableNativeFeedback>
						})}
						{/* {[1,2,3,4,5,6,7].map(item=><Text style={{margin:10}}>qweqwe</Text>)} */}
					</View>
				</View>
				{/* </View> */}
			</TouchableNativeFeedback>

			<View style={{ alignSelf: Platform.OS !== 'web' ? 'flex-start' : 'center', width: '80%' }}>
				<Text style={{ marginTop:3,
					alignSelf: 'flex-start', marginLeft: 22, fontSize: 14,
					color: Colors.gray1
				}}>Select children</Text></View>

			<View style={{ width: '100%' }}>
				<TouchableNativeFeedback onPress={checkNewJobConditions}>
					<View style={{
						width: '80%', alignSelf: 'center', marginTop: 30, marginBottom: 30, backgroundColor: Colors.green1, height: 40, borderRadius: 20,
						justifyContent: 'center', alignItems: 'center'
					}}>
						<Text style={[{ color: 'white' }, globalStyle.h4]}>{'Create Job'.toUpperCase()}</Text>
					</View>
				</TouchableNativeFeedback>
			</View>
		</View>
	</View>
}


const styles = StyleSheet.create({

});

export default AddJob
