/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
    ActivityIndicator,
    Animated,
    Dimensions,
    Image,
    ImageBackground,
    StyleSheet,
    View,
    Platform,
    TextInput,
    TouchableOpacity,
    StatusBar,
} from 'react-native';

import { useSelector, useDispatch } from 'react-redux'

import firebase from 'firebase/app';
import 'firebase/database';

import { getChequing } from '../../store/actions/parents/chequingActions';

import TouchableNativeFeedback from '../../components/TouchableFeedback'
import Text from '../../components/CustomText'
import CounterTextInput from '../../components/CounterTextInput'

import ModalContainer from '../../components/Modal/Modal'

import Colors from '../../constants/Colors'
import { defaultStyles } from '../../constants/style'

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const database = firebase.database;
const buttonSize = 80

function formatInputValue(str) {
    return (str || str === 0) ? str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".") : ''
}

async function deposit({ parentId, childId, transfer }) {
    var balance_ref = database().ref('/users/' + parentId + '/' + childId + '/chequing/balance');

    database().ref('/users/' + parentId + '/' + childId + '/chequing/transactions').push({
        amount: parseFloat(transfer.replace('$', '')),
        transaction_date: (new Date()).toISOString(),
        type: 'parent deposit'
    })

    const snapshot = await balance_ref.once('value')
    let old_balance = snapshot.val()

    await database().ref().child('users').child(parentId).child(childId).child('chequing')
        .update({ balance: parseFloat(old_balance) + parseFloat(transfer.replace('$', '')) });

    return 0
}

async function withdrawal({ parentId, childId, transfer }) {
    console.log('parentId, childId, transfer ', parentId, childId, transfer)
    var balance_ref = database().ref('/users/' + parentId + '/' + childId + '/chequing/balance');

    database().ref('/users/' + parentId + '/' + childId + '/chequing/transactions').push({
        amount: parseFloat(transfer.toString().replace('$', '')),
        transaction_date: (new Date()).toISOString(),
        type: 'parent withdrawal'
    })

    const snapshot = await balance_ref.once('value')
    let old_balance = snapshot.val()

    await database().ref().child('users').child(parentId).child(childId).child('chequing')
        .update({ balance: parseFloat(old_balance) - parseFloat(transfer.toString().replace('$', '')) });

    return 0
}

const TransferBlock = ({ title, transfer, label, setTransfer, setModalVisible, interval, update }) => {
    const dispatch = useDispatch()
    const parentId = useSelector(state => state.auth.parentId)
    const { childId } = useSelector(state => state.child)

    // console.log('parentId, childIds, names, id ',parentId, childIds, names, id)

    return <React.Fragment>
        <View style={{ justifyContent: 'center', marginTop: '5%', marginBottom: 20, }}>
            <Text style={{ textAlign: 'center', fontFamily: 'Carmen Sans Bold' }}>{title}</Text>
        </View>
        <View style={{ width: '100%', alignSelf: 'flex-start', justifyContent: 'flex-start', alignItems: 'center' }}>
            {/* <View style={{width:'100%', alignItems:'center', justifyContent:'center'}}> */}
            <CounterTextInput label={label}
                onPressUp={() => setTransfer('$' + (parseInt(transfer.toString().replace('$', '')) + 1))}
                onPressDown={() => parseInt(transfer.toString().replace('$', '')) > 0 ? setTransfer('$' + (parseInt(transfer.toString().replace('$', '')) - 1)) : null}
                centered={true} value={transfer ? '$' + formatInputValue(transfer) : ''}
                keyboardType={'numeric'}
                onChangeText={text => setTransfer(text || text !== '$' ? ('$' + text.toString().replace('$', '')) : (''))} />

            <View style={{ width: '100%' }}>
                <TouchableNativeFeedback onPress={() => {
                    if (transfer) {
                        console.log('123')
                        setModalVisible(false);
                        update({ parentId, childId: childIds[id], transfer })
                            .then(() => { dispatch(getChequing(interval, parentId, childIds[id])) })
                    }
                    console.log('431')

                }}>
                    <View style={{
                        boxShadow: '-5px 5px 15px 5px rgba(126, 193, 61, 0.5)',
                        width: '80%', alignSelf: 'center', marginBottom: 30, backgroundColor: Colors.green1, height: 40, borderRadius: 20,
                        justifyContent: 'center', alignItems: 'center'
                    }}>
                        <Text style={{ fontSize: 25, color: 'white' }}>Transfer</Text>
                    </View>
                </TouchableNativeFeedback>
            </View>
        </View>
    </React.Fragment>
}

const TransfersCard = ({ transfersVisible, setTransfersVisible, interval }) => {
    const [depositChequing, setDepositChequing] = useState(false)
    const [withdrawChequing, setWithdrawChequing] = useState(false)
    const [transfer, setTransfer] = useState(0)

    return <React.Fragment>
        <ModalContainer visible={depositChequing} setVisible={setDepositChequing} onClose={() => setDepositChequing(false)}>
            <TransferBlock title={'Transfer to Chequing'} label={'Amount to Transfer'} update={deposit}
                interval={interval} transfer={transfer} setTransfer={setTransfer} setModalVisible={setDepositChequing} />
        </ModalContainer>
        <ModalContainer visible={withdrawChequing} setVisible={setWithdrawChequing} onClose={() => setWithdrawChequing(false)}>
            <TransferBlock title={'Transfer to Savings'} label={'Amount to Transfer'} update={withdrawal}
                interval={interval} transfer={transfer} setTransfer={setTransfer} setModalVisible={setWithdrawChequing} />
        </ModalContainer>
        {(transfersVisible) && <View style={{
            boxShadow: '0 0 15px 15px rgba(76, 76, 76, 0.3)',
            zIndex: 2, backgroundColor: 'white',
            position: 'absolute', bottom: buttonSize / 2 + 10, borderRadius: 15,
            backgroundColor: 'rgba(240,240,240,1)', alignSelf: 'center',
            width: '80%', maxWidth: 300, paddingTop: 10, paddingBottom: 35,
            alignItems: "center"
        }}>
            {[{ text: 'Transfer to Chequing', setVisible: setDepositChequing }, { text: 'Transfer to Savings', setVisible: setWithdrawChequing }]
                .map((item, index) => <TouchableNativeFeedback onPress={() => item.setVisible(false)} style={Platform.OS === 'web' ? { width: '100%', alignItems: 'center' } : {}}>
                    <View key={index} style={{ width: '90%', marginBottom: 10, borderRadius: 10, backgroundColor: 'white' }}>
                        <Text style={{ fontFamily: 'Carmen Sans Bold', marginLeft: 10, marginVertical: 3 }}>{item.text}</Text></View></TouchableNativeFeedback>)}
        </View>}
    </React.Fragment>
}

const ParentTransferButtonMobile = ({ onPress, parentId, childId, navigation }) => {
    // const [focused, setFocused] = useState(1);
    const [transfersVisible, setTransfersVisible] = useState(false);
    const inputRef = React.useRef()
    const focused = inputRef.current && inputRef.current.isFocused()

    const positionStyle = { position: 'absolute', borderWidth: 1, bottom: 10, left: 0, right: 0, alignItems: 'center', zIndex: 2 }
    return <React.Fragment>
        {(transfersVisible) && <View style={{
            zIndex: 2,
            position: 'absolute', bottom: 25,
            borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
            maxWidth: 400, minHeight: 250
        }}>
            {/* <Text>plsplsplsplsplsplsplsplsplspls</Text> */}
        </View>}
        <View onPress={() => { console.log('321') }} style={positionStyle}>
            <ImageBackground onPress={() => { console.log('321') }} resizeMode={'contain'} style={{
                height: 50, width: 50,
                borderRadius: 25,
                backgroundColor: Colors.navy
                // height: vmin * 0.5, maxHeight: 50, width: vmin * 0.15,
            }} source={require('../../assets/Icons/Transfer.png')}>
                <View style={{ borderWidth: 2, height: '100%' }} onBlur={() => { console.log('TOUCH AQWAT') }}
                    onStartShouldSetResponder={() => { console.log('sdfsdf'); setTransfersVisible(!transfersVisible) }}>
                    <TextInput style={{  zIndex: -1, borderColor: 'green' }}
                        // onFocus={() => {console.log('123123123123'); setTransfersVisible(true); inputRef.current.blur()}}
                        onBlur={() => setTransfersVisible(false)}
                        ref={inputRef} caretHidden={true} showSoftInputOnFocus={false} />
                </View>
            </ImageBackground>
            {/* <Image resizeMode={'contain'} style={{
            height: 50, width: 50,
            backgroundColor: Colors.navy
            // height: vmin * 0.5, maxHeight: 50, width: vmin * 0.15,
        }} source={require('../../assets/Icons/Transfer.png')} /> */}
        </View>

    </React.Fragment>
}

const ParentTransferButtonWeb = ({ onPress, parentId, childId, navigation, interval }) => {
    const [focused, setFocused] = useState(1);
    const [transfersVisible, setTransfersVisible] = useState(false);
    const [depositChequing, setDepositChequing] = useState(false);

    const inputRef = React.useRef()

    const positionStyle = { position: 'absolute', bottom: 10, left: 0, right: 0, alignItems: 'center', zIndex: 2 }
    return <React.Fragment>
        {<TransfersCard transfersVisible={transfersVisible} interval={interval} setTransfersVisible={setTransfersVisible} />}
        <View style={positionStyle}>
            <TouchableNativeFeedback onPress={() => setTransfersVisible(!transfersVisible)} onBlur={() => setTransfersVisible(false)}>
                <View style={{ boxShadow: '-5px 5px 20px 7px rgba(126, 193, 61, 0.6)', justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.green1, height: buttonSize, width: buttonSize, borderRadius: '50%' }}>
                    <Image resizeMode={'contain'} onPress={() => { console.log('sdf'); setTransfersVisible(true) }} style={{
                        height: 55, width: 55,
                        // height: vmin * 0.5, maxHeight: 50, width: vmin * 0.15,
                    }} source={require('../../assets/Icons/Transfer.png')} />
                </View>
            </TouchableNativeFeedback>
        </View>

    </React.Fragment>
}



export default Platform.OS === 'web' ? ParentTransferButtonWeb : ParentTransferButtonMobile