/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components'
import {
	Animated,
	Dimensions,
	Image,
	ImageBackground,
	Platform,
	StyleSheet,
	View,
	ScrollView,
	TouchableOpacity,
} from 'react-native';

import {} from "react-redux";

import moment from "moment";
import { ConfirmationModal, ConfirmationView } from "../confirmation";

import NewJob from './NewJob';
import Colors from '../../constants/Colors';
import ModalContainer from '../Modal/Modal';
import { getDashboardData } from '../../helpers/dashboard/getDashboardData';

const jobFreqs = { 'None': 0, 'Daily': 1, 'Weekly': 2, 'Bi-weekly': 3, 'Monthly': 4 }
const jobFreqsRevese = ['None', 'Daily', 'Weekly', 'Bi-weekly', 'Monthly']
const jobCategories = { 'posted': 'data', 'pending': 'pending', 'completed': 'completed' }

function checkDate(date) {
	const today = new Date()
	return (new Date(date).valueOf() + today.getTimezoneOffset() * 60 * 1000) >= today.setHours(0, 0, 0, 0)
}

class NewJobModal extends React.Component {
	static navigationOptions = ({ navigation }) => {

		return {
			// headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
			headerTransparent: true,
			header: null,
		}
	}

	constructor(props) {
		super(props);
		this.descriptionRef = React.createRef()
		this.dateRef = React.createRef()

		this.state = {
			data: {},
			modalVisible: true,
			id: 0,
			jobValue:'',
			jobCategory: 'Chores',
			jobFrequency: ' None',
			jobTitle: '',
			isLoading: true,
			jobKids: {},
			jobStopRepeat: ''
			// addJobModal:true
		};
	};

	componentDidMount() {
		const { childIds, id, } = this.props.parent;

		this.setState({ jobKids: childIds.reduce((curr, item) => { return { ...curr, [item]: false, [childIds[id]]: true } }, {}) })
	}

	getKidsString() {
		const { names } = this.props.parent;
		let tmp = Object.keys(this.state.jobKids).filter(item => this.state.jobKids[item]).map(item => names[item])
		return tmp.join(', ')
	}

	parseInt(value) {
		return value !== '' ? parseInt(value) : 0
	}

	render() {
		const { visible, onClose, layout, names, parentId, updateDashboard, parent } = this.props;
		const {childIds, id} = parent;
		const { confirmation, jobCategory, jobValue, jobTitle, jobDeadline, jobFrequency, jobStopRepeat, jobKids } = this.state;

		return (<ModalContainer visible={visible} onClose={() => onClose()}>
			{confirmation ?
				<ConfirmationView image={require('../../assets/Jobs/Jobs_Learning.png')} title={'Add Job'}
					subtitles={[this.getKidsString(),
						jobTitle, '$' + jobValue.replace(/\D/, ''), 'Category: ' + jobCategory,
					'Frequency: ' + jobFrequency,
					jobFreqs[jobFrequency] ? ('Stop recurrence: ' + jobStopRepeat) : ('Deadline: ' + jobDeadline)]}
					confirmPress={() => {
						this.props.createJob(parentId, Object.keys(jobKids).filter(item => jobKids[item]), jobTitle, jobValue.replace(/\D/, ''), jobDeadline, jobCategory, jobFreqs[jobFrequency], jobStopRepeat)
							.then(() => {
								updateDashboard({parentId, childId: childIds[id] })
								this.setState({ jobCategory: 'Chores', jobFrequency: 'None', jobTitle: '', jobValue: '', jobDeadline: '', }) })
						this.setState({ confirmation: false, addJobModal: false })
						onClose()
					}}
					rejectPress={() => { this.setState({ jobCategory: 'Chores', jobFrequency: 'None', jobTitle: '', jobValue: '', jobDeadline: '', confirmation: false, addJobModal: false }) }}
				/>
				:
				<NewJob jobCategory={jobCategory} setJobCategory={text => this.setState({ jobCategory: text })}
					jobValue={jobValue} setJobValue={text => this.setState({ jobValue: text.replace(/\D/,'') })}
					jobTitle={jobTitle} setJobTitle={text => this.setState({ jobTitle: text })}
					jobDeadline={jobDeadline} setJobDeadline={text => this.setState({ jobDeadline: text })}
					jobFrequency={jobFrequency} setJobFrequency={text => this.setState({ jobFrequency: text })}
					jobStopRepeat={jobStopRepeat} setStopRepeat={t => this.setState({ jobStopRepeat: t })}
					layout={layout}
					kids={jobKids}
					setKid={(item) => { this.setState({ jobKids: { ...this.state.jobKids, [item]: !this.state.jobKids[item] } }) }}
					removeKid={(item) => { this.setState({ jobKids: { ...this.state.jobKids, [item]: false } }) }}
					names={names}
					checkNewJobConditions={() => {
						let todayMomemt = moment().startOf('day');
						const deadline = moment(this.state.jobDeadline).endOf('day');
						// console.log(deadline);
						(jobValue.replace(/\D/, '') && jobTitle &&
							// new Date(this.state.jobDeadline) >= today.setHours(0, 0, 0, 0)
							((!jobFreqs[jobFrequency] && jobDeadline && checkDate(jobDeadline)) || (jobFreqs[jobFrequency] && jobStopRepeat && checkDate(jobStopRepeat)))
							&&
							deadline.diff(todayMomemt, 'days') >= 0
							&&
							(Object.keys(jobKids).filter(item => jobKids[item]).length)
						) ? this.setState({ confirmation: true }) : null
					}}
				/>}
		</ModalContainer >
		)
	}
}

const styles = StyleSheet.create({

});

export default NewJobModal
