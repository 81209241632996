/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import {
  Platform,
  StyleSheet,
  View,
} from 'react-native';


import Text from '../components/CustomText'
import TouchableNativeFeedback from '../components/TouchableFeedback';
import Colors from '../constants/Colors';
import { defaultStyles } from '../constants/style';


class ParentComponentHeader extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data:{},
      modalVisible: true
    };
  };


  componentDidMount() {
  }

  componentWillUnmount() {
    // Remove the event listener
    // this.focusListener.remove();
  }

  defineValue(width, height, a, b, c) {
    if (width<850 && width<height) {return a}
    else if (width<850 && width> height) {return b}
    else {return c}
  }

  // boxShadow:'2px 2px 15px #272827',
  render() {
    const {navigation, auth, layout, title, theme, containerStyle} = this.props;
    const vmin = Math.min(...[layout.width,layout.height]);
    const titleColor = theme==='light'?Colors.green1:'white'
    return (<View style={[{flexDirection:'row',  maxWidth:550,alignSelf:'center', justifyContent:'space-around',
    marginTop: this.defineValue(layout.width,layout.height,0.2*vmin,0.1*vmin,0.1*vmin),
    marginBottom:  this.defineValue(layout.width,layout.height,0.08*vmin,0.04*vmin,0.04*vmin),},containerStyle]}>
        <TouchableNativeFeedback onPress={()=>this.props.pressLeft()}>
            <View style={{backgroundColor:'rgba(126,193,61,1)', height:33, width:33,borderRadius:30,
                    justifyContent:'center', alignItems:'center'}}>
                <Text style={[{ textAlign:'center', color:'white'}, defaultStyles.bold]}>{'<'}</Text>
            </View>
        </TouchableNativeFeedback>
            <Text style={{ width:layout.width*0.7, alignSelf: 'center', ...defaultStyles.bold,
            textAlign:'center',
                fontSize: Platform.OS==='web'?(layout.width>850?'1.75em':'1.375em'):30,color: titleColor }}>
                    {title}</Text>
        <TouchableNativeFeedback onPress={()=>this.props.pressRight()}>
            <View style={{backgroundColor:'rgba(126,193,61,1)', height:33, width:33,borderRadius:30,
                justifyContent:'center', alignItems:'center'}}>
            <Text style={[{ textAlign:'center', color:'white'}, defaultStyles.bold]}>{'>'}</Text>
            </View>
        </TouchableNativeFeedback>
    </View>
    )
  }
}

const styles = StyleSheet.create({

});

export default ParentComponentHeader