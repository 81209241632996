import * as actionTypes from './actionTypes'
// import {fetchWrapper} from "../lib/fetchWrapper";
import {AsyncStorage} from "react-native";
import { database, functions } from 'firebase';
import config from '../../../constants/config';

// const parentId = 'XcbB7fIw6pgbjoqxhmb9FwZb4BL2';
// const childId = 'coMBDNYQM0XB5xPfZ1dbJ3cai6u2';

function requestGoals() {
    return {
        type: actionTypes.REQUEST_GOALS,
        data: [],
        isLoading:true
    }
}

function receiveGoals(json) {

    return {
        type: actionTypes.RECEIVE_GOALS,
        payload:{
            // obj: json,
            data:json,
            isLoading:false,
        }
    }
}
export function getGoals(parentId, childId) {
    return async dispatch => {
        dispatch(requestGoals())
        var data_source = database().ref('/users/' + parentId + '/' + childId + '/goal');
        console.log('starg goals - ', )

    return data_source.orderByChild('active').equalTo(1).once('value')
            .then(snapshot => {return snapshot.val()})
            .then(snap => {
                let json = snap?Object.keys(snap).map(item => { return ({ key: item, ...snap[item] }) }):[];
                json.sort(function (x, y) {return (x === y) ? 0 : x.activated === 1 ? -1 : 1;});

                return dispatch(receiveGoals(json))})
    }
}


export function addGoal(amount, name, parentId, childId, link, type) {
    return async dispatch => {
        // dispatch(requestGoals())
    console.log('amount,name')
    console.log(amount,name)
    const itemLink = link? link : 'NA'
    const itemType = type? type:'savings credit purchase'
    const body = { amount: amount.replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, "."),
    name: name, parentId, childId, link:itemLink, type:itemType }

    // var addGoal = functions().httpsCallable('addGoalTest');

    return fetch(config.url + 'addGoalTest', {
        method: 'POST',
        body: JSON.stringify(body)
      })
    // return addGoal(body,{
      // mode:'cors',
      // 'Content-Type': 'text/plain'
    // })
        .then((resp) => {return resp.json()})
    }
}


export function removeGoal(goalId, parentId, childId) {
    return async dispatch => {
        // dispatch(requestGoals())

    const body = { goalId: goalId, parentId, childId }

    // var removeGoal = functions().httpsCallable('removeGoal');


      // removeGoal(body)
    return fetch(config.url + 'removeGoal', {
        method: 'POST',
        // headers: {
        //   'Accept': 'application/json',
        //   'Content-Type': 'application/json'
        // },
        body: JSON.stringify(body)
      })
    //     .then((resp) => {
    //             return resp.json()})
        // .then((resp) => {
        //     dispatch({type: actionTypes.REMOVE_GOAL,
        //             payload:{goalId:goalId},})
        //   return resp
        // })
    }
}


export function activateGoal(goalId, parentId, childId) {
    return async dispatch => {

    const body = { goalId: goalId, parentId, childId }
    return fetch(config.url + 'activateGoal', {
      method: 'POST',
      // mode: 'cors',
      // headers: {
      //   'Accept': 'application/json',
      //   'Content-Type': 'application/json',
      // },
      body: JSON.stringify(body)
    })
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        // this.setState({ data: [] })
        // this.getGoals()
        return resp
      })
  }
}

export function checkPurchaseGoal(goal, parentId, childId) {
  return async dispatch => {

  const request_date = new Date();
  // const goal = this.state.data.find(item=>item.key===goalId);

  const goalId = goal.key;
  const name = goal.name;
  const amount = goal.amount;
    console.log('1')

  const additional = (await database().ref().child('users').child(parentId).child(childId).child('chequing').child('additional').once('value')).val();
  if (!additional) {
    return {res:true}
  }

  const old_savings = (await database().ref().child('users').child(parentId).child(childId).child('savings').once('value')).val();
  console.log('2')

  let today = new Date();
  today.setDate(1)
  console.log('3')

  var data_source =  database().ref().child('/users/' + parentId + '/' + childId + '/savings/transactions');
  const snapshot = await data_source.orderByChild('transaction_date').startAt(today.toISOString()).once('value')
  let data = (snapshot.val())
  console.log('4')
  // data = data ? Object.values(data).filter(item => item.type === 'withdrawal' || item.type.includes('purchase')) : []
  data = data ? Object.values(data) : []
  console.log('5')

  const transactions_number = data.length
  // check fee
  console.log('6')

  const isFee = transactions_number >= old_savings.withdraw_limit ? true : false;
  const withdraw_fee = (isFee) ? old_savings.withdraw_fee : 0;
  // const transaction_fee = 0;
  const insuficient = (old_savings.balance - amount - withdraw_fee < 0 || old_savings.balance < old_savings.min_balance) ? true : false;
  console.log('7', insuficient, isFee, withdraw_fee, transactions_number, snapshot)

  if (insuficient) {

      return false
  }
  else if (withdraw_fee > 0) {

      return {res:true}
  }
  else {
      return {res:true}
  }

}
}


export function purchaseGoal(goal, parentId, childId) {
    return async dispatch => {

    const request_date = new Date();
    // const goal = this.state.data.find(item=>item.key===goalId);

    const goalId = goal.key;
    const name = goal.name;
    const price = goal.amount;
    const body = { goalId: goalId, name,price,request_date:request_date.toISOString(),
      parentId, childId, link:goal.link, type:goal.source }


    if (goal.link && goal.link!=='NA') {
      console.log('123123')
      console.log('goal.link ', goal.link)

      const root = database().ref();
      const store = 'amazon'
      const storeItem = (await root.child('store/'+store).orderByChild('link').equalTo(goal.link).once('value')).val();
      console.log('storeItem ', storeItem)
      const storeItemId = storeItem && Object.keys(storeItem)[0]
      const kids = storeItem? (Object.values(storeItem)[0].kids || 0) :null
      storeItemId?root.child('store/'+store+'/'+storeItemId).update({kids:kids+1}):null
      console.log('fin')

    }

    return fetch(config.url + 'purchaseGoal', {
      method: 'POST',
      // headers: {
      //   'Accept': 'application/json',
      //   'Content-Type': 'application/json'
      // },
      body: JSON.stringify(body)
    })
      .then((resp) => {
        return resp.json()
      })
      // .then((resp) => {
      //   dispatch({type: actionTypes.PURCHASE_GOAL,
      //     payload:{goalId:goalId},})
      //   return resp
      // })
  }
}



export function sendRequestPurchaseEmail(parentId, childId, itemName) {
  return async dispatch => {


  const body = {
    parentId, childId, itemName }
  return fetch(config.url + 'sendRequestPurchaseEmail', {
    method: 'POST',
    // headers: {
    //   'Accept': 'application/json',
    //   'Content-Type': 'application/json'
    // },
    body: JSON.stringify(body)
  })
    .then((resp) => {
      return resp.json()
    })
    // .then((resp) => {
    //   dispatch({type: actionTypes.PURCHASE_GOAL,
    //     payload:{goalId:goalId},})
    //   return resp
    // })
}
}


