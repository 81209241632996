/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import {
    ActivityIndicator,
    Animated,
    AsyncStorage,
    Image,
    FlatList,
    Linking,
    ScrollView,
    StyleSheet,
    TextInput,
    //   Text as DefaultText,
    View,
    Platform,
    TouchableOpacity,
} from 'react-native';

import * as WebBrowser from 'expo-web-browser';

import Text from '../../components/CustomText'

import TouchableNativeFeedback from '../../components/TouchableFeedback';

import Colors from '../../constants/Colors';
import { defaultStyles } from '../../constants/style';

const categories = ['Toys', 'Beauty', 'Electronics', 'Sports', 'Activities', 'Arts & Crafts', 'Food', 'Accessories', 'Donations', 'Other']

const exampleData = [{
    category: [],
    createdon: ["2019-12-06/16:54:12"],
    description: [{ short: 'short', long: ' Long' }],
    imageurl: ["https://www.lego.com/cdn/cs/set/assets/blt715b09153deaef73/75936.jpg?format=jpg&fit=bounds&width=800&height=800&quality=80"],
    linkurl: ["https://www.lego.com/cdn/cs/set/assets/blt715b09153deaef73/75936.jpg?format=jpg&fit=bounds&width=800&height=800&quality=80"],
    merchantname: ['TITLE'],
    productname: ['PRODUCTNAME'],
    saleprice: [{ '$': { currency: 'CAD' }, '_': '42.99' }],
    keywords: [""]
}]

function SearchItem({ id, title, image, description, url, price, addToStore, merchantname }) {
    return (
        <TouchableNativeFeedback onPress={() => WebBrowser.openBrowserAsync(url)}>
            <View style={{ flex: 1, paddingVertical: 10, zIndex: 5, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                <View style={{ flex: 0.5, alignItems: 'center' }}><Image resizeMode={'cover'} style={{ height: 60, width: 60 }} source={{ uri: image }} /></View>
                <View style={{ flex: 1, justifyContent: 'center' }}>
                    <Text numberOfLines={2} style={styles.title}>{title}</Text>
                    <Text numberOfLines={3} style={{ fontSize: 14 }}>{description}</Text>
                </View>
                <View style={{ flex: 0.5, justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ textAlign: 'center' }}>CAD {price}</Text>
                </View>
                <TouchableNativeFeedback onPress={() => addToStore({ id, url, image, category: categories[Math.floor(Math.random() * categories.length)], merchantname, description: description[0], title, price: parseFloat(price) })}>
                    <View style={{ alignSelf: 'center', marginRight: 20, justifyContent: 'center', alignItems: "center", borderWidth: 2, borderColor: Colors.green1, width: 30, height: 30, borderRadius: 30 }}>
                        <Text style={[{  color: Colors.green1 }, defaultStyles.bold]}>+</Text>
                    </View>
                </TouchableNativeFeedback>
            </View>
        </TouchableNativeFeedback>
    );
}

function ProductSearch({ auth, parent, layout, searchAction, addToStore }) {
    const [modalVisible, setModalVisible] = useState(false)
    const [text, setText] = useState('')
    const [lastText, setLastText] = useState('')

    const [itemsPromise, setItemsPromise] = useState([])
    const [items, setItems] = useState([])

    const [loading, setLoading] = useState(false)

    const [resultsVisible, setResultsVisible] = useState(true)

    // useEffect(()=>{
    //     Promise.resolve(itemsPromise).then(function(value) {
    //         console.log('value ', value.items.item, value.items.item); // "Success"
    //         setItems(value.items.item)
    //       }, function(value) {
    //         // not called
    //         console.log('not called ', value)
    //       });
    // },[itemsPromise])

    return (<View style={{
        zIndex: 11, alignSelf: 'center', width: '90%', marginVertical: 30, maxWidth: 650, alignItems: 'center', backgroundColor: 'white',
    }}>

        <View style={{ width: '100%', }}>
            <TextInput
                onBlur={() => { setResultsVisible(false) }}
                onFocus={() => { setResultsVisible(true) }}
                onChangeText={text => {
                    setText(text); setLoading(true);
                    // setItemsPromise(searchAction({ keyword: text }))
                    searchAction({ keyword: text }).then(items => { setLastText(text); setLoading(false); setItems(items.items.item) })
                }}
                value={text}
                style={[{
                    justifyContent: 'center', paddingTop: 5,paddingLeft: 10,
                    ...Platform.OS === 'web' ? { outlineWidth: 0, outlineStyle: 'none' } : {}, height: 34, width: '100%',
                    fontFamily: 'Carmen Sans Regular', fontSize: 20,color: Colors.gray1,
                    borderWidth: 2, borderRadius: 10, borderColor: Colors.green1,
                }]}
                multiline={false}
                placeholder={'Search'}
            />
        </View>
    </View>
    )

}

const styles = StyleSheet.create({

});

// export default Dashboard;
export default ProductSearch



// {
//     resultsVisible &&
//     // ()=>Promise.resolve(items).then(items=><View>Text {items.length}</View> )
//     <FlatList style={{
//         zIndex: 12, backgroundColor: 'white', width: '100%', position: 'absolute', top: '95%',
//         ...(items && items.length) ? { borderWidth: 2, borderTopWidth: 0, borderColor: Colors.green1, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 } : {}
//     }}
//         // data={(input) => {
//         //     return new Promise(async (resolve, reject) => {
//         //     try {
//         //      resolve(items);
//         //     }
//         //     catch (e) {
//         //     console.error('Fail', e);
//         //     reject(e);
//         //   }
//         //   });
//         //   }}
//         data={items}
//         ListHeaderComponent={() =>
//             // loading&&
//             <View style={{ flexDirection: 'row' }}><ActivityIndicator color={Colors.green1} /><Text>{lastText}</Text></View>}
//         // data={[...Array(5)].map(item=>exampleData[0]) }
//         renderItem={({ item }) => <SearchItem title={item.productname[0]} url={item.linkurl[0]} image={item.imageurl[0]} description={item.description[0].short}
//             // price={ item.saleprice[0]['$'].currency + ' ' + item.saleprice[0]._}
//             price={item.saleprice[0]._}
//             merchantname={item.merchantname[0]}
//             addToStore={addToStore} id={item.linkid[0]} />}
//         keyExtractor={item => item.linkid[0]} />
// }