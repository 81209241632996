// import { appConstants } from "";
import * as actionTypes from '../../actions/parents/actionTypes'


const initialState = {
    isLoading: true,
    data: [],
    type: 0,
    movement: 0,
    data: [],
    allowance: [],
    job: [],
    deposit: [],
    online_purchase: [],
    credit_purchase: [],
    savings_deposit: [],
    savings_withdrawal: [],
    chequing_balance: 0,
    job_display: 'none',
    allowance_display: 'none',
    deposit_display: 'none',
    online_purchase_display: 'none',
    credit_purchase_display: 'none',
    savings_deposit_display: 'none',
    savings_withdrawal_display: 'none',
    isLoading: true,
    interval: new Date(),
    income: 0,
    income_trans: 0,
    expense: 0,
    expense_trans: 0,
    saved: 0,
    saved_trans: 0,
    job_income: 0,
    allowance_income: 0,
    allowance_income_trans: 0,
    deposit_income: 0,
    deposit_income_trans: 0,
    online_expense: 0,
    online_expense_trans: 0,
    credit_expense: 0,
    credit_expense_trans: 0,
    deposit_savings: 0,
    deposit_savings_trans: 0,
    withdraw_savings: 0,
    withdraw_savings_trans: 0,
    total_savings: 0,
    total_savings_trans: 0,
    withdrawals_arr: [],
    withdrawals: 0,
    withdrawals_trans: 0,
    withdrawals_display: 'none',
    deposits_arr: [],
    deposits: 0,
    deposits_trans: 0,
    fees: 0,
    fees_data: [],
    fees_trans: 0,
    deposits_display: 'none',
    interest_rate: 0,
    transferChequingVisible: false,
    transferSavingVisible: false,
    visible: false,
    intervals: [false, true, false, false],
    email: '',
    transfer: '',
};

function filterChequing(data) {
    let job_income = 0, allowance_income = 0, deposit_income = 0;
    let job_trans = 0, allowance_income_trans = 0, deposit_income_trans = 0;
    let online_expense = 0, credit_expense = 0;
    let online_expense_trans = 0, credit_expense_trans = 0;
    let deposit_savings = 0, deposit_savings_trans = 0;
    let withdraw_savings = 0, withdraw_savings_trans = 0;
    let fees = 0, fees_trans = 0, fees_data = [];
    let allowance = [];
    let job = [];
    let deposit = [];
    let online_purchase = [];
    let credit_purchase = [];
    let savings_deposit = [];
    let savings_withdrawal = [];

    let savings_fees = 0, savings_fees_trans = 0, savings_fees_data = [];
    let expence_fees = 0, expence_fees_trans = 0, expence_fees_data = [];



    for (let i in data) {

        if (data[i] === null) continue

        switch (data[i].type) {
            // INCOME
            case 'job':
                job.push(data[i]);
                job_income += data[i].amount;
                job_trans++;
                break;
            case 'allowance':
                allowance.push(data[i])
                allowance_income += data[i].amount;
                allowance_income_trans++;
                break;
            case 'parent deposit':
                deposit.push(data[i])
                deposit_income += data[i].amount;
                deposit_income_trans++;
                break;
            case 'deposit':
                deposit.push(data[i])
                deposit_income += data[i].amount;
                deposit_income_trans++;
                break;

            case 'refund':
                deposit.push(data[i])
                deposit_income += data[i].amount;
                deposit_income_trans++;
                break;


            // EXPENSE
            case 'online purchase':
                online_purchase.push(data[i])
                online_expense += data[i].amount;
                online_expense_trans++;
                break;
            case 'credit purchase':
                credit_purchase.push(data[i])
                credit_expense += data[i].amount;
                credit_expense_trans++;
                break;

            case 'parent withdrawal':
                credit_purchase.push(data[i])
                credit_expense += data[i].amount;
                credit_expense_trans++;
                break;
            // SAVINGS
            case 'savings deposit':
                savings_deposit.push(data[i]);
                deposit_savings += data[i].amount;
                deposit_savings_trans++;
                break;
            case 'savings withdrawal':
                savings_withdrawal.push(data[i]);
                withdraw_savings += data[i].amount;
                withdraw_savings_trans++;
                break;

            case 'purchase transaction fee':
                expence_fees_data.push(data[i]);
                expence_fees += data[i].amount;
                expence_fees_trans++;
                break;
            case 'purchase account fee':
                expence_fees_data.push(data[i]);
                expence_fees += data[i].amount;
                expence_fees_trans++;
                break;
            case 'savings transaction fee':
                savings_fees_data.push(data[i]);
                savings_fees += data[i].amount;
                savings_fees_trans++;
                break;
            case 'savings account fee':
                savings_fees_data.push(data[i]);
                savings_fees += data[i].amount;
                savings_fees_trans++;
                break;
        }
    }
    return {
        data: data,
        // min_balance:min_balance,
        income: job_income + allowance_income + deposit_income,
        expense: online_expense + credit_expense + expence_fees,
        saved: deposit_savings - withdraw_savings,
        income_trans: job_trans + allowance_income_trans + deposit_income_trans,
        expense_trans: online_expense_trans + credit_expense_trans + expence_fees_trans,
        saved_trans: withdraw_savings_trans + deposit_savings_trans,
        job_income: job_income,
        // chequing_balance:chequing_balance,
        job_trans: job_trans,
        allowance: allowance,
        job: job,
        deposit: deposit,
        online_purchase: online_purchase,
        credit_purchase: credit_purchase,
        savings_withdrawal: savings_withdrawal,
        savings_deposit: savings_deposit,
        allowance_income: allowance_income,
        allowance_income_trans: allowance_income_trans,
        deposit_income: deposit_income,
        deposit_income_trans: deposit_income_trans,
        online_expense: online_expense,
        online_expense_trans: online_expense_trans,
        credit_expense: credit_expense,
        credit_expense_trans: credit_expense_trans,
        deposit_savings: deposit_savings,
        deposit_savings_trans: deposit_savings_trans,
        withdraw_savings: withdraw_savings,
        withdraw_savings_trans: withdraw_savings_trans,
        savings_fees_data:savings_fees_data, 
        savings_fees:savings_fees, savings_fees_trans:savings_fees_trans,
        expence_fees_trans,expence_fees, expence_fees_data,
        fees: fees,
        fees_data: fees_data,
        fees_trans: fees_trans,
        movement: ((job_income + allowance_income + deposit_income) - (deposit_savings - withdraw_savings + savings_fees) - (online_expense + credit_expense + expence_fees)),
     }
}


export default function chat(state = initialState, action) {
    let values = {}

    switch (action.type) {
        case actionTypes.REQUEST_CHEQUING:

            return {
                ...state,
                isLoading: true,
            };


        case actionTypes.RECEIVE_CHEQUING:
            values = filterChequing(action.payload.data)
            return {
                ...state,
                isLoading: false,
                chequing_balance: action.payload.chequing_balance,
                min_balance: action.payload.min_balance,
                [action.payload.childId]: {
                    ...values, chequing_balance: action.payload.chequing_balance,
                    min_balance: action.payload.min_balance,
                }
            };

        default:
            return state
    }
}