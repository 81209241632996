/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
	ActivityIndicator,
	Animated,
	Platform,
	StyleSheet,
	View,
} from 'react-native';

import DatePicker from 'react-native-datepicker'

import * as ImagePicker from 'expo-image-picker';

import TouchableNativeFeedback from '../../components/TouchableFeedback'
import FloatingLabelInput from '../FloatingLabelInput/FloatingLabelInput'
import Text from '../../components/CustomText'
import { Button } from '../../components/Buttons'

import { ConfirmationView, RejectionView } from '../../components/confirmation'
import { DropdownElement } from '../../components/Dropdown'

import { InfoButton, InfoButtonControlled } from '../../components/newChild/Buttons'

import Colors from '../../constants/Colors';
import { allowanceInfo, expenseInfo } from '../../constants/info';

import globalStyle, {defaultStyles} from '../../constants/style'

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const salaryFreqs = { 'Monthly': 1, 'Bi-weekly': 2, 'Weekly': 4, }
const salaryFreqsReversed = { 1: 'Monthly', 2: 'Bi-weekly', 4: 'Weekly', }
const salaryDays = {
	'1st of the month': 0, 'End of the month': 1,
	'Monday': 1, 'Tuesday': 2, 'Wednesday': 3, 'Thursday': 4, 'Friday': 5, 'Saturday': 6, 'Sunday': 0
}

function formatInputValue(str) {
	return (str || str === 0) ? str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".") : ''
}

Date.prototype.yyyymmdd = function () {
	var mm = this.getMonth() + 1; // getMonth() is zero-based
	var dd = this.getDate();

	return [this.getFullYear(),
	(mm > 9 ? '' : '0') + mm,
	(dd > 9 ? '' : '0') + dd
	].join('-');
};

function defaultDay(freq) {
	if (freq === 1 || freq === 'Monthly') {
		return '1st of the month'
	}
	return 'Friday'

}

const weekdays = { 1: 'Monday', 2: 'Tuesday', 3: 'Wednesday', 4: 'Thursday', 5: 'Friday', 6: 'Saturday', 0: 'Sunday' }
function salaryDay(freq, day) {
	if (freq === 1 || freq === 'Monthly') {
		return day ? 'End of the month' : '1st of the month'
	}
	return weekdays[day]

}

// function expenseDay(day, freq) {
// 	if (freq === 'Monthly') {
// 		return salary_day
// 	}
// 	return 'End of the month'

// }

function ExpenseTable({ expenses, removeExpense }) {

	console.log('expenses ', expenses)

	function row({ key, title, amount, frequency, header }) {
		console.log('title,amount, frequency} ', title, amount, frequency)

		return <View key={key} style={{ paddingVertical: 5, paddingTop: header ? 10 : 5, borderTopWidth: header ? 0 : 1, flex: 1,  flexDirection: 'row',
		alignItems: 'center', }}>
			<View style={{ flex: 1, paddingLeft: header ? 10 : 10,  }}><Text numberOfLines={1} style={{fontSize: 14, lineHeight: 16 }}>{title}</Text></View>
			<View style={{ flex: 1, paddingLeft: header ? 10 : 10, }}><Text style={{fontSize: 14}}>{!header && '$'}{amount}</Text></View>
			<View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
				<Text style={{fontSize: 14}}>{header ? 'Frequency' : salaryFreqsReversed[frequency]}</Text>
				{header ? null :<View style={{ flex: 0.2,  marginRight: 15}}>
				<TouchableNativeFeedback onPress={() => { removeExpense(key) }}>
					<View style={{ borderRadius: 10, width: 15, opacity: 0.7, backgroundColor: Colors.red, height: 15, justifyContent: 'center',
					alignItems: 'center', }}><Text style={{ textAlign: 'center', color: 'white' }}>x</Text></View>
				</TouchableNativeFeedback>
			</View>}
				</View>

		</View>
	}

	if (!expenses || !Object.keys(expenses).length) {
		return <View><Text>No expenses</Text></View>
	}

	return <View style={{ paddingHorizontal: 5, borderWidth: 2, borderRadius: 10, flex: 1, width: '100%', marginTop: 20 }}>
		{row({ title: 'Title', amount: 'Amount', frequency: 'Frequency', header: true })}
		{expenses && Object.keys(expenses).map((item) => { let expense = expenses[item]; console.log('item', item); return expense.info && row({ key: item, ...expense.info }) })}
	</View>
}

class Settings extends React.Component {
	static navigationOptions = ({ navigation }) => {
		return {
			// headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
			headerTransparent: true,
			headerLeft: null,
			header: null
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			data: [],
			isLoading: true,
			isFocused: false,
			hasCameraPermission: null,
			//  image: '0',
			image: null,
			emailChange: false,
			nameChange: false,
			oldPassword: '',
			password: '',
			confirmPassword: '',
			id: 0,
			allowanceStart: (new Date()).yyyymmdd(),

			expence_title: '',
			expence_amount: 0,
			expence_frequency: 'Weekly',
			expense_day: 'Friday',
			expense_title_error: false,
			expense_amount_error: false,

			expense_opacity: new Animated.Value(0),

			allowance_amount_error: false,
			...Object.keys(expenseInfo).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: '' } }, {}),
			...Object.keys(allowanceInfo).reduce((prev, curr, currIndex, arr) => { return { ...prev, [curr]: '' } }, {}),
			//  image: 'file:///data/user/0/host.exp.exponent/cache/ExperienceData/%2540dtsvetkov%252Fpennygembank/ImagePicker/cc28e74d-706e-4020-a1f0-00192ee0b38c.jpg',
			// type: Camera.Constants.Type.back,
		};
	};

	componentDidMount() {

		const { parentId, } = this.props.auth;
		const { childIds } = this.props.parent;

		this.props.actions.getSettings(parentId, childIds[this.props.id])
			.then(settings => {
				console.log('settings!!', settings);
				this.setState({ ...settings, salary_day: settings.salary_day ? salaryDay(settings.salary_freq, settings.salary_day) : defaultDay(settings.salary_freq), isLoading: false })
			})

	}

	componentDidUpdate(prevProps) {
		if ((this.props.id) !== (prevProps.id)) {
			const { parentId } = this.props.auth;
			const { childIds } = this.props.parent

			this.setState({ isLoading: true })
			this.props.actions.getSettings(parentId, childIds[this.props.id])
				.then(settings => {
					console.log('settings!!', settings);
					this.setState({ ...settings, salary_day: salaryDay(settings.salary_freq, settings.salary_day) || defaultDay(settings.salary_freq), isLoading: false })
				})
		}
	}

	changeDay(freq) {
		if (freq === 'Monthly') {
			return this.setState({ expense_day: '1st of the month' })
		}
		return this.setState({ expense_day: 'Friday' })

	}

	// rgba(126, 193, 61, 0.1)

	expence_row({ info, key }) {
		return info ? <View key={key} style={{ width: '100%', flexDirection: 'row', marginVertical: 10, justifyContent: "space-around" }}><Text>Title: {info.title}</Text>
			<Text>Amount: ${info.amount}</Text>
			<Text>Frequency: {salaryFreqsReversed[info.frequency]}</Text>
		</View> : null
	}


	render() {
		const { layout, actions, id, settings } = this.props;
		const { allowance_amount_error } = this.state;

		const { parentId, } = this.props.auth;
		const { childIds, names, isLoading, } = this.props.parent;

		const childId = childIds[id]

		const vmin = Math.min(...[layout.width, layout.height]);
		let width = layout.width;
		const closeFontSize = Platform.OS === 'web' ? (width > 850 ? '2.25em' : '1.625em') : (40)
		// const {int_rate, withdraw_fee, withdraw_limit, min_balance, monthly_fee, salary_amt, salary_freq,
		//     transact_fee, transact_limit} = childIds[this.state.id]?settings[childIds[this.state.id]]:{}
		return (<View style={{width: '80%'}}>
			<View style={{
				opacity: 0.5, zIndex: 1, position: 'absolute', borderRadius: 15, display: this.state.isLoading ? 'flex' : 'none',
				backgroundColor: 'transparent', width: '100%', height: '100%',
			}}>
				<ActivityIndicator style={{ margin: 85, top: 10, alignSelf: 'center', display: this.state.isLoading ? 'flex' : 'none', top: 0.04 * vmin, }} size={vmin > 650 ? "large" : "small"} color={Colors.green1} ></ActivityIndicator>
			</View>
			{<View onLayout={e => { this.setState({ childPos: e.nativeEvent.layout.y }) }} style={{ opacity: this.state.isLoading ? 0.5 : 1, width: '100%' }}>

				<View style={{ flexWrap: 'wrap', width: '100%', alignItems: 'center', marginTop: 10, }}>
					<Text style={{ ...defaultStyles.bold, color: Colors.gray0, marginVertical: 0.04 * vmin, ...globalStyle.h3 }}>Allowance</Text>

					<View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', zIndex: 2, alignItems: 'center' }}>
						<FloatingLabelInput style={{ borderColor: allowance_amount_error ? Colors.rose : Colors.gray }} additionalSign={'$'} label={'Allowance Amount'}
							value={formatInputValue(this.state.salary_amt)} onChangeText={text => this.setState({ allowance_amount_error: false, salary_amt: text })} />
						<InfoButton text={allowanceInfo.frequency.text} />
					</View>
					<View style={{ margin: 5 }}></View>
					{/* <FloatingLabelInput label={'Allowance Frequency'} value={this.state.salary_freq ?
													this.state.salary_freq.toString() : ''}
														onChangeText={text => this.setState({ salary_freq: text })} /> */}
					<View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', zIndex: 2, alignItems: 'center' }}>
						<DropdownElement
							dropdownStyle={{ width: '100%' }}
							containerStyle={{ zIndex: 2, width: '80%', }}
							mainElementStyle={{ width: '100%' }}
							onPress={(text) => { this.setState({ salary_day: text === 'Monthly' ? '1st day of the month' : 'Friday', salary_freq: text, }) }} defaultValue={this.state.salary_freq}
							categories={['Weekly', 'Bi-weekly', 'Monthly']} />
						<InfoButton text={allowanceInfo.frequency.text} />
					</View>
					<View style={{ alignSelf: 'flex-start', marginLeft: 21 }}><Text style={{ color: Colors.gray1, marginTop: 2, fontSize: 14 }}>Frequency *</Text></View>
					<View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', zIndex: 1, }}>
						<DropdownElement
							dropdownStyle={{ width: '100%', zIndex: 1, maxHeight: 200 }}
							containerStyle={{ zIndex: 1, width: '80%', marginHorizontal: 0 }}
							mainElementStyle={{ width: '100%' }}
							onPress={(text) => this.setState({ salary_day: text })} defaultValue={this.state.salary_day}
							categories={this.state.salary_freq === 'Monthly' ? ['1st of the month', 'End of the month'] :
								['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
							} />
						<InfoButton text={allowanceInfo.frequency.text} />
					</View>
					<View style={{ alignSelf: 'flex-start', marginLeft: 21 }}><Text style={{ color: Colors.gray1, marginTop: 2, fontSize: 14 }}>Day of the week to pay allowance *</Text></View>

				</View>


				<View style={{ zIndex: -11, alignItems:'center', width:'100%', }}>
					<Button styles={{ width: '100%', marginVertical: vmin * 0.05 }} textStyle={{ width: '100%', textAlign: 'center' }}
						title={'Save Allowance Changes'} onPress={() => {
							if (parseInt(this.state.salary_amt.toString().replace(/\D/g, ''))) {
								this.props.actions.updateAllowance(parentId, childIds[id],
									{ salary_amt: this.state.salary_amt, salary_freq: this.state.salary_freq, allowanceStart: this.state.allowanceStart }
								)

								this.props.actions.sendAllowanceJob(parentId, childIds[id],
									{
										salary_amt: this.state.salary_amt, salary_freq: this.state.salary_freq,
										salary_day: this.state.salary_day
									})
							}
							else {
								this.setState({ allowance_amount_error: true })
								console.log(111)
								// this.props.navigation.navigate('Dashboard');
							}
						}} />
				</View>

				<View style={{ flexWrap: 'wrap', width: '100%', alignItems: 'center', marginTop: 10 }}>
					<Text style={{ ...defaultStyles.bold, color: Colors.gray0, marginVertical: 0.04 * vmin, ...globalStyle.h3 }}>Expenses</Text>
					{/* {this.state.expences ? <View style={{ width: '80%' }}>{Object.values(this.state.expences).map((item, key) => this.expence_row({ key, info: item.info }))}</View> : <Text>No expences</Text>} */}
					{this.state.isLoading ? null : <ExpenseTable expenses={this.state.expences} removeExpense={(id) => { console.log('ID', id); actions.removeExpenseSchedule({ parentId, childId, expenseId: id }) }} />}

					{this.state.expenseVisible ?
						// <View>
							<Animated.View style={{ width: '100%', alignItems: 'center', opacity: this.state.expense_opacity }}>
								<View style={{ height: 30 }}></View>
								<Text style={{ fontSize: 30, ...defaultStyles.bold, color: Colors.gray0, marginVertical: 0.04 * vmin }}>Add New Expense</Text>

								<View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center' }}>
									<FloatingLabelInput style={{ borderColor: this.state.expense_title_error ? Colors.red : Colors.green1 }}
										label={'Expense Title *'} value={this.state.expence_title}
										onChangeText={text => this.setState({ expense_title_error: false, expence_title: text })} />
									<InfoButton text={expenseInfo.title.text} />
								</View>
								<View style={{ margin: 5 }} />
								<View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center' }}>
									<FloatingLabelInput style={{ borderColor: this.state.expense_amount_error ? Colors.red : Colors.green1 }}
										additionalSign={'$'} label={'Expense Amount *'}
										value={formatInputValue(this.state.expence_amount)}
										onChangeText={text => this.setState({ expense_amount_error: false, expence_amount: text })} />
									<InfoButton text={expenseInfo.amount.text} />
								</View>
								<View style={{ margin: 5 }}></View>
								<View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', zIndex: 2, alignItems: 'center' }}>
									<DropdownElement dropdownStyle={{ width: '100%' }} containerStyle={{ zIndex: 2, width: '80%', }}
										mainElementStyle={{ width: '100%' }} defaultValue={this.state.expence_frequency}
										onPress={(text) => { this.changeDay(text); this.setState({ expence_frequency: text, }) }} categories={['Weekly', 'Bi-weekly', 'Monthly']} />
									<InfoButton text={expenseInfo.frequency.text} />
								</View>
								<View style={{ width: '100%', alignSelf: 'flex-start', marginLeft: 22 }}><Text style={{ color: Colors.gray, marginTop: 2, fontSize: 14 }}>{expenseInfo.frequency.label}</Text></View>

								<View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', zIndex: 1, }}>
									<DropdownElement dropdownStyle={{ width: '100%', zIndex: 1, maxHeight: 200 }} containerStyle={{ zIndex: 1, width: '80%', marginHorizontal: 0 }}
										mainElementStyle={{ width: '100%' }} onPress={(text) => this.setState({ expense_day: text })} defaultValue={this.state.expense_day}
										categories={this.state.expence_frequency === 'Monthly' ? ['1st of the month', 'End of the month'] :
											['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
										} />
									<InfoButton text={expenseInfo.day.text} />
								</View>
								<View style={{ width: '80%', alignSelf: 'flex-start', marginLeft: 20 }}>
									<Text style={{ color: Colors.gray, marginTop: 2, fontSize: 14 }}>{expenseInfo.day.label}</Text>
								</View>


								<TouchableNativeFeedback
								 style={Platform.OS==='web'?{width:'100%'}:{}}
								onPress={() => {
									const { parentId, } = this.props.auth;
									const { childIds, id } = this.props.parent;
									const { expence_amount, expence_title, expence_frequency, expense_day } = this.state;
									console.log('expence_amount && parseInt(expence_amount) ', expence_amount, parseInt(expence_amount))
									this.setState({ isLoading: true })
									expence_amount && parseInt(expence_amount) && expence_title ?
										this.props.actions.createExpence({ parentId, childId: childIds[id], amount: expence_amount, title: expence_title, frequency: salaryFreqs[expence_frequency], day: salaryDays[expense_day] })
											.then(() => this.props.actions.getSettings(parentId, childIds[id]))
											.then(settings => { this.setState({ ...settings, salary_day: settings.salary_day || defaultDay(settings.salary_freq), isLoading: false }) })
										: this.setState({ isLoading: false, expense_amount_error: parseInt(this.state.expence_amount) ? false : true, expense_title_error: this.state.expence_title ? false : true })
									// console.log('shedule!', schedule, body)
								}}>
									<View elevation={5} style={[{
										width: '100%', paddingVertical:5,
										marginVertical: 10, backgroundColor: Colors.green1, borderRadius: 20,
										// boxShadow:'2px 2px 15px #272827',
										// padding: 5,
										// transform:[{scale:isHovered?1.1:1}],
									}]}>
										<Text style={[{ color: 'white', textAlign: 'center', marginVertical: 5 }]}>Create Expense</Text>
									</View>
								</TouchableNativeFeedback>

							</Animated.View>
						:
						<TouchableNativeFeedback onPress={() => {
							this.setState({ expenseVisible: true, })
							Animated.timing(
								this.state.expense_opacity,
								{
									toValue: 1,
									duration: 1000,
								}
							).start();
							// this.props.navigation.navigate('AdditionalExpense')
						}}>
							<View style={{ zIndex: -1, marginLeft: -20, flex: 1, width: '100%', flexDirection: 'row', marginVertical: 30, alignItems: 'center' }}>
								<View style={{
									borderRadius: 10, height: 30, width: 30, justifyContent: 'center', alignItems: 'center',
									borderColor: Colors.green1,
								}}>
									{<Text style={{ ...defaultStyles.bold, paddingTop: 3, justifyContent: 'center', fontSize: 30, color: Colors.green1 }}>+</Text>}
								</View>
								<View style={{ marginLeft: 10 }}>
									<Text style={[{ color: Colors.green1, }, defaultStyles.bold]}>Add another expense</Text>
								</View>
							</View>
						</TouchableNativeFeedback>}
				</View>
				{/* <TouchableNativeFeedback onPress={() => {
					this.setState({ expenseVisible: false })
					Animated.timing(
						this.state.expense_opacity,
						{
						  toValue: 0,
						  duration: 1000,
						}
					  ).start();
					// this.props.navigation.navigate('AdditionalExpense')
				}}>
					<Text>Hide</Text>
				</TouchableNativeFeedback> */}


			</View>}
		</View>
		)
	}
}

const styles = StyleSheet.create({

});

export default Settings;

// export default connect(state => {
//     // console.log(Object.keys(state.vacancies));
//     return ({
//         auth: state.auth,
//     })
// },
//     (dispatch) => ({
//         actions: bindActionCreators({ ...authActions, ...chequingActions }, dispatch)
//     })
// )(Settings);
