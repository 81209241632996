/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import {
	Animated,
	Dimensions,
	Image,
	ImageBackground,
	Platform,
	StyleSheet,
	View,
	ScrollView,
	TouchableOpacity,
} from 'react-native';

import { useHover, useFocus, useActive, useDimensions, Resizable } from 'react-native-web-hooks';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';
import * as Permissions from 'expo-permissions';
// import { Permissions} from 'expo';

import firebase from 'firebase/app';
import 'firebase/database';
// import 'firebase/analytics';
import * as Analytics from 'expo-firebase-analytics';

import Icon from 'react-native-vector-icons/FontAwesome5';

import { dropdown, handleScroll, dropdownParent } from '../../components/Dropdown/Dropdown';

import Text from '../../components/CustomText'
import TouchableNativeFeedback from '../../components/TouchableFeedback';
import ParentComponentHeader from '../../components/ComponentHeader';
import { CloseButton } from '../../components/Buttons';
import CounterTextInput from '../../components/CounterTextInput';
import FloatingLabelInput from '../../components/FloatingLabelInput/FloatingLabelInput';
import { ConfirmationView, ConfirmationModal } from '../../components/confirmation';

import AddJob from '../../components/jobs/NewJob';
import TransferBlock from '../../components/chequing/TransferBlock';

import { ParentHeaderCard } from '../../components/Header/HeaderBlock'

import ModalContainer from '../../components/Modal/Modal'
import NewJobModal from '../../components/jobs/NewJobModal'

import { ChequingCard, ActivityCard, PurchasesCard, StoreCard } from '../../components/Dashboard/DashboardBlocks';

import { withdrawal, deposit } from '../../helpers/parentTransfer';

import * as goalsActions from '../../store/actions/parents/goalsActions'
import * as authActions from '../../store/actions/parents/authActions'
import * as jobsActions from '../../store/actions/parents/jobsActions'
import * as chequingActions from '../../store/actions/parents/chequingActions'
import * as purchasesActions from '../../store/actions/parents/purchasesActions'
import * as savingsActions from '../../store/actions/parents/savingsActions'

import { defaultStyles } from '../../constants/style';
import Colors from '../../constants/Colors';

const moment = require('moment');

const database = firebase.database;
// const analytics = firebase.analytics;

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

Notifications.setNotificationHandler({
	handleNotification: async () => ({
		shouldShowAlert: true,
		//   shouldPlaySound: false,
		//   shouldSetBadge: false,
	}),
});


const maxWidth = 700;
const widthThreshold = 600;

async function registerForPushNotificationsAsync() {
	let token;
	if (true) {

		// if (Constants.isDevice) {
		const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
		let finalStatus = existingStatus;
		if (existingStatus !== 'granted') {
			const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
			finalStatus = status;
		}
		if (finalStatus !== 'granted') {
			alert('Failed to get push token for push notification!');
			return;
		}
		token = (await Notifications.getExpoPushTokenAsync()).data;
		console.log(token);
	} else {
		alert('Must use physical device for Push Notifications');
	}


	if (Platform.OS === 'android') {
		Notifications.setNotificationChannelAsync('default', {
			name: 'default',
			importance: Notifications.AndroidImportance.MAX,
			vibrationPattern: [0, 250, 250, 250],
			lightColor: '#FF231F7C',
		});
	}

	return token;
}

// String.prototype.toUpperCase()
// return String?
Date.prototype.yyyymmdd = function () {
	var mm = this.getMonth() + 1; // getMonth() is zero-based
	var dd = this.getDate();

	return [this.getFullYear(),
	(mm > 9 ? '' : '0') + mm,
	(dd > 9 ? '' : '0') + dd
	].join('-');
};

function WithdrawModal({ isVisible, toggle, onPress }) {
	const [value, setValue] = useState('')
	// const [withdrawVisible, setWithdrawVisible] = useState(false)

	return <ModalContainer visible={isVisible} onClose={() => toggle()}>
		<TransferBlock type={'withdraw'} title={'Withdraw from Chequing'} label={'Amount to Withdraw'} onPress={(value) => { onPress(value); toggle(); setValue('') }} />
	</ModalContainer>

}


class Dashboard extends React.Component {
	static navigationOptions = ({ navigation }) => {
		return {
			// headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
			headerTransparent: true,
			header: null,
		}
	}

	constructor(props) {
		super(props);
		this.descriptionRef = React.createRef()
		this.dateRef = React.createRef()

		this.myRef = {}


		this.state = {
			data: {},
			modalVisible: true,
			id: 0,
			isLoading: true,
			jobKids: {},
			// addJobModal:true
		};
	};


	componentDidMount() {
		const { actions, auth, parent, navigation } = this.props;
		const { parentId, } = auth;
		const { childIds, id } = this.props.parent;
		const childId = childIds[id]

		// firebase.analytics()
		Analytics.setCurrentScreen('parents/Dashboard');
		Analytics.logEvent('page_view', { page_path: 'parents/Dashboard', page_title: 'Dashboard' })

		if (!auth.parentId) {
			this.props.actions.clearAuth()
				.then(() => {
					this.props.navigation.navigate('Auth')
				})
		}
		else {
			Analytics.setUserId(this.props.auth.parentId)
		}


		Platform.OS !== 'web' ? registerForPushNotificationsAsync().then(token => {
			console.log('NOTIFICATION TOKEN ', token)
			this.props.actions.updateNotificationToken({ token, parentId: auth.parentId })
			// database().ref('parents/'+auth.parentId+'/notifications').update({[token]:true})
		})
			.catch((e) => console.log('NOTIFICATION ERROR', e))
			: null;

		actions.getDashboardData({ parentId, childId })
		// console.log('auth', auth)
		this.focusListener = navigation.addListener("didFocus", () => {

			const { parentId, } = auth;

			actions.getChildNodes(parentId)
				.then((childNodes) => {
					//   navigation.navigate('Settings')
					const { childIds, id } = this.props.parent;
					const childId = childIds[id]

					this.setState({ isLoading: false })
					const children = childNodes ? Object.values(childNodes) : []
					children.length > 0 ? actions.getDashboardData({ parentId, childId }) : this.props.navigation.navigate('NewKid')

					for (let i in children) {
						actions.getChildName(children[i])
					}
				})
				.catch((error) => {
					if (error.code === 'PERMISSION_DENIED') {
						this.props.navigation.navigate('Auth')
					}
					console.log(error.code)
				})
		});


	}

	componentWillUnmount() {
		// Remove the event listener
		console.log("UNMOUNTED MAIN !!!!")
		this.focusListener.remove();
		// this.focusListener.remove();
	}

	getAccumulateData(move) {
		const { actions } = this.props;
		const { parentId, } = this.props.auth;
		const { childIds, id } = this.props.parent;
		const { getJobs, changeChildIndex } = this.props.actions;

		let index = id + move;
		changeChildIndex(move)
			.then(() => {
				if (index > childIds.length - 1) { this.setState({ id: 0 }); actions.getDashboardData({ parentId, childId: childIds[0] }); return 0 }
				if (index < 0) { this.setState({ id: childIds.length - 1 }); actions.getDashboardData({ parentId, childId: childIds[childIds.length - 1] }); return 0 }
				this.setState({ id: index }); actions.getDashboardData({ parentId, childId: childIds[index] }); return 0
			});
	}


	defineValue(width, height, a, b, c) {
		if (width < 850 && width < height) {
			return a
		}
		else if (width < 850 && width > height) {
			return b
		}
		else {
			return c
		}
	}


	render() {
		const { withdrawModal } = this.state;

		const { navigation, auth, parent } = this.props;
		const { parentId } = auth;
		const { childIds, names, isLoading, id } = parent;
		const { chequing, savings,
			spent, goals, jobs, purchases_approved, purchases_pending } = this.props.parent.dashboard;

		const childId = childIds[id];

		const emailVerified = auth.user && auth.user.user && auth.user.user.emailVerified

		const purchasesApproved = purchases_approved
		const purchasesPending = purchases_pending

		const jobsPosted = jobs
		const goalsActive = goals

		// return (<View></View>)
		return (
			<Resizable>
				{layout => {
					const vmin = Math.min(...[layout.width, layout.height]);
					const width = layout.width;
					return <View style={{ flex: 1, backgroundColor: 'white' }}>
						<ModalContainer visible={this.state.transferChequingVisible} onClose={() => this.setState({ transferChequingVisible: false })}>
							<TransferBlock type={'deposit'} title={'Deposit to Chequing'} label={'Amount to Deposit'} onPress={(transfer) => {
								this.setState({ transferChequingVisible: false });
								deposit({ transfer, parentId, childId }).then(() => { this.props.actions.getDashboardData({ parentId, childId }) })
							}} />
						</ModalContainer>

						<WithdrawModal isVisible={withdrawModal} toggle={() => this.setState({ withdrawModal: false })}
							onPress={(value) => { withdrawal({ transfer: value, parentId, childId }).then(() => { this.props.actions.getDashboardData({ parentId, childId }) }) }} />

						<NewJobModal updateDashboard={this.props.actions.getDashboardData} parentId={parentId} createJob={this.props.actions.createJob} parent={this.props.parent}
							visible={this.state.addJobModal} onClose={() => this.setState({ addJobModal: false })} names={names} layout={layout} />

						{emailVerified ? null : <View style={{
							height: '100%', width: '100%', position: 'absolute', zIndex: 2,
							backgroundColor: 'rgba(128,128,128,0.5)', justifyContent: 'center', alignItems: 'center'
						}}>
							<View style={{
								backgroundColor: 'rgba(255,255,255,0.9)', paddingHorizontal: 20, paddingVertical: 10, width: 300, height: 100,
								borderRadius: 10, justifyContent: 'center', alignItems: 'center'
							}}>
								<Text>Please verify email</Text>
							</View>
						</View>}
						{(childIds.length === 0) ?
							this.state.isLoading ?
								<View></View> :
								<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
									<Text style={{
										margin: 0.01 * vmin, marginBottom: 0.05 * vmin,
										textAlign: 'center', fontFamily: 'Carmen Sans Bold'
									}}>
										No children yet
									</Text>
									{/* <Text style={{ marginBottom: 0.07 * vmin, color: 'white', fontSize: 30 }}>Go to settings</Text> */}
									<TouchableNativeFeedback onPress={() => this.props.navigation.navigate('Settings', { category: 'child' })}>
										<View style={{
											// backgroundColor: Colors.green1, borderRadius: 10, padding: 10,shadowColor: "#272827", shadowOffset: { width: 2, height: 2, }, shadowOpacity: 0.75, shadowRadius: 10,
										}}>
											<Text style={[{ textDecorationLine: 'underline', color: 'white', fontSize: 35, }, defaultStyles.bold]}>Add child</Text></View>
									</TouchableNativeFeedback>
								</View>
							:
							<View
								style={{
									flex: 1, height: '100%', flex: 1, flexGrow: 1, justifyContent: 'flex-start', alignItems: 'center',
									paddingTop: layout.height * 0.015, paddingBottom: layout.height * 0.04,
								}}
							>

								<ParentHeaderCard dashboard={true} pressNext={() => this.getAccumulateData(+1)} name={names[childIds[id]]} navigation={navigation} signOut={() => { }} vmin={vmin} layout={layout} clearAuth={this.props.actions.clearAuth} />

								<ChequingCard navigation={navigation} layout={layout} style={{ flex: 0.5, }}
									title={'Accounts'} additional={'Add Money'}
									upButton={() => this.setState({ transferChequingVisible: true })}
									downButton={() => this.setState({ withdrawModal: true })}
									earned={chequing} saved={savings} spent={spent} />

								<View style={{ height: 10, width: 1 }} />

								<View style={{
									flex: 1.3, flexDirection: layout.width > widthThreshold ? 'row' : 'column', width: '90%', justifyContent: 'space-between',
									maxWidth: maxWidth,
								}}>
									<View style={{
										flex: 0.5, justifyContent: 'space-between',
										maxWidth: maxWidth, flexDirection: layout.width > widthThreshold ? 'column' : 'row'
									}}>
										<ActivityCard color={Colors.blue}
											style={{ width: '100%', flex: 0.48 }} amount={jobsPosted}
											layout={layout} title={'Jobs'} buttonTitle={'+'} onPress={() => navigation.navigate('Jobs')}
											cornerImage={require('../../assets/dashboard/Jobs_2.png')}
											onButtonPress={() => this.setState({
												addJobModal: true,
												jobKids: childIds.reduce((curr, item) => { return { ...curr, [item]: false, [childIds[id]]: true } }, {})
											})} />
										<ActivityCard color={Colors.yellow1}
											style={{ width: '100%', flex: 0.48 }} amount={goalsActive}
											image={require('../../assets/Store/Shopping-Basket.png')}
											cornerImage={require('../../assets/dashboard/Goals_2.png')}
											layout={layout} title={'Goals'} buttonTitle={'Store'} onPress={() => navigation.navigate('Goals')}
											onButtonPress={() => navigation.navigate('Store')} />
									</View>
									<View style={{ height: 10, width: 10 }}></View>
									<View style={{ flex: 0.6, flexDirection: layout.width > widthThreshold ? 'column' : 'row', alignItems: 'stretch', justifyContent: 'space-between', }}>
										<PurchasesCard layout={layout} onPress={() => navigation.navigate('Purchases')}
											onButtonPress={() => this.props.navigation.navigate('Store')}
											info={[[purchasesPending + purchasesApproved, 'Made'], [purchasesApproved, 'Approved'], [purchasesPending, 'Pending',]]} />
										<StoreCard layout={layout} onPress={() => navigation.navigate('Store')} style={{}} />
									</View>
								</View>
							</View>}
						{/* </View> */}
						{/* </View> */}
						{/* </ImageBackground> */}
					</View>
				}}
			</Resizable>
		)
	}
}

const styles = StyleSheet.create({

});

export default connect(state => {
	return ({
		parent: state.parent,
		chequing: state.chequing,
		savings: state.savings,
		auth: state.auth,
		goals: state.goals,
		jobs: state.jobs,
		purchases: state.purchases
	})
},
	(dispatch) => ({
		actions: bindActionCreators({ ...authActions, ...goalsActions, ...purchasesActions, ...chequingActions, ...jobsActions, ...savingsActions, }, dispatch)
	})
)(Dashboard);
