/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import {
	Animated,
	// AsyncStorage,
	Dimensions,
	Image,
	ImageBackground,
	FlatList,
	ScrollView,
	StyleSheet,
	Text as Text1,
	TextInput,
	//   Text as DefaultText,
	View,
	Platform,
	TouchableOpacity,
} from 'react-native';

//import Slider from 'react-native-community/slider'
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import Icon from 'react-native-vector-icons/FontAwesome';


import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { useHover, useFocus, useActive, useDimensions, Resizable, Hoverable } from 'react-native-web-hooks';

import Text from './CustomText'

import TouchableNativeFeedback from './TouchableFeedback';
import { dropdown, handleScroll, DropdownElement } from './Dropdown';
import ParentComponentHeader from './ComponentHeader';
import { ConfirmationView } from './confirmation';
import { Button } from './Buttons';
import { ParentStoreChildCard as StoreCard } from './store/StoreCard';


import * as storeActions from '../store/actions/parents/storeActions'
import * as authActions from '../store/actions/parents/authActions'
import * as goalsActions from '../store/actions/parents/goalsActions'
import Colors from '../constants/Colors';
import globalStyle, { defaultStyles } from '../constants/style';


const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default


const sortCategories = { 'new': 'Newest First', 'priceLow': 'Price Low to High', 'priceHigh': 'Price High To Low' }

const categories = ['Toys', 'Beauty', 'Electronics', 'Sports', 'Activities', 'Arts & Crafts', 'Food', 'Accessories', 'Donations', 'Other']


const XButton = ({ onPress }) => {
	return <Hoverable onHoverIn={() => { console.log('start!') }} onHoverOut={() => { console.log('finish!!') }}>
		{isHovered => <TouchableNativeFeedback accessible onPress={onPress}>
			<View style={{
				zIndex: 10, opacity: isHovered ? 1 : 0.5, transform: [{ scale: isHovered ? 1.1 : 1 }],
				flex: 1, position: 'absolute', right: 0, top: 0, borderRadius: 25, height: 25, width: 25,
				backgroundColor: '#EE5060', alignItems: 'center', justifyContent: 'center'
			}}>
				<Text adjustsFontSizeToFit={true} numberOfLines={1} style={{
					textAlign: 'center', fontSize: 10,
					...defaultStyles.bold, textAlignVertical: 'center', color: 'white',
				}}>X</Text></View>
		</TouchableNativeFeedback>}
	</Hoverable>
}

const DropButton = ({ onPress, active, title }) => {
	return <Hoverable>
		{isHovered => <TouchableNativeFeedback onPress={() => onPress(title)}>
			<View style={{
				flex: 1, zIndex: 5, position: 'absolute', justifyContent: 'center', alignItems: 'center',
				right: -5, top: 10, height: 30, width: 30, opacity: isHovered ? 1 : 0.7,
				borderRadius: 15, backgroundColor: Colors.green1, transform: [{ scale: isHovered ? 1.1 : 1 }],
			}}>
				<Text style={{ color: 'white', fontSize: 15 }}>+</Text>
			</View>
		</TouchableNativeFeedback>}
	</Hoverable>

}


const ButtonContainerMobile = ({ title, image, onPress, isFocused, width }) => {
	const vmin = 1000;
	const [animation, setAnimation] = useState(new Animated.Value(0));
	const [focused, setFocus] = useState(false);

	let scale = animation.interpolate({
		inputRange: [0, 1],
		outputRange: [1, 1.1]
	})


	return <TouchableNativeFeedback onPress={onPress}>
		<View style={{ marginVertical: 15, alignItems: 'center', justifyContent: 'center', width: width * 0.3 }}>
			<View style={{ height: 20, width: 20, borderRadius: 5, borderColor: Colors.green1, borderWidth: 2, justifyContent: 'center', alignItems: 'center' }}>
				<Icon style={{ zIndex: 3, opacity: isFocused ? 1 : 0 }} name="check" size={16} color={Colors.green1} />
			</View>
			<Text style={[{ marginTop: 5, alignSelf: 'center', textAlign: 'center', },defaultStyles.bold]}>{title}</Text>
		</View>
	</TouchableNativeFeedback>

}

const sortValues = { 'Newest First': 'new', 'Price Low to High': 'priceLow', 'Price High To Low': 'priceHigh' }




class RenderShopCard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			collapse: true,
		};
	};


	getTitleFontSize(width) {
		if (width < 900 && width > 700) {
			return Platform.OS === 'web' ? '1.5em' : 20
		}
		else if (width < 700) {
			return Platform.OS === 'web' ? '1.375em' : 18
		}
		else {
			return Platform.OS === 'web' ? '1.75em' : 22
		}
	}

	render() {
		const { item, layout, itemKey } = this.props;

		return (
			<Resizable>
				{layout => {

					let blockWidth = layout.width > 800 ? layout.width * 0.2 : '100%';
					let maxWidth = 300;
					let vmin = Math.min(...[layout.width, layout.height]);
					let imageSize = vmin * 0.12

					return <TouchableNativeFeedback onPress={() => {
						this.props.navigation.navigate('StoreItem', { store: 'amazon', index: itemKey })
					}}>
						<View elevation={5} style={{
							borderWidth: 2, borderColor: Colors.green1, padding: 10,
							margin: 0.02 * vmin, marginTop: 0.01 * vmin, marginBottom: 0.05 * vmin,
							// paddingHorizontal: '5%',
							borderRadius: 15, alignSelf: 'center',
							backgroundColor: 'rgba(255,255,255,0.9)', width: blockWidth,
							height: 300,
							maxWidth: maxWidth, minHeight: Platform.OS === 'android' ? vmin * 0.7 : 0
						}}>
							{/* <DropButton title={item.title} onPress={() => this.props.onPress(item.title)} /> */}
							<XButton title={item.title} onPress={() => this.props.onPress(item.title)} />

							{/* <View style={{ borderWidth:1, justifyContent:'center', alignItems:'center'}}> */}
							<Image resizeMode={'contain'} style={{ minWidth: 120, minHeight: 120, alignSelf: 'center', zIndex: -1, width: imageSize, height: imageSize, maxHeight: 200 }} source={{ uri: item.image }} />

							{/* </View> */}
							<View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
								<View style={{ flexDirection: "row" }}>
									{[1, 2, 3].map((item, index) => <TouchableNativeFeedback key={index}>
										<Image resizeMode={"cover"} style={{ margin: 5, width: 20, height: 20, opacity: 1 }} source={require('../assets/Pennygem_Logo.png')} />
									</TouchableNativeFeedback>)}
								</View>
								{layout.width <= 850 ? <Text style={{ textAlign: 'center', color: Colors.green2, marginLeft: 0.02 * vmin, alignSelf: "center" }}>${item.price}</Text> : null}
							</View>
							<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
								<Text><Text style={[{ color: Colors.green1 }, defaultStyles.bold]}>{item.parents || 0}</Text> parents</Text>
								<Text><Text style={[{ color: Colors.green1 }, defaultStyles.bold]}>{item.kids || 0}</Text> kids</Text>
							</View>
							<View style={{}}>
								<View style={{ flexDirection: layout.width > 850 ? 'row' : 'column', marginBottom: 0.02 * vmin, }}>
									<Text numberOfLines={1} ellipsizeMode={'tail'} style={{
										paddingTop: 5, color: '#272827', fontWeight: '600',
									}}>{item.title}</Text>
									{layout.width > 850 ? <Text style={{ textAlign: 'center', color: Colors.green2, marginLeft: 0.02 * vmin, }}>${item.price}</Text> : null}
								</View>
								<View >
									<Text numberOfLines={3} style={{ color: '#272827' }}>{item.description}</Text>
								</View>
							</View>
						</View>
					</TouchableNativeFeedback>
				}}
			</Resizable>)
	}
}


class Shop extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			data: [],
			isLoading: true,
			type: 'new',
			avatar: null,
			modalVisible: false,
			settingsMobileVisible: false,
			sortButtons: [true, false, false],
			// categories:[false,false,false,false,false,false,false,false,false,false,],
			categories: {},
			tempCategories: {},
			scrolledHeader: new Animated.Value(0),
			scrolledPadding: new Animated.Value(0),
			sortMobile: 0,
			position: 0,
			raitingSelected: 3,
			age: [0, 18],
			price: [0, 100],
			sortDropdown: false,
			id: 0,
			sortBy: 'new',
			itemCategory: 'Select category',
			showing: 'all',
			stage: 0
		};
	};



	async componentDidMount() {
		const { items, setItems, } = this.props;

		const { parentId, } = this.props.auth;
		const { childIds } = this.props.parent;

		console.log('STORE CHILDREN MOUNTED ')

		this.props.actions.getChildStoreItems({parentId: parentId, childId: childIds[this.state.id], categories: JSON.parse(this.props.categories)})
			.then(result => { setItems(result.length); this.setState({ data: result }) })
		// this.props.actions.getFeaturedStoreItems()

	}

	componentWillUnmount() {

	}

	componentDidUpdate(prevProps) {
		const { items, setItems } = this.props;

		const { parentId } = this.props.auth;
		const { childIds } = this.props.parent;

		if (this.props.type !== prevProps.type) {
			console.log('UPDASTE ME')
			this.props.actions.getChildStoreItems({parentId, childId: childIds[this.state.id], categories: (this.props.categories)})
				.then(result => { setItems(result.length); this.setState({ data: result }) });
		}

		if ((this.props.categories) !== (prevProps.categories)) {
			console.log('UPDASTE ME')
			this.props.actions.getChildStoreItems({parentId, childId: childIds[this.state.id], categories: JSON.parse(this.props.categories)})
				.then(result => { setItems(result.length); this.setState({ data: result }) });
		}
	}


	renderCard({ item, }, layout) {
		const itemValue = item
		const title = itemValue?.title;
		// console.log(item.price
		console.log('item!!!!!!!!! ', item)
		// item, navigation, onGoal, onBuy
		return item && <StoreCard layout={layout} item={itemValue} auth={this.props.auth} itemKey={item.key} navigation={this.props.navigation}
			onPress={() => { this.setState({ modalVisible: true, confirmationGoal: true, title: title, price: itemValue.price, itemId: itemValue.key }) }} />
		return <RenderShopCard item={itemValue} itemKey={item.key} navigation={this.props.navigation}
			onPress={() => { this.setState({ modalVisible: true, confirmationGoal: true, title: title, price: itemValue.price, itemId: itemValue.amazon_id }) }} />
	}


	confirmationRemove(layout) {
		const { parentId, } = this.props.auth;
		const { childIds, names } = this.props.parent;

		const vmin = Math.min(...[layout.width, layout.height]);

		return <View style={{ justifyContent: 'center', alignItems: 'center' }}>
			{/* <Image style={{ width: 70, height: 70, marginTop: '10%' }} source={require('../assets/Goals/Goal_2.png')} /> */}
			<View >
				<Text style={[{ marginTop: '10%', alignSelf: 'center', }, defaultStyles.bold, globalStyle.h4]}>Remove from child store?</Text>
				<Text numberOfLines={3} style={{ marginHorizontal: 10, textAlign: 'center', alignSelf: 'center', marginVertical: 15 }}>{this.state.title}</Text>
			</View>

			<View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', marginHorizontal: 15, }}>
				<View style={{ width: '40%', }}>
					<TouchableNativeFeedback onPress={() => {
						this.setState({ modalVisible: false, confirmationGoal: false,
							data: this.state.data.filter(item=>item.key!==this.state.itemId)
						});
						this.props.setItems(this.state.data.length-1)
						console.log('data ', this.state.data,);
						// this.props.actions.addGoal(this.state.price.toString(), this.state.title, parentId, childIds[this.state.id])
						this.props.actions.removeFromChildStore({parentId, childId:childIds[this.state.id], itemId: this.state.itemId})
						// .then(()=>{})
					}}>
						<View style={{
							justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 20,
							margin: 5, backgroundColor: 'rgba(126,193,61,1)'
						}}>
							<Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>YES</Text></View>
					</TouchableNativeFeedback>
				</View>
				<View style={{ width: '40%', }}>
					<TouchableNativeFeedback onPress={() => this.setState({ modalVisible: false, confirmationActivate: false })}>
						<View style={{
							justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 20, margin: 5,
							backgroundColor: '#EE5060'
						}}>
							<Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>NO</Text></View>
					</TouchableNativeFeedback>
				</View>
			</View>
		</View>
	}



	getKidId(move) {
		const { parentId } = this.props.auth;
		const { childIds } = this.props.parent;
		const { getJobs } = this.props.actions;

		const { type, categories } = this.props;
		let categoriesJSON = JSON.parse(categories)
		console.log('type, cat', type, categories)
		let index = this.state.id + move;
		if (index > childIds.length - 1) {
			this.setState({ id: 0 });
			this.props.actions.getChildStoreItems({parentId, childId: childIds[0], categories: JSON.parse(this.props.categories)})
				.then(result => { this.setState({ data: result }) }); return 0
		}
		if (index < 0) {
			this.setState({ id: childIds.length - 1 });
			this.props.actions.getChildStoreItems({parentId, childId: childIds[childIds.length-1], categories: JSON.parse(this.props.categories)})
				.then(result => { this.setState({ data: result }) }); return 0
		}
		this.setState({ id: index });
		this.props.actions.getChildStoreItems({parentId, childId: childIds[index], categories: JSON.parse(this.props.categories)})
			.then(result => { this.setState({ data: result }) }); return 0
	}


	render() {
		const { navigation, store, auth, parent } = this.props;
		const { parentId } = auth;
		const { names, childIds, } = parent;

		return (
			<Resizable>
				{layout => {
					const vmin = Math.min(...[layout.width, layout.height]);
					const width = layout.width;
					const numColumns = width > 1300 ? 2 : (width > 1000 ? 1 : (width > 850 ? 1 : 1))

					return <>
						{<Modal
							animationIn={'zoomIn'} animationOut={'zoomOut'}
							animationInTiming={500} animationOutTiming={500}
							backdropColor={'darkgrey'} backdropOpacity={0.6}
							onBackdropPress={() => this.setState({
								modalVisible: false, confirmationDelete: false,
								confirmationPurchase: false, confirmationActivate: false
							})}
							isVisible={this.state.modalVisible}
							onRequestClose={() => {
								this.setState({
									modalVisible: false, confirmationDelete: false,
									confirmationPurchase: false, confirmationActivate: false
								})
							}}>
							<View style={{
								borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
								maxWidth: 400, paddingBottom: 10
							}}>
								<View style={{ position: 'absolute', top: 0, right: 0, zIndex: 5 }}>
									<TouchableNativeFeedback onPress={() => this.setState({
									modalVisible: false, confirmationDelete: false,
									confirmationPurchase: false, confirmationActivate: false
								})}>
										<View elevation={10} style={{ alignSelf: 'center', padding: 5, margin: 10, }}>
											<Image style={{ height: 10, width: 10 }} source={require('../assets/Delete-X.png')} />
											{/* <Text style={{ fontSize: closeFontSize, color: Colors.gray0, }}>X</Text> */}
										</View>
									</TouchableNativeFeedback>
								</View>
								{this.confirmationRemove(layout)}
							</View>
						</Modal>}

						{childIds.length > 0 ? <>
							{/* <Text>Child Items</Text> */}

							<ParentComponentHeader theme={'light'}
								containerStyle={{ marginTop: 20, marginBottom: 20 }}
								layout={layout}
								title={(Object.keys(names).length && childIds.length) ? (names[childIds[this.state.id]].toString()).toUpperCase() : ''}
								pressLeft={() => this.getKidId(-1)} pressRight={() => this.getKidId(+1)} />
							<FlatList
								key={numColumns}
								nestedScrollEnabled={false}
								numColumns={numColumns}
								showsVerticalScrollIndicator={false}
								contentContainerStyle={{ justifyContent: 'center', alignContent: 'center' }}
								style={{ zIndex: -1, flex: 1, alignSelf: 'center', paddingVertical: 0.05 * vmin }}
								// data={this.state.data}
								data={this.state.data}
								keyExtractor={(item, index) => index.toString()}
								renderItem={(item) => this.renderCard(item, layout)}
							/>
						</> :
							<View />}

						{/* </ImageBackground> */}
					</>
				}}
			</Resizable>
		)
	}
}

const styles = StyleSheet.create({

});

// export default Dashboard;
export default connect(state => {
	// console.log(Object.keys(state.vacancies));
	return ({
		auth: state.auth,
		parent: state.parent,
		store: state.store,
	})
},
	(dispatch) => ({
		actions: bindActionCreators({ ...storeActions, ...authActions, ...goalsActions }, dispatch)
	})
)(Shop);
