/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
	Animated,
	Dimensions,
	Image,
	ImageBackground,
	StyleSheet,
	ScrollView,
	View,
	Platform,
} from 'react-native';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import firebase from 'firebase/app';
import 'firebase/database';

import TouchableNativeFeedback from '../../components/TouchableFeedback'
import Text from '../../components/CustomText'
import Stars from '../../components/Stars';
import { dropdown, handleScroll, dropdownRedesign } from '../../components/Dropdown';
import { ConfirmationView } from '../../components/confirmation'

import { SectionTable, SectionRow } from '../../components/chequing/table'
import Intervals from '../../components/chequing/Intervals'

import ModalContainer from '../../components/Modal/Modal'

import { ChildHeaderCard } from '../../components/Header/HeaderBlock'
import FooterBlock from '../../components/Footer/FooterBlock'

import { useDimensions, useHover, Resizable, Hoverable } from 'react-native-web-hooks';

import * as purchasesActions from '../../store/actions/kids/purchasesActions';
import * as authActions from '../../store/actions/kids/authActions'
import Colors from '../../constants/Colors';

const database = firebase.database;

class Purchases extends React.Component {
	static navigationOptions = ({ navigation }) => {
		return {
			headerTransparent: true,
			header: null
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			type: 0,
			data: [],
			total_approved: 0,
			total_pending: 0,
			total_approved_trans: 0,
			total_pending_trans: 0,
			approved_online_purchases: [],
			approved_online: 0,
			approved_online_trans: 0,
			approved_online_display: 'none',
			approved_credit_purchases: [],
			approved_credit: 0,
			approved_credit_trans: 0,
			approved_credit_display: 'none',
			pending_online_purchases: [],
			pending_online: 0,
			pending_online_trans: 0,
			pending_online_display: 'none',
			pending_credit_purchases: [],
			pending_credit: 0,
			pending_credit_trans: 0,
			pending_credit_display: 'none',
			interval: new Date(),
			intervalId: 1,
			intervals: [false, true, false, false],
			scrolledHeader: new Animated.Value(0),
			scrolledPadding: new Animated.Value(0),
			header: false
		};
	};


	async getData(interval) {
		const { parentId, childId } = this.props.auth;
		let data_source_approved = database().ref('/users/' + parentId + '/' + childId + '/purchases/approved');
		let snapshot = await data_source_approved.orderByChild('approval_date').startAt(interval.toISOString())
			.once('value')
		let data_approved = snapshot.val();


		if (data_approved != null) {
			if (!Array.isArray(data_approved)) {
				// data_approved = Object.values(data_approved);
				data_approved = Object.keys(data_approved).map(item => { return { ...data_approved[item], key: item } });

			}
			data_approved = data_approved.filter(Boolean);
		}

		var data_source_pending = database().ref('/users/' + parentId + '/' + childId + '/purchases/requested');
		snapshot = await data_source_pending.orderByChild('request_date').startAt(interval.toISOString()).once('value')
		let data_pending = snapshot.val();
		//   data_received ++;
		if (data_pending != null) {
			if (!Array.isArray(data_pending)) {
				// data_pending = Object.values(data_pending);
				data_pending = Object.keys(data_pending).map(item => { return { ...data_pending[item], key: item } });

			}
			data_pending = data_pending.filter(Boolean);
		}

		// data_received = 1;
		var total_income = 0;
		let income = 0;
		let approved_online = 0, approved_online_trans = 0;
		let approved_credit = 0, approved_credit_trans = 0;
		let pending_online = 0, pending_online_trans = 0;
		let pending_credit = 0, pending_credit_trans = 0;

		let pending_online_purchases = [];
		let pending_credit_purchases = [];
		let approved_online_purchases = [];
		let approved_credit_purchases = [];

		for (let i in data_approved) {
			// console.log('data');
			// console.log(data[i])
			if (data_approved[i] === null) continue

			switch (data_approved[i].type) {
				// INCOME
				case 'online purchase':
					approved_online_purchases.push(data_approved[i]);
					approved_online += data_approved[i].price;
					approved_online_trans++;
					break;
				case 'credit purchase':
					approved_credit_purchases.push(data_approved[i])
					approved_credit += data_approved[i].price;
					approved_credit_trans++;
					break;
				case 'savings online purchase':
					approved_online_purchases.push(data_approved[i]);
					approved_online += data_approved[i].price;
					approved_online_trans++;
					break;
				case 'savings credit purchase':
					approved_credit_purchases.push(data_approved[i])
					approved_credit += data_approved[i].price;
					approved_credit_trans++;
					break;
			}
		}

		let total_approved = approved_online + approved_credit;
		let total_approved_trans = approved_online_trans + approved_credit_trans;


		for (let i in data_pending) {
			// console.log('data');
			// console.log(data[i])
			if (data_pending[i] === null) continue

			switch (data_pending[i].type) {
				// INCOME
				case 'online purchase':
					pending_online_purchases.push(data_pending[i]);
					pending_online += data_pending[i].price;
					pending_online_trans++;
					break;
				case 'credit purchase':
					pending_credit_purchases.push(data_pending[i])
					pending_credit += data_pending[i].price;
					pending_credit_trans++;
					break;

				case 'savings online purchase':
					pending_online_purchases.push(data_pending[i]);
					pending_online += data_pending[i].price;
					pending_online_trans++;
					break;
				case 'savings credit purchase':
					pending_credit_purchases.push(data_pending[i])
					pending_credit += data_pending[i].price;
					pending_credit_trans++;
					break;

			}
		}

		let total_pending = pending_online + pending_credit;
		let total_pending_trans = pending_online_trans + pending_credit_trans;

		this.setState({
			approved_online_purchases: approved_online_purchases,
			approved_online: approved_online,
			approved_online_trans: approved_online_trans,
			approved_credit_purchases: approved_credit_purchases,
			approved_credit: approved_credit,
			approved_credit_trans: approved_credit_trans,

			pending_online_purchases: pending_online_purchases,
			pending_online: pending_online,
			pending_online_trans: pending_online_trans,
			pending_credit_purchases: pending_credit_purchases,
			pending_credit: pending_credit,
			pending_credit_trans: pending_credit_trans,

			total_approved: total_approved,
			total_approved_trans: total_approved_trans,
			total_pending: total_pending,
			total_pending_trans: total_pending_trans,
			isLoading: false
		})

	}

	async componentDidMount() {
		this.state.interval.setMonth(new Date().getMonth() - 1);

		if (!this.props.auth.childId) {
			this.props.actions.clearAuth()
				.then(() => { this.props.navigation.navigate('Auth') })
		}

		this.getData(this.state.interval);
	}


	renderList(data, vmin) {
		return data.map((item, index) => <View style={{
			width: '100%', marginTop: 0.02 * vmin, paddingRight: 15,
			flexDirection: 'row', justifyContent: 'space-between'
		}} key={index}>
			<Text style={{ flex: 1, }}>
				{item.approval_date ?
					new Date((new Date(item.approval_date)).valueOf() + new Date().getTimezoneOffset() * 60 * 1000).toLocaleDateString("en-US")
					:
					new Date((new Date(item.request_date)).valueOf() + new Date().getTimezoneOffset() * 60 * 1000).toLocaleDateString("en-US")
				}</Text>
			<Text numberOfLines={1} style={{ flex: 1,  fontSize: 15, marginRight: 15 }}>{item.item_name}</Text>
			<Text style={{ flex: 0.4,  }}>${item.price}</Text>
		</View>)
	}

	renderPendingList(data, vmin) {
		return data.map((item, index) => <View style={{
			width: '100%', marginTop: 0.02 * vmin,
			flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'
		}} key={index}>
			<Text style={{ flex: 0.5, marginRight: 15, }}>
				{item.approval_date ?
					new Date((new Date(item.approval_date)).valueOf() + new Date().getTimezoneOffset() * 60 * 1000).toLocaleDateString("en-US")
					:
					new Date((new Date(item.request_date)).valueOf() + new Date().getTimezoneOffset() * 60 * 1000).toLocaleDateString("en-US")
				}</Text>
			<Text numberOfLines={2} style={{ flex: 1,fontSize: 15, marginRight: 15 }}>{item.item_name}</Text>
			<Text style={{ flex: 0.4,  }}>${parseFloat(item.price).toFixed(2)}</Text>
			<TouchableNativeFeedback style={{ flex: 0.4 }} onPress={() => {
				this.setState({
					cancelPurchaseModal: true,
					purchaseTitle: item.item_name, purchaseValue: item.price, purchaseId: item.key
				})
			}}>
				<Hoverable>
					{isHovered => <View style={{
						alignSelf: 'center', marginHorizontal: 10, opacity: isHovered ? 1 : 0.5, backgroundColor: '#EE5060', borderRadius: 10, zIndex: 4, height: 20, width: 20,
						justifyContent: 'center', alignItems: 'center'
					}}><Text style={{
						textAlign: 'center', fontSize: 12,
						...defaultStyles.bold, textAlignVertical: 'center', color: 'white'
					}}>X</Text></View>}
				</Hoverable>
			</TouchableNativeFeedback>
		</View>)
	}


	render() {
		const { purchases, navigation } = this.props;
		const { parentId, childId } = this.props.auth;

		return (
			<Resizable>
				{layout => {
					const vmin = Math.min(...[layout.width, layout.height]);

					return <View style={{ flex: 1, backgroundColor: 'white', paddingTop: layout.height * 0.015 }}>
						<ModalContainer visible={this.state.cancelPurchaseModal} onClose={() => { this.setState({ cancelPurchaseModal: false }) }}>
							<ConfirmationView image={require('../../assets/Purchases/binoculars_3.png')} title={'Cancel Purchase'}
								confirmText={'Yes'} rejectText={'No'}
								subtitles={[this.state.jobTitle, this.state.purchaseTitle, '$' + this.state.purchaseValue]}
								confirmPress={() => {
									this.props.actions.cancelPurchase(parentId, childId, this.state.purchaseId)
										.then(() => {
											this.getData(this.state.interval)
											// this.props.actions.getPurchases(parentId, childId, this.state.interval)
										});
									this.setState({ cancelPurchaseModal: false })
								}}
								rejectPress={() => { this.setState({ cancelPurchaseModal: false }) }}
							/>
						</ModalContainer>

						<FooterBlock onPress={() => navigation.navigate('Store')}
							label={<Image resizeMode={'contain'} style={{ width: '80%', height: '80%', }} source={require('../../assets/Store/store_icon.png')} />} />

						<ScrollView style={{ flex: 1, }}
							onScroll={(e) => handleScroll.call(this, e, layout.height)}
						>
							<ChildHeaderCard name={''} signOut={() => { }} vmin={vmin} layout={layout} clearAuth={this.props.actions.clearAuth} navigation={navigation} />
							<View style={{ flex: 1, zIndex: 1, paddingTop: layout.height * 0.02 }}>

								<View style={{ width: '100%', alignItems: 'center' }}>
									<Intervals focused={this.state.intervalId} setFocused={(index) => this.setState({ intervalId: index })} onPress={(date) => this.getData(date)} type={this.state.type}
										parentId={parentId} childId={childId} navigation={navigation} />
								</View>

								<View style={{ width: '100%' }}>
									<SectionTable title={'Approved'} value={this.state.total_approved} transactions={this.state.total_approved_trans}>
										<SectionRow data={this.state.approved_online_purchases} title={'Online Purchases'} value={this.state.approved_online}
											transactions={this.state.approved_online_trans} renderList={(data) => this.renderList(data, vmin)}
											bottomBorder={true} />
										<SectionRow data={this.state.approved_credit_purchases} title={'Credit Purchases'} value={this.state.approved_credit}
											transactions={this.state.approved_credit_trans} renderList={(data) => this.renderList(data, vmin)} />
									</SectionTable>

									<SectionTable title={'Pending'} value={this.state.total_pending} transactions={this.state.total_pending_trans}>
										<SectionRow data={this.state.pending_online_purchases} title={'Online Purchases'} value={this.state.pending_online}
											transactions={this.state.pending_online_trans} renderList={(data) => this.renderPendingList(data, vmin)}
											bottomBorder={true} />
										{/* <View style={{height:0.5, width:'100%', backgroundColor:'#94B42D'}}></View>                       */}
										<SectionRow data={this.state.pending_credit_purchases} title={'Credit Purchases'} value={this.state.pending_credit}
											transactions={this.state.pending_credit_trans} renderList={(data) => this.renderPendingList(data, vmin)} />
									</SectionTable>

								</View>

							</View>
						</ScrollView>
					</View>
				}}
			</Resizable>)
	}
}

const styles = StyleSheet.create({

});

// export default Purchases
export default connect(state => {
	return ({
		auth: state.auth,
		child: state.child,
		purchases: state.purchases
	})
},
	(dispatch) => ({
		actions: bindActionCreators({ ...purchasesActions, ...authActions }, dispatch)
	})
)(Purchases);
