/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
	Image,
	StyleSheet,
	View,
} from 'react-native';


import TouchableNativeFeedback from '../../components/TouchableFeedback'
import Text from '../../components/CustomText'

export function renderList(data, vmin) {
    return data.map((item, index) => <View style={{
        width: '100%', marginTop: 0.02 * vmin, paddingRight: 0.02 * vmin,
        flexDirection: 'row', justifyContent: 'space-between',
        borderWidth: 1
    }} key={index}>
        <Text style={{ flex: 0.8,}}>{
            item.approval_date ?
                new Date((new Date(item.approval_date)).valueOf() + new Date().getTimezoneOffset() * 60 * 1000).toLocaleDateString("en-US")
                :
                new Date((new Date(item.request_date)).valueOf() + new Date().getTimezoneOffset() * 60 * 1000).toLocaleDateString("en-US")
        }</Text>
        <Text numberOfLines={2} style={{ flex: 1,marginRight: 15 }}>{item.item_name}</Text>
        <Text style={{ flex: 0.4, }}>${item.price}</Text>
    </View>)
}

export function renderPendingList(data, vmin) {
    // console.log('datta', data)
    return data.map((item, index) => <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 0.02 * vmin, }} key={index}>
        <Text style={{ flex: 1, }}>{item.approval_date ? new Date(item.approval_date).toLocaleDateString("en-US") : new Date(item.request_date).toLocaleDateString("en-US")}</Text>
        <Text numberOfLines={2} style={{ flex: 1, marginRight: 15  }}>{item.item_name}</Text>
        <Text style={{ flex: 0.4, }}>${item.price}</Text>
        <TouchableNativeFeedback onPress={() => { this.setState({ confirmPurchaseModal: true, purchaseLink: item.link, purchaseId: item.key, purchaseTitle: item.item_name, purchaseValue: item.price }) }}>
            <View style={{ marginHorizontal: 10 }}><Image resizeMode={'contain'} style={{ flex: 1, zIndex: 4, height: 30, width: 30 }}
                source={require('../../assets/Purchases/binoculars.png')} /></View>
        </TouchableNativeFeedback>
    </View>)
}


const styles = StyleSheet.create({

});
