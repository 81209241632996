
import * as actionTypes from './actionTypes'
// import {fetchWrapper} from "../lib/fetchWrapper";
import { AsyncStorage, Platform } from "react-native";

import firebase from 'firebase/app';
import 'firebase/database';

import algoliasearch from 'algoliasearch';

import { withNavigation } from 'react-navigation';
import config from '../../../constants/config'

const firestore = firebase.firestore;
const database = firebase.database;

const client = algoliasearch('3URDKMQJ8W', '815fe4f051d2ad0a89d87ab40c543c0d');
const index = client.initIndex('test_store');

// index.setSettings({
//     ranking: ['desc(parents)']
//   })


let proxyUrl = ''
proxyUrl = 'http://178.128.243.15:80?'
proxyUrl = 'http://cors-anywhere.herokuapp.com/'
// proxyUrl = 'https://api.codetabs.com/v1/proxy?quest='
// const proxyUrl = 'https://test.cors.workers.dev/'

// const serverUrl = 'https://young-hamlet-23565.herokuapp.com/parse_url'
const serverUrl = 'http://192.99.166.26:5000/parse_url'

const categoriesStore = ['All', 'Toys', 'Beauty', 'Electronics', 'Sports', 'Activities', 'Arts & Crafts', 'Food',
    'Accessories', 'Donations', 'Other']

const sortObj = {'Most popular':'parents desc', 'Price Low to High': 'price asc', 'Price High To Low':'price desc'}

function receiveStoreItems(data) {
    //fixme
    return {
        type: actionTypes.RECEIVE_STORE,
        payload: {
            data: data,
        }
    }
}


function receiveStoreItem(data, index) {
    // fixme
    console.log('actoins!!!')
    return {
        type: actionTypes.RECEIVE_STORE_ITEM,
        payload: {
            data: data,
            index
        }
    }
}

export function sortStoreItems(data, type) {
    return dispatch => {

        // let sortable = Object.values(data);
        let sortable = Object.keys(data).map(item => { return { ...data[item], key: item } })
        // for (var item in data) {
        //     sortable.push([item, data[item]]);
        // }
        console.log('sortable', sortable)
        sortable.sort(function (a, b) {
            console.log(a.price, b.price, a.price - b.price)
            return a.price - b.price;
        });

        let objSorted = {}
        console.log('sortable sorted?', sortable)


        // for (let item in sortable) {
        //     objSorted[sortable[item].key] = sortable[item]
        // }
        sortable.forEach(function (item) {
            objSorted[item.key] = item
        })

        console.log('objSorted', objSorted)
        // dispatch(receiveStoreItems(dataObj))

    }
}

// with params
function categoryFilterQuery({filter}) {
    // return filter.map(item=>'category:'+item)
    return filter.map(item=>'category:'+item).join(' OR ')
}

export function searchStoreItems({ type, filter, prices, search, page, sort }) {
    return async dispatch => {
        let query = search
        // const ageFilter = (age[0]?`age>${age[0]}`:'') + (age[1]?`AND age<${age[1]}`:'')


        return index.search(query, {
            page,
            filters:'category:Sports',
            // sortFacetValuesBy:
            // attributesToRetrieve: ['firstname', 'lastname'],
            hitsPerPage: 20,
        })
          .then(( hits ) => {
            //   hits, nbPages, page, query,
            console.log(hits);
            return hits.hits
          });
        // dispatch(receiveStoreItems(dataObj))
    }
}


export function getStoreItems({ type, filter, prices, search, page, sort, rating }) {
    return async dispatch => {
        console.log('categoryFilterQuery({filter}) ', categoryFilterQuery({filter:Object.keys(filter)}))
        console.log('type , ', type, sort)
        // const categoryFilter = filter['All']?'':categoryFilterQuery({filter:Object.keys(filter)})
        const categoryFilter = filter['All']?[]:Object.keys(filter)

        const priceFilter = [(prices[0]?`price>${prices[0]}`:''),(prices[1]?`price<${prices[1]}`:'')]

        const body = { type, keyword:search, categoryFilter, minPrice:prices[0], maxPrice:prices[1], sort:sortObj[sort], rating }

        console.log('sort ', sort)
        // if (sort === 'asc') {
        //     await index.setSettings({
        //         ranking: ['asc(price)']
        //     })
        // }
        // else if (sort === 'desc') {
        //     await index.setSettings({
        //         ranking: ['desc(price)']
        //     })
        // }
        // else {
        //     await index.setSettings({
        //         ranking: ['desc(parents)']
        //     })
        // }

        return fetch(config.url + 'searchParentStore', {
            method: 'POST',
            // headers: {
            // 	'Accept': 'application/json',
            // 	'Content-Type': 'application/json'
            // },
            body: JSON.stringify(body)
        })
            .then(response => response.json())
            .then(response => {console.log('response parent store', response);
            return {hits:response.items,  page: 1, nbPages: 1, nbHits: response.items.length,}})

        return index.setSettings({
            searchableAttributes: [
                sort?'unordered(parents)':'unordered(price)',
              ],
            ranking: [sort?'desc(price)':'desc(parents)']

            // ranking: sort?['desc(price)', 'unordered(parents)']:['desc(parents)', 'unordered(price)']
            // ranking: [sort?'desc(price)':'desc(parents)']
        }).then(()=>index.search(search, {
            page,
            filters:'' + [categoryFilter, ...priceFilter].filter(item=>item).join(' AND '),
            // attributesToRetrieve: ['firstname', 'lastname'],
            hitsPerPage: 20,
        })
          .then(( hits ) => {
            //   hits, nbPages, page, query,
            console.log(hits);
            return hits
          }))


        // dispatch(receiveStoreItems(dataObj))
    }
}

export function getStoreItem(store, index) {
    return dispatch => {
        var data_source = database().ref('/store/').child(index)
        console.log(0)
        let data = []
        console.log(1)

        data_source.once('value')
            .then((snapshot) => {
                let answer = snapshot.val()
                console.log(2)
                console.log(answer)
                // this.setState({data:Object.values(data), isLoading:false})
                dispatch(receiveStoreItem(answer, index))

            })


    }
}

export function dropStoreItem(type, filter) {
    return dispatch => {
        if (type.includes('price')) {
            var data_source = database().ref('/store/amazon').orderByChild('price');
        }
        else {
            var data_source = database().ref('/store/amazon')
        }
        let data = []
        let filterList = filter['All'] ? [] : Object.keys(filter)
        data_source.once('value')
            // console.log('1')

            .then((snapshot) => {
                snapshot.forEach(function (child) {
                    data.push(child.val()); // NOW THE CHILDREN PRINT IN ORDER
                });
                console.log('3')
                type === 'priceLow' ? data : data.reverse();
                console.log(filter)
                data = filterList.length ? data.filter(item => filterList.includes(item.category)) : data
                // this.setState({data:Object.values(data), isLoading:false})
                dispatch(receiveStoreItems(data))

            })


    }
}



export function addToChildStore(parentId, childIds, store, itemId) {
    return async dispatch => {

        let storeItem = (await database().ref('store/'+ itemId).once('value')).val()
        // console.log(Object.keys(storeItem))
        let storeItemId = Object.keys(storeItem)[0]
        storeItem = Object.values(storeItem)[0]
        // console.log('parents ', storeItem.parents)
        // let kids = storeItem.kids ? storeItem.kids + childIds.length:childIds.length;
        let kids = childIds.length + (storeItem.kids || 0);
        // let parents = childIds.length + (storeItem.parents || 0);
        let parents = (storeItem.parents || 0);

        console.log('kids ', kids, 'parents ', parents)

        // const added =  (await database().ref('store/'+store).orderByChild(store+'_id').equalTo(itemId).once('value')).val()
        // const kids = (await database().ref('store/'+store+'/'+itemId + '/kids').once('value')).val()
        // const parents = (await database().ref('store/'+store+'/'+itemId + '/parents').once('value')).val()

        // let filterList = filter['All'] ? [] : Object.keys(filter)
        for (let i in childIds) {
            let childId = childIds[i]
            let childStoreItem = (await database().ref('users/' + parentId + '/' + childId + '/store/' +  itemId).once('value')).val()
            console.log('childStoreItem ', childStoreItem)
            if (childStoreItem === null) {
                parents += 1
            }
            database().ref('users/' + parentId + '/' + childId + '/store/' ).update({ [itemId]: (new Date()).toISOString() })
        }
        console.log('parents - final ', parents)
        database().ref('/store/' + storeItemId).update({ parents: parents })

        // console.log('1')

    }
}


function receiveFeaturedStoreItems(data) {
    //fixme
    return {
        type: actionTypes.RECEIVE_FEATURED_STORE,
        payload: {
            data: data,
        }
    }
}




export function getFeaturedStoreItems(type, filter) {
    return dispatch => {

        var data_source = database().ref('/store').orderByChild('parents').limitToFirst(10)

        data_source.once('value')
            .then((snapshot) => {
                // console.log('FEATURED snapshot.val() ', snapshot.val())
                dispatch(receiveFeaturedStoreItems(snapshot.val()))
            })
    }
}

let mapOrder = {
    'Price Low to High': 'asc',
    'Price High to Low': 'desc',
    null:null,
}

export function getChildStoreItems({parentId, childId, sort, categories}) {
    return async dispatch => {
        let data = []
        let categoriesArr = categories?Object.keys(categories):[]
        if (categoriesArr.includes('All')) {
            categoriesArr = []
        }
        const order = mapOrder[sort]
        // if (categoriesArr.length===0)
        console.log('parentId, childId, sort,categoriesArr ', parentId, childId, sort, order, categoriesArr)
        const body = {parentId, childId, sort:order, categories: categoriesArr}

        return  fetch(config.url + 'searchChildStore', {
            method: 'POST',
            // headers: {
            // 	'Accept': 'application/json',
            // 	'Content-Type': 'application/json'
            // },
            body: JSON.stringify(body)
        })
            .then(response => {return response.json()})
            .then(response => {console.log('response CHILD STOER', response); return response.data})




    }
}



export function getChildStoreItemsOld(type, filter, parentId, childId) {
    return async dispatch => {
        let data_child_items = (await database().ref('/users/' + parentId + '/' + childId + '/store').once('value')).val();
        let data_child_items_obj = data_child_items ? data_child_items : {}
        console.log(0)
        data_child_items = data_child_items ? Object.keys(data_child_items).filter(item => data_child_items[item]) : []
        console.log(1)

        console.log('Child Keys - ', data_child_items)
        if (type.includes('price')) {
            var data_source = database().ref('/store/').orderByChild('price');
        }
        else {
            var data_source = database().ref('/store/')
        }
        let data = []
        let filterList = filter['All'] ? [] : Object.keys(filter)
        return data_source.once('value')
            // console.log('1')

            .then((snapshot) => {
                // snapshot.forEach(function (child) {
                //     data.push(child.val()); // NOW THE CHILDREN PRINT IN ORDER
                // });
                let snap = snapshot.val()
                data = snap ? Object.keys(snap).map(item => { return { ...snap[item], key: item } }) : []
                console.log('3')
                type === 'priceLow' ? data : data.reverse();
                console.log('data - ', data)
                console.log('type - ', type)


                console.log(filter)
                data = filterList.length ? data.filter(item => { return filterList.includes(item.category) && data_child_items.includes(item.amazon_id) }) :
                    data.filter(item => { return data_child_items.includes(item.amazon_id) })
                if (type.includes('new')) {
                    data = data.map(item => {
                        console.log('item, data_child_items_obj', item, data_child_items_obj);
                        return { ...item, created: data_child_items_obj[item.amazon_id] }
                    })
                    data.sort((a, b) => a.created > b.created)
                    console.log('SORT BY NEWEST', data)
                }
                // this.setState({data:Object.values(data), isLoading:false})
                // dispatch(receiveStoreItems(data))
                return data
            })


    }
}



export function removeFromChildStore({parentId, childId, store, itemId}) {
    return async  dispatch => {

        const body = {parentId, childId, itemId}
        console.log('REMOVING ITEMS!!!! - ', itemId, '\n', parentId, '\n', childId)
        // database().ref('users/'+parentId+'/'+childId+'/store/'+store+'/'+itemId).remove()
        return database().ref('users/'+parentId+'/'+childId+'/store/'+itemId).remove()
        // return database().ref('users/' + parentId + '/' + childId + '/store/').update({ [itemId]: 0 })

        // return fetch(config.url + 'amazonParseUrl', {
        //     method: 'POST',
        //     body: JSON.stringify(body)
        // })
        //     .then((resp) => { return resp.json() })
    }
}



// Access-Control-Allow-Origin
export function parseDataTest(url) {
    return async dispatch => {

        fetch(url, { mode: 'cors' })
            .then(resp => { console.log(resp); return resp.text() })
            .then(resp => { console.log(resp); })

    }
}

export function parseDataFromUrl(url, category, age) {
    return async dispatch => {

        var regex = RegExp('(?:[/dp/]|$)([A-Z0-9]{10})');
        let m = url.match(regex);
        m = m[m.length - 1]
        let amazon_id = m
        const root = database().ref();
        const old_data = (await root.child('store').child('amazon').orderByChild("amazon_id").equalTo(m).once("value")).val();
        console.log('old_data')
        console.log(old_data)
        // sdfds
        if (old_data) {
            // this.setState({ success: false, reason: "Item is already in collection" })
            // return Object.keys(old_data)[0]
            return amazon_id

        }

        const token = await firebase.auth().currentUser.getIdToken()
        console.log('sdklfjasdklfgj', token, firebase.User)
        // return fetch(serverUrl, {
        return fetch(config.url + 'amazonParseUrl', {
            method: 'POST',
            mode: 'no-cors',
            headers: {
                'Authorization': 'Bearer ' + token,
                // 'Content-Type': 'application/json'
            },
            body: JSON.stringify({ url })
        })
            .then((resp) => {
                if (resp.status !== 200) {
                    console.log('Err ', resp)
                    throw 'Err'
                }
                return resp.json()
            })
            .then(async (resp) => {
                // if (html)
                console.log('resp SDKLFHSDJKLFGH', resp)
                // this.setState({ success: true, title, description, price })
                const answer = await root.child('store').child('amazon')
                    .push({
                        amazon_id: amazon_id,
                        url: url,
                        category: category,
                        description: resp.description,
                        image: resp.image,
                        minAge: age ? age[0] : null,
                        price: resp.price,
                        title: resp.title
                    })
                // console.log('answer', answer.key)
                // return answer.key
                return amazon_id
            })
    }

}

export function parseDataFromUrlOld(url) {
    return async dispatch => {

        var regex = RegExp('(?:[/dp/]|$)([A-Z0-9]{10})');
        let m = url.match(regex);
        if (!m) {
            // this.setState({ reason: 'wrong link?' })
            return 1
        }
        console.log('!!!!m')
        console.log(m[m.length - 1])
        if (m) {
            // this.setState({ amazonId: m })
        }
        m = m[m.length - 1]

        const root = database().ref();
        const old_data = (await root.child('store').child('amazon').orderByChild("amazon_id").equalTo(m).once("value")).val();
        console.log('old_data')
        console.log(old_data)
        // sdfds
        if (old_data) {
            // this.setState({ success: false, reason: "Item is already in collection" })
            return 0
        }
        // else {
        if (Platform.OS === 'web') {
            fetch(proxyUrl + url, {
                // headers:{
                //   'Access-Control-Allow-Headers': 'Origin'
                // }
            })
                .then((resp) => {
                    console.log(resp)
                    if (resp.status !== 200) {
                        console.log('Error happened')
                        throw "Didn't success!";
                    }
                    return resp.text()
                })
                .then(async (html) => {
                    // if (html)
                    let parser = new DOMParser();
                    console.log('html', html)
                    var doc = parser.parseFromString(html, "text/html");

                    let title = doc.getElementById('productTitle')
                    title = title ? title.innerText.replace(/\s+/g, ' ').trim() : title;
                    let description = doc.getElementById('productDescription')
                    description = description ? description.innerText.replace(/\s+/g, ' ').trim() : 'No description'
                    let price = ''
                    if (doc.getElementById('priceblock_ourprice')) {
                        price = doc.getElementById('priceblock_ourprice')
                    }
                    else {
                        price = doc.getElementById('priceblock_dealprice')
                    }
                    price = price ? price.innerText.replace(/\s+/g, ' ').trim() : price
                    let category = doc.getElementsByClassName('a-link-normal a-color-tertiary')
                    console.log(category)
                    let image = doc.getElementById('landingImage')
                    console.log('image')
                    console.log(image)
                    image = image ? Object.keys(JSON.parse(image.getAttribute("data-a-dynamic-image")))[0] : image
                    console.log('image')
                    console.log(image)

                    // this.setState({ success: true, title, description, price })
                    await root.child('store').child('amazon')
                        .push({
                            amazon_id: m,
                            category: categoriesStore[Math.floor(Math.random() * categoriesStore.length)],
                            description: description,
                            image: image,
                            price: price ? parseFloat(price.replace(/[^\d.-]/g, '')) : 42,
                            title: title
                        })
                })
        }
    }
}



export function rakutenSearch({ keyword, pagenumber }) {
    return async dispatch => {

        return fetch(config.url + 'productSearch', {
            method: 'POST',
            body: JSON.stringify({ keyword, pagenumber })
        })
            .then((resp) => { return resp.json() })
            .then(async (resp) => {
                console.log('resp ', resp)
                return resp
            })
            .catch(err => console.log('err?', err))
    }

}


export function addToStore({ parentId, childIds, id, url, image, category, description, title, price, age, merchantname }) {
    return async dispatch => {

        const root = database().ref();
        // return fetch(serverUrl, {
        const newItem = root.child('store').push()

        ;(await newItem).update({
            category: category,
            description: description,
            id: id,
            image: image,
            key:newItem.key,
            kids:childIds.length,
            parents:1,
            link: url,
            merchantname,
            minAge: age || null,
            price: price,
            title: title
    })

    for (let i in childIds) {
        let childId = childIds[i]
        database().ref('/users/'+parentId+'/'+childId+'/store').update({ [newItem.key]:(new Date()).toISOString() })
    }

    }

}


function getStoreItemsOutdated({ type, filter, ages, prices, search }) {
    return async dispatch => {
        let data_rakuten
        if (type.includes('price')) {
            var data_source = database().ref('/store/amazon').orderByChild('price');
            data_rakuten = database().ref('/store/rakuten').orderByChild('price');
        }
        else {
            var data_source = database().ref('/store/amazon')
            data_rakuten = database().ref('/store/rakuten')
        }

        if (search) {
            data_source = database().ref('/store/amazon').orderByChild('title').startAt(search).endAt(search+"\uf8ff");
        }

        let data = []
        let dataObj = {}
        let filterList = filter['All'] ? [] : Object.keys(filter)
        let snapshot = await data_source.once('value')
        let snapshot_rakuten = await data_rakuten.once('value')


        dataObj = { ...snapshot.val(), ...snapshot_rakuten.val() }
        console.log('dataObj!!!!!', dataObj, prices)
        // console.log('dataObj', dataObj)
        data = Object.values(dataObj)

        data = data.filter(item=>{
            let filterByCateogry = (filterList.length > 0)?filterList.includes(item.category):true
            return filterByCateogry  && !(item.minAge > ages[1]) && !(item.price > prices[1] || item.price < prices[0])
        })

        // dispatch(receiveStoreItems(dataObj))
        return data

        let first = firestore().collection("store")
        .orderBy("price")
        .limit(25);

        return first.get().then(documentSnapshots=>{
            // documentSnapshots.forEach(doc=>console.log(doc.id, '=>',doc.data()))
            return documentSnapshots.docs.map(item=>{return item.data()})
        })
        // })
    }
}



export function searchParentStore(parentId, childId) {
    return dispatch => {
        const body = { parentId, childId, }

        return fetch(config.url + 'searchParentStore', {
            method: 'POST',
            // headers: {
            // 	'Accept': 'application/json',
            // 	'Content-Type': 'application/json'
            // },
            body: JSON.stringify(body)
        })
            .then(response => response.json())
            .then(response => {console.log('response parent store', response); return response})
    }
}
