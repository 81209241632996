/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import {
    Animated,
    AsyncStorage,
    Image,
    FlatList,
    ScrollView,
    StyleSheet,
    TextInput,
    View,
    // Picker,
    Platform,
    TouchableOpacity,
} from 'react-native';

import Slider from 'rc-slider';
import Icon from 'react-native-vector-icons/FontAwesome';

import moment from 'moment';

import * as firebase from 'firebase'
import 'firebase/firestore';
import 'firebase/database';

import { useHover, useFocus, useActive, useDimensions, Resizable, Hoverable } from 'react-native-web-hooks';

import Text from '../../components/CustomText'

import TouchableNativeFeedback from '../../components/TouchableFeedback';

import Colors from '../../constants/Colors';
import globalStyles, { defaultStyles } from '../../constants/style';


const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default


const sortCategories = { 'new': 'Most popular', 'priceLow': 'Price Low to High', 'priceHigh': 'Price High To Low' }
const categories = ['Toys', 'Beauty', 'Electronics', 'Sports', 'Activities', 'Arts & Crafts', 'Food', 'Accessories', 'Donations', 'Other']

const PriceUpdated = ({ updated }) => {
    const margin = 10
    return <Text style={{ marginTop: 5, color: Colors.gray0, fontSize: 12 }}>Price as of {updated ? moment(updated).format('MMM DD, YY') : moment().format('MMM DD, YY')} {"\n"}Excludes tax and shipping fees.</Text>
}

const BuyButton = ({ onPress }) => {
    return <TouchableNativeFeedback onPress={() => onPress()}>
        <View style={{ flex: 0.5, minWidth: 100, maxWidth: 150, borderColor: Colors.gray, borderWidth: 3, borderRadius: 25, paddingHorizontal: 20, paddingVertical: 10, justifyContent: 'center', alignItems: 'center', }}><Text>Buy</Text></View>
    </TouchableNativeFeedback>
}

const DropButton = ({ onPress, active, title, color, buttonTitle }) => {
    const isHovered = false
    const size = 100
    return <TouchableNativeFeedback style={{ zIndex: 1 }} onPress={() => onPress(title)}>
        <View style={{
            zIndex: 25, position: 'absolute', justifyContent: 'center', alignItems: 'center',
            right: -size / 1.8, top: -size / 1.8,
            borderRadius: size / 2, backgroundColor: color || Colors.green1, transform: [{ scale: isHovered ? 1.1 : 1 }],
            height: size, width: size,
        }}>
            <Text style={[{ color: 'white', position: 'absolute',
            ...buttonTitle?{left: size / 5, top: size / 2.4}:{left: size / 5, top: size / 2.3} }, globalStyles.h1]}>{buttonTitle || '+'}</Text>
        </View>
    </TouchableNativeFeedback>
}

const NewDropButton = ({ onPress, active, title, buttonTitle }) => {
    const isHovered = false
    const size = 100

    return <TouchableNativeFeedback style={{ zIndex: 1 }} onPress={() => onPress(title)}>
        <View style={{
            zIndex: 25, position: 'absolute', justifyContent: 'center', alignItems: 'center',
            right: -size / 1.8, top: -size / 1.8,
            borderRadius: size / 2, backgroundColor: Colors.yellow1, transform: [{ scale: isHovered ? 1.1 : 1 }],
            height: size, width: size,
        }}>
            <Text style={[{ color: 'white', position: 'absolute', left: size / 5, top: size / 2.3 }, globalStyles.h1]}>{buttonTitle || '+'}</Text>
        </View>
    </TouchableNativeFeedback>
}

const sortValues = { 'Most popular': 'new', 'Newest First': 'new', 'Price Low to High': 'priceLow', 'Price High To Low': 'priceHigh' }


const Rating = ({ rating, vmin }) => {
    const sizem = 125
    const size = 120
    rating = rating > 2 ? rating : 3
    return <View style={{
        flex: 0.5,
        width: '100%',
        minWidth: size,
        // minWidth: sizem, zIndex: -1, width: size, maxWidth: sizem,
        height: 30,
        flexDirection: 'row', justifyContent: 'space-between'
    }}>
        {[...new Array(5)].map((item, index) => <Image resizeMode={"contain"} style={{ flex: 1, width: '15%', height: '100%', opacity: index < rating ? 1 : 0 }} source={require('../../assets/Pennygem_Logo.png')} />)}
    </View>
}

function RenderShopCard({ layout, item, auth, navigation, infoBlock, bottomBlock, children }) {
    const [collapse, setCollapse] = useState(true);

    console.log(item)
    let updated = item.updated?.value
    let blockWidth = layout.width > 1300 ? layout.width * 0.3 : '90%';
    let maxWidth = 300;
    let vmin = Math.min(...[layout.width, layout.height]);
    let margin = vmin * 0.02

    return (item ? <TouchableNativeFeedback style={Platform.OS==='ios' && defaultStyles.shadow} disabled={!!(auth.type === 1)} onPress={() => {
        item.new ? null : navigation.navigate('StoreItem', { api: null, store: item.merchantname || 'Amazon', index: item.key })
    }}>
        <View elevation={5} style={[{
            flex: 1,
            zIndex: 10,
            overflow: 'hidden',
            backgroundColor: 'white',
            justifyContent: 'space-between',
            padding: 15, marginHorizontal: 0.05 * vmin, marginBottom: 0.05 * vmin,
            borderRadius: 25,
            alignSelf: 'center',
            // backgroundColor: 'rgba(255,255,255,0.9)',
            width: blockWidth,
        }, defaultStyles.shadow]}>
            {/* this.props.onPress(item.title) */}
            {/* {(auth.type === 0 && !item.new) ? <DropButton title={item.title} onPress={() => { }} /> : null} */}
            {/* this.props.addNew(item.title) */}
            {/* {(auth.type === 0 && item.new) ? <NewDropButton title={item.title} onPress={() => { }} /> : null} */}
            {children}
            <View style={{ width: '100%', flexDirection: 'row', flex: 1, alignItems: 'flex-start' }}>
                <Image resizeMode={'contain'} style={{
                    width: '50%', height: '50%', alignSelf: 'center',
                    flex: 0.5,
                    minWidth: 125, minHeight: 125,
                    // maxWidth: 125, maxHeight: 125,
                }}
                    source={{ uri: item ? item.image : null }} />

                <View style={{ flex: 1, marginLeft: margin, }}>
                    <View><Text style={[{ color: Colors.green1, }, defaultStyles.bold, globalStyles.h3]}>${item.price}</Text></View>
                    {(auth.type === 0 && !item.new) ?
                        <View style={{ marginTop: margin, width: '80%', flexDirection: 'row', }}>
                            <Text style={{ marginRight: margin }}><Text style={[{ color: Colors.green1, }, defaultStyles.bold]}>{item.parents || 1}</Text> parents</Text>
                            <Text><Text style={[{ color: Colors.green1 }, defaultStyles.bold]}>{item.kids || 1}</Text> kids</Text>
                        </View>
                        : null}
                    <View style={{ flex: 1, }}><Text numberOfLines={3} ellipsizeMode={'tail'} style={[{ width: '80%', marginVertical: margin, }, defaultStyles.bold]}>{item.title}</Text></View>
                    <View style={{ flex: 1, }}><Text numberOfLines={3} ellipsizeMode={'tail'} style={{marginBottom: margin,}}>{item.description}</Text></View>
                    {infoBlock}
                </View>
            </View>

            <View style={{ width: '100%', marginTop: 15, flexDirection: 'row', }}>
                <Rating rating={item.rating} />
                {<View style={{ flex: 1, }}>
                    {bottomBlock}
                </View>
                }
            </View>
        </View>
    </TouchableNativeFeedback> : null)
}


const RenderChildCard = ({ layout, item, auth, navigation, onGoal, onBuy }) => {
    return <RenderShopCard layout={layout} item={item} auth={auth} navigation={navigation}
        infoBlock={<PriceUpdated updated={item.updated} />}
        bottomBlock={<BuyButton onPress={onBuy} />} >
        {<DropButton title={item.title} onPress={() => { onGoal() }} />}
    </RenderShopCard>
}

const RenderParentCard = ({ layout, item, auth, navigation, addNew, onPress }) => {
    // {auth.type !== 0 && <Text style={{}}>Approved by <Text style={{ fontFamily: 'Carmen Sans Bold' }}>{item.parents || 1}</Text> {item.parents > 1 ? 'parents' : 'parent'}</Text>}

    return <RenderShopCard layout={layout} item={item} auth={auth} navigation={navigation} >
        {(auth.type === 0 && !item.new) ? <DropButton title={item.title} onPress={() => { onPress(item.title) }} /> : null}
        {(auth.type === 0 && item.new) ? <NewDropButton title={item.title} onPress={() => { addNew(item.title) }} /> : null}
    </RenderShopCard>
}

const RenderParentStoreChildCard = ({ layout, item, auth, navigation, onPress}) => {
    return <RenderShopCard layout={layout} item={item} auth={auth} navigation={navigation}
        infoBlock={<PriceUpdated updated={item.updated} />}
        bottomBlock={null} >
        {<DropButton color={`${Colors.rose}80`} title={item.title} buttonTitle={'-'} onPress={() => { onPress() }} />}
    </RenderShopCard>
}

export const ParentStoreCard = RenderParentCard

export const ChildStoreCard = RenderChildCard

export const ParentStoreChildCard = RenderParentStoreChildCard
