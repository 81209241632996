/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
	// AsyncStorage,
	// Button,
	Dimensions,
	Image,
	ImageBackground,
	KeyboardAvoidingView,
	Platform,
	SafeAreaView,
	TouchableOpacity,
	// Platform,
	TouchableWithoutFeedback,
	StyleSheet,
	ScrollView,
	View,
	StatusBar,
} from 'react-native';

import {
	useDimensions,
	useActive,
	useFocus,
	useHover,
	useREM,
	useScaledSize,
	Hoverable, Resizable
} from 'react-native-web-hooks';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import DatePicker from 'react-native-datepicker'


import * as ImagePicker from 'expo-image-picker';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';


import TouchableNativeFeedback from '../../components/TouchableFeedback'
import FloatingLabelInput from '../FloatingLabelInput/FloatingLabelInput'
import Text from '../../components/CustomText'
import { Button } from '../../components/Buttons'
import ParentComponentHeader from '../../components/ComponentHeader'
import { ConfirmationView, RejectionView } from '../../components/confirmation'
import { DropdownElement } from '../../components/Dropdown'

import * as chequingActions from '../../store/actions/parents/chequingActions'
import * as authActions from '../../store/actions/parents/authActions'
import Colors from '../../constants/Colors';

import {defaultStyles} from '../../constants/style'

const auth = firebase.auth;
const database = firebase.database;

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const salaryFreqs = { 'Monthly': 1, 'Bi-weekly': 2, 'Weekly': 4, }
const salaryFreqsReversed = { 1: 'Monthly', 2: 'Bi-weekly', 4: 'Weekly', }

function formatInputValue(str) {
	return (str || str === 0) ? str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".") : ''
}

function formatInterestRate(str) {
	return (str && str !== '%') ? parseFloat(str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".")) + '%' : ''
}


Date.prototype.yyyymmdd = function () {
	var mm = this.getMonth() + 1; // getMonth() is zero-based
	var dd = this.getDate();

	return [this.getFullYear(),
	(mm > 9 ? '' : '0') + mm,
	(dd > 9 ? '' : '0') + dd
	].join('-');
};


class Settings extends React.Component {
	static navigationOptions = ({ navigation }) => {
		return {
			// headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
			headerTransparent: true,
			headerLeft: null,
			header: null
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			data: [],
			isLoading: true,
			isFocused: false,
			hasCameraPermission: null,
			//  image: '0',
			image: null,
			emailChange: false,
			nameChange: false,
			oldPassword: '',
			password: '',
			confirmPassword: '',
			id: 0,
			allowanceStart: (new Date()).yyyymmdd()
			//  image: 'file:///data/user/0/host.exp.exponent/cache/ExperienceData/%2540dtsvetkov%252Fpennygembank/ImagePicker/cc28e74d-706e-4020-a1f0-00192ee0b38c.jpg',
			// type: Camera.Constants.Type.back,
		};
	};

	componentDidMount() {
		const { parentId } = this.props.auth;
		const { childIds } = this.props.parent;

		this.props.actions.getSettings(parentId, childIds[this.props.id])
			.then(settings => { console.log('settings!!', settings); this.setState({ ...settings }) })
	}

	componentDidUpdate(prevProps) {

		if ((this.props.id) !== (prevProps.id)) {
			const { parentId, childIds } = this.props.auth;
			this.props.actions.getSettings(parentId, childIds[this.props.id])
			.then(settings => { console.log('settings!!', settings); this.setState({ ...settings }) })
		}
	}


	render() {
		const { layout, actions, id, settings } = this.props;

		const { parentId, } = this.props.auth;
		const { childIds, names, isLoading, } = this.props.parent;

		console.log('Object.keys(childIds).length')
		console.log(Object.keys(childIds).length)
		console.log((childIds))
		console.log('parentId - ', parentId)

		const vmin = Math.min(...[layout.width, layout.height]);
		let width = layout.width;
		const closeFontSize = Platform.OS === 'web' ? (width > 850 ? '2.25em' : '1.625em') : (40)
		// const {int_rate, withdraw_fee, withdraw_limit, min_balance, monthly_fee, salary_amt, salary_freq,
		//     transact_fee, transact_limit} = childIds[this.state.id]?settings[childIds[this.state.id]]:{}
		return (<View>

			{<View onLayout={e => { this.setState({ childPos: e.nativeEvent.layout.y }) }} style={{ width: '100%' }}>

				<View style={{ flexWrap: 'wrap', width: '100%', alignItems: 'center', marginTop: 10 }}>
					<Text style={{ fontSize: 30, ...defaultStyles.bold, color: Colors.gray0, marginVertical: 0.04 * vmin }}>Account Fees</Text>
					<Text style={{
						fontSize: 20, alignSelf: 'flex-start', marginLeft: '10%', ...defaultStyles.bold, color: '#7ec13d', marginBottom: 10
					}}>Chequing Account</Text>
					<FloatingLabelInput additionalSign={'$'} keyboardType={Platform.OS !== 'web' ? 'number-pad' : 'default'} label={'Minimum Balance'}
						value={formatInputValue(this.state.min_balance)}
						onChangeText={text => this.setState({ min_balance: text })} />
					<View style={{ margin: 5 }}></View>
					<FloatingLabelInput additionalSign={'$'} label={'Monthly Fee'} value={formatInputValue(this.state.monthly_fee)}
						onChangeText={text => this.setState({ monthly_fee: text })} />
					<View style={{ margin: 5 }}></View>
					<FloatingLabelInput keyboardType={Platform.OS !== 'web' ? 'number-pad' : 'default'} label={'Allowed Monthly Transactions'}
						value={formatInputValue(this.state.transact_limit)}
						onChangeText={text => this.setState({ transact_limit: text })} />
					<View style={{ margin: 5 }}></View>
					<FloatingLabelInput additionalSign={'$'} label={'Transactions Fee'} value={formatInputValue(this.state.transact_fee)}
						onChangeText={text => this.setState({ transact_fee: text })} />

					<Text style={{
						fontSize: 20, marginTop: 20, alignSelf: 'flex-start', marginLeft: '10%',
						...defaultStyles.bold, color: '#7ec13d', marginBottom: 10
					}}>Savigns Account</Text>

					<FloatingLabelInput label={'Interest Rate'} value={formatInterestRate(this.state.int_rate)}
						onChangeText={text => this.setState({ int_rate: text })} />
					<View style={{ margin: 5 }}></View>
					<FloatingLabelInput label={'Allowed Monthly Withdrawals'} value={formatInputValue(this.state.withdraw_limit)}
						onChangeText={text => this.setState({ withdraw_limit: text })} />
					<View style={{ margin: 5 }}></View>
					<FloatingLabelInput additionalSign={'$'} label={'Withdrawal Fee'} value={formatInputValue(this.state.withdraw_fee)}
						onChangeText={text => this.setState({ withdraw_fee: text })} />
					<Button styles={{ width: '80%', alignSelf: 'center', marginVertical: vmin * 0.05 }} textStyle={{ width: '100%', textAlign: 'center' }}
						title={'Save Settings'} onPress={() => {
							this.props.actions.updateSettings(parentId, childIds[id],
								{ min_balance: this.state.min_balance, monthly_fee: this.state.monthly_fee, transact_limit: this.state.transact_limit, transact_fee: this.state.transact_fee },
								{ withdraw_limit: this.state.withdraw_limit, withdraw_fee: this.state.withdraw_fee, int_rate: this.state.int_rate / 100 }
							); this.props.navigation.navigate('Dashboard')
						}} />
				</View>

				<View style={{ flexWrap: 'wrap', width: '100%', alignItems: 'center', marginTop: 10 }}>
					<Text style={{ fontSize: 30, ...defaultStyles.bold, color: Colors.gray0, marginVertical: 0.04 * vmin }}>Account Allowance</Text>
					<FloatingLabelInput additionalSign={'$'} label={'Allowance Amount'}
						value={formatInputValue(this.state.salary_amt)} onChangeText={text => this.setState({ salary_amt: text })} />
					<View style={{ margin: 5 }}></View>
					{/* <FloatingLabelInput label={'Allowance Frequency'} value={this.state.salary_freq ?
													this.state.salary_freq.toString() : ''}
														onChangeText={text => this.setState({ salary_freq: text })} /> */}
					<DropdownElement containerStyle={{ zIndex: 2, width: '100%', alignSelf: 'center' }}
						onPress={(val) => { this.setState({ salary_freq: val }) }} defaultValue={this.state.salary_freq}
						categories={Object.keys(salaryFreqs)}></DropdownElement>
					<View style={{ width: '80%', paddingLeft: 20, }}>
						<Text style={{ alignSelf: 'flex-start', ...defaultStyles.bold, fontSize: 14, color: '#7ec13d' }}>Allowance Frequency</Text>
					</View>
					<View style={{ margin: 5 }}></View>
					{Platform.OS === 'web' ? <React.Fragment>
						<View style={{ width: '80%', marginTop: 10, alignItems: 'center', borderColor: Colors.green1, borderWidth: 2, borderRadius: 10, height: 40, justifyContent: 'center' }}>
							<input min={(new Date()).yyyymmdd()} onChange={(e) => { this.setState({ allowanceStart: e.target.value }) }}
								style={{ color: Colors.green1, width: '100%', background: 0, boxShadow: 'none', borderStyle: 'none', borderTopStyle: 'none', borderBottomStyle: 'none' }}
								type="date" value={this.state.allowanceStart} placeholder="YYYY DD MM" />
						</View>
					</React.Fragment> :
						<View style={{ width: '100%', height: 40, marginTop: 5, zIndex: 1, borderWidth: 2, borderRadius: 10, borderColor: Colors.green1 }}>
							<DatePicker
								style={{ marginLeft: 0, width: 220, }}
								date={this.state.allowanceStart}
								mode="date" placeholder="select date" format="YYYY-MM-DD"
								minDate={(new Date()).toISOString()}
								confirmBtnText="Confirm" cancelBtnText="Cancel"
								showIcon={false}
								customStyles={{
									dateInput: { alignSelf: 'center', marginRight: '5%', borderWidth: 0 }, dateText: { color: Colors.green1 },
									// ... You can check the source to find the other keys.
								}}
								onDateChange={(date) => { this.setState({ jobDeadline: date }) }}
							/>
						</View>}

					<View style={{ width: '80%', paddingLeft: 20, }}>
						<Text style={{ alignSelf: 'flex-start', ...defaultStyles.bold, fontSize: 14, color: '#7ec13d' }}>Allowance Frequency</Text>
					</View>
				</View>


				<View style={{ zIndex: -11, }}>
					<Button styles={{ width: '80%', alignSelf: 'center', marginVertical: vmin * 0.05 }} textStyle={{ width: '100%', textAlign: 'center' }}
						title={'Save Allowance Changes'} onPress={() => {
							if (parseFloat(this.state.salary_amt.toString().replace(/\D/g, ''))) {
								this.props.actions.updateAllowance(parentId, childIds[id],
									{ salary_amt: this.state.salary_amt, salary_freq: this.state.salary_freq, allowanceStart: this.state.allowanceStart }
								).then(() => {
									let newDate = new Date()
									newDate.setMonth(newDate.getMonth() - 1);
									this.props.actions.getChequing(newDate, parentId, childIds[id], this.props.navigation)
								});; this.props.navigation.navigate('Dashboard');

								this.props.actions.sendAllowanceJob(parentId, childIds[id],
									{
										salary_amt: this.state.salary_amt, salary_freq: this.state.salary_freq,
										allowanceStart: this.state.allowanceStart
									})
							}
							else {
								this.props.navigation.navigate('Dashboard');
							}
						}} />
				</View>
			</View>}
		</View>
		)
	}
}

const styles = StyleSheet.create({

});

export default Settings;
// export default connect(state => {
//     // console.log(Object.keys(state.vacancies));
//     return ({
//         auth: state.auth,
//     })
// },
//     (dispatch) => ({
//         actions: bindActionCreators({ ...authActions, ...chequingActions }, dispatch)
//     })
// )(Settings);
