const tintColor = '#2f95dc';

export default {
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  
  green1: '#7ec13d',
  green2:'#94B42D',
  green3:'#106433',
  // gray: '#414042',
  gray0: '#666666',
  gray1: '#7d7d7d',
  red: 'rgb(238, 80, 96)', // #ee5060
  transparentWhite:'rgba(255,255,255,0.4)',
  purple: '#f158ac',
  orange: '#ec8a27',
  green: '#b1cc1f',
  // yellow: '#ffd61c',
  yellow: '#f6c71d',
  yellow1: '#fdb608',
  rose: '#ee5060',
  blue: '#0adcd1',
  black:  '#282828', // #282828
  gray: '#c3c3c3',
  navy: '#0adcd1'
  // yellow: 'rgba(220, 200, 28, 1)'
};
