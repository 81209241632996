/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
	ActivityIndicator,
	Animated,
	Dimensions,
	Image,
	ImageBackground,
	StyleSheet,
	ScrollView,
	View,
	Platform,
	TouchableOpacity,
	TouchableWithoutFeedback,
	StatusBar,
} from 'react-native';


import {
	useDimensions,
	useHover,
} from 'react-native-web-hooks';
import Icon from 'react-native-vector-icons/FontAwesome';

import TouchableNativeFeedback from '../../components/TouchableFeedback'
import Text from '../../components/CustomText'

import Colors from '../../constants/Colors'
import globalStyles,{ defaultStyles } from '../../constants/style'

const intervalsData = [{ number: 1, title: 'week' }, { number: 1, title: 'month' }, { number: 6, title: 'months' }, { number: 1, title: 'year' }]

function getDate(index) {
	let newDate = new Date();
	if (index === 0) {
		newDate.setDate(newDate.getDate() - 7)
	}
	if (index === 1) {
		newDate.setMonth(newDate.getMonth() - 1)

	}
	if (index === 2) {
		newDate.setMonth(newDate.getMonth() - 6)

	}
	if (index === 3) {
		newDate.setFullYear(newDate.getFullYear() - 7)
	}
	return newDate
}

const IntervalButton = React.forwardRef(({ onPress, type, number, title, focused }, ref) => {
	const {
		window: { width, height, fontScale, },

	} = useDimensions();
	const { isHovered } = useHover(ref);
	const [animation, setAnimation] = useState(new Animated.Value(0));

	let scale = animation.interpolate({
		inputRange: [0, 1],
		outputRange: [1, 1.1]
	})

	return <TouchableNativeFeedback onPress={() => { onPress(); }}>
		<Animated.View
			ref={ref}
			style={{
				// transform: [{ scale: scale }],
				width: width * 0.2, height: width * 0.22, maxHeight: 90, maxWidth: 85,
				boxShadow: focused ? defaultStyles.intervalBoxShadow : '',
				borderRadius: 20, borderWidth: focused ? 0 : 3, borderColor: Colors.gray,
				justifyContent: 'center', alignItems: 'center',
				backgroundColor: focused ? Colors.yellow1 : 'white',
			}}>
			<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
				<Text style={[{ color: focused ? 'white' : Colors.black }, defaultStyles.bold, globalStyles.h3]}>{number}</Text>
				<Text style={{ color: focused ? 'white' : Colors.black, textAlign: 'center', width: '100%' }}>{title}</Text>
			</View>
		</Animated.View>
	</TouchableNativeFeedback>
})

const Intervals = ({ onPress, parentId, childId, navigation, focused, setFocused }) => {
	// const [focused, setFocused] = useState(1);
	return <View style={{ width: '90%', maxWidth: 600, flexDirection: 'row', justifyContent: 'space-between' }}>
		{intervalsData.map((item, index) => <IntervalButton key={index}
			onPress={() => {
				setFocused(index);
				// setFocused(index); console.log(getDate(index), typeof getDate(index));
				onPress(getDate(index), parentId, childId, navigation)
			}}
			focused={focused === index} number={item.number} title={item.title} />)}
	</View>
}


export default Intervals