// import { appConstants } from "";
import * as actionTypes from '../../actions/parents/actionTypes'


const initialState = {
    email: '',
    isLoading: true,
    user: {},
    id: 0,
    parentId: '',
    childIds: [],
    names: {},
    settings: {},
    parentProfile: {},
    newKid: {
        parent: {},
        profile: {},
        allowance: {}
    },
    dashboard: {
        chequing: 0,
        savings: 0,
        spent: 0,
        goals: 0,
        jobs: 0,
        purchases_approved: 0,
        purchases_pending: 0
    }
    // parentId:'XcbB7fIw6pgbjoqxhmb9FwZb4BL2'
};


export default function auth(state = initialState, action) {
    let email = state.email;

    switch (action.type) {

        case actionTypes.USER_LOGIN:
            console.log('user.user.uid parent -', action.payload.user.user.uid)
            return {
                ...state,
                isLoading: false,
                user: action.payload.user,
                userId: action.payload.user.user.uid,
                parentId: action.payload.parentId
            };

        case actionTypes.USER_SIGNUP:
            console.log('I AM HERE AT SIGNUP ', action.payload.user.uid)
            return {
                ...state,
                isLoading: false,
                user: action.payload.user,
                parentId: action.payload.user.uid
            };

        case actionTypes.REMEMBER_ME:
            email = action.payload.email;
            return { ...state, email };


        case actionTypes.CLEAR_AUTH:
            email = state.email;
            return { ...initialState, email };
        // return {...state, ...initialState};

        case actionTypes.USER_LOGOUT:
            email = state.email;
            return { ...initialState, email };
        // return initialState;

        case actionTypes.RECEIVE_CHILDREN:
            console.log('action.payload.children', action.payload.children)
            return {
                ...state,
                isLoading: false,
                childIds: action.payload.children ? Object.values(action.payload.children) : []
            };
        case actionTypes.RECEIVE_CHILD_NAME:

            return {
                ...state,
                isLoading: false,
                names: { ...state.names, [action.payload.childId]: action.payload.name }
            };
        case actionTypes.CREATE_CHILD:

            return {
                ...state,
                isLoading: false,
                names: { ...state.names, [action.payload.childId]: action.payload.name },
                childIds: [...state.childIds, action.payload.childId]

            };

        case actionTypes.SAVE_SETTINGS:
            // console.log('user.user.uid ',action.payload.user.user.uid)
            return {
                ...state,
                settings: { ...state.settings, [action.payload.childId]: action.payload.settings }
            };

        case actionTypes.CHANGE_INDEX:
            // console.log('user.user.uid ',action.payload.user.user.uid)
            let index = state.id + action.payload.move;
            if (index >= state.childIds.length) {
                index = 0
            }
            else if (index < 0) {
                index = state.childIds.length - 1

            }
            else {
                // ...
            }

            return {
                ...state,
                id: index
            };

        case actionTypes.SAVE_KID_DATA:
            // console.log('user.user.uid ',action.payload.user.user.uid)
            return {
                ...state,
                newKid: { ...state.newKid, [action.payload.type]: action.payload.data }
            };

        case actionTypes.CLEAR_KID_DATA:
            // console.log('user.user.uid ',action.payload.user.user.uid)
            return {
                ...state,
                newKid: initialState.newKid
            };

        case actionTypes.SAVE_PARENT_PROFILE:
            // console.log('user.user.uid ',action.payload.user.user.uid)
            return {
                ...state,
                parentProfile: { ...state.parentProfile, ...action.payload }
            };

        case actionTypes.REMOVE_EXPENSE:
            console.log('state.settings.expenses[action.payload.expenseId] ', state.settings, action.payload.expenseId)
            delete state.settings[action.payload.childId].expences[action.payload.expenseId]
            return {
                ...state,
                settings: { ...state.settings }
            };

        case actionTypes.CREATE_EXPENSE:
            console.log('state.settings.expenses[action.payload.expenseId] ', state.settings, action.payload.expenseId)
            // delete state.settings[action.payload.childId].expences[action.payload.expenseId]
            return {
                ...state,
                // settings: { ...state.settings, [action.payload.childId]:{...state.settings[action.payload.childId], expences:{...state.settings[action.payload.childId].expences, {}}} }
            };

        case actionTypes.RECEIVE_DASHBOARD_DATA:
            return {
                ...state,
                dashboard: {...action.payload}
            };

        default:
            return state
    }
}