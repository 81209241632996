
const projectId = 'pennygem-dcc98';
const apiKey = "AIzaSyDCPsVALoc1GXZWLzxIWqYqCAs_Ax52YdE";
const authDomain = "pennygem-dcc98.firebaseapp.com";
const databaseURL = "https://pennygem-dcc98.firebaseio.com";
const storageBucket = "pennygem-dcc98.appspot.com";
const messagingSenderId = "78736420530";
const appId = "1:78736420530:web:32dc3732e80097b7";

let configBuild = {}

let config = {
	projectId: 'pennygem-dcc98',
	apiKey: "AIzaSyDCPsVALoc1GXZWLzxIWqYqCAs_Ax52YdE",
	authDomain: "pennygem-dcc98.firebaseapp.com",
	databaseURL: "https://pennygem-dcc98.firebaseio.com",
	storageBucket: "pennygem-dcc98.appspot.com",
	messagingSenderId: "78736420530",
	appId: "1:78736420530:web:32dc3732e80097b7",
}

// configBuild = {
config = {
	projectId: "website-ed816",
	apiKey: "AIzaSyAyxAdlZbl9rYXG7uqg2OKBBZEUqrZnRwc",
	authDomain: "website-ed816.firebaseapp.com",
	databaseURL: "https://website-ed816.firebaseio.com",
	storageBucket: "website-ed816.appspot.com",
	messagingSenderId: "141497077893",
	appId: "1:141497077893:web:8877fe4e46fc0b648dd9ec",
	measurementId: "G-WQF9YN7S4R"
}


export default {

	...config,

	// measurementId,

	url: 'https://us-central1-' + config.projectId + '.cloudfunctions.net/'
};
