/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import {
	Animated,
	AsyncStorage,
	Dimensions,
	Image,
	ImageBackground,
	FlatList,
	ScrollView,
	StyleSheet,
	TextInput,
	//   Text as DefaultText,
	View,
	Platform,
	TouchableOpacity,
} from 'react-native';

import { database, storage } from 'firebase';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { useHover, useFocus, useActive, useDimensions, Resizable, Hoverable } from 'react-native-web-hooks';

import moment from 'moment';

import Text from '../../components/CustomText'
import TouchableNativeFeedback from '../../components/TouchableFeedback';
import { dropdown, dropdownParent, handleScroll } from '../../components/Dropdown';
import ParentComponentHeader from '../../components/ComponentHeader';
import { AddChildren } from '../../components/store/SelectChildren';
import StoreHeader from '../../components/store/StoreHeader';

import * as storeActions from '../../store/actions/parents/storeActions'
import * as authActions from '../../store/actions/parents/authActions'
import * as goalsActions from '../../store/actions/parents/goalsActions'
import Colors from '../../constants/Colors';
import globalStyle, { defaultStyles } from '../../constants/style';


const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const Rating = ({ rating }) => {
	console.log(rating)
	rating = rating > 2 ? rating : 3
	return <View style={{ flexDirection: 'row' }}>
		{[1, 2, 3, 4, 5].map(item => <View>
			<Image resizeMode={"cover"} style={{ width: 30, height: 30, opacity: rating < item ? 0.5 : 1 }} source={require('../../assets/Pennygem_Logo.png')} />
		</View>)}
	</View>
}


class Dashboard extends React.Component {
	static navigationOptions = ({ navigation }) => {
		return {
			header: null
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			data: [],
			isLoading: true,
			type: 'new',
			avatar: null,
			modalVisible: false,
			settingsMobileVisible: false,
			sortButtons: [true, false, false],
			// categories:[false,false,false,false,false,false,false,false,false,false,],
			categories: {},
			tempCategories: {},
			scrolledHeader: new Animated.Value(0),
			scrolledPadding: new Animated.Value(0),
			sortMobile: 0,
			position: 0,
			raitingSelected: 3,
			age: [3, 8],
			price: [50, 170],
			id: 0,
			storeKids: {}
		};
	};



	async componentDidMount() {
		// console.log('GLOBASL', global.window)
		const store = this.props.navigation.getParam('store')
		const index = this.props.navigation.getParam('index')
		// if (this.props.store.data[index] === undefined) {
		this.props.actions.getStoreItem(store, index);
		// }
	}

	componentWillUnmount() {
	}

	defineValue(width, height, a, b, c) {
		if (width < 850 && width < height) {
			return a
		}
		else if (width < 850 && width > height) {
			return b
		}
		else {
			return c
		}
	}

	logout = async () => {
		// this.props.actions.logout();
		await AsyncStorage.clear();
		// this.props.navigation.navigate('Login')
	}


	handleScroll(e, height) {
		if (e.nativeEvent.contentOffset.y / height > 0.1 && this.state.header !== true) {
			// this.props.navigation.setParams({scrolledHeader:true})
			Animated.timing(this.state.scrolledHeader, {
				toValue: 1,
				duration: 1000,
			}).start();
			Animated.timing(this.state.scrolledPadding, {
				toValue: 1,
				duration: 200,
			}).start();
			this.setState({ header: true })
			return 0
		}

		if (e.nativeEvent.contentOffset.y / height < 0.1) {
			// this.props.navigation.setParams({scrolledHeader:false})
			Animated.timing(this.state.scrolledHeader, {
				toValue: 0,
				duration: 1000,
			}).start();
			Animated.timing(this.state.scrolledPadding, {
				toValue: 0,
				duration: 200,
			}).start();
			this.setState({ header: false })
			return 0
		}
	}

	getKidId(move) {
		const { parentId, } = this.props.auth;
		const { childIds } = this.props.parent;

		const { getJobs } = this.props.actions;
		let index = this.state.id + move;
		if (index > childIds.length - 1) { this.setState({ id: 0 }); return 0 }
		if (index < 0) { this.setState({ id: childIds.length - 1 }); return 0 }
		this.setState({ id: index }); return 0
	}

	confirmationGoal(layout) {
		const { parentId, } = this.props.auth;
		const { childIds, names } = this.props.parent;

		const vmin = Math.min(...[layout.width, layout.height]);
		console.log('childIds', childIds)
		console.log('childIds', names)
		const itemId = this.props.navigation.getParam('index', false)
		const item_id = this.props.store.data[itemId].amazon_id;
		console.log('StoreItem - itemId, ', itemId, this.props.store.data[itemId].amazon_id)
		return <View style={{ justifyContent: 'center', alignItems: 'center' }}>
			<Image style={{ width: 70, height: 70, marginTop: '10%' }} source={require('../../assets/Goals/Goal_2.png')} />
			<View>
				<Text style={[{ color: Colors.gray }, globalStyle.h3]}>Add to store?</Text>
			</View>

			{/* <ParentComponentHeader layout={layout} theme={'light'} containerStyle={{ width: '80%', marginTop: 0.03 * vmin, marginBottom: 0.03 * vmin }}
        title={names[childIds[this.state.id]]}
        pressLeft={() => this.getKidId(-1)}
        pressRight={() => this.getKidId(+1)}
      /> */}

			<AddChildren
				kids={this.state.storeKids}
				names={names}
				setKid={(item) => { this.setState({ storeKids: { ...this.state.storeKids, [item]: !this.state.storeKids[item] } }) }}
				removeKid={(item) => { this.setState({ storeKids: { ...this.state.storeKids, [item]: false } }) }}
			/>

			<View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', marginHorizontal: 15, }}>
				<View style={{ width: '40%', }}>
					<TouchableNativeFeedback onPress={() => {
						this.setState({ modalVisible: false, confirmationGoal: false, });
						// this.props.actions.addGoal(this.state.price.toString(), this.state.title, parentId, childIds[this.state.id])
						itemId ? this.props.actions.addToChildStore(parentId, Object.keys(this.state.storeKids).filter(item => this.state.storeKids[item]), 'amazon', item_id) : null
						// this.props.actions.addToChildStore(parentId, childIds[this.state.id], 'amazon',this.state.itemId)
					}}>
						<View style={{
							justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
							margin: 5, backgroundColor: 'rgba(126,193,61,1)'
						}}>
							<Text style={[{  marginVertical: 5, color: 'white' }, defaultStyles.bold]}>YES</Text></View>
					</TouchableNativeFeedback>
				</View>
				<View style={{ width: '40%', }}>
					<TouchableNativeFeedback onPress={() => this.setState({ modalVisible: false, confirmationActivate: false })}>
						<View style={{
							justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10, margin: 5,
							backgroundColor: '#EE5060'
						}}>
							<Text style={[{ marginVertical: 5, color: 'white' }, defaultStyles.bold]}>NO</Text></View>
					</TouchableNativeFeedback>
				</View>
			</View>
		</View>
	}

	confirmationBuy() {
		const { parentId, childId } = this.props.auth;

		return <View style={{ justifyContent: 'center', alignItems: 'center' }}>
			<Image style={{ width: 70, height: 70, marginTop: '10%' }} source={require('../../assets/Goals/Goal_2.png')} />
			<View style={{ marginVertical: 15, alignItems: 'center' }}><Text style={{ fontSize: 18, margin: 5, color: '#5aca3c' }}>Activate</Text>
				<Text style={{ fontSize: 20, margin: 5, color: '#5aca3c', }}>Goal</Text>
			</View>
			<View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', marginHorizontal: 15, }}>
				<View style={{ width: '40%', }}>
					<TouchableNativeFeedback onPress={() => {
						this.setState({ modalVisible: false, confirmationActivate: false });
						this.props.actions.activateGoal(this.state.confirmGoalId, parentId, childId).then(() => { this.setState({ isLoading: true }); this.props.actions.getGoals(parentId, childId).then(this.setState({ isLoading: false })) })
					}}>
						<View style={{
							justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
							margin: 5, backgroundColor: 'rgba(126,193,61,1)'
						}}>
							<Text style={[{ fontSize: 19, marginVertical: 5,color: 'white' }, defaultStyles.bold]}>YES</Text></View>
					</TouchableNativeFeedback>
				</View>
				<View style={{ width: '40%', }}>
					<TouchableNativeFeedback onPress={() => this.setState({ modalVisible: false, confirmationActivate: false })}>
						<View style={{
							justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10, margin: 5,
							backgroundColor: '#EE5060'
						}}>
							<Text style={[{ fontSize: 19, marginVertical: 5, color: 'white' }, defaultStyles.bold]}>NO</Text></View>
					</TouchableNativeFeedback>
				</View>
			</View>
		</View>
	}


	navigateToAmazon() {
		const itemId = this.props.navigation.getParam('index', false)
		const item_id = this.props.store.data[itemId].amazon_id;
		const link = this.props.store.data[itemId].link;

		let extUrl = link || 'https://amazon.ca/dp/' + item_id

		Platform.OS === 'web' ? window.open(extUrl, '_blank') :
			Linking.openURL(extUrl);
	}



	render() {
		const { navigation, auth, parent } = this.props;
		const { childIds, names } = parent;
		const index = navigation.getParam('index')

		const { title, description, image, price, merchantname, updated, parents } = this.props.store.data[index] || {}

		return (
			<Resizable>
				{layout => {
					const vmin = Math.min(...[layout.width, layout.height]);
					const width = layout.width;
					return <View style={{ flex: 1, backgroundColor: 'white' }}>

						{/* {dropdown.call(this, vmin, width, false, true)} */}
						{auth.type === 0 ?
							null :
							<TouchableNativeFeedback style={{ zIndex: 2 }} onPress={() => navigation.navigate('Store')}>
								<View style={{ zIndex: 2, position: 'absolute' }}>
									<Text style={[{ color: Colors.green1, zIndex: 3,  margin: 10, }, globalStyle.h1, defaultStyles.bold]}>{'<'}</Text>
								</View>
							</TouchableNativeFeedback>}

						{<Modal
							animationIn={'zoomIn'} animationOut={'zoomOut'}
							animationInTiming={500} animationOutTiming={500}
							backdropColor={'darkgrey'}
							backdropOpacity={0.6}
							onBackdropPress={() => this.setState({
								modalVisible: false, confirmationDelete: false,
								confirmationPurchase: false, confirmationActivate: false
							})}
							isVisible={this.state.modalVisible}
							onRequestClose={() => {
								this.setState({
									modalVisible: false, confirmationDelete: false,
									confirmationPurchase: false, confirmationActivate: false
								})
							}}>
							<View style={{
								borderRadius: 15, backgroundColor: 'white', width: '80%', alignSelf: 'center',
								maxWidth: 400, paddingBottom: 10
							}}>
								<View style={{}}>
									{this.state.confirmationGoal ? this.confirmationGoal(layout) : null}
									{this.state.confirmationBuy ? this.confirmationBuy() : null}
								</View>
							</View>
						</Modal>}

						<View style={{ flex: 1, width: '100%', height: Platform.OS === 'web' ? '100vh' : '100%' }}>
							<ScrollView ref={ref => this._scroll = ref}
								style={{ flex: 1, zIndex: -1 }}
								// stickyHeaderIndices={[0]}
								onScroll={(e) => handleScroll.call(this, e, layout.height)}>
								{/* <Stars /> */}
								{auth.type===0 && <StoreHeader style={{marginVertical:0.02*vmin}} navigation={this.props.navigation} signOut={()=>{}} vmin={vmin} layout={layout} clearAuth={this.props.actions.clearAuth} dashboard={false} />}
								<TouchableNativeFeedback onPress={()=>this.props.navigation.navigate('Store')}>
									<View style={{alignSelf:'center', width: '90%', maxWidth: 700, paddingHorizontal: 5, alignItems: 'flex-start'}}>
										<Image style={{ height: 30, width: 30, transform: [{rotate: '180deg'}], tintColor: Colors.black}} resizeMode={'contain'} source={require('../../assets/arrow_white.png')} />
									</View>
								</TouchableNativeFeedback>
								<View style={{ flex: 1, zIndex: 2, backgroundColor: 'white', justifyContent: 'space-between', flexDirection: width > 1100 ? 'row' : 'column' }}>
									<View style={{ alignItems: 'center', }}>
										<Image resizeMode={'contain'} style={{ width: layout.width * 0.4, height: layout.width * 0.4 }} source={{ uri: image }} />
									</View>
									<View style={{ flex: 1, width: layout.width > 1100 ? layout.width * 0.4 : layout.width * 0.9, margin: 20 }}>
										<View><Text style={[{ }, globalStyle.h2, defaultStyles.bold]}>{title}</Text></View>
										<Rating rating={(title && parseInt(title.slice(0, 5).split('').map(function (char) {return (char.charCodeAt(0) + 1);}).join('')) % 5 + 1)} />
										{/* marginVertical: 0.05 * vmin */}
										<View><Text style={[{ color: Colors.green1, marginVertical: 0.02 * vmin }, globalStyle.h2]}>${price}</Text></View>
										{auth.type !== 0 && <Text>Approved by <Text style={[{ }, defaultStyles.bold]}>{parents || 1}</Text> {parents > 1 ? 'parents' : 'parent'}</Text>}
										{<Text style={{ color: Colors.gray0 , marginBottom: 0.02 * vmin}}>Price as of {updated ? moment(updated).format('MMM DD, YY') : moment().format('MMM DD, YY')} {"\n"}Excludes tax and shipping fees.</Text>}
										<View><Text style={{}}>{description}</Text></View>
									</View>
									<View style={{ ...width > 1100 ? { flex: 0.5 } : {}, zIndex: -1, marginHorizontal: 20, }}>
										<TouchableNativeFeedback onPress={() => { this.navigateToAmazon() }}>
											<View style={{ padding: 10, borderWidth: 2, borderColor: Colors.green1, borderRadius: 10 }}>
												<Text numberOfLines={1} style={{ textAlign: 'center', color: Colors.green1 }}>Purchase on {merchantname || 'Amazon'}</Text>
											</View>
										</TouchableNativeFeedback>
										<TouchableNativeFeedback onPress={() => {
											this.setState({
												modalVisible: true, confirmationGoal: true,
												storeKids: childIds.reduce((curr, item) => { return { ...curr, [item]: false, } }, {}),
											})
										}}>
											{auth.type === 0 && <View style={{ marginVertical: 20, padding: 10, backgroundColor: Colors.green1, borderRadius: 10 }}>
												<Text style={{ textAlign: 'center', color: 'white' }}>Drop to Child Store</Text>
											</View>}
										</TouchableNativeFeedback>
									</View>
								</View>

							</ScrollView>
						</View>
					</View>
				}}
			</Resizable>
		)
	}
}

const styles = StyleSheet.create({

});

// export default Dashboard;
export default connect(state => {
	// console.log(Object.keys(state.vacancies));
	return ({
		auth: state.auth,
		parent: state.parent,
		store: state.store,
	})
},
	(dispatch) => ({
		actions: bindActionCreators({ ...storeActions, ...authActions, ...goalsActions }, dispatch)
	})
)(Dashboard);
