
/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import { AsyncStorage, Platform } from 'react-native';

import { Linking } from 'expo';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';

import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';

import { useDispatch } from 'react-redux'

import { Buffer } from 'buffer'

import * as authActions from '../store/actions/kids/authActions';

// import { Avatar } from './Settings';

const database = firebase.database;
const storage = firebase.storage;

function save_avatar(data) {
    const { parentId, childId } = this.props.auth;

    database().ref('/users/' + parentId + '/' + childId + '/settings').update({
        avatar: data
    });
}

function getImageHash(image) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', image, true);
    xhr.responseType = 'arraybuffer'; // this will accept the response as an ArrayBuffer
    xhr.onload = function (buffer) {
        var words = new Uint32Array(buffer),
            hex = '';
        console.log('words', words)

        for (var i = 0; i < words.length; i++) {
            hex += words.get(i).toString(16);  // this will convert it to a 4byte hex string
        }
        console.log('hex -', hex);
    };
    xhr.send();
}

const uriToBlob = (uri) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.onload = function () {
            // return the blob
            console.log(xhr.response)
            resolve(xhr.response);
        };

        xhr.onerror = function () {
            // something went wrong
            reject(new Error('uriToBlob failed'));
        };

        // this helps us get a blob
        xhr.responseType = 'blob';

        xhr.open('GET', uri, true);
        xhr.send(null);

    });

}

export const _launchCamera = async ({parentId, childId, dispatch }) => {
    // const {  } = this.props.auth;
    // const dispatch = useDispatch()

    let result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Image,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 0.1
    });

    console.log(result);

    if (!result.cancelled) {
        uriToBlob(result.uri).then(blob => {
            storage().ref('children/' + childId + '.png').put(blob)
                .then((res) => {
                    return res
                })
        });
        // this.setState({ image: result.uri });
        AsyncStorage.setItem('avatar', result.uri)
        dispatch(authActions.saveAvatar(parentId, childId, 5, result.uri))
    }
};

export const _pickImage = async ({ parentId, childId, dispatch }) => {
    // const dispatch = useDispatch()

    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 0
    });

    console.log(result);
    // ImageManipulator.manipulateAsync(uri, actions, saveOptions)

    if (!result.cancelled) {
        let uri = result.uri
        console.log(result)
        if (Platform.OS === 'web') {
            const manipResult = await ImageManipulator.manipulateAsync(result.uri,
                //  [{ crop: { originX:0, originY:0, width:'100%', height:'100%' } }, ], 
                { compress: 0, format: ImageManipulator.SaveFormat.PNG })
            console.log('manipResult, ', manipResult)
            uri = manipResult.uri
        }

        uriToBlob(uri).then(blob => {
            storage().ref('children/' + childId + '.png').put(blob)
                .then((res) => {
                    return res
                })
        });
        // this.setState({ image: uri });
        AsyncStorage.setItem('avatar', uri)
        dispatch(authActions.saveAvatar(parentId, childId, 5, uri))

    }
};


