/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
    Animated,
    Dimensions,
    Image,
    ImageBackground,
    StyleSheet,
    ScrollView,
    View,
    Platform,
    TouchableOpacity,
} from 'react-native';

import TouchableNativeFeedback from '../../components/TouchableFeedback';
import Text from '../CustomText';

import Colors from '../../constants/Colors';

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const ModalContainer = ({ visible, setVisible, onClose, children }) => {
    return <Modal
        animationIn={'zoomIn'} animationOut={'zoomOut'}
        animationInTiming={500} animationOutTiming={500}
        backdropColor={'darkgrey'} backdropOpacity={0.6}
        onBackdropPress={() => onClose()}
        isVisible={visible}
        onRequestClose={() => onClose()}>
            <View style={{borderRadius: 20, backgroundColor: 'white', width: '80%', justifyContent:'center',alignItems:'center', alignSelf: 'center',maxWidth: 400, minHeight: 250}}>
                <TouchableNativeFeedback style={{position:'absolute', top:20, right:20, zIndex: 1 }} onPress={()=>onClose()}>
                    <View style={{position:'absolute', right:0, top:0}}>
                        <Image resizeMode={'center'} style={{height:12, width:12}} source={require('../../assets/Delete-X.png')} />
                    </View>
                </TouchableNativeFeedback>
                {children}
            </View>
    </Modal>
}


const styles = StyleSheet.create({

});

// export default Chequing
export default ModalContainer