
/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState } from 'react';
import { ImageBackground, KeyboardAvoidingView, StyleSheet, ScrollView, TextInput, View, Platform } from 'react-native';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { Resizable, Hoverable } from 'react-native-web-hooks';

import Icon from 'react-native-vector-icons/FontAwesome5';

import * as Analytics from 'expo-firebase-analytics';

import TouchableNativeFeedback from '../../../components/TouchableFeedback'
import FloatingLabelInput from '../../../components/FloatingLabelInput/FloatingLabelInput'
import Text from '../../../components/CustomText'
// import { ConfirmationView, } from '../components/confirmation'
import Stars from '../../../components/Stars';
import { DropdownElement } from '../../../components/Dropdown';

import { BlockWrapper } from '../../../components/newChild/BlockWrapper'
import { Button, InfoButton } from '../../../components/newChild/Buttons'

import * as authActions from '../../../store/actions/parents/authActions';
import * as kidAuthActions from '../../../store/actions/kids/authActions';

import ChequingSettings from '../../../components/settings/ChequingSettings'
import ChildSwitch from '../../../components/settings/ChildSwitch'

import Colors from '../../../constants/Colors';
import { expenseInfo, chequingInfo } from '../../../constants/info';

class ChequingScreen extends React.Component {

    static navigationOptions = ({ navigation }) => {

        return {
            // headerTitle: <Text style={{color: 'white', fontSize: 18}}>Test</Text>,
            headerTransparent: true,
            header: null,
        }
    }


    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            section: 1,
            loading: false
        };
    };



    componentDidMount() {
        const { childIds } = this.props.parent;

        if (childIds.length > 0) {
            this.setState({ section: 2 })
        }

    }

    row({ info, item }) {
        const data = info[item]
        console.log('LABEL ', info, item, data)
        return <View key={item} style={{ width: '100%', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
            <FloatingLabelInput {...data.params} containerStyle={{ width: '100%', alignItems: 'center', alignSelf: 'center' }} label={data.label}
                value={this.state[item]}
                onChangeText={text => this.setState({ [item]: data.format ? data.format(text) : text })} />
            <InfoButton text={data.text} />
        </View>
    }

    render() {
        const { layout, navigation, auth, parent, actions } = this.props;
        const { id, childIds, names } = parent;
        const name = names[childIds[id]]

                    return <BlockWrapper navigation={navigation} description={null} title={'Chequing Setup'}>
                        {layout => <>
                            <ChildSwitch name={name} changeIndex={actions.changeChildIndex} />
                            <ChequingSettings navigation={navigation} settings={{}} auth={this.props.auth} parent={parent} id={id} actions={this.props.actions} layout={layout} />
                        </>}
                    </BlockWrapper>
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 32,
        paddingHorizontal: 24,
    },
});

export default connect(state => {
    // console.log(Object.keys(state.vacancies));
    return ({
        auth: state.auth,
        parent: state.parent,
        store: state.store,
    })
},
    (dispatch) => ({
        actions: bindActionCreators({ ...authActions, ...kidAuthActions, }, dispatch)
    })
)(ChequingScreen);


