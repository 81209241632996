/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useRef, useEffect, useState } from 'react';
import {
    Animated,
    // AsyncStorage,
    Dimensions,
    Image,
    ImageBackground,
    Platform,
    StyleSheet,
    View,
    TouchableOpacity,
} from 'react-native';

import { useHover, useFocus, useActive, useDimensions, Resizable } from 'react-native-web-hooks';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import firebase from 'firebase/app';

import { database, auth, storage } from 'firebase';

import Text from '../../components/CustomText'
import TouchableNativeFeedback from '../../components/TouchableFeedback';

import Colors from '../../constants/Colors';

const Modal = Platform.OS === 'web' ? require('modal-enhanced-react-native-web').default : require('react-native-modal').default

const avatars = {
    0: require('../../assets/Avatars/icon_1.png'), 1: require('../../assets/Avatars/icon_2.png'),
    2: require('../../assets/Avatars/icon_3.png'), 3: require('../../assets/Avatars/icon_4.png'),
    4: require('../../assets/Avatars/icon_5.png'), 5: require('../../assets/Avatars/icon_6.png')
}

const Avatar = ({ isFocused, layout, avatar, style, onPress }) => {
    const vmin = 500;

    function scale() {
        return isFocused ? 1.2 : 1
    }
    function scaleImage() {
        return isFocused ? 1.4 : 1
    }

    return <TouchableNativeFeedback onPress={onPress}>
        <View style={{
            aspectRatio: 1, alignSelf: 'center', justifyContent: 'center', alignItems: 'center'
        }}>
            <View elevation={5} style={[{
                aspectRatio: 1, maxHeight: 140, maxWidth: 140,
                width: 0.2 * vmin, height: 0.2 * vmin,
                borderRadius: Platform.OS === 'web' ? '50%' : 0.15 * vmin,
                alignSelf: 'center', opacity: isFocused ? 1 : 0.5,
                transform: [{ scale: scale() }], boxShadow: '2px 2px 15px #272827',
                backgroundColor: 'white', borderWidth: isFocused ? 3 : 0,
                borderColor: '#7ec13d', justifyContent: 'center', alignItems: 'center'
            }, style]}>
                <Image resizeMode={'cover'} style={{
                    aspectRatio: 1, borderRadius: Platform.OS === 'web' ? '50%' : 80,
                    width: '110%', height: '110%'
                }} source={avatar} />
                {/* <Text style={{}}>{this.props.isFocused.toString()}</Text> */}
            </View>
        </View>
    </TouchableNativeFeedback>
}

class ChangeAvatarBlock extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoading: true,
        };
    };


    componentDidMount() {
    }

    componentWillUnmount() {
    }

    getImageHash(image) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', image, true);
        xhr.responseType = 'arraybuffer'; // this will accept the response as an ArrayBuffer
        xhr.onload = function (buffer) {
            var words = new Uint32Array(buffer),
                hex = '';
            console.log('words', words)

            for (var i = 0; i < words.length; i++) {
                hex += words.get(i).toString(16);  // this will convert it to a 4byte hex string
            }
            console.log('hex -', hex);
        };
        xhr.send();
    }


    async get_avatar() {
        if (this.state.avatar !== 5) {
            // console.log(require('../../assets/Avatars/icon_3.png'))
            return avatars[this.state.avatar]
        }
        else {

            let avatar = await AsyncStorage.getItem('avatar')
            if (!avatar) {
                avatar = await storage().ref(child_image_url).getDownloadURL()
                console.log('avatar, ', avatar)
            }
            const obj = { uri: avatar }
            return obj
        }
    }

    parseInt(value) {
        return value !== '' ? parseInt(value) : 0
    }



    defineValue(width, height, a, b, c) {
        if (width < 850 && width < height) {
            return a
        }
        else if (width < 850 && width > height) {
            return b
        }
        else {
            return c
        }
    }


    render() {
        const { onPress, avatar, layout, image } = this.props;
        return (
            <View style={{
                flexDirection: 'column', width: layout.width > 850 ? '50%' : '100%', alignItems: 'stretch',
                justifyContent: 'space-between',
            }}>
                {
                    layout.width > 850
                        ?
                        <React.Fragment>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 15 }}>
                                {[0, 1, 2].map((item, index) => {
                                    return <Avatar key={index} avatar={avatars[item]}
                                        onPress={() => onPress(item)}
                                        isFocused={avatar === item}
                                    />
                                })}
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 15 }}>
                                {[3, 4, 5].map((item, index) => {
                                return <Avatar key={index} 
                                    avatar={(item===5 && image && avatar === 5) ? { uri: image } : avatars[item]}
                                    // avatar={avatars[item]}
                                    // style={{width:vmin*0.3, height:vmin*0.3, borderWidth:1}}
                                    onPress={() => onPress(item)}
                                    // ref={(c) => { this.avatar1 = c; }}
                                    isFocused={avatar === item}
                                />
                            })}
                            </View>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 15 }}>
                                {[0, 1].map((item, index) => {
                                    return <Avatar key={index} avatar={avatars[item]}
                                        // style={{width:vmin*0.3, height:vmin*0.3, borderWidth:1}}
                                        onPress={() => onPress(item)}
                                        // ref={(c) => { this.avatar1 = c; }}
                                        isFocused={avatar === item}
                                    />
                                })}
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 15 }}>
                                {[2, 3].map((item, index) => {
                                    return <Avatar key={index} avatar={avatars[item]}
                                        // style={{width:vmin*0.3, height:vmin*0.3, borderWidth:1}}
                                        onPress={() => onPress(item)}
                                        // ref={(c) => { this.avatar1 = c; }}
                                        isFocused={avatar === item}
                                    />
                                })}
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 15 }}>
                                {[4, 5].map((item, index) => {
                                    return <Avatar key={index} 
                                    avatar={(item===5 && image && avatar === 5) ? { uri: image } : avatars[item]}
                                    // avatar={avatars[item]}
                                        // style={{width:vmin*0.3, height:vmin*0.3, borderWidth:1}}
                                        onPress={() => onPress(item)}
                                        // ref={(c) => { this.avatar1 = c; }}
                                        isFocused={avatar === item}
                                    />
                                })}
                            </View>
                        </React.Fragment>
                }
            </View>
        )
    }
}

const styles = StyleSheet.create({

});

export default ChangeAvatarBlock;