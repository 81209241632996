/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useState, useEffect } from 'react';
import {
    Animated,
    Dimensions,
    Image,
    ImageBackground,
    StyleSheet,
    ScrollView,
    View,
    Platform,
    TouchableOpacity,
} from 'react-native';

import {
    useDimensions,
    useActive,
    useFocus,
    useHover,
    useREM,
    useScaledSize,
    Hoverable,
    Resizable
} from 'react-native-web-hooks';
import Icon from 'react-native-vector-icons/FontAwesome';

import TouchableNativeFeedback from '../../components/TouchableFeedback'
import Text from '../../components/CustomText'

import globalStyle, { defaultStyles } from '../../constants/style'
import Colors from '../../constants/Colors';
import {formatNumberFloat} from '../../helpers/formatNumber';


function formatInputValue(str) {
    return (str || str === 0) ? str.toString().replace(/[^\d\.]/g, '').replace(/\./, "x").replace(/\./g, "").replace(/x/, ".") : ''
}

export class SectionRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: 0,
            data_display: 'none'
        };
    };

    returnType(item) {
        if (item.type === 'job') {
            return item.category
        }
        else if (item.type.includes('fee')) {
            return item.type.replace('savings', '').replace('purchase', '')
        }
        else if (item.type.includes('purchase')) {
            return item.name
        }
        // else if (item.type==='withdrawal') {
        //   return item.name
        // }
        else {
            return item.type
        }
    }

    renderList(data, vmin) {
        if (!data) return null
        return this.props.renderList ? this.props.renderList(data) :
            data.map((item, index) => <View key={index.toString()} style={{
                width: '100%', marginTop: 0.02 * vmin, paddingRight: 0.02 * vmin,
                flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'
            }}>
                <Text style={{ flex: 1, color: Colors.black }}>{
                    new Date((new Date(item.transaction_date)).valueOf() + new Date().getTimezoneOffset() * 60 * 1000).toLocaleDateString("en-US")
                    // new Date(item.transaction_date).toLocaleDateString("en-US")
                }</Text>
                <Text numberOfLines={2} style={{ flex: 0.9, color: Colors.black, textAlign: 'left' }}>{this.returnType(item)}</Text>
                {/* <Text style={{ flex: 1, color: '#106433', textAlign: 'left' }}>{item.type === 'job' ? item.category : item.type}</Text> */}
                {/* <Text style={{ flex: 1, color: '#106433', textAlign: 'left' }}>{item.type === 'job' ? item.category : item.type.split(' ')[item.type.split(' ').length - 1]}</Text> */}
                <View style={{ flex: 0.5, }}>
                    <Text numberOfLines={1} style={{
                        minWidth: Platform.OS === 'web' ? 'fit-content' : (item.amount ? ('$' + item.amount) : '').length * 10,
                        color: Colors.black, textAlign: 'left'
                    }}>${formatNumberFloat(item.amount)}</Text></View>
            </View>)
    }


    display(value) {
        return value === 'none' ? 'flex' : 'none'
    }

    render() {
        const { bottomBorder } = this.props;
        const titleFontSize = Platform.OS === 'web' ? '1.125em' : 18;

        return <Resizable>
            {layout => {
                const vmin = Math.min(...[layout.width, layout.height])
                return <View style={{ width: '100%', paddingVertical: 10, }}>
                    <TouchableNativeFeedback onPress={() => { this.setState({ data_display: this.display(this.state.data_display) }) }}>
                        <View style={{}}>
                            <View style={{
                                flex: 1, marginTop: 0.02 * vmin, marginBottom: 5, alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: '5%',
                            }}>
                                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                    <Text style={[{ flex: 1, width: '70%', color: Colors.black,}, defaultStyles.bold]}>{this.props.title}</Text>
                                    <Text style={[{ textAlign: 'center', }, defaultStyles.bold]}>${formatNumberFloat(this.props.value)}</Text>

                                </View>
                                <View style={{ flex: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                    <Text style={{ flex: 1, marginBottom: 0.01 * vmin, color: Colors.black, }}>{this.props.transactions} Transactions</Text>
                                    <Text style={[{ marginRight: 15, textAlign: 'right', color: Colors.green1, }, globalStyle.h1]}>
                                        {this.state.data_display === 'none' ? '+' : '-'}
                                    </Text>
                                </View>
                            </View>

                        </View>
                    </TouchableNativeFeedback>
                    <View style={{ display: this.state.data_display, marginTop: 15, paddingBottom: 0.02 * vmin, marginLeft: layout.width > 850 ? 0.1 * vmin : 0.07 * vmin, }}>
                        {this.renderList(this.props.data, vmin)}
                    </View>
                    {bottomBorder && <View style={{ width: '90%', height: 0.5, opacity: 0.2, alignSelf: 'center', backgroundColor: Colors.black }} />}
                </View>
            }}
        </Resizable>
    }
}

export class SectionTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: 0,
            table_display: 'none'
        };
    };

    display(value) {
        return value === 'none' ? 'flex' : 'none'
    }

    render() {
        const shadowStyle = Platform.OS === "web" ? { boxShadow: '0 0 15px 15px rgba(76, 76, 76, 0.1)', } : { shadowColor: "#272827", shadowOffset: { width: 2, height: 4.5, }, shadowOpacity: 0.41, shadowRadius: 5.11, }
        return <Resizable>
            {layout => {
                const titleFontSize = Platform.OS === 'web' ? (layout.width > 850 ? '1.75em' : '1.375em') : 24;
                const vmin = Math.min(...[layout.width, layout.height])
                return <View style={[{
                    elevation: 4, backgroundColor: 'white',
                    // borderWidth: 1,
                    // shadowColor: "#000", shadowOffset: { width: 0, height: 2, }, shadowOpacity: 0.25, shadowRadius: 3.84,
                    width: '90%', minWidth: 300, maxWidth: 800, justifyContent: 'center', alignSelf: 'center',
                     borderRadius: 25, marginTop: 0.05 * vmin
                }, defaultStyles.shadow]}>
                    <View elevation={7} style={[{
                        backgroundColor: 'white', width: '100%', borderRadius: 25
                    }, defaultStyles.shadow]}>
                        <View style={{
                            marginBottom: 10, marginTop: 0.03 * vmin,
                            marginHorizontal: 0.05 * vmin, flex: 1, alignItems: "center", flexDirection: 'row', justifyContent: 'space-between'
                        }}>
                            <Text style={[{ color: Colors.black,}, defaultStyles.bold,]}>{this.props.title}</Text>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={[{ color: Colors.black,}, defaultStyles.bold,]}>${formatNumberFloat(this.props.value)}</Text>
                            </View>
                        </View>
                        <Text style={{ flex: 1, marginLeft: 0.05 * vmin, marginBottom: 0.02 * vmin, color: Colors.black, }}>{this.props.transactions} Transactions</Text>
                    </View>
                    {this.props.children}
                </View>
            }}
        </Resizable>
    }
}